import React from "react";
import {Helmet} from "react-helmet";

export default function QuestionsAboutScienceEducation() {
    return (
        <div>
            <Helmet>
                <title>对科学教育的困惑 Questions About Science Education</title>
            </Helmet>
            <h6 className="App-essay-title">对科学教育的困惑</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 九月</b>
                <br/><br/>
                今天的科学教育，通过把延绵上千年的科学知识以工程结论或科学定理的方式高度浓缩地交给下一代。这种方法很不完美，但也是迫不得已，因为科学技术的失传实在是太容易了——这与现代人对科技必然进步的直觉完全相反。
                <br/><br/>
                事实上，天灾人祸、战争瘟疫都可以轻而易举的消灭科学技术。每一代人都是一张白纸，甚至在和平时期，如果知识不加以有效的传承，科学成果也会失传。埃及人4000多年前建造了金字塔，现代人也没想明白在工具那么简陋的文明初期，这些巨石是如何垒起来的（当然如果你相信埃及文明是外星人创造的，那你就没有疑惑了）。罗马人利用混凝土建造了室内跨度超过43米的万神庙，一千多年来没有室内结构突破过这个跨度；当然，拱券技术适用于大量人力浇筑的巨型公民设施，在罗马帝国被攻破后这种需求也不复存在了，所以拱券并非简单的技术失传，但也从侧面应证人们忘掉一门技术有多简单。
                <br/><br/>
                为了避免重要科技成果的失传，就诞生了现代知识的传承体系——以高度压缩的科学成果作为教学的主要内容，而忽略科学发现的过程。人类过去500年中不断发现的各种重要科学定理，还在变得越来越多，但用来解释科学方法的时间变得越来越少。而这种传承方式有极大的隐患，当科学成果固化成“拜科学教”的肌肉记忆时，科学精神中最重要的那个部分——理性怀疑，就被丢到九霄云外了。
                <br/><br/>
                牛顿和莱布尼茨创造了现代微积分的思想，但是在牛顿的著作中关于极限的定义漏洞百出，当时的宗教人士贝克莱主教嘲笑那个无穷小变量Δ是个鬼魂，想有就有，想没就没，非常随意，怎么方便怎么用。如果让牛顿去考今天的大学微积分，他可能连其中的某些定理都没见过，甚至看完后也不一定赞同，更不用说回答对了。科学巨匠无法答对基本的微积分知识，这听起来可笑吗？不可笑，因为这些考试的目的是让人类的科学成果不要失传，所以培养了大量人体储存器，来掌握过去数代科学巨匠呕心沥血的研究成果。科学家的真正价值，是对一个重要问题进行反复思辨，然后发现其中的理解方法或计算手段，而不在于对某个知识内容烂熟于心。爱因斯坦被美国记者随口问引力常数g是多少，爱因斯坦说：“我不知道”，就是个绝佳的例子。爱因斯坦没在耍大牌，这个数字确实对他不重要，他关心的重点是关于质能转换、引力波等如何突破物理想象力边界的思想革命，简单地背诵一个常量对他没有太大价值。
                <br/><br/>
                这也提出了一个直接的问题，现代的科学教育是否丢失了传承科学精神的关键要素——理性怀疑，那些无数机械式的背诵和训练让科学教育成了智力的“伪竞技场”。人的大脑虽然是复杂的自然产物，但是擅长理解的对象都是简单的抽象概念，比如点、直线、完美的圆、基础函数、线性问题等等。所以，你会在科学中反复发现一种趋势——用已知模拟未知，用简单描述复杂，用线性逼近非线性。如果人类大脑能进化到让现代人徒手计算自然曲线、描述复杂函数、徒手破解各类非标准的微分方程，那么科学的发展也不会是今天这个样子了。我们的大脑塑造了我们知识积累的方式和方向。这里面蕴含了一种倾向，就是我们的肉体也决定了我们所塑造的科学，科学不是绝对公正的宇宙真理，而是像文学一样，充满了人的特征。
                <br/><br/>
                这就隐含了一个大胆的方案——是否科学教育应该和语言教育类似，以受教者感受到科学的合理性、并适应其文法逻辑作为目标，而不是简单地作为比拼谁算得更快的"伪智力竞赛"。如果每一代人都是一张白纸，那么每一代人都在被上一代人的教育方式塑造，现代人对科学的喜恶爱憎都极大依赖上一代人允许下一代人接触这类知识的途径和方法。所以，避免以压缩包和竞赛式地心态传承科学知识，可能会是最大化传递科学思想的办法。
                <br/><br/>
                可以提出一个简单初步的观点来增进科学教育的效果——把“放慢速度”和“保持自信”作为科学教育的纲领。例如，在学习数理基础的过程中，学生应该被鼓励“解不出来也没事”，因为科学大师花了毕生精力可能才搞明白了一半，强迫普通人在40分钟一堂课的时间完全掌握是有悖常识的。于是信心就被建立了，大家下次会有勇气通过一种新的思路进行尝试，这样过大的心理负担和对智力的怀疑就被扔掉了。尤为重要的是，在好奇心和不断尝试的驱动下，必然有人会重走牛顿等巨匠走过的小径，这时再去看柯西准则就能连带着科学思想一路传承下去。
                <br/><br/>
                但这个方法的弊端很明显，人类的科学成果太多了，让一个智力普通的人重走科学革命的蜿蜒小径再次发现科学定理，所花费的时间无疑是个无底洞。但好在科学成果的答案已经有了，鼓励在更长的时间里允许大家试错，绝不是一件坏事。因为对基本问题的反复拷问可以增进科学传承的质量，甚至引发更基本的科学革命。毕竟人类在科学世界中，从未学会过任何事情，我们只是费力地理解并适应大自然而已，那为何不放缓节奏，充分适应呢？


            </p>

            <br/>

            <h6 className="App-essay-title">Questions About Science Education</h6>
            <p className="App-essay-article">
                <br/>
                <b>Sep 2022</b>
                <br/><br/>


            </p>
        </div>)
}
