import React from "react";
import {Helmet} from "react-helmet";

export default function Brave() {
    return (
        <div>
            <Helmet>
                <title>理科比文科高贵？ Is STEM Higher Than Liberal Arts?</title>
            </Helmet>
            <h6 className="App-essay-title">理科比文科高贵？</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 八月</b>
                <br/><br/>
                十年前我在清华大学建筑系读本科，那时候建筑系是清华皇冠上的明珠，加上我国大基建的背景，清华建筑可谓呼风唤雨。
                <br/><br/>
                但孙猴子也说：皇帝轮流做，明年到我家！时过境迁，人一定要理解，没有什么是永恒存在的，花无百日红。
                <br/><br/>
                我喜欢设计，年轻无知的我认为酷的设计比舒服更高贵，但等我给自己收拾屋子的时候，我才发现自己的愚蠢。舒服比酷高贵得多！现实会教训我，视觉逻辑和现实感觉，你看看吧，孰高孰低？美是一种经验理性。
                <br/><br/>
                我也喜欢力学，虽然力学中有些经验公式，并非严谨的科学推理，但这些经验公式蕴含的实用性，是科学精神的善，它被用来帮助我们躲避大自然的追杀。
                <br/><br/>
                不过很可惜，清华大学建筑系的力学教育就是一场灾难。我无法理解为什么两个如此相辅相成的学科内容，能被如此糟糕地组织在一起。而我从不认为，这是学生们的错。
                <br/><br/>
                因为这套关于文理的撕裂，早就存在于系统的内心深处。
                <br/><br/>
                美和精确，逻辑和感受——这些东西竟然是对立不兼容的，你敢相信？真是可笑，这些东西是不可分割的。
                <br/><br/>
                理科高于文科的声音是主流给出的。而聪明的你，估计早就发现，这个世界的“主流声音”大多数时候都是错的。共识存在的唯一目的，就是等着被少数人掌握的真理颠覆。
                <br/><br/>
                让我们先看看，主流声音如何定义一个典型的“聪明”理科人：算得快、踏实、体面内敛。
                <br/><br/>
                但很抱歉，这简直是对“平庸”二字的定义。
                <br/><br/>
                如果你仔细看看那些做出颠覆性发现和成就的科学家和企业家——达·芬奇、富兰克林、爱因斯坦、薛定谔、香农、费曼、沃森和克里克、乔布斯、马斯克……他们经常有的特点是（虽然不是每个人都这样）：读得慢--常有阅读障碍、不体面--内向或狂妄、藐视权威。
                <br/><br/>
                所以我想对“聪明”进行一个非典型的、不同于主流声音的描述：
                <br/><br/>
                <ol>
                    <li>能否进行清晰严密的逻辑推理和归纳思考？</li>
                    <li>遭遇困难，能否保持意志坚强？</li>
                    <li>人生艰难，能否保持幽默？</li>
                </ol>
                这三件事里最能体现聪明才智的，是第三点。
                <br/><br/>
                很意外吧，因为一个人只有做到了1和2，才有资格做到3。在逆境中保持幽默是智慧的集大成者，何况幽默还给智慧注入了勇气。
                <br/><br/>
                让我们回到主流对文理之争的定义，如果你觉得理科比文科高贵？让我们再看几个例子：
                <br/><br/>
                <ul>
                    <li>傅里叶在发现自己数学天赋之前，是一流的拉丁文研究者。</li>
                    <br/>
                    <li>费马的正经职业是律师，数学是他业余爱好，关于代数方程的成就，在费马眼里远比不上他作为律师重要（而他甚至只是个二流律师）。</li>
                    <br/>
                    <li>庞加莱写论文只用写一遍，因为他文笔一流的程度，甚至无需任何修改。</li>
                    <br/>
                    <li>富兰克林关于电学的研究，首先是一本优美流畅、普通人都能读懂的科普作品，然后他很自然地在这本“科普作品”里定义了电学的最基本概念，比如电势和电流。</li>
                    <br/>
                    <li>薛定谔的《生命是什么》，首先是一部一流的哲学作品，然后才是关于生命和物理学的探讨。</li>
                    <br/>
                </ul>
                文科在深度和广度的发散性上常高于理科，而理科比起文科的最大优势是精确性。
                <br/><br/>
                但这条分界线从来都不清晰。
                <br/><br/>
                出于对理工自我感觉良好的极大疑惑，我也提出如下观点：
                <br/><br/>
                <ol>
                    <li>第一流的聪明人，TA们属于文史哲。</li>
                    <li>第二流的聪明人，因为缺乏顶级的直觉和想象力，只能研究数理化。</li>
                    <li>如果比第一流更聪明一点儿，则什么都会研究。</li>
                </ol>
                这些观点是出于对主流观点（理高于文）的极大反感，所必须进行的反驳，如有不适，请聪明的人别往心里去，或许你还会发现其中的合理之处。类似地，如果主流声音认为“文高于理”，我也会如是进行反驳。
                <br/><br/>
                天底下不会有任何一个理智的聪明人，会故意把有用的智慧强行放进不同颜色的桶里，这个行为本身就不聪明。
                <br/><br/>
                在《笑傲江湖》里，剑宗强调招式（实用），气宗强调内力（主义），两宗同属华山派，但因为理念不同而势不两立。
                <br/><br/>
                金庸用“剑气之争”讽刺了真实世界里的某些裂痕，剑气之争也是笑傲江湖里最意义深远的比喻。前几天，我在知乎上读到了一句评论：
                <br/><br/>
                <i style={{color: "grey"}}>剑气之争源自《葵花宝典》，但是林远图和东方不败都练成了《葵花宝典》，谁也没认为其中有什么分歧，只有岳肃和蔡子峰两人读出了分歧，这说明分歧本就在他俩的心中。</i>
                <br/><br/>
                在别人的智慧之上，我也简单总结一句：<b>文理本没有分歧，和任何分歧一样，分歧本就在人们心中。</b>

            </p>

            <br/>

            <h6 className="App-essay-title">Is STEM Higher Than Liberal Arts?</h6>
            <p className="App-essay-article">
                <br/>
                <b>Aug 2024</b>
                <br/><br/>

            </p>
        </div>)
}
