import React from "react";
import {Helmet} from "react-helmet";

export default function DayOne() {
    return (
        <div>
            <Helmet>
                <title>第一天 Day One</title>
            </Helmet>
            <h6 className="App-essay-title">第一天</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 五月</b>
                <br/><br/>
                如果常看财经频道，不会对北美电商亚马逊Amazon的创始人，杰夫·贝索斯（Jeff Bezos）的<b>第一天原则</b>（Day One Principle）感到陌生。
                <br/><br/>
                从字面上理解，“第一天原则”是说一家公司，无论规模变成多大，每天都应该把公司当做存在的“第一天”。这个口号最早可以追溯到贝索斯在1997年致股东们的一封信。[1]
                <br/><br/>
                <i style={{color: 'grey'}}>“This is Day 1 for the Internet,” Bezos wrote, “and, if we execute well, for Amazon.com."</i>
                <br/><br/>
                <i style={{color: 'grey'}}>“这是互联网的第1天，”贝索斯写道，“同时，如果我们执行得好，也是 Amazon.com
                    的第一天。”</i>
                <br/><br/>
                2024年，Lex Fridman采访贝索斯 [2]，引用了作为“演讲艺术家”的贝索斯，在之前一次活动中的反证法诠释：我说下<b>第二天（Day Two）</b>意味着什么？如果公司遵循了<b>第二天原则</b>，会变成什么样？（言下之意，Day One是什么大家自己领悟）
                <br/><br/>
                <h6><b>Lex:</b></h6>
                <i style={{color: 'grey'}}>
                    "You (Bezos) said 'Day 2 is stasis. Followed by irrelevance. Followed by excruciating, painful decline. Followed by death. And that is why it is always Day One!'"
                </i>
                <br/><br/>
                <i style={{color: 'grey'}}>
                    "你（贝索斯）之前说过：‘第二天就意味着停滞。紧接着（公司变得）无关紧要，紧接着是极度痛苦的衰退，紧接着就是死亡。这就是为什么永远要第一天！’"
                </i>
                <br/><br/>
                <h6><b>Bezos:</b></h6>
                <i style={{color: 'grey'}}>
                    "It's a very simple, and, I think age-old idea about renewal and rebirth, and like every day is day
                    one. Every day, you are gonna decide what you're gonna do, and you are not trapped by what you were
                    or who you were or any self-consistency; self-consistency can even be a trap. So day one thinking is
                    kind of we start fresh every day, and we get to make new decisions every day about invention, about
                    customers, about how we're going to operate. Even as deeply as what our principles are, we can go
                    back to that; turns out we don't change those very often, but we change them occasionally."
                </i>
                <br/><br/>
                <i style={{color: 'grey'}}>
                    "这是一个非常简洁，也很古老的关于更新和重生的概念，就像每一天都是第一天。每一天，你都要决定自己要做什么，你不会被你曾经是什么、你过去是谁、或任何“自我一致”所束缚，甚至连“自我一致”都可能是一个陷阱。所以‘第一天思维’意味着我们每天都重新开始，我们每天都可以就发明、客户、我们的运营方式做出新的决定。甚至连我们的原则是什么这种深度的问题，我们也可以重新回头修改；虽然我们并不经常改变原则，但我们偶尔也会改变它们。"
                </i>
                <br/><br/>
                如果只把这个信条当做商业原则看，里面的内涵还可以延伸为：
                <br/><br/>
                <ol>
                    <li>放低姿态，用第一天的眼光考虑客户需求</li>
                    <li>不要自我束缚，不要为了自我一致而裹足不前</li>
                    <li>接受当前环境的变化，永远在路上</li>
                    <li>……</li>
                </ol>
                <br/>
                一个优美的核心原则可以引申出无数种解读，第一天原则就是这种优美的原则。如果你理解得不同，甚至和提出者贝索斯的解释不完全一致，那也很正常。
                <br/><br/>
                You got the idea. 因为从现实角度看，Day One也永远是对的：今天，永远是未来的第一天。
                <br/><br/>
                Day One的真正含义不只是商业，这句话不只对公司成立。第一天原则，对工作、领导和生活，都有用。
                <br/><br/>
                美剧Billions里Chuck和对手说：“一个从不自我怀疑的人不配手握重权。”Chuck说这句话的背景，是对着一个准备好随时按动核按钮、自负到有进攻性现实主义的“患者”讲的，无法自我审视的自信很可怕。这个隐含的论断适用于那些用“绝对自信”来武装自己的任何领导。不进行自我审视的领袖根本无法有作为地领导。
                <br/><br/>
                自信很有价值，但领袖的自我怀疑不能也不应该停止，这份内心煎熬是别人追随领袖的全部意义和责任。英国首相温斯顿·丘吉尔，也曾饱受抑郁症的折磨，但依然坚强地领导英国人强硬地抗击法西斯，然后获得胜利。自信和自我审视并不相互排斥。
                <br/><br/>
                如果从这句话中你看到了家庭、学业、工作、人生和信仰，那也完全没问题。我们每个人都在寻找我们需要的东西，过往的成就和失败，都应该和今天这个全新的自我划清界限。斯嘉丽在《飘》的最后一句说：After all, tomorrow is another day. 毕竟，明天又是新的一天。
                <br/><br/>
                地球自转给了我们一个重置生理周期的按钮，也应当利用它来重置我们的心理周期。太阳照常升起，每天睁眼都是Day One。
                <br/><br/>
                今天永远是剩余人生的第一天。

                <br/><br/><br/>
                <hr/>

                <b>Reference</b>
                <br/>
                <b>1. </b>Day One Principle: <a href="https://aws.amazon.com/executive-insights/content/how-amazon-defines-and-operationalizes-a-day-1-culture/" target="_blank" rel="noopener noreferrer">https://aws.amazon.com/executive-insights/content/how-amazon-defines-and-operationalizes-a-day-1-culture/</a>
                <br/>
                <b>2. </b>Lex Interviews Jeff Bezos: <a href="https://www.youtube.com/watch?v=DcWqzZ3I2cY" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=DcWqzZ3I2cY</a>
                <br/>

            </p>

            <br/>

            <h6 className="App-essay-title">Day One</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2024</b>
                <br/><br/>

            </p>
        </div>)
}
