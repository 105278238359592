import React from "react";
import {Helmet} from "react-helmet";

export default function TheValueOfExpression() {
    return (
        <div>
            <Helmet>
                <title>表达的价值 The Value Of Expression</title>
            </Helmet>
            <h6 className="App-essay-title">表达的价值</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 八月</b>
                <br/><br/>
                有些人喜欢写宣言，比如那个非常出名且影响过绝大多数人口的德国哲学家。此外还有很多科学家，无论他们的学说有没有宣言二字，事实上都扮演着宣言的角色。哥白尼的《日心说》宣布教会的天文模型有问题，牛顿的《自然哲学的数学原理》宣布天上的星星和地上的人都服从这个规律，哈维的《心血运行论》宣布了心脏与血液循环的秘密，达尔文的《物种起源》宣布人并不比猴子高贵，还要包括明朝宋应星的《天工开物》，光明正大地宣布在明朝中国科举之外的大千世界也非常重要且有趣。
                <br/><br/>
                启蒙运动的学者们也喜欢写宣言，但也不会使用宣言二字。他们喜欢用对关键问题的陈述来掩盖其作为宣言的实质，比如洛克的《政府论》、孟德斯鸠《论法的精神》、卢梭的《社会契约论》、亚当·斯密的《国富论》，当然还有狄德罗的《百科全书》——他甚至重新论述了世间所有事物，里面每个词条都像一部微型的理性主义宣言。
                <br/><br/>
                从旧大陆来到新大陆，梭罗的《瓦尔登湖》是他选择与嘈杂割席的宣言，后世怎么解读和利用与他无关，但他的作品永远可以成为个人感受的心灵港湾。很多宣言不会自称为宣言，不只因为这些论述本身就非常自圆其说、无需复议，更因为很多时候作者并不想强调自己表达的内容可能相当异端。例如哥白尼发表《日心说》就心有戚戚，达尔文因为物种学说和个人信仰的冲突也有激烈的内心交战。不过，当一群人聚集在一起，他们更容易不在乎被当做异端。这群人无论是出于革命诉求还是表达诉求，也常常会产生一篇宣言，而且往往直接就叫“宣言”，所以许多宣言经常不只有一个作者。
                <br/><br/>
                1776年7月4日，在美国费城召开的第二次大陆会议中，以五州领袖：托马斯·杰斐逊、本杰明·富兰克林、约翰·亚当斯、罗伯特·李文斯顿、罗杰·谢尔曼为核心，代表十三州通过发表了《独立宣言》。这篇宣言当时的价值是在思想上武装了北美十三州的殖民地群众，促成在了政治上推翻英帝国的统治。而今天的价值可能是大家对宣言的第一句话很熟悉：
                <br/><br/>
                <i style={{ color: 'grey'}}>“我们认为这些真理是不言而喻的：人人生而平等，造物者赋予他们若干不可剥夺的权利，其中包括生命权、自由权和追求幸福的权利。”</i>
                <br/><br/>
                然而《独立宣言》作为政治文件长期被神化，对比现实常会被讽刺为一部伟大的“文学作品”。但现实的挑战只能在现实中解决，不能因此否定宣言中鼓舞人心的力量。
                <br/><br/>
                表达的诉求并不限定于哲学科学、政治经济，对表达的诉求可以来自四面八方和所有人。回到旧大陆，公元675年的中国江西南昌，王勃在宴会席上从高朋满座谈到宇宙无穷，唐风磅礴，扑面而来。《滕王阁序》也是一篇宣言，他把一次本注定要淹没在历史细节中的尘埃变成了千年故事。时光再往前拨，公元353年的浙江绍兴，东晋的一帮官宦政要在那个动荡年代划拳行令，感叹时光易逝、苦乐无常，右将军王羲之微醺后的《兰亭序》把字写得过于行云流水，甚至遮盖了兰亭序的文采，但“后之览者，亦将有感于斯文！”
                <br/><br/>
                当然表达的群体也少不了建筑师，独立宣言的主要起草人、第三任美国总统托马斯·杰斐逊也是建筑师。弗吉尼亚大学校园是他设计的，百年后的清华大学在以大礼堂为中心的老校区内，几乎复刻了弗吉尼亚大学的规划。这么看无论是文字还是建筑，任何高质量的表达都可以穿越时空。
                <br/><br/>
                类似的，古罗马建筑师维特鲁威的《建筑十书》宣布了好的建筑需要符合“坚固、实用、美观”三要素，除了建造工具，书中对军事器械的描绘充满了实用主义的激情。一千多年后，达芬奇忍不住要画一张完美比例的人物裸体，并称之为维特鲁威人，以示文艺复兴巨匠对罗马经典的跨时空膜拜。而后，帕拉迪奥的《建筑四书》宣布了文艺复兴的美学成就可与古典时代鼎足而立，甚至更高。而后二十世纪初，柯布西耶的《走向新建筑》宣布了钢筋混凝了带来的创作自由——"住宅是居住的机器，横向长窗、底层架空、屋顶花园、自由空间、自由立面"——正式把大众从思想上带入了一个全新的"形式追随功能"的现代主义。但在半个世纪功能主义的大旗下，建筑变得冰冷疏离。于是，六十年代文丘里《建筑的复杂性与矛盾性》又给后现代符号化、奇妙乖张的欲望和千奇百怪的表达打气，让各种趣味都能顺理成章地增砖添瓦。
                <br/><br/>
                当然，一个时期的表达不可能只有一个声音，但如同暗流涌动的建筑革新会被某一篇奇妙的宣言点燃，很多自由和常识都源自戳穿了那层窗户纸，在历史中锚定了里程碑，让激情从沉默的螺旋中被解放，让谨小慎微变得光明正大，让迂腐陈旧变得合理高效，让技术变革作为社会进步的原动力再次驱动现实的建造，然后给予每个人重新组织这个五彩斑斓的世界如何运作的权利。
                <br/><br/>
                近几十年，还有一批人在表达的竞技场中异军突起，黑客。这里的黑客是指精通电脑技术的hacker，不是cracker——那是以破坏、窃取为目的骇客。Aaron Swartz——作为互联网开放分享精神的一面旗帜——认为人类所有的知识都应该公平开放给所有人，尤其是平等地被大众获取。2008年，他在《开放访问游击队宣言》（Guerilla Open Access Manifesto）里质问学术出版公司的专利和版权，把人类共同创造的知识财富中饱私囊，而让大众无法获取。他还身体力行地引发了一场又一场开放共享知识的运动，甚至成功地反击了美国国会的反线上盗版提案。重读《开放访问游击队宣言》的第一段依然掷地有声：
                <br/><br/>
                <i style={{ color: 'grey' }}>“信息就是力量。但如同所有力量，总有一些人想把这些力量据为己有。这个世界上过去数个世纪中以书籍和期刊论文发表的全部科学和文化财产，正在被加速数字化，然后又被一小撮私有企业关在门里。想要阅读一些最知名的科学论文吗？那你得先给Reed Elsevier之类的出版商寄一大笔钱。”</i>
                <br/><br/>
                在那场反对国会并支持开放知识信息的运动中，维基百科等一众开放知识文库关闭了服务，在屏幕上打出“试想一个没有维基百科的世界”来应援对开放信息的支持。《开放访问游击队宣言》只是一篇不长的文字，但它所引发的关闭自由访问信息的权限而引起的每个普通人的反思更振聋发聩。大家开始重新思考自己随处可得的知识信息是不是真那么理所应当。
                <br/><br/>
                所有关于表达的意义正在于此：不是所有的常识都正确无误，也不是所有现实都理所应当，也没有任何观点是绝对真理。表达的诉求是一种基本需求，而高质量的表达经常有宣言式的价值，会引发重要的社会进步。
                <br/><br/>
                但无论表达的成果是否能被称作宣言，也无论形式上是文字、图形、建筑、影像、实物、还是行为，这些价值都常能给人以复杂的共鸣：勇气、安慰、快乐、惊愕、恐惧、反思、智慧、力量……不一而足。很多因素都会直接影响表达的质量，例如近百年来激变的媒介让表达变得更容易也更混乱。但这些都无法遏制表达的诉求，更无法撼动表达的意义。

            </p>

            <br/>

            <h6 className="App-essay-title">The Value Of Expression</h6>
            <p className="App-essay-article">
                <br/>
                <b>Aug 2022</b>
                <br/><br/>


            </p>
        </div>)
}
