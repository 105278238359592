import React from "react";
import {Helmet} from "react-helmet";

export default function Peril() {
    return (
        <div>
            <Helmet>
                <title>追逐完美的危险 The Peril of Pursuing Perfection</title>
            </Helmet>
            <h6 className="App-essay-title">追逐完美的危险</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 六月</b>
                <br/><br/>
                追逐全面，会抛弃看似微不足道的小技巧，尽管这些小技巧叠加起来能撬动地球。
                <br/><br/>
                追逐全部，会放弃小部分，进而放弃很多部分，直到放弃全部。
                <br/><br/>
                追逐完美，会粉碎每一点小进步，进而放弃能组成一切事情的大进步，直到绝不可能完成任何事情。
                <br/><br/>
                追逐完美不允许错误发生，禁止尝试任何新奇的事物和方法，也绝不容许孕育新事物的离奇航道在第一秒出现的那刻就及时扬帆出海。
                <br/><br/>
                完美是均衡的，死气沉沉；不完美是向前的，充满活力。做个肤浅的实用论者。

            </p>

            <br/>

            <h6 className="App-essay-title">The Peril of Pursuing Perfection</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jun 2023</b>
                <br/><br/>

            </p>
        </div>)
}
