import React from "react";
import {Helmet} from "react-helmet";

export default function DayOne() {
    return (
        <div>
            <Helmet>
                <title>以你自己的方式 In Your Own Way</title>
            </Helmet>
            <h6 className="App-essay-title">以你自己的方式</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 六月</b>
                <br/><br/>
                有些人喜欢坐着思考，有些人喜欢躺着，还有些人喜欢把脚翘在天上、头枕在地上思考。你我有可能是最后一类。
                <br/><br/>
                有人喜欢数学，有人喜欢文学，有人喜欢数学和文学，有人既不喜欢数学也不喜欢文学。
                <br/><br/>
                有人喜欢正着读书，有人喜欢先看结论，有人喜欢随便翻翻、就看自己关心的那几页。
                <br/><br/>
                上学的时候，有些人还喜欢倒着做题，先做最后一道大题，时间差不多了再从头写起。事实证明，这个习惯不一定影响成绩。
                <br/><br/>
                人可以极度认可方法，人也可以极度反对方法。因为高速路有两个隐喻：可能是捷径；也可能是禁锢——因为你再也无法去离经叛道的小路上慢慢欣赏沿途风景了。
                <br/><br/>
                我们不能完全不守规矩，但我们也绝不能那么守规矩。
                <br/><br/>
                两周前，我在旧金山的数据峰会上远远看到黄仁勋。老黄身穿皮衣，Databricks的CEO Ali
                Ghodsi问老黄：你有什么建议给年轻的CEO吗？老黄思忖片刻，说：Just Don't Build GPUs!（别再造GPU了！）
                <br/><br/>
                放心吧，年轻人不会听你这些建议的。他们要么没听到，要么就是听了之后，更想做你让他们别做的事了。老黄确实充满了个人魅力，大家都喜欢他。而很多人是因为他成功，才喜欢他。
                <br/><br/>
                你可能说黄仁勋是大佬，他有资格我行我素，也配拥有强烈的个人风格。年轻人多吸取人家的经验教训就好。
                <br/><br/>
                但这种B.S.很难让人同意。
                <br/><br/>
                你和我不都是人吗？正常人就不配拥有特殊的个人风格？正常人就不能对大佬的建议充耳不闻？正常人就不能把脚翘在天上，头枕在地上思考问题？
                <br/><br/>
                如果有人说这是装模作样，然后我们还果然放弃了这些风格，那说明这些东西本不属于我们。该好好问问自己，什么才是我们真心认可的方式。
                <br/><br/>
                人固有的天性无法自控，那些习惯会一遍又一遍重新回到身上。而这些习惯只有在我们真心认同、并有能力支配这些行为时，人的天性才最蓬勃有力。
                <br/><br/>
                哪怕这些习惯是练倒立的蛤蟆功和逆运真气的九阴真经，只要这些习惯对你胃口，不妨就这么干吧。对胃口的东西常常相当有效，用自己的方式行事，比什么建议都高效有力。
                <br/><br/>
                Homebrew的开发者Max Howell没通过谷歌的白板面试，因为他没能、也拒绝完成翻转一个二叉树的算法题（而谷歌估计90%的程序员都用过Max Howell开发的工具）。一场重压之下，最好的程序员也会心生强烈的逆反。
                <br/><br/>
                有些人批评这位黑客太傲慢，拒绝配合，但我认为更深层次的原因是这样：人，只有用自己舒服并有效的方式，才能得出令自己满意的结果，进一步，才有可能造福社会。
                <br/><br/>
                欧洲的文艺复兴也一样，如果上帝的概念不能把人从黑死病的阴影里拯救出来，那么俗世中的人凭什么还坚信教会能拯救万物？文艺复兴时期的人突然认识到，应该先用自己舒服的方式活着，然后才有可能去谈论“神有什么用”之类的问题。
                <br/><br/>
                心理学家卡尔·荣格（Carl Jung），会把自己关起来，与世隔绝地连续写作数周，然后一年重复上几次：这是他进行深度研究的独特方式。他用这种自己舒服的研究方法成就了荣格心理学派。
                <br/><br/>
                人是自然界中被驯化程度最高的生物。因为我们不仅相互驯化，我们还会自我驯化：要延迟满足，要自律，还要时时刻刻的自我控制。
                <br/><br/>
                自然界中的有些动物永远无法被驯化，并不是因为它们多么宁死不屈，而是这些被挫败了锐气的动物，竟然出现了一些奇特的抗争方式——它们拒绝在人类的注视下进行交配。于是，这些自由基因里带有的远古野性宁愿选择自我消灭，也拒绝配合繁殖一种被圈养的命运。
                <br/><br/>
                现在的年轻人极大程度地做到了这件事。他们不社交、躺平、拒绝进取、甚至拒绝交配，这是大自然的内在编码。有声音说这是后工业时代的异化，但异化是对现代社会的过度批判：因为文明，可能就是历史上最规模浩大、并持续了几十个世纪的一场异化。
                <br/><br/>
                但事实上，人也充满了野性，人也不会在强迫下进行繁殖，在强迫下进行繁殖不仅被定义为违反道德，还违法了任何时代的文明律法。而在没有强迫行为存在的时候，大多数人又会相当享受繁殖行为。请问这该如何解释呢？
                <br/><br/>
                环境可以驯化动物的行为，环境甚至可以强迫它们服从，但环境永远无法强迫它们繁衍。
                <br/><br/>
                甚至连人也一样。
                <br/><br/>
                TA们拒绝在别人的观察下生产，也拒绝在别人的强迫下再生产（reproduce, 繁殖）。
                <br/><br/>
                那些保存在双螺旋结构里的野性因子，注定要用自己的方式纵横驰骋，哪怕代价高昂。

            </p>

            <br/>

            <h6 className="App-essay-title">In Your Own Way</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jun 2024</b>
                <br/><br/>

            </p>
        </div>)
}
