import React from "react";
import {Helmet} from "react-helmet";

export default function Imperialism() {
    return (
        <div>
            <Helmet>
                <title>历史地理的读书笔记 Reading Notes on Historical Geography</title>
            </Helmet>
            <h6 className="App-essay-title">历史地理的读书笔记</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 三月</b>
                <br/><br/>


            </p>

            <br/>

            <h6 className="App-essay-title">Reading Notes on Historical Geography</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2024</b>
                <br/><br/>

            </p>
        </div>)
}
