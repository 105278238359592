import React from "react";
import {Helmet} from "react-helmet";

export default function AnalyticalTheoryOfHeat() {
    return (
        <div>
            <Helmet>
                <title>热的解析 Analytical Theory Of Heat</title>
            </Helmet>
            <h6 className="App-essay-title">热的解析</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 九月</b>
                <br/><br/>
                <b>现代的黎明</b>
                <br/><br/>
                1768年3月21日，一个男婴出生在法国中北部的一个小镇欧塞尔（Auxerre）。欧塞尔在巴黎的东南边，到巴黎直线距离不到一百五十公里。这里历史上属于勃艮第公国的疆域范围，是个有名的葡萄酒区，文化传统和地方教育也出类拔萃，十五世纪后才成为法兰西的领土。男孩的父亲是镇上的裁缝，和第一任妻子有三个孩子，在妻子过世后才娶了男孩的母亲Edmée。男孩的母亲生了十二个孩子，他排行第九，父母给他取名Jean Baptiste Joseph Fourier——让·巴蒂斯特·约瑟夫·傅里叶。次年1769年的8月15日，另一名男婴出生在科西嘉岛上，这个岛位于地中海，历史上常年是热那亚共和国（今意大利）的势力范围，但因为这一年路易十五的入侵，科西嘉岛短暂地成了法国领土，这个在战火动荡中出生的男婴叫拿破仑·波拿巴，家里是个没落贵族。两个身世迥异的男孩未来的命运会多次相逢。
                <br/><br/>
                十八世纪初，得益于路易十四的文治武功，法国变成了欧洲陆上第一强国，法语成为欧洲上流社会的象征，凡尔赛也和今日中文的意思大致相当了。作为有记录以来在位时间最长的独立主权君主，2022年刚去世的英国女王伊丽莎白二世也只能位列第二，屈居这位自称“太阳王”的路易十四之下。1715年，路易十四归西时，在拥挤的欧洲大陆，给法国留下了一个匹敌欧洲其余国家总和的常备陆军，在水上与英荷相当的海军，和大片的北美殖民地——如果你今天生活在美国，看看以路易斯命名的土地，就能大致明白当时法国人的殖民前线在哪里了，更不要说还有被法国控制的大片非洲土地——数数今天有多少非洲国家的官方语言是法语。文化上，启蒙运动的三剑客是三个法国文豪：卢梭、伏尔泰和孟德斯鸠。总而言之，这就是十八世纪初的法兰西，吸收了佛罗伦萨的文艺复兴、葡萄牙人的商船、荷兰人的股票交易所、西班牙人和英国人的战舰——胡人不敢南下而牧马，士不敢弯弓而报怨。1715年，路易十四归西后，霸权如烟的历史规律没有对法国例外。但在政治、军事、文化的优势滋养下，十八世纪不断滑坡的法兰西还有一些重要礼物要送给欧洲和整个世界。
                <br/><br/>
                路易十四死的时候，路易十五只有五岁。继位的路易十五对文治武功没有太阳王那么感兴趣，作为国王他更注重沉迷声色，在自己情妇蓬巴杜夫人的影响下，他用精美纤弱的洛可可改造了欧洲时尚圈，替代了雄伟磅礴的巴洛克——或许只有建筑和艺术会感谢路易十五丰富了历史。1756年前后，国力下沉的法国被迫对欧洲本土和殖民地的利益再分配做出反应，法国参与了七年战争，与英国和西班牙一并卷入了欧洲所有重要国家和美洲、亚洲的殖民地领土，开展了横跨多块大陆的多线作战，让民族冲突和殖民利益在新旧大陆上不断蔓延。七年后的1763年，欧洲参战国签署《巴黎和约》，法国以丢失北美的新法兰西（法属加拿大）、法属路易斯安那、密西西比河以东的殖民地作为结局。这场战争让法国元气大伤。挥霍无度的路易十五最终把全部力气都用在享受宫廷生活的声色犬马上，在这些东西里他感到安全。被启蒙运动影响的法国人发现，想通过君主的自律来保证国富民强，无异于把国家命运交给赌徒的骰子。
                <br/><br/>
                1774年，放纵的路易十五也死了。据说路易十五和情妇蓬巴杜夫人留下名言，“我死后，将洪水滔天！”如果这句话是法国人民编造的，那也很可能是基于对路易十五政权的真实感受。路易十五把一个外强中干像火药桶般的王国交给了路易十六——一个酷爱造锁的机械师，如果路易十六认识明朝皇帝明熹宗朱由校，这两位极富工匠精神的帝国统治者大概率能成为好朋友——朱由校酷爱木工活，喜欢制作精巧的机械模型。总之路易十六的兴趣也不在政治，他还抽空亲手改良了断头台的铡刀设计，出于对干净利落斩首的人道主义诉求。这个改良后的铡刀会在1789年后成为历史的主角。
                <br/><br/>
                到了十八世纪末，离哥伦布发现美洲新大陆已经过去快三百年，旧大陆的移民仍在不断涌入美洲的广阔新世界。那段时间，科学革命催生的新武器还在不断定义战争的边界和内涵。大炮作为军事火力的上限，有今天核武器的战略地位，为了提高杀伤力和精度，弹道和火力计算需要大量的数学和工程人才。1776年，北美的大地主托马斯·杰斐逊带领十三州发表《独立宣言》，向英帝国宣战。法国开始暗中向大陆军支援物资军火，后期甚至直接参战，想以此削弱不断崛起的英国。落在法国平民身上的负担更重了。
                <br/><br/>
                1777年，9岁傅里叶的母亲去世，第二年父亲也死了。变成孤儿的傅里叶在地方主教的安排下进入了欧塞尔当地的教会学校读书，在那里他学习了拉丁语和法语。他年纪虽小，但在文学上却极有天赋。1780年，12岁的傅里叶结束了在教会学校的时光，前往镇上的欧塞尔皇家军校（École Royale Militaire of Auxerre）就读。14岁那年，他发现了世界上最有趣的东西——数学，那一年他把当时的法国数学家Étienne Bézout主编的一套六卷本数学课程（Cours de mathématiques）全部读完了。展现了过人数学天赋的少年傅里叶，在军校递交了他的个人申请：成为一名炮兵。但他的出身实在过于微贱，军校拒绝了他的申请。相比炮兵，贫寒的傅里叶更适合成为炮灰。
                <br/><br/>
                成为炮兵的人生理想被另一个男孩顺利实现了，那个身材矮小、操着科西嘉古怪口音的年轻人除了研究军事理论外，对数学、物理、历史、建筑、文化等各类知识涉猎极为广泛。1785年，16岁的拿破仑·波拿巴从巴黎军官学校毕业，成为了一名炮兵少尉。两年后的1787年，没能成为炮兵的19岁傅里叶，必须接受一个新的人生规划，他被派往奥尔良市郊区的圣伯努瓦隐修会（Saint-Benoît-sur-Loire Benedictine Abbey）学习，期待未来能去巴黎的某个神学院当一名神职人员。但他对成为神父有些怀疑，同时他对数学的兴趣从未停止。1789年，21岁的傅里叶没有宣誓成为神职人员，他离开了圣伯努瓦前往巴黎，给巴黎数学家蒙蒂克拉（Montucla）寄去了他关于代数方程的一篇论文，同时他给自己在欧塞尔读书时的数学老师伯纳德（Bonard）写信说：
                <br/><br/>
                <i style={{ color: 'grey' }}>“昨天是我的21岁生日，在这个年纪，牛顿和帕斯卡已经提出了许多不朽的科学理论。”</i>
                <br/><br/>
                显然，傅里叶的理想不是进入教会，即使成为一名神职人员是当时法国的铁饭碗和跨越阶级的大好机会。但科学和启蒙运动对神权和君权孜孜不倦的瓦解，让傅里叶嗅到了教堂和城堡的虚弱。当时法国的百科全书派领导人狄德罗，在Les Éleuthéromanes这首诗里写：
                <br/><br/>
                <i style={{ color: 'grey' }}>"手中缠绕着神父的肠子，因为找不到一根绳线来绞死国王"</i>
                <br/><br/>
                在这种极度崇拜理性的激进风气下，科学释放出横扫一切的强大威力，对年轻的傅里叶充满了诱惑。对于这点，年轻的炮兵拿破仑也坚信不移，他喜欢大炮的威力、研究欧洲诸国的风土人情和阅读启蒙运动的文豪卢梭。现代的黎明正通过慢慢衰落的法国照进欧洲大陆。
                <br/><br/>
                <b>现代的分界线</b>
                <br/><br/>
                1789年，除了迷茫但依然固执地前往巴黎的平民傅里叶和空有一身雄才大略的穷贵族拿破仑，法国还发生了许多事，这些事揭开了十八世纪最后无比动荡的十年。整整一百年前的1689年，英国的威廉三世在议会要求下签署了《权利法案》，然后才成了英国国王——史称光荣革命。至此，英国的君主立宪完成了对王权的架空和平民阶层的流动，议会开始合法地代表“普通人”的权利——那些在广阔的殖民掠夺和繁荣的商业交换里，从“非贵族”的平民中脱颖而出的有产者。一百年前英国那场不流血的革命把王室永远地变成了吉祥物，开创了资产阶级与贵族联手统治的政治先河。
                <br/><br/>
                十八世纪末，法国平民的土地权利和生活质量在欧洲各国里名列前茅，法国农民甚至可以拥有土地，相比英国农民、普鲁士与俄国的农奴，日子要好过得多。在英国宪政和国内情况的影响下，法国皇室也需要展现出更自信、宽容的高标准，至少做出对平民“广开言路”的安抚。但历史规律往往很反常，越是虚弱不堪的国民，反而越难形成革命。所以，生活质量和知识水平都在欧洲独树一帜的法国民众，对王权和教会的安排早已厌倦，一切的准备工作都在漫长的时间里完成了，如同地心引力——所需的只是轻轻一推。
                <br/><br/>
                1789年5月，上百年都未召开的三级会议<a href="#footnote-1" id="footnote1">[1]</a>被路易十六重新启动。这个会议一般在国家有军事或财政危机时才召开，一方面是“倾听”平民阶级的声音，另一方面也是让国家“合理地”向他们伸手。自1302年腓力四世召开第一次三级会议，到1789年路易十六召开的这次，已有四百多年历史。路易十五的七年战争耗干了国库，1774年继位的“锁匠”路易十六又支持了美国的独立战争。相比前任的国王们，路易十六性格更温和，在政治上更优柔寡断、政策摇摆，执政期间数度易帅。时间来到1789年，法国连续几年的旱情和前一年的冰雹导致粮食歉收，底层民众饥肠辘辘。这一年5月5日的三级会议，将是法国国王召开的最后一次。
                <br/><br/>
                巴黎城里，平民代表们焦急地关注着会议形制和计票方式——这是在旧制度下合法争取政治利益的唯一手段——虽然三级会议从一开始就没打算给他们太多。5月5日当天，会议在凡尔赛宫举行，国王、财政总监先后发言，这位从日内瓦来的新任财政总监雅克·内克尔尽力站在人民这一侧，会前已尽其所能为平民争取利益。但内克尔在大会上主要谈了国家严重的财政赤字和补救方法，发言人都没有提及关于计票规则和加税问题等第三阶级最关心的事项，平民代表和民众们更躁动了。
                <br/><br/>
                计票细节迟迟得不到确定，而在征税的问题上，国王、教会、贵族和平民的冲突更加尖锐——一方面，粮食价格和通货膨胀已经严重破坏了底层群众的正常生活，面包已经买不起了；另一方面，因为对外战争的接连失利，上层阶级认为国家正在内忧外患之中，广大平民应承担更大的责任，现在尤其需要对他们加税！会议持续了一个多月，关于投票和加税的问题没有达成一致。6月17日，身为神父的约瑟夫·西哀士因为发表过《论特权》和广为流传的小册子《什么是第三阶级》而被当作支持底层群众的自由人士。在三级会议陷入僵持的情况下，他带领第三阶级成立了“国民议会” ，目的是立法服务全体“人民”。国民议会也邀请了另外两个阶级参与，但也宣布即使没有他们参与，议会的决议也对所有人有效。很快，许多教会的代表和一部分贵族也加入了国民议会，除了巴黎外还有不少城市爆发了市民暴动。6月27日，路易十六在巴黎和凡尔赛集结了军队。7月9日，国民议会改名为“国民制宪议会”，代表了这个“人民议会”的目标越来越明确——制定一部宪法，把法国变成像英国一样的君主立宪制国家。7月11日，亲民众的财政总监内克尔被国王革职，巴黎市民预感镇压即将到来，第二天巴黎市内就出现了暴动。
                <br/><br/>
                时间来到7月14日，参与暴动的市民们攻占了巴黎市内一座用作监狱的旧城堡，占领了监狱的军火库——巴士底狱就这样被市民攻陷了，暴力的法国大革命正式拉开帷幕。7月14日成了今天法国的国庆日。如果你惊异于电影《蝙蝠侠3：黑暗骑士崛起》中叛军攻入纽约做的第一件事是打开联邦监狱的大门，然后在私人法庭上审判有钱人的罪行，那么法国大革命的历史会诉说这些镜头的起源。8月26日，国民制宪议会发布《人权和公民宣言》，这篇宣言由拉法耶特侯爵起草，他属于第二阶级的贵族；但在1777年，他远渡重洋，参与了美国的独立战争，曾和乔治·华盛顿并肩作战，回到法国后支持第三阶级的平民。从1776年托马斯·杰斐逊写《独立宣言》到1789年法国的《人权和公民宣言》，这个时期的革命文件有浓厚的科学革命的痕迹——他们看起来很像是先声明公理然后再做演绎的《几何原本》或《自然哲学的数学原理》。
                <br/><br/>
                1789年的10月，通货膨胀加上食物越来越稀缺，农贸市场中买不起面包的妇女们越来越生气，又有谣传说宫廷侍卫在皇宫里屯粮哄抬物价，妇女们更愤怒了。10月6日，凡尔赛发生了大规模游行，七千多名妇女们走上大街，洗劫了军火库，带着火炮和菜刀等临时武器加入了游行，朝着市政厅和皇宫行进——史称十月事件，凡尔赛妇女大游行。在市民的暴动下，路易十六“搬离”了凡尔赛回到巴黎来平息危机，同时承认了国民制宪议会的合法性。
                <br/><br/>
                和平岁月里，日子漫长好似看不到头，但在动荡年代，时间是按照分秒计算的。旧大陆上的斗争以天为单位的速度传导到新大陆，影响着也被美洲的革命所影响。可能没有一场革命同那场大革命一样，在之后的一百多年时间里，以势不可挡的力量把地球上所有人全方位推进了一个叫做“现代”的历史。当然，当时的法国人完全没意识到这些暴力和激情会产生什么影响，他们只是想吃饱饭然后要求君主立宪罢了，但这场革命塑造了两百多年后我们每个人都触手可及的今日世界。革命发生半个世纪后，托克维尔在《旧制度与大革命》里总结自己祖国这场突然迸发的革命：
                <br/><br/>
                <i style={{ color: 'grey' }}>“那个时候，他们的心中充满了对自由和平等的热爱；他们渴望建立起自由和民主的制度；他们想要摧毁那个旧世界的种种特权，重新确认人的各种权利，并将这些权利神圣化。那个时代里，青春、激情、自豪、慷慨与真诚四处洋溢。那个时代，尽管也有着不少的缺失，但是它必将永垂不朽，人们会永远怀念它。而且，它还将在以后的漫长岁月里，让那些企图侵犯或者奴役别人的人感到恐惧，不得安眠。”</i>
                <br/><br/>
                革命没有结束，暴风雨才刚刚开始。
                <br/><br/>
                <b>科学的疆场</b>
                <br/><br/>
                1789年，刚到巴黎的傅里叶在报纸上看到皇家科学院（Académie Royale des Sciences）关于代数方程的一个问题——高次方程的正负根个数，这正是他感兴趣的研究方向。傅里叶对这个问题提交了一份和过往证明不同的巧妙解法。他被邀请去这座国家级的学术殿堂做汇报。从14岁开始，傅里叶就是各类科学竞赛获奖席上的常客，但这次不一样，能去一趟作为世界科学中心的巴黎皇家科学院，对他而言太珍贵了。
                <br/><br/>
                1789年的风暴来的过于突然，三级会议的冲突震撼了巴黎，市民们也对自己的破坏力感到吃惊，暴动像洪水一样愈发凶猛，没有消停的趋势。外地人傅里叶感觉局势一天比一天坏，萌生了离开巴黎的打算。这段时间的傅里叶还在为宗教问题苦恼，作为平民的他去教会成为一名第一阶级的教士依然看起来是个好买卖，家人朋友也对他的选择不理解。但大革命的动荡给了他一些理由，现在社会不太平，求个安稳是最好的。时间来到1790年，他回到家乡欧塞尔，在自己的母校欧塞尔皇家军校谋了一份教职。有了份教师的工作，至少他可以暂时安心生活了，母校和城镇熟悉的味道也让他感到久违的安宁和平静。
                <br/><br/>
                十八世纪末，科学的理性革命还在如火如荼地进行中，虽然看起来没有政治革命那么明显。那段时间里，欧洲人对运动理论的研究已经被广泛地运用在生产、战争和宗教革命中，行星的运动、炮弹的轨迹、船只的航行、经纬度的计算，等等。但是，还有一类问题的挑战愈发凸显，地表的温度、金属的工艺、武器的生产，这些从大自然到工业制造的诸多现象，需要对热量进行研究。但要解释热传导的奥妙，已经超出了当时数学工具的极限。
                <br/><br/>
                古希腊以来，欧几里得在《几何原本》里解释了静态几何图形的秘密；阿基米德又在《方法》等一系列著作中解释了静力学的数学原理；文艺复兴开始，伽利略、牛顿、莱布尼茨用微积分的武器揭示了运动物体的数学规律。这些成就组成了十八世纪的欧洲人对几何学、静力学和运动学的数学武器库。牛顿莱布尼茨发现的微积分定理可以完美地解决了刚体运动的问题，但热运动是如此不同——想对热进行分析，就要涉及对一个复杂的粒子或波动系统（假象的热流）进行动力学分析，十七世纪发明的微积分方法还无法解析热，他们需要一个新的数学工具。傅里叶最感兴趣的是代数方程——这个由笛卡尔和费马完善的新数学形式，还可以用坐标系完美地融合数字和几何。曾被莱布尼茨和牛顿发扬光大，代数方程还会在傅里叶的补充下，变成十九世纪最欣欣向荣的数学分支。这个数学军火库的新武器，将被傅里叶扩充，但他当时还不知道这点。
                <br/><br/>
                <b>炮兵的打算</b>
                <br/><br/>
                巴黎局势的动荡对于拿破仑而言是另一种心情，这个在科西嘉岛上出生的外省人事实上更像半个意大利人，而如何让科西嘉岛从法兰西、热那亚等一切外来势力中独立才是他的心灵寄托。他刚去巴黎时甚至懒得学法语，毕竟那是敌人的语言。1785年从军校毕业后，16岁的拿破仑就被派往瓦朗斯驻扎，这个地方在法国东南边，罗讷河蜿蜒穿过市区，通向南部的马赛然后汇入地中海。
                <br/><br/>
                1789年大革命爆发后，他脑子里一直想着科西嘉——回家解放我的同胞们。满心的机会主义是大革命发生时这个炮兵少尉的信条。如果这群自相残杀的法国佬给了我解放科西嘉的机会，那真是谢天谢地！1789年，拿破仑回了一趟科西嘉，但是他一个年轻的少尉，能有什么能耐？还是在法军中慢慢待着等着晋升吧。他又重新回到部队报道，继续驻扎在瓦朗斯。这是拿破仑静待时机的一段时间，阅读和写作是他除了完成炮兵任务外最主要的工作，大革命传播来的思想也整日萦绕在他心头。
                <br/><br/>
                巴黎的风暴还在持续进行，1791年4月，他因为表现优异晋升为中尉。革命的躁动让他激动又郁闷，解放科西嘉对一个小小的中尉依然不切实际，虽然法国佬的大革命还在疯狂进行中。他继续阅读大量的书籍和报告，在笔记里一遍又一遍记录和计算：在哪里架设大炮、粮食和武器的数量、国家的财政、英国殖民地的范围、阿尔卑斯山的地形、金字塔的尺寸，等等。周围人看到他总在笔记本和地图上写写算算，都叫他是数学家。埃米尔·路德维希对那段时间里这个年轻中尉的描绘力透纸背：
                <br/><br/>
                <i style={{ color: 'grey' }}>“他孤独地坐在台球室的隔壁，面色苍白，沉浸于自己的精神世界。同事们在结束短暂的执勤后纷纷外出寻欢作乐，有的去赌场，有的找女人，这个贫穷的中尉却坐在陋室里，凭着坚定的直觉研读并且只研读那些将来对他有用的东西：炮兵的原理和历史，攻坚战的法则，柏拉图的《理想国》，波斯、雅典和斯巴达人的宪法，英国历史，腓特烈大帝的征战，法国的财政，鞑靼人和土耳其人的国家与风俗，埃及和迦太基的历史，印度概况，英国人关于当代法国的报告，米拉波、布丰和马基雅维利，瑞士的历史与宪法，中国、印度和印加帝国的历史与宪法，贵族的历史，贵族的罪行，天文、地理和气象学，繁殖规律，死亡率统计。”</i>
                <br/><br/>
                这个空有贵族头衔、身无分文、但如斯巴达人般强硬的中尉，正在如饥似渴地扩充他的世界观和战术法则，数学、历史、地理中的智慧和古往今来的战争铁律会让他崛起后势如破竹，也让他在绝境中拯救自己的部队。住在那家寒碜的咖啡馆里，中尉在喧闹嘈杂的台球声中，正在为一支欧洲人做梦都想不到的现代法国军队做着紧锣密鼓的准备。
                <br/><br/>
                <b>无套裤汉</b>
                <br/><br/>
                18世纪的法国贵族穿紧身裙裤，小腿穿长筒袜。那时候巴黎城里有大量的小业主和平民，他们的工作性质和财力并不允许他们日常穿得起裙裤，他们是巴黎这座城最活跃的动力源泉，也是这部机器上随时可被替代的小齿轮，但是，一旦被某种强大的共识联合，他们会突然变得无比重要。这个不穿裙裤、但穿紧身或直筒长裤的外在形象，组成了一个共同的阶级——无套裤汉。三级会议以来的一系列城市暴动，在路易十六近乎绥靖的宽容下，让旧制度的一角被掀开给人看：那些强大到令人窒息的权利机器和阶级象征，它们似乎并不是坚不可摧的。
                <br/><br/>
                1789年10月6日，在凡尔赛妇女大游行结束后，法国出现了几个明显的趋势。首先，国王路易十六开始常住巴黎了，很大程度上是“被迫”搬离凡尔赛而被“要求”在巴黎执政。随路易十六而来的还有御前会议这样的行政中枢，中央集权机器的到来让巴黎替代凡尔赛成了名副其实的首都和政治中心。同时，民间自发的政治组织——各类政治俱乐部——正在变得异常活跃，这些俱乐部像太阳光谱一样，在渐变的谱系中几乎包含了从保守到激进的所有成分。此外，因为英国工业革命的扩散，巴黎周边的工厂和工人在短时间内以惊人的速度增长，为了逃避军税的乡下人越来越多地聚集在巴黎，让本不稳定的局势更加动荡不安。性格仁慈的路易十六自始至终都没有想到，这些暗藏在表层下的潜在因素，会让巴黎变成有史以来最强劲的火药桶。革命的火把被巴士底狱、国民制宪议会和凡尔赛大游行点燃，接着交给了一群最有向心力的政治组织——政治俱乐部，其中雅各宾俱乐部几乎成了无套裤汉和暴力革命的代名词。
                <br/><br/>
                这几样东西，像一堆曾经分散在各处的强壮四肢，终于第一次在巴黎这口大锅里完成了组装。它们静待着、静待着被安上不同的脑袋。
                <br/><br/>
                <b>左还是右</b>
                <br/><br/>
                自从1790年初，国民议会就开始各项立法限制教会的权利，并要求法兰西国土上的教士效忠国家、宪法和国王。就算国王可以忍耐，罗马教廷已无法允许，议会和教会的冲突传导到这个半成品的“君主立宪”上，使它更加不稳定。这一年7月14日，是攻占巴士底狱的第一个纪念日，路易十六公开表态支持立宪的合法性，对于一个封建君王而言堪称开明，但对法兰西王国和皇室自身，他不彻底的立宪态度和处理教会的犹豫，让这个走在半路的立宪像火药般一触即发。历史在这段时间变得异常密集。
                <br/><br/>
                1791年年初，国民议会和教会、皇室的矛盾在四处飞散的谣言中逐步失控，这年初贵族前往巴黎“勤王”，更加深了平民和贵族的间隙；4月18日，国民警卫禁止皇室前往圣克卢宫庆祝复活节；6月20日，国王路易十六化妆出逃，沿途被群众认出，议会和君主的冲突被置于光天化日之下，君主立宪的愿望被渐渐撕裂；6月25日，国王被侍卫“护送”回巴黎，暂停职权并受到监视；各政治党派对于如何处理这个正走向失败的君主立宪意见纷纭。7月，雅各宾俱乐部出现分裂，温和派自行组建斐扬俱乐部，而雅各宾的激进元素也更加纯粹。刚开始，君主立宪派是不少人的共识，毕竟英国皇室的最佳实践就在不远处，不流血的革命可以是一场好革命。但这艘船随着国王的犹豫不决和群众被逐步释放的力量，渐渐越开越远。
                <br/><br/>
                路易十六被“送回”巴黎杜伊勒里宫之后，制宪议会的车轮接着转动，这一年9月，制宪议会制定了第一部基于三权分立的宪法，并在月底解散了制宪议会，组建了国民立法议会。10月1日，议会宣布法兰西为君主立宪制国家。这时候，欧洲大陆上的君主制国家已经感觉苗头不对，路易十六的处境像是被胁迫和软禁，哪里有君主立宪的样子，而事实也确实如此，路易十六也在暗中写信，请求欧洲君主国驱兵解救他。1792年2月，神圣罗马帝国与普鲁士签订了进攻巴黎的军事盟约，为了防止路易十六形容的“法国这样的悲剧”在欧洲君主国家重演。这一年，国民议会还颁布了不少废除教会和贵族特权的法令，平民要求的改革进度，超过了这个所谓的君主立宪国家所能给，矛盾在继续发酵。4月，法国向奥地利帝国宣战，同时有更多雇佣军团也加入了反法联军，反法联盟已初步形成。国家危难激化了所有阶级的矛盾，8月10日的前夜，雅各宾派领导的国民卫兵进攻杜伊勒里宫，拘禁了国王和王后，这让各阶层最后一点和平共处的希望也被掐灭了，政变发生后保守的吉伦特派控制了议会。
                <br/><br/>
                8月19日反法联军进入法国国境，8月20日联军在凡尔登会战，法军被普鲁士军击败。吉伦特派开始提议从巴黎迁都，但誓不投降的雅各宾派募集士兵六万余人，并发动群众赶往前线支援。9月20日，反法联军继续朝着巴黎推进，很快就到了瓦尔密（距巴黎仅有200多公里），在这里联军遭遇了抵抗，这些抵抗来自法国的一些老兵和志愿群众组成了杂牌军。战斗双方在瓦尔密交战，战役从凌晨持续到下午4点，双方伤亡500多人，以法军击退联军作为结局。这是一场无显著战果但极具战略意义的反击。9月20日，法兰西的那支杂牌军打败了旧大陆上装备精良、训练有素的普军和奥军的消息，极大地鼓舞了革命中虚弱的法兰西；9月21日，国民议会废除了去年那部君主立宪的宪法，通过普选成立了“国民公会”；9月22日，国民公会宣布成立法兰西共和国：法兰西第一共和国这样诞生了。10天之后，占据心理主动的法军绕后切断联军的粮食和弹药补给；10月5日，损失过半的联军退出法国国境。
                <br/><br/>
                这次胜利让大家都隐隐察觉到，一种新的力量正在形成，这种力量野蛮又生机勃勃。旧制度的帝国军队中大量是贵族阶级，还有为钱而战的雇佣军，充当底层苦力的是被兵役胁迫的农民，职业军人服从荣誉或金钱的感召，但这些感召都来自特权。而在瓦尔密，那些群众和志愿军的反抗意志不出于特权，他们被国破家亡的恐惧，和对敌人——尤其是国内外那些特权阶层的痛恨——所组织，他们被象征大革命“自由、平等、博爱”的蓝白红三色旗所鼓舞，他们被高呼的“人民万岁，法国万岁”所感召，一股新的力量正在浮出水面，从这儿开始，很多事物将永远变得不同。至此，法国大革命送出了第一份礼物，一个由民族意志和革命理想召唤出的国家，法兰西释放出一头由广大群众对共和国的认同所激发的民族主义野兽，它的庞大力量将被充分动员，然后投放在此后的军事行动中，弱小正在变得强大。
                <br/><br/>
                但是，法国失去了传统的君主集权，随后又失去了君主立宪的最佳时机，整个国家正在失去共识。国民公会的会场变成了各色政治人物的竞技场，所谓的“人民”也不是铁板一块。公会大厅选在巴黎市内的马术厅（Salle du Manège），这个体育竞技场像一座提供表演舞台的歌剧院，座椅的左右、高低、前后，一如这些词的天然禀赋，它们开始不再单纯地表示方位，而是和政治倾向深度绑定。雅各宾俱乐部和科德利埃俱乐部喜欢坐在会场左手边的高处，后被称为山岳党人，暗示了激烈的主张，如高山般大起大落，也是认知中最具代表性的“雅各宾派”。吉伦特派虽然也参加了雅各宾俱乐部，但经济主张支持放任自由主义，反对彻底废除君主，他们坐在中间偏右侧的位置。而保皇党，政治倾向一如其名，他们坐在会场的最右翼。剩下的中间派摇摆不定，则坐在中间低处，也被称作平原派或沼泽派，这些人中的很大一部分将来会为了结束雅各宾派的恐怖统治，而组成热月党人。从国民制宪议会建立以来，这些政治派别在国民议会上轮番登场，而在国民公会中，一个党派的存在往往更需要反对派的存在，没有黑自然也就不存在白，这些名字可以朝立夕改。
                <br/><br/>
                至此，法国大革命送出了第二份礼物，一串生动的现代政治术语：左翼-激进前卫，右翼-保守稳健，中间派，机会主义，雅各宾式统治，等等。这些词汇超越了法兰西的疆土和十八世纪的时间界限，深刻地渗透在当代政治中。
                <br/><br/>

                <i>to be continued...</i>

                <br/><br/><br/>
                <hr/>
                <b>注释</b>
                <p id="footnote-1"><a href="#footnote1">[1]</a> 三级会议的法语是États généraux，直译是全体阶级大会 Estates General。除皇室外参会的社会阶级有三，依次为：神职人员、贵族和平民。三个阶级的边界在长时间内都较为稳定，中文也直接译为三级会议。</p>

            </p>

            <br/>

            <h6 className="App-essay-title">Analytical Theory Of Heat</h6>
            <p className="App-essay-article">
                <br/>
                <b>Sep 2022</b>
                <br/><br/>


            </p>
        </div>)
}
