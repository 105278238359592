import React from "react";
import {Helmet} from "react-helmet";

export default function LureOfMedium() {
    return (
        <div>
            <Helmet>
                <title>媒介的魅惑 The Lure Of Medium</title>
            </Helmet>
            <h6 className="App-essay-title">媒介的魅惑</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 二月</b>
                <br/><br/>
                我们今天抱怨媒体的烦恼，其实从古到今都没有停止过。媒介对生活方式的雕刻能力数千年来都一以贯之。语言没出现的时候，人们通过手把手教导和身体力行的示范，传承着世界上几乎所有知识，那时候的知行合一是如此简单自然，弄虚作假的机会几乎无法存在。
                <br/><br/>
                语言出现后，人们通过口口相传的神话故事和口授经验，延长了知识信息存在时间和方式，媒介的魅惑力开始崭露头角：当一个智慧老人随着死亡烟消云散后，有关他的智慧和生平故事的信息仍在源源不断地被语言讲述给素昧平生的年轻人；而这些被传递的知识，也开始有了被语言歪曲的机会，知行不再合一，弄虚作假也有了更大的空间，甚至这个空间里的一部分还变成了艺术。苏格拉底就抱怨语言文字是一种药，精心炮制之后会有迷惑人心的力量。
                <br/><br/>
                文字出现后，我们更是把随风飘散的声音记录在莎草纸、竹简、棉箔和蔡伦纸上，而后出现的活字印刷术和古腾堡印刷机，让信息在极其广泛的范围内得以传播和被大众消费，大众媒体的时代在纸张和印刷术就位之后，终于开始了。
                <br/><br/>
                留声机和电影让声音和影像可以被直接永久的固定，文字和绘画第一次受到了重大威胁。电影出现之后，人们能够以前所未有的丰富度复制人生中的一段时空，影象的力量是建设性也是摧毁性的，其中弄虚作假的那部分我们称之为“表演”——或“电影艺术”。等影像的成本变得无比低廉之后，过量的影象和声音充斥着视听，反而让电影这个摧毁文字和绘画的后起之秀，都变成了一种难以咀嚼的艺术（从数量和质量上都是）。所以今天快餐式三分钟看完一部电影的解说栏目会悄然兴起，电影从消解文字绘画的俗物本身变成了被解构的艺术。
                <br/><br/>
                计算机、个人电脑和手机被放进每个凡人手里的那一刻起，声音、文字、图像的生产和传播成本被进一步压缩到不值一提，对生活细节的记录和交流达到了令人恐惧的程度，历史上所有滋生于媒介的艺术都被冲淡了——语言、文学、绘画、摄影、电影——甚至直播寡淡无味的生活本身，都正在变成一门艺术。
                <br/><br/>
                我并不是单一地鼓吹媒介对人造成的审美和表达蜕化，虽然这是所有人都切身感受到的事实。但客观地说，媒介除了加速人对真实世界的扭曲能力之外，媒介也给人插上了力量巨大的记录和表达能力的翅膀。但媒介绝不只是孤立的工具发明，这些充满魅力的介质是人体装置。无论是记录还是交流，媒介都深深地拓展着人和人的互动能力，它们塑造着我们沟通交流的方式和记录沟通交流的强度大小。与此同时，这些魅惑的媒介，还在一刻不停地改造着所有历史时期里，人们倾其全部生命所沟通交流的内容成果，而这，才是媒介最让人无法抗拒的魅惑源泉。

            </p>

            <br/>

            <h6 className="App-essay-title">The Lure Of Medium</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2023</b>
                <br/><br/>

            </p>
        </div>)
}
