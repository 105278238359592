import React from "react";
import {Helmet} from "react-helmet";

export default function Agent() {
    return (
        <div>
            <Helmet>
                <title>智能代理 AI Agent</title>
            </Helmet>
            <h6 className="App-essay-title">智能代理</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 三月</b>
                <br/><br/>


            </p>

            <br/>

            <h6 className="App-essay-title">AI Agent</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2024</b>
                <br/><br/>

            </p>
        </div>)
}
