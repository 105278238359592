import React from "react";
import {Helmet} from "react-helmet";

export default function Knockout() {
    return (
        <div>
            <Helmet>
                <title>关于《狂飙》（含剧透） About The Knockout (Spoilers)</title>
            </Helmet>
            <h6 className="App-essay-title">关于《狂飙》（含剧透） </h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 二月</b>
                <br/><br/>
                <b>三幕剧</b>
                <br/><br/>
                这部剧有两个主角，高启强和安欣，有三幕剧和三个高度动荡的时代幕布。
                <br/><br/>
                <b>1.街头保护费和徐江的死</b>
                <br/><br/>
                第一幕剧在国企改革的余波中展开，旧厂街代表的国企破败是第一幕剧的背景板。工人的孩子高启强早年失去父母，在菜市场开了个鱼摊，把弟弟高启盛和妹妹高晓兰拉扯大，一干就是十几年。那个时代下海经商是社会浪潮，有多大能耐吃多少饭。这段时间是资本原始积累的时期，社会整体很混乱，小型帮派的暴力冲突是第一幕的主旋律，街头保护费也是第一幕的主要冲突形式。高启强被街头混混欺负，阴差阳错认识了警察安欣，又被安欣同情照顾。他们的亲近关系被街坊和混混们当成“高启强有警察靠山”的错觉。这段友情，成了高启强的机会和未来一切问题的导火索。
                <br/><br/>
                当高启强开始厌倦被暴力欺负，也意识到这段友谊的“价值”。在那个深夜，他用“跟安欣警官关系好”狐假虎威，冒充大哥到音像店给街坊撑腰，这是他第一次对暴力和权力的觉醒，他尝到了“压迫”这个词语另一面的味道。但真正让他彻底把心里那头野兽释放出来的，是在黑帮大哥徐江死的时候，他癫狂大喊的那句：“你儿子不是我杀的，但你他妈是我杀的！”他开始品味邪恶和暴力带来的力量。
                <br/><br/>
                从接纳暴力那天起，他强壮了五十倍，也注定了他的结局。第一幕在一袭白衣的高启强被黑压压的匪徒们拥护着带上墨镜，和警察安欣驻足相视后很快又擦肩而过的镜头中结束。旧王已死，新王当立。
                <br/><br/>
                <b>2.大基建和第一次正邪的湮灭</b>
                <br/><br/>
                国企改革的余波被自由的市场经济治愈，二十一世纪的头二十年，是中国的基础建设主宰一切的时间，煤炭、钢铁、水泥、道路和房地产是那个时代资本积累最高速的行业。那二十年中国狂飙突进的“大基建”是地球上最浩瀚的星际工程。第一幕结束时的那些原始资本，隐约瞧见了一个大资本的帝国，小帮派被整合成大型的犯罪组织，街头保护费也早已被拆迁建设的大资本运作取代。第二幕里，黑帮头目和贪官污吏都是资本天然地想集中起来的“资本天性”所产生的棋子，官商勾结只是“资本天性”的手段，第二幕需要所有人以更快的速度，陷得更深。
                <br/><br/>
                这一幕出现的高启强是放肆张扬的，这也是他在剧中最春风得意的成长期。杀了旧的黑帮头子，娶了前任采沙场的霸道老婆做妻子，他的气场也随着霸道嚣张的穿衣风格越来越外显。戗驳领、双排扣、皮夹克、全黑套装、灰风衣和高领毛衣……这些穿在年轻无能的演员身上无比油腻的装束，在张颂文饰演的高启强的驾驭下，只觉得越来越合身。
                <br/><br/>
                这段时间里，阴狠也在弟弟高启盛身上日益渐长：在KTV的生日上祝酒说“天上掉下来一个铜板，都得姓高”；不满哥哥不让自己插手组织的事所以私下贩毒；不听哥哥安排拿着一条冻结实的鱼打死了拆迁村委主任的儿子李宏伟（因为被他侮辱是“臭卖鱼的”）。高启盛斯文儒雅的外表和内心深处的阴鸷，强烈的冲突让弟弟这个角色充满了邪恶的爆发力。高启盛最后冲向刑警队长李响，抱着李响从骑楼的二层摔下来同归于尽时，他脸上似疯似癫的狂笑，是高启盛过往所有疯狂压抑积累后的爆发。坠楼的这一幕将被观众记住，注定要成为国产剧中相当惊人的一幕。
                <br/><br/>
                弟弟高启盛的死也让第二幕来到了分水岭。正邪双方的二号人物同归于尽后，两个主角突然被剥夺了所有意义，如果有来生，别再做人，太难了，观众的观感也会同意。第二幕终结得非常痛苦，是整部片子悲剧感的巅峰。警察安欣和黑帮头子高启强，两人在坠楼现场看着自己死掉的最亲的人，失魂落魄的场景在《战争之王》里似曾相识——军火商的弟弟和军阀的儿子在争斗中被同一批手榴弹炸死，之后军火商和军阀擦肩而过时，两个人眼中空荡荡的落寞和《狂飙》是如此相似。
                <br/><br/>
                如果故事在这里戛然而止，那么《狂飙》的名字可以改叫《狂飙·第一季》，然后把第三幕剧放在下一季慢慢展开。可惜我们观众早就知道结局会是什么，所以干脆拍完吧，长痛不如短痛。
                <br/><br/>
                <b>3.陈舒婷的死</b>
                <br/><br/>
                《狂飙》是插叙展开的，第三幕才是整部剧的开头。剧一开始的专案组整风和高启强吃广东盆菜，都发生在高启强失去老婆陈舒婷之后。陈舒婷的死，是把第二幕和第三幕彻底分开的界限。
                <br/><br/>
                这幕剧刚开始，戴眼镜的白发小老头高启强回到当年卖鱼的摊位买鱼，年轻的鱼贩已经不认识高启强了，还吹嘘自己的摊位是打下“丰功伟业”的高启强曾经的那个，椅子也是他当年那把。高启强听着这一切，脸上笑眯眯的，现在的黑帮大哥身份和他的经营手段一样，变得低调又隐秘。第三幕是资本开始帝国化的阶段，从犯罪集团到生活水电，这个城市中大大小小的一切都和强盛这个资本帝国有关，无论是黑是白，所有人都再也洗不清了。大规模的犯罪集团和帝国化的资本是第三幕的背景板。
                <br/><br/>
                从高启强失去陈舒婷那一刻起，他开始对一切都意兴阑珊，也觉得自己什么都不是，什么都不重要了，他也跟儿子这么承认。老婆对他的约法三章没有了，他的所有忌讳和牵挂都随着陈舒婷的死一并消失，他的张狂恣肆被阴郁内敛代替，失去老婆的他变得更像他自己，他的外在气场和穿衣品味都消失殆尽了。这也是第三幕剧一开始就放出来的隐喻：狂飙突进的时代要结束了，罪恶的血泪将被终结，一个时代的强音也要准备入土为安。
                <br/><br/>
                第三幕给了不少贪官匪徒和调查组对抗的细节，很多观众认为最后十多集很不好看，事实上最后一幕大量使用的插叙，把从弟弟高启盛死到老婆陈舒婷死，之间这段关键时间的故事，补充地很好。第三幕中调查组的剧情主线依然不紧不慢地走着，但插叙的故事让之前挖下的坑被填的挺有说服力，每段插叙都合理地解答人物的故事和心理动机。
                <br/><br/>
                事实上，第三幕最大的败笔在于，如此根深蒂固的资本和犯罪帝国，结束得是这么匆忙，这么草率。但这部分无论拍多长都没办法拍好，这个结尾反而可能是最体面的，对观众和剧组都是种解脱。
                <br/><br/>
                <b>配角</b>
                <br/><br/>
                先说主角。三幕剧的切换，主角高启强从懦弱善良，到张狂自大，再到阴狠内敛，三个阶段的形象都很鲜明，这在国产剧里真不容易。如果非要在鸡蛋里找骨头，张颂文依然没有完全发挥彻底，但这是各种因素决定的，对某些状态的表演必须拉着缰绳，电视剧拍出来还是想上映的。但如果这匹野马可以彻底狂奔，那高启强这个角色前后对比的爆发力会更引人遐想。另一个主角张译的表演，因为警察安欣这个角色是非常不讨喜的设计，不说现实中存不存在这样的人（即使存在也不会有很多人喜欢，太伟光正了），想把一个不太讨喜的形象表现得够复杂，甚至让人钦佩是很不容易的。第一幕执拗，第二幕愤怒，第三幕犬儒，张译都高质量做到了，虽然他的感情戏和不自然的小动作要扣分，但绝对瑕不掩瑜。有些事情，不仅仅是表演，一个角色本身是否讨喜，各种因素是否允许发挥，都不是表演能决定的。
                <br/><br/>
                再说配角，这部剧真是精彩绝伦。杀手老默的角色演得极好，把一个现实冷血但精明强干的杀人机器刻画得非常真实，“我想吃鱼了”这句话带来的寒意都是老默给的。老默一次次在通往正道的路上被打断，继续误入歧途的他和飞黄腾达后的高启强因为地位差异带来的卑微，混合着知恩图报的兄弟情，对女儿的温情，还有最后疯狂的死亡，老默的复杂性绝不比主角低。他奉献了除高启强外最让人惊叹的表演。街头混混唐小龙和唐小虎的各阶段处理也很自然，刚出狱的唐小龙眼中的压抑不安，重操旧业后继续作恶都处理得合理精湛。
                <br/><br/>
                第二幕的主要配角村委主任李有田，把村民两面三刀和油滑狡诈完全拿捏。村主任儿子李宏伟的外强中干和性格乖张也都入木三分，李宏伟的出场更充满了暴躁的爆发力。市长赵立冬的精明贪婪和王秘书的阴险圆滑，都表达地合情合理。剧中配角的优秀无法全部细说。
                <br/><br/>
                至于专项调查组的表演，就不评论了，作为插播广告看就可以了。
                <br/><br/>
                <b>优点</b>
                <br/><br/>
                尝试看过一些国产剧，我能坚持十分钟以上不关，就算挺好的作品了。但《狂飙》我甚至会追着看。国产剧的优点不要和美剧比，因为创作环境不一样，这种比较很不公平。给美国人这种条件，他们也拍不出好作品。
                <br/><br/>
                《狂飙》带着镣铐跳了舞，很精彩。“我想吃鱼了”、“你忘了我以前卖鱼的？”、“风浪越大，鱼越贵”，肠粉、猪脚面、盆菜、粥底火锅、港式粤语……剧里极富广东特色的各种对话和取景，更让《狂飙》鲜活生猛。在码头的一幕，要抓捕船头上的两个亡命之徒，搁浅的船身高耸，强烈的视觉刺激粗糙硬朗，似带来阵阵风浪，极具压迫性。
                <br/><br/>
                但这些优点都不能说明文艺的春天来了，在这片土地上，有太多事情“出道即巅峰”，根本不稀奇。但希望这个判断是错的。
                <br/><br/>
                <b>问题</b>
                <br/><br/>
                说说败笔。
                <br/><br/>
                1.幼稚化和脸谱化的感情。国产剧的感情处理让人如鲠在喉，绝大多数感情都毫无真诚，也无法引起共鸣。国产剧的感情戏很少能起到调节剧情节奏的作用，平添生硬尴尬。《狂飙》也不例外，除了主线高启强和陈舒婷的感情，前两幕安欣的感情显得很乏味脱节。
                <br/><br/>
                2.（相比第一点是优点）高启强和陈舒婷的感情线就相当精彩了，不仅是两位演员的选角和表演到位，更因为这条线其实压根没有进行感情描写，它表现的是外表、身份和性格带来的性张力。它透过隐约但强烈的心理暗示描绘了复杂的感情变化，干脆利落。剧里其他部分的感情戏也该这么做，或者至少模糊化处理。
                <br/><br/>
                3.某些剧情依然很套路化，没有太多更令人印象深刻的精彩细节（让观众很意外但回想起来非常合理的精彩瞬间）。不少场景的谈话完全可以猜到下一句要说什么，特别乏味。
                <br/><br/>
                4.细节的表达依然不够深入精细，剧情故事的代入感需要更真实动人的细节做支撑，有些细节拍得很范式化，但这点也受多种因素制约。
                <br/><br/>
                5.剧的结尾只能安全处理，草草收尾，但就不讨论了。不过很有趣，正因为这种突然插入的说教和正常剧情的生硬反差，观众反而形成了免疫，会直接当成是植入广告而主动屏蔽，反而并没有想象中那么影响观感。
                <br/><br/>
                <b>反派</b>
                <br/><br/>
                在电影《蝙蝠侠·黑暗骑士》里，蝙蝠侠的饰演者克里斯蒂安·贝尔的女儿问他，为什么他不去演小丑，她的朋友们（甚至人人）都喜欢希斯·莱杰演的小丑。
                <br/><br/>
                人们当然喜欢小丑。不仅因为演员希斯·莱杰的死让小丑这个形象被蒙上了更深的悲剧色彩，还因为人们真的喜欢反派！人们虽然嘴上说着热爱善良，但在心底里，邪恶依然充满了魅力。邪恶通过毫无掩饰的直白，和极具破坏性的力量，永远魅惑着动荡不安的心。人会为邪恶付出代价，但难道你认为人不会为善良付出代价？在生命游戏里，因果只能解释一部分故事，人会为各种各样的原因付出代价。
                <br/><br/>
                <b>结尾</b>
                <br/><br/>
                结尾就不评论了，真实世界的猫鼠游戏无法暂停，剧本最后也说“要把整顿工作常态化”，所以也承认了恶无法被根除。
                <br/><br/>
                美剧《绝命毒师》的主角老白，一直都在用“我做的这一切，全是为了这个家！”来作他的犯罪借口，和高启强没什么两样。但在全剧终，老白将死之时安静地对着老婆，说了那句让《绝命毒师》封神的话：
                <br/><br/>
                <i style={{ color: 'grey' }}>I did it for me. I liked it. I was good at it. I was alive.</i>
                <br/>
                <i style={{ color: 'grey' }}>我做这些是为了自己。我喜欢。我很在行。我感到我活着。</i>
                <br/><br/>
                老白最后死在了自己的作案现场。但我们的剧本，还不允许说这样的话，可能这些话真实得让人害怕。
                <br/><br/>
                等年轻人又白发苍苍，等起点又一次来到终点，等一个轮回又再次终结，警局里还会有一盒饺子和两个老警匪，外面世界还会有年轻的鱼贩和年轻的警官，一切终点还会再次回到起点，一个人还会对着另一个喟叹：“我吃过的最好吃的饺子，是在2000年。”
                <br/><br/>
                吾欲与若，复牵黄犬，俱出上蔡东门逐狡兔，岂可得乎？

            </p>

            <br/>

            <h6 className="App-essay-title">About The Knockout (Spoilers)</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2023</b>
                <br/><br/>

            </p>
        </div>)
}
