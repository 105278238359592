import React from "react";
import {Helmet} from "react-helmet";

export default function HowToLearn() {
    return (
        <div>
            <Helmet>
                <title>如何学习：一场浪费时间的阅读 How to Learn: A Waste-Of-Time Type Of Reading</title>
            </Helmet>
            <h6 className="App-essay-title">如何学习：一场浪费时间的阅读</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 四月</b>
                <br/><br/>
                <b>功利的研究</b>
                <br/><br/>
                如何学习，或者问如何在一个完全不同的领域从头开始高效学习，这个问题一直是认知科学领域最具功利性的研究对象。因为很容易写书卖给那些疯狂追求“学习奥妙”的人。
                <br/><br/>
                我最近用极其零散的时间，快速阅读了三五本这样的类似书籍。《Study Less, Learn More》、《西蒙学习法》、《费曼学习法》、《学习之道》……等等这类让人头皮一紧（读之前这是褒义词）的指南类读物。
                <br/><br/>
                在阅读这类书籍前，我满怀希望地期待，自己能从书中获取到一些机密信息，能让我这颗普通头脑也拥有更优异的、高效燃烧葡萄糖的方式。但最终我发现，就像你在课本的最后一页永远找不到习题解法，在工作计划中永远找不到工作方式，在圣经里也永远找不到灵魂救赎一样，当我进行完这场浪费时间的阅读后，获得了这个千百次人们在各种各样别的场景中也会获得的醍醐灌顶的领悟：你不可能通过研究所谓的抽象方法本身，就获得任何令人惊喜、立等可取的意外收获，那些空泛的抽象方法必须在具体问题中得到执行时才有价值。
                <br/><br/>
                总体而言，这些书的质量比中信出版社的飞机场读物都要糟糕，虽然有些真诚的经历和反思很打动人，比如《学习之道》，但我在这场追求学习技巧（Technique）为目的的探索旅程中，依旧可以把这场阅读归结为某种类型的“浪费时间”。不过，是否我真的在浪费时间，我们后面再说。阅读这些浪费时间的“垃圾书籍”，让我收获了一些额外见解。
                <br/><br/>
                下面是我对“如何学习”的粗浅见识，同时我也拒绝对任何不理想的应用效果负责。
                <br/><br/>
                <b>大师和学习法</b>
                <br/><br/>
                首先，这类学习法中理查德·费曼（Richard Feynman）<a href="#footnote-1" id="footnote1">[1]</a><a href="#footnote-2" id="footnote2">[2]</a>和赫伯特·西蒙（Herbert Simon）<a href="#footnote-3" id="footnote3">[3]</a>的名字反复出现。但最让我震惊的是，这些大师对高效学习法没什么兴趣，西蒙本身最感兴趣的是认知科学中对学习（Learning）这个问题的根本探究：什么是学习？什么是教学？什么是专业性？信息技术如何促进教和学？而至于费曼，他就更嚣张了，他更感兴趣的是你怎么知道你真的“知道”了？“为什么”是什么？如何“教学”？如何在帮助别人理解知识的教学过程中，尽量保持混乱？当然，这一切都很费曼。
                <br/><br/>
                这些大师都没这么自大敢号称他们掌握了学习的奥妙，而那些写学习指南的人，竟然胆敢号称自己拥有“学习方法”的灵丹妙药，还用无比自信的语调冠之以XXX学习法的名头。所以，西蒙在卡耐基梅隆大学1998年发表的只有6页的paper，和费曼5分钟的聊天视频，都会比这些狗屁书籍中所呈现的内容总和的一万倍要深刻得多。
                <br/><br/>
                其次，这些书（联合我的个人见解）总结了影响学习效果的几个关键因素：智力，动机，时间投入，学习方法。这几样东西是影响学习效果最直接的要素，但这四样东西中的前两样，很难通过人为的调整改变。智力自不用说，而学习动机一般来源于主观兴趣和客观环境的综合，动机是一个人的热情所在，而热情基本是难以自我捏造的，就像纯粹强烈的好奇心本身就是人格的一部分，所以动机很可能像智力一样难以被撼动。这也是大多数这类狗屁书籍搞错的第一件事，它们往往强调你得调动积极性“爱上学习”。
                <br/><br/>
                所以，既然智力不可控，而动机又呈现出明显的二分趋势，那让我们根据“动机”把学习分成两种情况：第一种，你不想学，但必须要学；第二种，你想学。
                <br/><br/>
                <b>两种情况</b>
                <br/><br/>
                在第一种情况下，如果你能足够真诚地再问自己一次，你所面对的学习目标是否在某些情况下可以变成第二种？如果可以的话，恭喜你，你已经获得了最高级的学习方法，除了智力不可改变之外，你只需要投入更多的学习时间了！你有没有发现，我什么都没说？
                <br/><br/>
                好的，现在让我们假设，你就是喜欢不起来，你只是需要通过一场考试或者完成一些无聊工作才去学这些东西的。现在就是你阅读这类“浪费时间”的爆米花式教学手册的最佳时机了。它们总结的学习方法有三个层面，实际上颇为有用：
                <br/><br/>
                <b>1. </b>分解目标知识，找出来最终无法分割的基本问题，获得基本问题的解可以演绎出上层问题的解。同时，缩减对无用问题（非基本问题）的时间投入。
                <br/>
                <b>2. </b>输出引导输入，以教学作为目的进行学习，如果你用最简单的话都给别人讲不清，那你也没学懂。教别人还经常能让你获得学习动力。
                <br/>
                <b>3. </b>保持精力专注，在生活习惯上减少刺激大脑的诱惑，把手机放远点，把书本放近些，这些小动作很有意义。同时训练自己习惯挫折和无聊。
                <br/><br/>
                这些提升学习效率的方法大同小异，往往都由这三方面构成：缩减目标，减少对非基础知识的时间投入；以教为学，输出引导输入；忍耐无聊，保持专注。
                <br/><br/>
                总结完，我就要唱唱反调了。在这种状况下，最需要被回答而没有被回答的真正问题是：这些我无论如何都永远喜欢不起来的东西，是否压根需要被学？难道我的时间是太多了所以需要被糟蹋？但是，这类书永远不会告诉你如何判断你应该学些什么，其实我也不能告诉你，我们都只能自己判断。因此，这些书是对着一个错误问题给出了一个正确答案，即使亲身实践后发现它们确实很有用，但这个答案也可以为害不浅。
                <br/><br/>
                让我们回到第二种情况，你对学习目标充满了好奇和激情。此刻，你不需要任何方法，你可以把这类“如何学习”的书扔进垃圾桶了——在热爱的情况下，你的一切动作都是对的！
                <br/><br/>
                好了，经过我的阅读、实践和思考，我对这两种动机引发的学习方法做了几点综合：
                <br/><br/>
                <b>1. </b>首先，对于学习动机，要对自己尽可能地诚实，要学会修剪残枝败叶，把学习目标缩减到尽可能属于第二种范畴内的“激情目标”，后续的“学习方法”才最有可能产生正向价值。
                <br/>
                <b>2. </b>对于具体的学习目标，重点关注学习目标中结构性的内容——即能给其它知识提供基础支持的那些知识，尽可能忽略无关紧要的花哨信息。
                <br/>
                <b>3. </b>对于智力，你毫无办法。
                <br/>
                <b>4. </b>时间投入，训练忍耐无聊的能力，能增长专注的时间。
                <br/>
                <b>5. </b>如果你对学习内容本身就充满激情，这种情况下，反而要培养自己“反方法”，坚信适合自己的方式才是对的，因为热情会不断校正你最终找到属于你的高效方法。方法可以借鉴，但切忌盲从。对于每个人，那个“正确”的方式有极强的量身定制的意味，一个人的铁律很可能是另一个人的毒药。
                <br/><br/>
                <b>写在最后</b>
                <br/><br/>
                最会忏悔的是罪人，最可能乱投医的是病急之人，最想掌握快速学习奥妙的正是那些最想抄捷径的人。所以，当你拿起这类书的那刻起，你在心态上就已经输了。保持自信，保持诚实，不抄捷径，慢就是快。或许意识到在生命旅程中实现任何目标、成就任何事业、获得任何幸福的过程里，没有任何捷径可言，就已经走在捷径上了。
                <br/><br/>
                现在回到标题，“一场浪费时间的阅读”，出于抄捷径的动机，让我阅读了一系列抄捷径的“秘籍”，最后得出不能抄“捷径”的结论。那么请问，在这趟南辕北辙的旅程中，我浪费时间了吗？
                <br/><br/><br/>
                <hr/>
                <b>Reference</b>
                <br/>
                <p id="footnote-1" style={{ margin: "0px" }}>
                    <a href="#footnote1">[1] </a>Feynman, "Why" - 费曼，“为什么”：<a
                    href="https://www.youtube.com/watch?v=36GT2zI8lVA" rel="noreferrer noopener" target="_blank">
                    Youtube: 7mins</a>
                </p>
                <p id="footnote-2" style={{ margin: "0px" }}>
                    <a href="#footnote2">[2] </a>Feynman, "On Teaching" - 费曼，“论教学“：<a
                    href="https://www.youtube.com/watch?v=BY6VntTmtIo" rel="noreferrer noopener" target="_blank">
                    Youtube: 3mins</a>
                </p>
                <p id="footnote-3" style={{ margin: "0px" }}>
                    <a href="#footnote3">[3] </a>西蒙，“关于学习我们都知道什么：<a
                    href="https://onlinelibrary.wiley.com/doi/abs/10.1002/j.2168-9830.1998.tb00363.x"
                    rel="noreferrer noopener" target="_blank">
                    Herbert Simon, 1998, "What We Know About Learning?"</a>
                </p>

            </p>

            <br/>

            <h6 className="App-essay-title">How to Learn: A Waste-Of-Time Type Of Reading</h6>
            <p className="App-essay-article">
                <br/>
                <b>Apr 2023</b>
                <br/><br/>

            </p>
        </div>)
}
