import React from "react";
import p5 from "p5";

let movers;
let mouseMover;


export default class CatChasing extends React.Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.width !== prevProps.width) {
    //         console.log('Data updated in parent:', this.props.width);
    //         // Add your desired logic to execute when data changes
    //     }
    // }

    Sketch = (p5) => {
        // make wait till the width is updated and different from 0.

        p5.setup = () => {
            p5.createCanvas(this.props.width, 456);
            // console.log("the imported width is: " + this.props.width);
            p5.background(240);
            p5.frameRate();

            movers = [];
            // mouseMover = new Mover(2, p5.mouseX, p5.mouseY);

            for (let i = 0; i < 6; i++) {
                movers.push(new Mover(p5.random(0.5, 5), p5.random(p5.width), p5.random(p5.height)));
                // console.log(p5.random(0.5, 5), p5.random(p5.width), p5.random(p5.height));
            }
        }

        p5.draw = () => {
            p5.noStroke();
            p5.fill(240, 255);
            p5.rect(0, 0, p5.width, p5.height);
            //background(255);

            p5.textSize(11);
            p5.fill(0, 0, 0, 50);
            // p5.text("© Modified from Daniel Shiffman, NOC.", 6, p5.height - 6);

            // create a mouse mover object every time in the draw loop
            mouseMover = new Mover(2, p5.mouseX, p5.mouseY);
            // console.log(mouseMover.position);

            for (let i = 0; i < movers.length; i++) {
                let forces = p5.createVector(0, 0);

                // add mouse attraction
                forces.add(movers[i].attract(mouseMover).mult(-1));

                // add the rest movers
                for (let j = 0; j < movers.length; j++) {
                    if (i !== j) {
                        let force = movers[i].attract(movers[j]);
                        forces.add(force);
                    }
                }

                // apply, update, and display
                movers[i].applyForce(forces);
                movers[i].update();
                movers[i].display();
                mouseMover.display3();

                p5.textSize(11);
                p5.fill(255);
                p5.text("我", p5.mouseX - 6, p5.mouseY + 4);

                // println(mouseX, mouseY);
                // console.log(movers);

            }

            let energy = 0;
            for (let i = 0; i < movers.length; i++) {
                energy += 0.5 * movers[i].mass * movers[i].velocity.mag() * movers[i].velocity.mag();
            }

            // console.log(energy);
            const truncateDecimals = function (number) {
                return Math[number < 0 ? 'ceil' : 'floor'](number);
            }

            let trucated = truncateDecimals(energy * 100000) / 100000;
            // console.log(trucated);

            p5.textSize(11);
            p5.fill(144, 12, 63);
            p5.text(`Kinetic Energy: ${trucated}`, p5.width - 380, p5.height - 6);
        }

        class Mover {

            constructor(m, x, y) {

                this.mass = m;
                this.position = p5.createVector(x, y);
                this.velocity = p5.createVector(0, 0);
                this.acceleration = p5.createVector(0, 0);

                this.r = Math.random() * 256;
                this.g = Math.random() * 256;
                this.b = Math.random() * 256;
            }

            applyForce(force) {
                let a = force.div(this.mass);
                this.acceleration.add(a);
            }

            // update velocity, position
            update() {
                // velocity update
                this.velocity.add(this.acceleration);

                // limitation on velocity
                this.velocity.limit(20);

                // position update
                this.position.add(this.velocity);
                // corner case
                if (this.position.x <= 0 || this.position.x >= p5.width) {
                    this.velocity.x *= -1;
                }
                if (this.position.y <= 0 || this.position.y >= p5.height) {
                    this.velocity.y *= -1;
                }

                this.acceleration.mult(0);    // VERY IMPORTANT

                // add to trajectory
                // this.position.add(this.velocity);
                // history.add(this.position.copy());
                // if (history.size() > 1000) {
                //   history.remove(0);
                // }
            }

            // display the mover
            display() {
                // draw the mass

                p5.stroke(0);
                p5.noStroke();
                p5.fill(this.r, this.g, this.b, 50);
                p5.ellipse(this.position.x, this.position.y, this.mass * 20, this.mass * 20);

                //// trace trajectory
                // beginShape();
                // stroke(col.x, col.y, col.z);
                // noFill();
                // for (PVector v : history) {
                //   vertex(v.x, v.y);
                // }
                // endShape();
            }

            display2() {
                // draw the mass

                p5.stroke(0);
                p5.noStroke();
                p5.fill(this.r, this.g, this.b, 100);
                p5.ellipse(this.position.x, this.position.y, this.mass * 20, this.mass * 20);

                // trace trajectory
                //beginShape();
                //stroke(col.x, col.y, col.z);
                //noFill();
                //for (PVector v : history) {
                //  vertex(v.x, v.y);
                //}
                //endShape();
            }

            display3() {
                p5.stroke(0);
                p5.noStroke();
                p5.fill(144, 12, 63);
                p5.square(this.position.x - this.mass * 7.5, this.position.y - this.mass * 7.5, this.mass * 15);
            }

            // calculate attraction force
            attract(newMover) {
                let G = 10.0;
                // get a normalized direction vector
                // Remember: sub is wrong!!!
                // let pos = [this.position.x, this.position.y];
                // let direction = newMover.position.sub(pos);
                let direction = p5.createVector(newMover.position.x - this.position.x, newMover.position.y - this.position.y);
                direction.normalize();

                // calculate the magnitude of the force
                let distance = newMover.position.dist(this.position);
                distance = p5.constrain(distance, 0.000001, 25.0);
                let forceMag = G * this.mass * newMover.mass / p5.sq(distance);

                // combine direction and magnitude
                return direction.mult(forceMag);
            }
        }
    }

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current)
    }

    render() {
        const componentWith = this.props.width;
        // console.log(`Now the width is ${this.props.width}`);

        return (
            <div ref={this.myRef}>
                <div style={{ display: 'none' }}>{componentWith}</div>
            </div>
        )
    }

}
