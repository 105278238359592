import React from "react";
import {Helmet} from "react-helmet";

export default function OfDeath() {
    return (
        <div>
            <Helmet>
                <title>关于死亡 Of Death</title>
            </Helmet>
            <h6 className="App-essay-title">关于死亡</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 十一月</b>
                <br/><br/>
                所有人都会死。真的吗？
                <br/><br/>
                让我们假设，如果真的有人像《这个男人来自地球》一样，从智人时代就出生，一直活到今天，是耶稣的真身，经历过旧石器时代，目睹了地质二十四小时中人类出现并高速进化的最后那几秒种（也就三百万年吧）的其中一瞬。
                <br/><br/>
                那么他应该早就被别人发现了。换句话说，一个不死的人，大概率没法藏身于凡人中这么久。
                <br/><br/>
                不仅因为这个人上万年的记忆很可能导致ta和普通人的行为大相径庭，同时还存有另外一个问题：如果ta目睹过无数历史的真相，一批又一批敌人、朋友和挚爱都从人间离开，此人的神经需要有多么迟钝，才能允许ta继续活在世上，而不是自我消灭。
                <br/><br/>
                不过我不能猜测永生。或许这世间确实存在另一种可能——甚至存活了上百万年的人，不仅可以在生理层面进化，还能在心理层面持续接受人类社会每几十和几百年的横断面所给出的生命价值的定义。所以在生理层面存不存在永生，是和在精神层面能否接受永生一样的问题，是概率无限接近0但不等于0的事件。
                <br/><br/>
                我大概率没法儿永生，不用问我怎么知道的，我的大脑和身体通过心理和生理的暗示，无时无刻都在提醒这个事实不是人类传说。
                <br/><br/>
                苏格拉底在审判前拒绝逃跑而喝下毒药是接受死亡的方式，而托尔斯泰则因为无法接受存在的毫无意义而客死他乡。那么我对死亡的客观性和几乎一定为真的必然性所能给出的应对措施估计不是托尔斯泰式的，他享受过太多放纵极乐和命运恩宠，他无法理解生命的价值是对他的放纵过往所必须支付的代价，无穷无尽的困惑是他反思生命的主题，也确实如此。
                <br/><br/>
                我没有托尔斯泰的困惑，苏格拉底的气魄更令我震撼，不仅因为苏格拉底一以贯之对根本问题的持续拷问，而他最后拒绝逃跑甚至拒绝为自己辩护而直面判决的勇气也早已不是行为艺术，他在哲学层面上向死亡宣战，他消失了但他从未离开。
                <br/><br/>
                太史公曰：人固有一死，或重于泰山，或轻于鸿毛。诚然。
                <br/><br/>
                既然所有人的终点都是死，那么逃避死亡就毫无意义。唯一有用的训练是接受死亡，学习并研究死亡，然后不停地询问自己，想以何种方式度过这一生，然后为一种梦寐以求的死亡方式而奋斗。
                <br/><br/>
                对死亡的适应和训练是最重要的必修课，它能直接解决关于如何活着的问题。同时，这也从侧面应证了永生是多么无聊且无意义的一件事。
                <br/><br/>
                没有局限，就没有故事，没有故事，就没有意义。

            </p>

            <br/>

            <h6 className="App-essay-title">Of Death</h6>
            <p className="App-essay-article">
                <br/>
                <b>Nov 2023</b>
                <br/><br/>

            </p>
        </div>)
}
