import React from "react";
import {Helmet} from "react-helmet";

export default function ThePoisonOfArrogance() {
    return (
        <div>
            <Helmet>
                <title>自负的毒性 The Poison Of Arrogance</title>
            </Helmet>
            <h6 className="App-essay-title">自负的毒性</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 九月</b>
                <br/><br/>
                自负在有毒性之前，首先有很多无可取代的优点。自负可以帮人们成就很多事，有人会称之为自信，但他们真正想说的是一种非常纯粹甚至有些邪恶的"自信"——一种强硬、偏执、又敏感的自负。这类特殊的自负保证了一个人对自我的无条件自信，和对其所持一切观点的狂热执行。这类人往往很容易被冒犯，而且少有人像他们一样，会把被冒犯作为一种必需品，久而久之寻求被冒犯变成了他们的觅药行为。
                <br/><br/>
                这个因果链条是这样的，被冒犯会带来愤怒，愤怒会引发憎恶，憎恶会加强自负，而极致的自负可以支撑一个人呼吸、奋斗甚至生存的全部动力。被冒犯引发的极端自负是一种强大的燃料，很少有东西比它燃烧得更纯粹。如果你没有因为被冒犯而愤怒，又被愤怒引发自负，然后被强烈的自负推动完成你从未想要完成的任务，最后甚至开始主动寻觅被冒犯的机会。那么很幸运，这篇文章和你关系不大。
                <br/><br/>
                自负开始变得有毒，是当一个人开始沉湎自负所带来的激情与活力，以至于幸福反而离他越来越远。这就像人类沉湎化石燃料能带来文明动力一样，那些燃料的副产物早在冥冥之中就注定加速了我们的灭亡。这世上确实没有什么东西可以单纯的好，如同铸造了一枚有正面的硬币就一定会天然地造出反面一样，正面的锋利也会成就反面。
                <br/><br/>
                自负的第一点毒性，是你开始失去认清事实的能力，因为你太需要被冒犯进而被激发出无可辩驳的自负带来的进攻欲和好胜心。于是久而久之，假设敌意、夸大困境就变成了条件反射，这种过份渲染逆境的做法几乎总以误判形势告终，最后经常是悲剧收场。
                <br/><br/>
                自负的第二点毒性，是你会忘记自己的本来面目，因为保持自负可以极大地激发勇气和斗志，长此以往一个人会爱上自己活力四射——即使浑身都散发着仇恨和愤怒——的样子。这一点的长期毒性是你再也无法接受在某些状况下会束手无策，进而会不择手段地采取行动。而事实上，真正的灾难降临时，束手无策是所有人都必须学会接受的唯一结局，就像死亡一样。
                <br/><br/>
                自负的第三点毒性，是你会失去解决某些问题的唯一方法，因为在这些特殊时刻，低头认输是最体面简单且唯一有效的办法。放弃自负然后学会认输，其可贵之处不在于强迫自己认输，而是心平气和地承认失败并接受无能，是个退一步进两步的高招，它关注的重点是问题真正被解决后的胜利。但这要求一个人有勇气对自我坦诚，这是心理成熟和拥有高等智慧的象征——脑子不会拐弯的笨蛋往往极爱面子也从不投降。生活是个缓慢受锤的过程，拒绝承认这点值得尊敬，而承认这点能收获安宁。
                <br/><br/>
                按照这个分析，最理想的聪明人绝不是过份自负的那类，相反应该是温和且不易被冒犯的。但很可惜，在现实中这实在太罕见了，历史上真实的天才们往往生性孤僻、偏执狂妄、又敏感多疑。所以这也可能说明了一个悲剧性的事实——毒药和解药往往是同一种东西。

            </p>

            <br/>

            <h6 className="App-essay-title">The Poison Of Arrogance</h6>
            <p className="App-essay-article">
                <br/>
                <b>Sep 2022</b>
                <br/><br/>


            </p>
        </div>)
}
