import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import jsusagemd from './JSUsage.md';


export default function JSUsage() {
    // // find out which markdown file to use exactly
    // const fileName = ``;
    // console.log(fileName);

    let [content, setContent] = useState({markdown: ""});

    useEffect(() => {
        fetch(jsusagemd)
            .then((res) => res.text())
            .then((markdown) => {
                setContent({markdown})
            })
    }, [])

    return (
        <div>
            <Helmet>
                <title>Javascript编程This和That的用法 This & That in JavaScript</title>
            </Helmet>
            <h6 className="App-essay-title">Javascript编程This和That的用法</h6>
            <p className="App-essay-article" style={{overflow: "hidden"}}>
                <br/>
                <b>二〇二四 十一月</b>
                <br/><br/>
                <ReactMarkdown children={content.markdown}
                               remarkPlugins={[remarkGfm]}
                               components={{
                                   em: ({node, ...props}) => <i style={{color: 'grey'}} {...props} />,
                                   // a: ({node, children, ...props}) => <a target="_blank" rel="noreferrer noopener" {...props}>{children}</a>,
                                   // i: ({node, ...props}) => <i style={{color: '#900C3F'}} {...props} />,
                                   h2: ({id = "footnote-label", children}) => <p><b>{children}</b></p>
                               }}
                               style={{overflow: "hidden"}}
                />
            </p>
            <br/>
            <h6 className="App-essay-title">This & That in JavaScript</h6>
            <p className="App-essay-article">
                <br/>
                <b>Nov 2024</b>
                <br/><br/>
            </p>
        </div>
    )
}