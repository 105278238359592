import React, {useEffect, useRef, useState} from "react";
// import Glider from "../assets/images/Glider.png";
import CatChasing from "./games/CatChasing";
import {Helmet} from "react-helmet";

export default function Game() {
    const refContainer = useRef();
    // console.log(refContainer);
    const [dimensions, setDimensions] = useState({width: 386, height: 0,});
    useEffect(() => {

        if (refContainer.current && dimensions.width === 0) {
            setDimensions({
                width: refContainer.current.offsetWidth,
                height: refContainer.current.offsetHeight,
            });
        }
    }, [dimensions.width]);

    // console.log(`current dimensions.width is ${dimensions.width}`);


    return (
        <div ref={refContainer}>
            <Helmet>
                {/*<title>Game</title>*/}
                <title>Game</title>
            </Helmet>
            <h6 className="App-game-title">Game</h6>
            <br/>
            {/*<img src={Glider} alt="glider" className={"App-game-image"}/>*/}
            <CatChasing
                width={dimensions.width}
                height={dimensions.height}
            />
            <figcaption
                className={"App-game-illustration"}>一个简单的力学系统，各色小球的质量随机且存在万有引力，共同组成一个N体系统。小球间吸引力上限为非无穷大，下限为非无穷小。我是一个产生反作用力的质量体，和小球相互排斥，移动鼠标会驱赶小球。N体系统无阻力，但引力有上下限，过大和过小的引力会被“削弱”和“加强”，系统动能震动不恒定。移动鼠标倾向于增加系统总动能使小球高速运转。刷新页面重置系统。
            </figcaption>
            <p className={"App-game-paragraph"}></p>
        </div>
    )
}
