import React from "react";
import {Helmet} from "react-helmet";

export default function Nomadic() {
    return (
        <div>
            <Helmet>
                <title>边界，边疆与游击战 Edge, Frontier, And Guerrilla</title>
            </Helmet>
            <h6 className="App-essay-title">边界，边疆与游击战</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 五月</b>
                <br/><br/>
                <b>第一阶段</b>
                <br/><br/>
                1890年，美国所占据的大陆范围内的边疆地带基本消失了。不断西进的人流通过定居（一平方英里内有两人或两人以上）把边疆地带的范围推进至太平洋沿岸，新大陆的边界终于抵达了另一片大洋的一边。美洲大陆上已没有更多土地可供边界扩张了。美国人口调查局局长在1890年的报告中陈述：
                <br/><br/>
                <i style={{ color: 'grey' }}>“……现在未开发的土地大多已被各个独自为政的定居地所占领，所以已经不能说有边境地带了。因此，对边境范围，对向西部移民运动等等进行讨论，也已不能再在人口调查报告中占有篇幅了。”</i>
                <br/><br/>
                19世纪末，西进运动通过融合旧大陆的生活方式和美洲大陆的荒蛮气质，在北美大陆上塑造了“美利坚”的民族性格：
                <br/><br/>
                <i style={{ color: 'grey' }}>瀑布线是17世纪的边疆，阿勒格尼山脉是18世纪的边疆，密西西比河是19世纪第一个二十五年的边疆；密苏里河是19世纪中叶的边疆……落基山脉和干旱地带是现在的边疆。每条边疆都是通过一系列对印第安人的战争而获得的。……在美洲发现后四百年和宪法公布后一百年的今天，边疆已经消失，同时也就结束了美国历史的第一个时期。(Turner, 1893)</i>
                <br/><br/>
                新大陆的故事是一部丰富的边疆拓荒史，以欧洲白人为视角的现代美洲史，是一部以西进运动为精神的边疆史。失去边疆斗争的美洲大陆会失去最具特色的“美国风味”。边疆的不停扩张改造了旧大陆的人口流动，又塑造了新大陆的气质。对边疆的游击式拓展是美国性格最重要的部分，在边界作战的天性几乎和对边疆的追逐一样根深蒂固。
                <br/><br/>
                <b>第二阶段</b>
                <br/><br/>
                这个拓展的边疆随着美洲大陆上向西扩张的天然边界——遇到太平洋——而宣告第一阶段的终结。但西进运动还远没有停止，它不只悄然发生在落基山脉深处的711、星巴克和赛百味里，西进运动还继续发生在延绵万里、又无险可守的大洋中。
                <br/><br/>
                海洋不能阻止美利坚对边界的扩展，当对大西洋边界的重新塑造再定义了旧大陆的生活方式，边疆还被继续推进至东欧、中东、阿富汗、中亚、东南亚、东北亚。边疆以不断前进的势头迅速异化被边疆吞没的边境地区，又无可避免地被高速同化。边疆持续推动，向东统治着北大西洋直到波罗的海，向西扩张至无人值守的太平洋，充斥着整个无人主宰的辽阔公海地带，驱逐舰、航母和潜艇组成的“移动军事岗哨”被用来把边疆的界限推进至东亚的边界，直到遇上强有力的抵抗。
                <br/><br/>
                <b>第三阶段</b>
                <br/><br/>
                对边疆的拓荒推动经济增长和制度变化，这一惯性渗透在美国的民族血液中。平面的大陆，球面的海洋，都无法满足始终在持续膨胀的边疆，边疆的消亡意味着西进运动的消亡和美国精神的最终解体——而这，是不被允许的，同时也不会发生。西进运动被延伸到三维空间，还继续发生在火箭试图穿越的上万米的稀薄大气层中。
                <br/><br/>
                <i style={{ color: 'grey' }}>如果有人一定断言，美国生活中的这种扩张性现在已经完全停止了的话，那么，他一定是一个冒失的预言家。向边疆迁移曾经是这种扩张的最有力的事实，除非这种训练对一个民族没有影响，否则，美国人势必急需要求一个更加广阔的领域，以便发泄他们旺盛的精力。(Turner, 1893)</i>
                <br/><br/>
                边界的人力和资源密度无法支撑大部队的军团作战，对边疆的探索只由小规模的游击战构成，能支撑高密度的人力和资源的前线不足以称之为边疆，反而更像是国界——一个旧时代诸国林立、被反复盘踞的分界线。游击战把重新定义边界的生活方式作为手段，同时以移动或取代现有中心的位置作为结果。
                <br/><br/>
                在大陆和大洋的各个角落，在近地轨道，在星际飞船上，未来的拓荒人不再是种田的英国农民、做生意的法国掮客和穿梭在落基山脉里的各色商队，来自边疆对面的阻力也不再是苦苦抵抗的印第安部落。未来的边疆会由打散的家庭、边缘人群组成的冲锋队和观念与技术组织起来的混合部落构成，这个边疆会继续在商业、科技和军事层面竞争，并以游击形式作战。
                <br/><br/>
                这个移动的边界上，界限会重新被拓展为游击的新边疆。对外是大气层，远地轨道，火星殖民；对内是计算机，智能机械，纳米机器人，脑机装置。
                <br/><br/>
                <b>边界 Edge，尖端 Cutting-Edge，边疆 Frontier</b>
                <br/><br/>
                不抵达边界的前进，就无法带来新事物。
                <br/><br/>
                新世界不诞生在丰饶稳定的疆域中央，新世界诞生于旧世界的边缘地带，它出现在动荡不安的边界上。超越边界的地方，就是边疆，那里水草丰茂，是另一片自由的迷宫。
                <br/><br/><br/>
                <hr/>
                <b>Reference</b>
                <br/><br/>
                1. <a
                href="https://www.historians.org/about-aha-and-membership/aha-history-and-archives/historical-archives/the-significance-of-the-frontier-in-american-history-(1893)"
                rel="noreferrer noopener" target="_blank">
                The Significance of the Frontier in American History, Frederick Jackson Turner, 1893.
                </a> （《边疆在美国历史上的重要性》 黄巨兴译，张芝联校）
                <br/>

            </p>

            <br/>

            <h6 className="App-essay-title">Edge, Frontier, And Guerrilla</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2023</b>
                <br/><br/>

            </p>
        </div>)
}
