import React from "react";
import {Helmet} from "react-helmet";

export default function NoPurpose() {
    return (
        <div>
            <Helmet>
                <title>漫无目的 No Purpose</title>
            </Helmet>
            <h6 className="App-essay-title">漫无目的</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 十月</b>
                <br/><br/>
                我观察到这样一个浅薄的规律，当一个人可以自发地、漫无目的地做一件事，那么ta极大概率在这件事上，是完全不计较成果的。ta做这件事，是出于纯粹的喜爱，甚至做这件事本身就是一种目的——一种个人选择的胜利。就像饿极了会吃东西一样，我们是出于无法遏制的生命冲动在狼吞虎咽，而不是有“目的性”地把自己塞饱。
                <br/><br/>
                漫无目的做一件事和饿了想吃饭非常相似，虽然饿极了吃东西更紧迫，但这两件事在“计较成果”方面的相似之处是非常惊人的。人们对这些事不会也不敢计较成果，比如体重。类似的，大多数人对于自己漫无目的想做的事都难以启齿，人们更喜欢展现自己充满“目的性”的力量感和执行力，同时也很怕自己给别人一种散漫游荡的感觉。但我猜应该有不少人的“积极奋进”不是真的，但或许大家私底下漫无目的想做的事过于光怪陆离，实在不方便说出口，所以我们甚少听说“漫无目的”的故事。（其实就算能说出口，这些“漫无目的”也都被修饰过，或者至少一开始也没那么离经叛道。）
                <br/><br/>
                再举一次吃东西的例子，这种对比会更直观。健身是一件充满“目的性”的任务，健身可以提高身心健康和延长寿命的概率、好身材可以增加性吸引力、健身还可以提高工作时长和效率（当然不是每个人都追求这个）。于是出于这些“目的”带来的种种好处，我脑中充满了“得去锻炼”的想法。久而久之，“健身”这个饱含了目的性的词语，对我不再是一个中性词，它向我投射了监督、催促和审判。终于，我再也受不了无法完成这些“目的”带来的精神压力——我选择躺着，嘴里吃着高热量食物，仅仅为了对冲心中的烦恼，而这烦恼还是我自找的。
                <br/><br/>
                事实上，健身和暴饮暴食，这两样事都不是我在“漫无目的”下会做的事情。通过一些被社会鼓励、科学验证的“目的”，我收获了两件不想做的事，堪称损上加损。如果漫无目的地挑一些事情做，我大概率在“发呆”、“思考繁殖”、“看一些有关无关的书籍和电影”以及“写一些没有直接价值的文字”。所以，你可以大概判断出我此刻的目的有几成。这是我首次发现“目的性”的危害，但绝不是最后一次。
                <br/><br/>
                对于创作型的职业，比如作家和歌手，有个让人慨叹的词是“江郎才尽”。一方面这是真的，因为如果哪个江郎可以不才尽，那这个宇宙中就一定能造出永动机。人们称呼这些创作者“江郎才尽”，和我们提醒自己去“健身”是一个意思——你应该运动，如同天才就应该产出！至少努力创作就是江郎的主要目的！我们这样唏嘘江郎，也这样敦促自己。但我真的很怀疑，这种确切的“目的”是否在一定程度上提前了江郎的才尽。
                <br/><br/>
                不少出名后的作家一旦成名就被声望所束缚，这些功成名就的才俊们开始真的把自己当成文曲星下凡，以至于他们必须让自己写的每个字都充满意义，充满目的！于是乎，最后让ta们写一百个字都难如登天。这一点我比较喜欢陈忠实，读者调侃他“这辈子只写过一本《白鹿原》”，他理直气壮地回敬“曹雪芹这辈子还只写过一本《红楼梦》呢！”（还没写完）。这种抵抗“目的性”的态度十分要的，不管陈老的文学成就有没有《红楼梦》或《百年孤独》高，至少在创作自由上，他应该是个比较幸福的人。所以为什么名校毕业生有很多废材，也大概是相同的道理，大家的“目的性”都太强了，不允许自己漫无目的，那当然过得痛苦又拘束，最后只得失望地活着。
                <br/><br/>
                整体上，我不认为自己是个懒散的人，但我对“漫无目的”始终充满了兴趣。通过实践，我甚至发现漫无目的并没有那么糟糕，至少在对抗“目的性”带来的痛苦方面，屡试不爽。所以我总结了一些基本操作，这里用锻炼和暴饮暴食这对孪生的怪胎为例。首先需要彻底忘记“我应该去锻炼”这个虚伪的目标，正因为我先产生了这个虚伪的目标，为了摆脱这个虚伪目标的审判，我最终躺着大块朵颖。自始至终，健身者的暴饮暴食都是为了对抗“健身”这个虚伪目的产生的自我修复的手段。如同治疗失眠的方法绝不是催促自己赶紧睡着，而是告诉自己：“我可以不睡，起来干啥都行”，反而一会儿就困了。
                <br/><br/>
                现代人的生活充满了压迫性的使命感，让生命充满意义不再是少数人激情的浪漫追求，而是一种需要被加急完成的使命目的。这时候，“目的”成了最有破坏力的异物。太想成为艺术家的人摸不到门槛，想成为诗人的人做不了诗人，企图创造万物的人连一块木头都削不平整，而妄想改变世界的人第一个不能改变的就是自己。老子在《道德经》里说的更气势磅礴：企者不立，跨者不行，自见者不明，自是者不彰，自伐者无功，自矜者不长。
                <br/><br/>
                经过一番渲染，“目的”这东西现在听起来就像个笑话，你想对这些目的说不，你不在乎也懒得达成。你甚至可能开始有些认可，“漫无目的”也并非那么全无“目的”。但假以时日，我们会比自己想象地更容易被这些斤斤计较的“目的”俘获。原因很简单，虽然“漫无目的”代表着松弛、无为和真实，也必须得正视它的缺陷——盲目而低效；而“目的性”代表着紧绷、执行和死板，还有不得不承认的优点——确定而高效。正因为“目的”有这种无法拒绝的确定，这个确定性的魔力能让我们每个人都感到安全，最后转而投身到“目的”的阵营，即使这可能是最虚假的承诺。
                <br/><br/>
                我们就像在水面上漂浮的花粉，水分子无规则的运动会不断推搡拉扯我们这些漂浮的花粉颗粒。这类无规律的布朗运动，几乎没有任何机会可以形成有意义的特殊模式或轨迹。但也总有那么一些奇妙的巧合，会让有些花粉被推到了水盆的边缘，然后稳定在那里。这些花粉抵达了一个毫无意义，但足够别致的“目的地”。这时候，剩下的花粉宣布：到达水盆边缘才是目的啊！让我们在“漫无目的”的分子热运动中，拼命追求这个目标吧！作为旁观者，我会忍不住会对这些花粉笑出声。
                <br/><br/>
                但我难道没有干过类似的事？像这些花粉，让读闲书的爱好变成“一年内读500本书”这样的空洞目标；让对山川大河的热爱变成地图上打卡的图钉；让享受劳作的充实变成我要成为一名成功创业者的雄图。把复杂的功利心掺杂在自发的、直觉式的活动中，这个不太相关的“目的”代替了“漫无目的”的纯粹状态，侵占了“漫无目的”的敏锐直觉，直到最后变成那个最不相关的“目的”本身。所以人们追求的人生目标往往无法达成，指定的宏图伟愿也经常落空。
                <br/><br/>
                这也很可能是为什么人很难被目的鼓动去创造，因为创造需要一些完全相反的东西，创造需要不确定性，如同那些花粉的运动。如果“目的”的威力已经失效了，那还有什么值得追求？不知大家有没发现，我们似乎总可以被“漫无目的”吸引，追求“漫无目的”几乎毫不费力，而人们将要接触或创造的新东西，反而孕育在这些漫无目的的不规则运动中，漫无目的是我们自己。
                <br/><br/>
                我们总是想把形成于一连串意外巧合的创造、成功、伟大和正确再次复制，把这些定义为所企图的目标，而这往往都是痴心妄想——想把一种源于复杂巧合、随机运动的美妙过程，转化为一套明确的行为准则所导向的“目的”。这个愿望可能因为两个因素而落空。首先，行为和结果之间的因果关系从未被充分探明；此外，未来尚未发生的细节也像“花粉运动”一样不可控制。如果凑巧“目的”被完美地实现了，那也和最初制定的那个“目的”毫无关系。这个被完美实现的“目的”，其实是另一个崭新的、“漫无目的”的美妙过程。
                <br/><br/>
                所以，让我们再多问几个问题：为什么大公司一再失败？为什么帝国终将崩塌？为什么鲁迅“再也没有吃到像那夜的好豆，再也没有看到像那夜的好戏”？为什么漫无目的读的书通常是记忆最深刻的？为什么你漫无目的做的事最像你自己？为什么欲买桂花同载酒，终不似，少年游？
                <br/><br/>
                我可以用“不存在永动机”这种道理解释，但也可以用“所有‘目的’驱动的行为常常带来反效果”解释。漫无目的是目的本身，目的是一种幻觉。
                <br/><br/>
                写到最后，有两个消息可以被发掘，一个好一个坏。坏消息是，我们可能无法通过指定一个明确“目的”从而准确实现这个目标，南辕北辙是以“目的”为导向的常态；而“漫无目的”是能接受的、最接近“目的”的过程准则，而且这世上可能没有可靠的直达“目的”的准则。但好消息是，未来依然在我们手中，未来的全部“目的”依然隐藏在那一堆“漫无目的”当中——无法破解、无法驯化、自由得难以捉摸。
            </p>

            <br/>

            <h6 className="App-essay-title">No Purpose</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2022</b>
                <br/><br/>

            </p>
        </div>)
}
