import React from "react";
import {Helmet} from "react-helmet";

export default function Her() {
    return (
        <div>
            <Helmet>
                <title>她 Her</title>
            </Helmet>
            <h6 className="App-essay-title">她</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 五月</b>
                <br/><br/>
                她是一团放射性物质，在漫长的半衰期内静待着，一个非自然的力量将这团烈性物质的体积瞬间压缩，无数个漫射的中子是她的样子。她像太阳一样燃烧，链式反应形成巨大的蘑菇云，吨计的TNT只配做她的当量。
                <br/><br/>
                他是一堆老式黑火药，一硝二磺三木炭，老派古板。他不计较环境，只要有合适的温度和氧气，他像一张纸一样燃烧，比纸更快速猛烈地燃烧，直到把全部的热量耗光。
                <br/><br/>
                她要制造一朵蘑菇云，需要阳光，空气和水，活在阳光下。
                <br/><br/>
                他要制造一朵蘑菇，废料浇灌，厌恶光线，长在黑暗中。

            </p>

            <br/>

            <h6 className="App-essay-title">Her</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2023</b>
                <br/><br/>

            </p>
        </div>)
}
