import React from "react";
import {Helmet} from "react-helmet";
import Borges from "../../assets/images/borges.jpg";

export default function Conspiracy() {
    return (
        <div>
            <Helmet>
                <title>[文]博尔赫斯 · 时间的新反驳 | Jorge Luis Borges: A New Refutation of Time</title>
            </Helmet>
            <h6 className="App-essay-title">[文]博尔赫斯 · 时间的新反驳 | 陆经生 · 译</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 十月</b>
                <br/><br/>
                <img src={Borges} alt="Craftsman" width={"100%"}/>
                <br/><br/>
                ……
                <br/><br/>
                然而，然而……否定时间的持续性，否定“我”，否定天体宇宙，都是表面的绝望和秘密的安慰。
                <br/><br/>
                我们的归宿（不同于斯威登堡的地狱和西藏神话的地狱）不因为非现实而可怕，却由于不可逆转并坚硬如铁而恐怖。
                <br/><br/>
                时间是我的构成实体：时间是一条令我沉迷的河流，但我就是河流；
                <br/><br/>
                时间是一只使我粉身碎骨的虎，但我就是虎；
                <br/><br/>
                时间是一团吞噬我的烈火，但我就是烈火。
                <br/><br/>
                世界，很不幸，是真实的；我，很不幸，是博尔赫斯。
                <br/><br/>
            </p>

            <br/>

            <h6 className="App-essay-title">Essay by Jorge Luis Borges: A New Refutation of Time</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2024</b>
                <br/><br/>
                ……
                <br/><br/>
                And yet, and yet . . . To deny temporal succession, to deny the self, to deny the astronomical universe,
                appear to be acts of desperation and are secret consolations.
                <br/><br/>
                Our destiny (unlike the hell of Sweden borg and the hell of Tibetan mythology) is not terrifying because
                it is unreal;
                <br/><br/>
                it is terrifying because it is irreversible and iron-bound.
                <br/><br/>
                Time is the substance of which I am made.
                <br/><br/>
                Time is a river that sweeps me along, but I am the river;
                <br/><br/>
                it is a tiger that mangles me, but I am the tiger;
                <br/><br/>
                it is a fire that consumes me, but I am the fire.
                <br/><br/>
                The world, unfortunately, is real; I, unfortunately, am Borges.

                <br/><br/><br/>

                <hr/>

                <b>Reference</b>
                <br/>
                1.《探讨别集》，[阿根廷] 豪尔赫·路易斯·博尔赫斯，时间的新反驳，上海译文出版社。
                <br/>
                2. NUEVA REFUTACIÓN DEL TIEMPO, Jorge Luis Borges.
                https://elcinesigno.wordpress.com/wp-content/uploads/2011/07/nueva-refutacion-del-tiempo.pdf

            </p>


        </div>)
}
