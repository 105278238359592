import React from "react";
import {Helmet} from "react-helmet";

export default function SlavAndCyber() {
    return (
        <div>
            <Helmet>
                <title>关于尊重 Of Respect</title>
            </Helmet>
            <h6 className="App-essay-title">关于尊重</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 一月</b>
                <br/><br/>
                我们因为文化传统、社会稳定和路径依赖，强迫人们尊重年长、身居高位或令人生畏的人。
                <br/><br/>
                因为文化传统的惯性，我们默认年纪大的人拥有部落中最完善的知识结构和风险判断能力。尤其是当一个人岁数足够大，TA可能还被看做是一种运气和吉祥的象征。所以让部落人口尊重知识积累和命运安排，几乎毫无抵抗力。但我猜大家都见过无知的、和借助年龄优势对晚出生几年的人大放厥词的长辈。中文对此还发明过一个成语，叫“为老不尊”，来解释这种倚老卖老、令人嗤之以鼻的行为。
                <br/><br/>
                从文化传统出发，一个稳定的社会也需要一种尊重年长者——尤其是身居高位者——的规则来平衡社会冲突。这种社会结构在大多数时候都是有用的。身居高位者并非绝对的道德完善，但每日处理的社会挑战和个人诘难的等级，非同普通人可想，这反向要求了此人不可道德“天真”。TA不再是一个活生生的人，而是变成了一个符号，好或坏，尤其是坏，都会与TA深度绑定，这在某种程度上强化了TA们应被尊重的理由，因为责任与尊重成正比。但今天的身居高位者，和当初帝国管理技艺刚被发明出来一样，都拥有某种责任匹配的真空，即，“所担负的责任/罪恶/压力”完全不匹配其应该得到的尊重，真令人遗憾！这种错配是一种不能精确数字化管理的技术缺憾，它亘古存在于社会管理的艺术中。
                <br/><br/>
                至于路径依赖则结合了上面两者，把年长者或身居高位者当做尊重的代名词。人们把这种尊重当做了肌肉记忆，直到整个文明机器都把它当做赖以生存的机制。看到年长或身居高位者就忍不住心生仰慕。
                <br/><br/>
                相反，有些人本应受到绝对的尊重，如：警察、清洁工、列车员……缺少这些角色，社会一秒钟都无法运行！但他们反而得到了最低程度的尊重，这不让人奇怪吗？所以如何准确衡量尊重如何被分配的价值依据，是一个重要的组织学问题。
                <br/><br/>
                在今天，尊重能力和人格水平——这两点是最难获取的资源，而不只尊重所谓应被“尊重”的先决条件，是一种重要的认知突破。一只股票过去的回报率不能作为预测未来的依据，请投资者谨慎判断（我们少读过类似的警告吗？）
                <br/><br/>
                所有人都会死，所有人都会重新回到原子炉中变成一撮灰，尊重的分配会被生死问题重新归零。
                <br/><br/>
                所以，无论觉得自己是谁，请不要索取“应得”的尊重，没有什么事是“应得”的。相反，去赢得你想要的尊重。尊重应当被争取，也只能被争取！尤其在这个万事万物都被反复试探挑衅的年代。

            </p>

            <br/>

            <h6 className="App-essay-title">Of Respect</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2024</b>
                <br/><br/>

            </p>
        </div>)
}
