import React from "react";
import {Helmet} from "react-helmet";

export default function Nomad() {
    return (
        <div>
            <Helmet>
                <title>游牧部落 Nomadic Tribe</title>
            </Helmet>
            <h6 className="App-essay-title">游牧部落</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 四月</b>
                <br/><br/>
                部落在四蹄之上快速移动，野马，骆驼，牦牛，山羊……动物承载着人，也承载着食物和交通。褐黄的沙尘，埋葬着绿洲，也被延绵万里的青草埋葬。
                <br/><br/>
                部落必须快速移动，迁徙给部落带来活力，停留意味着死亡。部落必须居无定所，部落不许驻足停留，部落只存在于移动当中，迁徙是部落的第一本能。
                <br/><br/>
                部落移动在草原、高坡和沙漠，部落游荡在洪泛退去的河谷，部落出现在忽隐忽现的绿洲。左衽，窄袖，皮靴，来去如电；右衽，宽袍，长袖，胡服骑射。部落在边缘地带作战，部落在边疆地区游牧。部落时刻在抵近、融化、渗透着中间地区的神经和心跳。
                <br/><br/>
                部落在远处呼唤，用弯刀号角，也用电流信号，如旧时绵密的动物纤维织成的旗帜牙帐，在萧萧野风中猎猎作响。风沙的一端拍打着边疆的部落，风沙的另一端拂过中心的城郭。边疆不是边缘地带，边疆是另一个快速移动的心脏。

            </p>

            <br/>

            <h6 className="App-essay-title">Nomadic Tribe</h6>
            <p className="App-essay-article">
                <br/>
                <b>Apr 2023</b>
                <br/><br/>

            </p>
        </div>)
}
