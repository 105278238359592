import React from "react";
import {Helmet} from "react-helmet";

export default function MechanicalAesthetics() {
    return (
        <div>
            <Helmet>
                <title>机械美学 Mechanical Aesthetics</title>
            </Helmet>
            <h6 className="App-essay-title">机械美学</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 一月</b>
                <br/><br/>
                <b>1.机械美学是视觉直觉的合理化，合乎视觉理性的机械是美的。</b>
                <br/><br/>
                一个结构、材料和形状只要是安全有效的，就很容易产生机械美，安全、高效是机械美学产生的重要原因，也是视觉理性被培养出判断什么是机械美的基础——在机械构件里，没有什么比安全、坚固和高效能带给视角上更多的教化和享受。
                <br/><br/>
                用树杈拼成的三角形结构就是这样一个例子。走出森林的原始人很早就发现，拿树枝搭建的用来遮风避雨的四方形原始构筑物，在地心引力的作用下，或是一阵大风、或玩耍的孩童轻轻一推，就会轰然倒塌。但那些搭成三角的原始构筑物，在三角形魔力的约束下，不仅会使用更少的材料，还坚若磐石。这个过程，就是安全高效的机械结构，在人类的视觉理性中投射最原初的机械美学的过程。类似地，打制石器的锋利边缘比钝刀磨肉的效率质量高太多，所以石刀石斧的锋利边缘也驯化着机械美的视觉原则。这些原始机械的形状、材质和结构，在百万年中培养了一代又一代人类，从尼安德特人到现代人，我们的基因中留着对机械美学偏好判断的遗传因子。我们的机械审美被这样一遍又一遍地驯化筛选，直到最后，三角形开始隐含了力量、坚固和美；而锋利的边缘则意味着精确、进攻和危险。
                <br/><br/>
                经过一代又一代筛选，眼睛就变成判断机械美的重要器官，自然事物和人类发明在潜意识里被视觉理性时时刻刻地解构成：安全与不安全，高效与低效，合理与不合理，然后把它们分门别类，而我们做出美学判断的时间所需不过一瞬。这些遗传的和被训练的视觉理性，把安全、合理和高效的机械归为美，并把丑赋予相反的特质。
                <br/><br/>
                远古的手工机械偶尔会有充满个性的纹饰图案和艺术趣味，但现代机械很难是纯艺术，现代的机械美学尤其如此，甚至现代的机械美学在一定程度上需要排除纯艺术的绝对自由成分，因为大工业时代带来的机器轰鸣，是机械被批量生产的底色，机械美学必须从属于最广泛的大众直觉，而不是小众艺术，现代机械也几乎总与批量制造有关。
                <br/><br/>
                如果要粗浅地给机械分类，可以简单划分成：静力机械和动力机械。静力机械的代表物是建筑，动力机械的代表物是内燃机驱动的载具，从巨型邮轮、F1跑车、音速B-2轰炸机到长征火箭，这些动力机械都充满了现代的机械美。而这种机械美学，在视觉上几乎一定与机械的安全、合理和高效相匹配。
                <br/><br/>
                to be continued...
                <br/><br/>
                <b>2.符合直觉的机械美允许一定程度的不严谨，这些不绝对严谨的直觉也贡献了机械美学。</b>
                <br/><br/>
                当你看到一块做成金属外壳发出闪闪寒光的蛋糕，以为它是一块金属，但当它被软乎乎切开的那一刻，违反你直觉的那种不适感、意外和惊讶就是机械美学存在的反面例证。好比我们会用“金属就该有金属的样子”或“蛋糕就该有蛋糕的样子”这类描述去形容很多东西一样。所以当机械没有了机械该有的样子，那就违背了机械美学中“该有样子”的感觉。但是，如果一旦这种反差大到让人惊奇，那么美反而也会发生，一个看起来无比坚硬的金属被切开发现是蛋糕的过程当然是美的，因为让人惊奇的巨大反差也会激发出舒张人性的快感。然而，虽然机械美学允许直觉犯一定程度的错，但彻底违反科学规律会直接告诉感觉这个东西是一出荒诞剧，即使也可以很美，但很难把这种美归因为机械理性。
                <br/><br/>
                这可能是美和纯粹科学之间相当不同的地方：科学不允许虚假出现，科学需要对迷信和不确定性祛魅；但美是可以包含不确定和虚假成分的一种艺术，虚伪、暴力、谎言、阴冷、荒谬甚至错误都可以成为美，机械美的第二层或许也根植于此：不完全正确的直觉也可以成为机械美学的一部分。
                <br/><br/>
                机械美学的审美标准随着时间经常发生翻天覆地的变化，人类幼年时期幻想的飞行器是木头做的，像飞鸟一样的外形。千百年前，人们想象的空中交通是长着翅膀的人、满天的大型热气球和在天上飞行的小汽车，或许几百年后当人们对科学和真理掌握的多得多，回头去看钢铁侠的盔甲，就好像我们看一百年前《科技画报》中离奇可笑的未来世界一样，或是把烟花火箭绑在自己身上的万户飞天——看起来是那么反直觉，那么愚蠢幼稚，甚至还会让人觉得可爱或肃然起敬。但是，我们很难把这个装置和机械美带来的技术确信和充满力量的酷联系起来。毕竟，古代人类的直觉早已不是今天人类的直觉。
                <br/><br/>
                过去的人一定自信地觉得，未来的人们也依然应该觉得这个飞行装置充满了机械美。但现代人绝不会觉得木鸟飞行器是对的，也更不会觉得这个木鸟飞行器充满了机械美学应有的特征，我们大概率会感觉这个发明有种不可实现的脆弱性，除此之外可能是美的。但也偶尔有一些发明能跨越时代，长久地让人们感受到机械美，原因很可能是那两个时代里，人们对这部分的真理掌握，并没有发生太大变化。
                <br/><br/>
                除了技术进步造成的直觉变迁引起机械美的变化，还存在另一类反科学的机械美学是因为人类对规模尺度的极不敏感。举个例子，地球的半径和太阳系的半径相比，到底有多大？银河系的尺寸在这个螺旋星系的旋臂中所占比例到底是多大？
                <br/><br/>
                我们能意识到自己很渺小，但是如果不进行刻意训练，人很难真正意识到我们到底有多渺小。人的渺小不在于我们的绝对渺小，更在于我们甚至缺乏一种比视觉、听觉、触觉之类更高级的感觉或想象力（算比感觉更高级的能力了）来感知我们渺小的尺度：我们真实的渺小真正得有多渺小才能被称作渺小？
                <br/><br/>
                再次用钢铁侠的盔甲作为例子，如果肉体凡胎而不是钢筋铁骨的人坐在那个钢铁铠甲里，比如（不一定准确）：以50G的加速度射出，然后以几十马赫的速度飞行，这是完全不合理的！这种程度的突然加速和超高音速飞行能把肉体在钢铁盔甲里挤成一团浆糊。
                <br/><br/>
                一件真正可用的钢铁盔甲不能这样设计，但我们现代人的机械经验和直觉（在这里是完全错误的）告诉我们，这件钢铁外套实在太性感了，那套盔甲看起来不仅合理甚至还相当舒适！钢铁盔甲当然是机械美学！这里的机械美学依然强烈的存在，但是机械原理已经被颠覆了。原因是任何人，如果不进行严格的科学计算和刻意思考，对机械的直觉都很可能是错的，他会犯人类对物理尺度不敏感的错误，然后过度简化地认为——金属是人类能利用的最坚硬的材料之一；至于50G的加速度，绝不是什么天文数字，虽然从来没体验过，但应该问题不大。加上钢铁盔甲流畅性感的色彩和外观、对金属材料上限的错误估计、对加速度和速度的不敏感，共同创造了一个虚假的但是绝对符合美学品味的机械装置。毕竟我们在谈论机械美，而不是机械工程。
                <br/><br/>
                所以，机械美学那些奇幻想象的源头，还会来源于人类对真正天文数字的尺度到底有多大或多小的判断，是极不敏感的：100000000和100000000000000000000哪个更大，这个很好回答，但到底大多少？如何理解？如果描述和感受？如何准确形容和比较？——就像百万富翁和亿万富翁的区别到底有多大？贫穷会限制想象力，当然不仅来源于信息的贫乏，更来源于人类对悬殊的数字、尺度、体量等等，本身就相当不敏感。但这种迟钝，反而又成就了机械美学。

            </p>

            <br/>

            <h6 className="App-essay-title">Mechanical Aesthetics</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2023</b>
                <br/><br/>

            </p>
        </div>)
}
