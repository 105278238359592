import React from "react";
import {Helmet} from "react-helmet";

export default function Calendar() {
    return (
        <div>
            <Helmet>
                <title>日历 Calendar</title>
            </Helmet>
            <h6 className="App-essay-title">日历</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 三月</b>
                <br/><br/>
                不清楚80后和90后有没有代沟，但可以确定90后和00后有代沟，甚至有一条鸿沟。对于00后而言，90年代就是上辈子的事儿。这情有可原，因为对他们而言，自己90年代还没出生呢。虽然对于90后而言，80年代也像上辈子的事儿，但这是完全不同的上辈子。
                <br/><br/>
                日历和纪年法的原则绝不仅仅更改了对时间的数字标记，日历关乎一切，纪年法是对心理时间的修订。
                <br/><br/>
                当90后看到一个历史人物，如果这个人物出生在20世纪，出生年份以19XX开头，那么在广阔的大历史里，这个人依然能和90后同属于一个时代，以100年为周期。即使这个人出生在18XX年，但是死在19XX年，90后依然可以认为，我们同属于一个百年。但很明显，这是日历在作祟，这些心理活动是在格里高利纪年法之下的产物——公历过份强调了10，100，1000这些十进制的特殊性。
                <br/><br/>
                出生于1999年的人就很有意思了，这些人在心里似乎遥遥望见了新时代的曙光，日历将跨过宏伟的一大步，但他们依然确定无疑地出生在旧时代结束的最后一秒前。
                <br/><br/>
                千禧年的划分宏大得惊人，导致一个小于十年的代际差异，如果横跨在千禧带上，都完全足够形成横亘在心理的鸿沟。这就是格里高利纪年法的威力，让全世界都以耶稣诞生的时间作为时间的锚定物。那么对于今天，耶稣诞生于2022年3个月零12天前。（公元1年才是AC的开始，且假设这确实是耶稣的生日）
                <br/><br/>
                跑在Unix系统里的时间是从1970年开始的，1970年01月01日是计算机开始让时间滴答作响的伊始。所以对于机器，早于1970年之前的日历，无异于宇宙大爆发前，时间混沌无意义。
                <br/><br/>
                对于晚清的秀才们，他们回忆起康乾盛世是否会觉得两百年的时间如一条鸿沟是值得存疑的。对于他们，农历天干地支的六十年才是一个轮回；此外，出自同一个王朝的渊源、在东方帝国盛世兴隆中流淌的民族自负，能否超越了十进制的禁锢，激发两百年后民族危亡时的斗志，依然是说不准的（虽然答案早就有了，两百年真的太久了）。但是，一个比两百年更遥远的、用明月做暗号的汉人王朝依然能再次点燃想象，那么日历确实只能禁锢心理，而无法左右现实。
                <br/><br/>
                尤其，如果你能和张学良一样长寿，那你还能天然地超越二十世纪的日历和00后对话。死于2001年的张学良轻松穿越了满洲铁路和东北军阀的历史烟尘，和听过周杰伦的人牵扯在一起。所以，如果出生得太早，那就活久一点，日历的禁锢还能被长寿填平。
                <br/><br/>
                因为纪年法，我们的心里横亘着一条又一条鸿沟，而这个千禧年伊始的二十年代，又会给我们什么惊喜。本文写于：
                <br/><br/>
                <b>-计算机时间/Unix Time：</b>1678615396秒（自1970年01月01日00分00秒开始 - UTC Time 英国·格林威治天文台）
                <br/><br/>
                <b>-格里高利纪年法：</b>二〇二三年，三月，十二日
                <br/><br/>
                <b>-农历：</b>癸卯年，乙卯月，己巳日（二月廿一），下弦月

            </p>

            <br/>

            <h6 className="App-essay-title">Calendar</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2023</b>
                <br/><br/>

            </p>
        </div>)
}
