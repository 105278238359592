import React from "react";
import {Helmet} from "react-helmet";

export default function OneAndAnother() {
    return (
        <div>
            <Helmet>
                <title>一个问题和另一个问题 One And One Other Question</title>
            </Helmet>
            <h6 className="App-essay-title">一个问题和另一个问题</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 十一月</b>
                <br/><br/>
                <b>第一个：关于自由的抽象问题</b>
                <br/><br/>
                自由无法被剥夺，一个人哭着宣布自由被外界剥夺是可笑的。自由除非被主动放弃，自由只能被自由的主体亲手交出。自由不是一个结果，也不能被固化成最终的战利品。自由是主体的后花园，它永远需要修剪，精心呵护。
                <br/><br/>
                如果外界可以剥夺自由，那么自由就可以被固化、被存放、被给予、被执行，这种行为会最终导致可以被固化和强制执行的那个东西不再是自由，自由消失得无影无踪。自由不能承接被动语态，否则自由会化成烟溜走。自由只存在于尚未发生的未知过程，而不能存在于既定事实的组成方式，已知的和确定的都是不自由的。
                <br/><br/>
                自由是方的、圆的和扁的，也可以是红的、蓝的和无色的。自由可以被一切不愿交出自由的人获取，但是不能被占有，也永远不能被外界给予，因而也不能被剥夺。自由的获得只能是一种主动过程，如果主体拒绝自由被剥夺，那么自由就永远无法被剥夺。
                <br/><br/>
                自由和制度、种族、性别、甚至物种都毫无关系，当一个主体能意识到自我与外部世界的界限，都会产生对自由的渴求。自我意识是自由产生的根源。自由无法被占有，无法被最终获取，自由只能在获取和保护自由的过程中得到实践，而不是被保留；自由无法被给予，也无法被剥夺，它只能在主动争取的过程中存在，或在主动放弃的过程中丧失。
                <br/><br/>
                自由独立于一切系统存在，声称某种事物可以赋予自由是可笑的、自大的和危险的，强行把自由和某种体制关联，会把自由变成舰艇和大炮，在强制执行自由的过程中，导致最大化的反自由。自由不和任何一种主义绑定，自由与意识形态无关，自由只能在拒绝交出自由的过程中反复实践，而不能被任何一种容器、材料和组织方式独占专有。号称可以强化或保障的自由，会导致失去更多的自由。
                <br/><br/>
                自由与主体跟外界互动的方式有关。主体，也只有主体，能对自己是否在实践自由负全部责任。身陷囹圄的自由者，比海滩上自由活动的看客们，更自由，且他们无法互换——因为自由无法被当做固化的商品交换，它无法量化，但可以感受和比较。自由是相对的，因而有形的、固定的、绝对存在的自由势必因为需要被获取、被保存、被传承、被执行而变得不自由。自由只能让主体保护、实践、拒绝交出，感受自由的是主体，自由对外部世界没有意义。
                <br/><br/>
                自由只和主体的选择有关，声称因为外界的限制而无法获得自由，是一种狡猾的脱罪。在过去的某个瞬间，失去自由的主体主动交出了自由，甚至以最不情愿的方式交出，才能完成对自由的失去；甚至以死亡为方式的交出自由，死亡也只能由主体产生，随死亡而来的彻底解脱，是交出自由后所得的回报。
                <br/><br/><br/>
                <b>另一个：关于生存的现实问题</b>
                <br/><br/>
                主体的生存是继续和外界互动的前提，但生存不是存在的前提；自由具有强大的生命力，它是生存繁盛的催化剂，但自由也不是存在的前提。在生存的过程中失去自由，依然可以继续生存。失去自由的结果不是可耻的，主体选择了用自由交换生存，在这个过程里，主体为了生存牺牲自由是主体实践自由的方式——它以获取生存，但丧失自由为最终代价。但试图剥夺自由是可耻的，压迫自由和强迫自由，是看似相反但实则相同的一类行为。
                <br/><br/>
                声称自由需要被遗忘的呼声是晦暗的，因为在这种方式的存在里，生存只能被以最低限度的状态维持，生存的繁荣是遥不可及的，它明目张胆地跟自由宣战。声称自由能以某种方式被保证获取是邪恶的，在那块伪自由的遮羞布下，藏着最极致的奴役。
                <br/><br/>
                遗忘自由会导致直接的奴役，强制执行自由会导致自由变成摧毁自由的大炮，这两者都在引发更多的自由被迫放弃。强行把自由关联任何体制或意识都是危险的描绘，它声称自由属于圆圈，而不属于方块，是一种狡猾的诡辩，它把主动交出自由的过程描绘成外界的剥夺，最终会导致：方块遗忘了自由，圆圈将自由武器化，最后让自由在所有地方都更大规模地灭绝。
                <br/><br/>
                声称自由需要被允许且只有在合适条件下才能获取，是自由被遗忘的根源；声称自由可以被某种制度和意识形态更好的加强和获取，是自由被武器化的根源。对自由的遗忘只关注生存本身，而不关心生存的繁荣；对自由的武器化只关注生存的繁荣，而不关心生存本身。
                <br/><br/>
                生存和自由，它们是无限的存在方式中的两种，它们是主体存在的一次选择，它们互不排斥，也不互为基石，它们盘根错节，想让彼此枝繁叶茂。
            </p>
            <br/>
            <h6 className="App-essay-title">One And One Other Question</h6>
            <p className="App-essay-article">
                <br/>
                <b>Nov 2022</b>
                <br/><br/>

            </p>
        </div>)
}
