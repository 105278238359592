import React from "react";
import {Helmet} from "react-helmet";

export default function Irrelevant() {
    return (
        <div>
            <Helmet>
                <title>重要和不重要 Important And Unimportant</title>
            </Helmet>
            <h6 className="App-essay-title">重要和不重要</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 十月</b>
                <br/><br/><br/>
                <h6><b>高效需要直接丢弃无关紧要的一切内容</b></h6>
                <br/>
                工作效率最高的人，可以放弃除最关键任务外的一切杂务。TA们对核心任务的重视，甚至可以极端到摒除一切排在第二位及以下的任务。这点给了TA们摆脱琐事的自由。
                <br/><br/>
                在学校也一样，成绩最好的学生一般都被过度刻苦绑架，早早被栓在学习成绩的攀比中白白浪费才华。而那些成绩平平的学生则不同，TA们很早就被迫失去在成绩上获胜的可能，反而从那个无关紧要的成绩指标中被解放出来。其中最成就斐然的那些人，在学生时代的表现都不是最顶尖的，有些常年是“中不溜”，甚至很多极端的天才会直接辍学。这些从成绩中解放出来的学生，都只专注地完成极其有限的几件事，这些事甚至会常常被压缩到只有一件。
                <br/><br/>
                工作绩效和学习成绩作为一种指代性很低的指标，对成就的预测实在不怎么准确。虽然不是绝对如此，但这个大趋势一定有深层原因。
                <br/><br/>
                什么可以作为指标？对工作有强大促进的重要力量有三：对结果的激情、专注的目标、果断的执行。
                <br/><br/>
                或者倒过来说也对：执行、目标、激情。这三种力量可以互为因果。
                <br/><br/>
                如果有激情，就会产生目标，有具体的目标就有行动力；如果有行动力，就很可能发现或验证真实的激情，同时制定更精确的目标然后执行；如果有精确的目标，就自然而然产生了强大的行动力，然后就有验证和发现激情的可能。
                <br/><br/>
                - 目标导向：Aim and Shoot. 瞄准了，再射击。这时候目标是最重要的，核心驱动力来自精确化的任务。
                <br/><br/>
                - 行动导向：Shoot and Aim. 先射击，再瞄准。这时候行动是最重要的，当务之急是离开眼前这个泥潭。
                <br/><br/>
                正反都有用，正反都需要。
                <br/><br/>
                但我们还没解决标题中最棘手的问题：什么是最重要的？不要正着问，反过来问：什么事情是你没做最后悔的？
                <br/><br/><br/>
                <h6><b>精通是把最重要的信息内化在直觉里</b></h6>
                <br/>
                如果学英语，最基础的英文单词有500个，熟练得掌握这些基础词汇就能应付最常见的交流……如果做建筑师，最需要掌握的基本设计尺寸可能有几十个：80-90厘米的门宽，1.2m-1.5m的走廊，2.4米层高，2英寸×4英寸的松木立柱/横梁……如果进行文学创作，一个作家能信手拈来几百个TA最喜欢的成语、习语、俗语，熟练掌握两三种文学体裁，对四五种文学母题得心应手……如果做科学研究，一个熟练的生物化学家可能需要对几种实验常用的物质制备流程了如指掌……
                <br/><br/>
                这些数字不是绝对的，但熟练掌握这些重要信息（基础砖块）对精通极有帮助，这些能被闪电般快速获取的内存记忆，丰富了可扩展的发明创造。
                <br/><br/>
                箭袋里的箭，再多都不多。但过多的基本知识是不现实的，过多基础砖块会把所有东西都变成“重要的”，最终丧失简洁性。这些最重要的基础信息，必须在“快速获取” v.s. “数量丰富”之间做取舍。想在任何行业达到精通，最需要被掌握的基础砖块，应该是一个很有限的数字。

            </p>

            <br/>

            <h6 className="App-essay-title">Important And Unimportant</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2024</b>
                <br/><br/>

            </p>
        </div>)
}
