import React from "react";
import {Helmet} from "react-helmet";

export default function PathDependencyOfTechnology() {
    return (
        <div>
            <Helmet>
                <title>技术的惯性 The Path Dependency Of Technology</title>
            </Helmet>
            <h6 className="App-essay-title">技术的惯性</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 三月</b>
                <br/><br/>
                我们身处这个时代，但依然生活在上个时代。因为技术的惯性，我们总是倾向于模仿之前的一切生活细节，即使它们早已荡然无存。
                <br/><br/>
                <b>1. 古典与新古典</b>
                <br/><br/>
                没想好，需要某人输入。
                <br/><br/>
                <b>2. 工艺美术运动</b>
                <br/><br/>
                没想好，需要朋友们输入。
                <br/><br/>
                <b>3. 后现代审美</b>
                <br/><br/>
                你回家看看，你的公寓外立面、楼梯扶手、门把手、窗帘、踢脚线、浴室龙头、茶杯……有多少东西不属于这个时代——一个能把木头、塑料、石材、玻璃和金属扭曲成几乎任何形状的时代：你的窗帘上绣着维多利亚时期的花纹、瓷器是青花、茶几的曲线是明式家具的线脚收分，只有那张宜家的桌子弥漫着大工业时代机器渗透的资本主义味道。
                <br/><br/>
                <b>4. 信息革命</b>
                <br/><br/>
                更可笑的事情，Google在2008年发布了自家浏览器Chrome的新版引擎。从新世纪开始，自Netscape引发的浏览器革命，信息时代融合了激进的黑客理想、开放信息的宏图伟愿、知识平等的革命激情，但Chrome居然需要一个化石能源驱动的、被左派反复鞭挞不环保的、政治上也不太正确的V8内燃机引擎来驱动？“文明的你”应该从脚指头都笑出了声。
                <br/><br/>
                不仅因为我们如此热爱“环保”也如此“文明”，更因为你在电脑芯片、主板或Chrome浏览器里，可曾看到过任何气缸和活塞运动的痕迹？技术的惯性渗透在历史脉络里最重要的表现就是我们对它的称呼——Google Chrome V8 JavaScript Engine——一串时空错乱又古怪离奇的组合，仿佛打开电脑用Chrome网上冲浪，还能听到八缸四冲程发动机里活塞的回响。
                <br/><br/>
                <b>5. 智能时代</b>
                <br/><br/>
                没想好，需要我补充，如果技术的惯性缺席，那就很意外了。
                <br/><br/><br/>
                To be continued...

            </p>

            <br/>

            <h6 className="App-essay-title">The Path Dependency Of Technology</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2023</b>
                <br/><br/>

            </p>
        </div>)
}
