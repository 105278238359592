import React from "react";
import {Helmet} from "react-helmet";
import TwoSystems from "../../assets/images/augmentation/two_systems.png";
import DeAug from "../../assets/images/augmentation/de_augmentation.png";
import Cover from "../../assets/images/augmentation/cover.png";

export default function Augmentation() {
    return (
        <div>
            <Helmet>
                <title>读《增强人的智能：一个概念框架》 Reading "Augmenting Human Intellect: A Conceptual Framework"</title>
            </Helmet>
            <h6 className="App-essay-title">读《增强人的智能：一个概念框架》</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 五月</b>
                <br/><br/>
                <img src={Cover} alt="" width={"66%"}/>
                <br/><br/><br/>
                <h6><b>序</b></h6>
                <br/>
                Notion的创始人Ivan Zhao在一次播客采访中提到了这篇写于半个世纪前但很有建设性的论文。[1]
                <br/><br/>
                我最近重读了这篇名叫"Augmenting Human Intellect: A Conceptual Framework"的论文（增强人的智能：一个概念框架）[2]。Douglas Engelbart著 [3]，斯坦福大学研究所和美国空军出资支持。论文发表于1962年10月，最终以删节本的形式发表于Vistas in information Handling, Published by Spartan Books.
                <br/><br/>
                这篇文章用一种独特的方式，分析了技术增强人类智能的定义和方法，以及什么是“人-人造物”界面，同时剖析了哪些是增强人智能的关键因素。
                <br/><br/>
                技术狂欢发生时，人们很少意识到，技术的目的既不是提高人本身固有的智力，也不是通过技术淘汰人。“人与人造物”是一种共生关系，当人和工具的结构关系被精巧地编排时，这个精妙的组合会遵循自然的进化方式，赋予人被自己所创造的工具增强智力后所带来的进化效果。
                <br/><br/>
                在人-人造物的二元关系里，最重要的观点是不要将这个二元关系中的“人”工具化，如何把“人”所创造的“人造物”最高效地嵌入“人-人造物”的系统中，才是人在过去的数个世纪中能够作为一个物种在新技术下不断进化的根本原因和手段。在过去的几十个世纪里，我们曾这样无意识地操作，而今天我们必须有意识地这样做。
                <br/><br/>
                文中第一段，我节选了Engelbart在1962年对建筑师被增强智能后的工作方式的畅想。半个世纪后的建筑师看到，应该会无比惊讶地感到熟悉。
                <br/><br/><br/>
                <h6><b>1. 引言 Introduction </b></h6>
                <br/>
                首先，“如何增强人的智能”是一个研究框架，这个思维框架认为人进行智力活动的效率可以被工具提升，而提升的边界需要被限定，同时其效果应当被测量。
                <br/><br/>
                <b>1.1 解决复杂问题的建筑师 Architect</b>
                <br/><br/>
                Engelbart在第一章的引言中描述了对未来建筑师工作场景的想象。为什么用建筑师做例子？因为在前计算机时代，建筑师是智慧和专家的代名词，建筑师的日常挑战完全符合开篇Engelbart对复杂场景（Complex Situation）的定义。
                <br/><br/>
                建筑师的挑战时而具体细碎、时而抽象含糊。这个挑战需要具象为一个“建筑方案”（Plan），然后形成一套客户委托建筑师所寻求的“解决办法”（Solution）。最后这套想象会变成叫做建筑（Architecture）的物质实体。
                <br/><br/>
                想象你自己身处1962年，第一台苹果计算机要在1975年6月才被史蒂夫·沃兹尼亚克制作。当年最资源优越的人能接触的电脑是什么样的：最多有一个阴极射线管（CRT）的显示器，一台黑乎乎的庞大机器，没有鼠标。
                <br/><br/>
                <i style={{color: "grey"}}>
                    Let us consider an augmented architect at work. He sits at a working station that has a visual
                    display
                    screen some three feet on a side; this is his working surface, and is controlled by a computer (his
                    "clerk") with which he can communicate by means of a small keyboard and various other devices.
                    <br/><br/>
                    He is designing a building. He has already dreamed up several basic layouts and structural forms,
                    and is
                    trying them out on the screen. The surveying data for the layout he is working on now have already
                    been
                    entered, and he has just coaxed the clerk to show him a perspective view of the steep hillside
                    building
                    site with the roadway above, symbolic representations of the various trees that are to remain on the
                    lot, and the service tie points for the different utilities. The view occupies the left two-thirds
                    of
                    the screen. With a "pointer," he indicates two points of interest, moves his left hand rapidly over
                    the
                    keyboard, and the distance and elevation between the points indicated appear on the right-hand third
                    of
                    the screen.
                    <br/><br/>
                    Now he enters a reference line with his pointer, and the keyboard. Gradually the screen begins to
                    show the work he is doing—a neat excavation appears in the hillside revises itself slightly, and
                    revises itself again. After a moment, the architect changes the scene on the screen to an overhead
                    plan view
                    of
                    the site, still showing the excavation. A few minutes of study, and he enters on the keyboard a list
                    of
                    items, checking each one as it appears on the screen, to be studied later.
                    <br/><br/>
                    Ignoring the representation on the display, the architect next begins to enter a series of
                    specifications and data—a six-inch slab floor, twelve-inch concrete walls eight feet high within the
                    excavation, and so on. When he has finished, the revised scene appears on the screen. A structure is
                    taking shape. He examines it, adjusts it, pauses long enough to ask for handbook or catalog
                    information
                    from the clerk at various points, and readjusts accordingly. He often recalls from the "clerk" his
                    working lists of specifications and considerations to refer to them, modify them, or add to them.
                    These
                    lists grow into an evermore-detailed, interlinked structure, which represents the maturing thought
                    behind the actual design.
                    <br/><br/>
                    Prescribing different planes here and there, curved surfaces occasionally, and moving the whole
                    structure about five feet, he finally has the rough external form of the building balanced nicely
                    with
                    the setting and he is assured that this form is basically compatible with the materials to be used
                    as
                    well as with the function of the building.
                    <br/><br/>
                    Now he begins to enter detailed information about the interior. Here the capability of the clerk to
                    show
                    him any view he wants to examine (a slice of the interior, or how the structure would look from the
                    roadway above) is important. He enters particular fixture designs, and examines them in a particular
                    room. He checks to make sure that sun glare from the windows will not blind a driver on the roadway,
                    and
                    the "clerk" computes the information that one window will reflect strongly onto the roadway between
                    6
                    and 6:30 on midsummer mornings.
                    <br/><br/>
                    Next he begins a functional analysis. He has a list of the people who will occupy this building, and
                    the
                    daily sequences of their activities. The "clerk" allows him to follow each in turn, examining how
                    doors
                    swing, where special lighting might be needed. Finally he has the "clerk" combine all of these
                    sequences
                    of activity to indicate spots where traffic is heavy in the building, or where congestion might
                    occur,
                    and to determine what the severest drain on the utilities is likely to be.
                    <br/><br/>
                    All of this information (the building design and its associated "thought structure") can be stored
                    on a
                    tape to represent the design manual for the building. Loading this tape into his own clerk, another
                    architect, a builder, or the client can maneuver within this design manual to pursue whatever
                    details or
                    insights are of interest to him—and can append special notes that are integrated into the design
                    manual
                    for his own or someone else's later benefit.
                    <br/><br/>
                    让我们来考虑一位增强智能的建筑师在工作。他坐在一个工作站前，工作站配有一个大约三英尺见方的视觉显示屏；这是他的工作台，由一台计算机（他的“助手”）控制，他可以通过一个小键盘和其他各种设备与其沟通。
                    <br/><br/>
                    他正在设计一栋建筑。他已经构思了几种基本布局和结构形式，并在屏幕上进行尝试。他正在处理的布局的测量数据已经输入完毕，他刚刚让助手显示出陡峭山坡建筑场地的透视图，图中包括上方的道路、将保留在地块上的各种树木的符号表示，以及不同公用设施的服务连接点。这幅图占据了屏幕的左三分之二。他用一个“指示器”指向两个感兴趣的点，迅速在键盘上移动左手，所指点之间的距离和高程出现在屏幕右侧的三分之一处。
                    <br/><br/>
                    现在他用指示器和键盘输入了一条参考线。屏幕逐渐开始显示他的工作——山坡上出现了一处整齐的挖掘区，稍微修改了一下，又再修改了一下。过了一会儿，建筑师将屏幕上的场景切换到场地的俯视平面图，仍然显示着挖掘区。经过几分钟的研究，他在键盘上输入了一系列项目，并在每个项目出现在屏幕上时进行检查，以便稍后研究。
                    <br/><br/>
                    忽略显示屏上的表示，建筑师接下来开始输入一系列规格和数据——六英寸厚的地板，十二英寸厚、八英尺高的混凝土墙，等等。当他完成后，修订后的场景出现在屏幕上。一个结构正在成形。他检查它，调整它，暂停片刻，向助手请求手册或目录信息，并相应地进行再调整。他经常从“助手”那里调出他的工作清单来参考、修改或添加。这些清单逐渐形成一个越来越详细、互相关联的结构，代表着实际设计背后的成熟思考。
                    <br/><br/>
                    在这里那里规定不同的平面，偶尔有弯曲的表面，并将整个结构移动约五英尺后，他终于使建筑的粗略外形与环境很好地平衡，并确信这种形态基本上与将使用的材料以及建筑的功能相兼容。
                    <br/><br/>
                    现在他开始输入关于内部的详细信息。在这里，助手能够显示他想要检查的任何视图（内部的切片，或从上方道路看结构的样子）非常重要。他输入特定的固定装置设计，并在特定房间中进行检查。他确保窗户的阳光不会使道路上的司机眩目，“助手”计算出某个窗户将在仲夏早晨6点到6点半之间强烈反射到道路上。
                    <br/><br/>
                    接下来他开始进行功能分析。他有一份将要占用这栋建筑的人员名单，以及他们日常活动的顺序。“助手”允许他依次跟踪每个人，检查门的开关方向，哪里可能需要特殊照明。最后，他让“助手”结合所有这些活动顺序，指出建筑中交通繁忙的地方或可能发生拥堵的地方，并确定公用设施的最大负荷可能在哪里。
                    <br/><br/>
                    所有这些信息（建筑设计及其相关的“思维结构”）都可以存储在磁带上，作为建筑设计手册的呈现。将这盘磁带加载到他自己的助手中，另一个建筑师、建造者或客户就可以在这个设计手册中进行操作，追寻他感兴趣的任何细节或见解，并可以添加特别注释，这些注释会被整合到设计手册中，以供他自己或他人日后使用。
                </i>
                <br/><br/>
                让我们回到1962年。当时的计算机主要用于政府和大型企业，再倒退二十年，计算机的目的是为了赢得一次世界大战。在那个时代背景下，如果有一台允许个人使用的计算机，Engelbart的问题就显得非常自然：这会带来什么好处？这台机器能否大规模地增强个人的智力？
                <br/><br/>
                这就是Douglas Engelbart在1962年的时代背景。他在论文中提出的问题更基本：怎么定义智能？如何增强智能？如何设计一个系统来持续增强它？哪些是增强智能的关键因素？
                <br/><br/>
                <b>1.2 HCI（Human Computer Interface 人机交互）的研究重点</b>
                <br/><br/>
                <i style={{color: "grey"}}>
                    (1)to find the factors that limit the effectiveness of the individual's basic information-handling
                    capabilities in meeting the various needs of society for problem solving in its most general sense;
                    and
                    <br/><br/>
                    (2)to develop new techniques, procedures, and systems that will better match these basic
                    capabilities to
                    the needs' problems.
                    <br/><br/>
                    (1)找出限制个体基本的信息处理能力在满足社会各种需求（从最广义上来说是解决问题）方面的因素；以及
                    <br/><br/>
                    (2)开发新的技术、程序和系统，更好地将这些基本能力与需求问题相匹配。
                </i>
                <br/><br/><br/>
                <h6><b>2. 概念框架 Conceptual Framework</b></h6>
                <br/>
                <b>A. 基本定义</b>
                <br/><br/>
                智力是多层级的处理能力及其子能力(processes &
                sub-process)被精巧地编排在一起，所形成的理解力(Comprehension)和解决问题的能力(Problem Solving)。
                <br/><br/>
                - Process & Sub-Process: 过程、子过程
                <br/>
                - Process Capability: 处理能力
                <br/><br/>
                <b>
                    - H-LAM/T System (Human using Language, Artifacts and Methodology with his Training): <br/>
                    人类(Human)经过训练(Training)，使用语言(Language)、人造物(Artifact)和方法(Method)的能力系统。
                    <br/><br/>
                </b>
                - Capabilities Repertoire Hierarchy: 全部能力的层级结构
                <br/><br/>
                <b>B. 基本视角</b>
                <br/><br/>
                如何准确地探索和评估智能的各项是什么及其水平是其中的关键步骤。
                <br/><br/>
                一切对于智能进行增强的手段，根本上是对语言、人造物和方法进行增强的手段。
                <br/><br/>
                <i style={{color: "grey"}}>
                    Competitive individuals that react to useful methods, will create a more radical equipment change.
                    <br/><br/>
                    而那些对有用方法做出反应的竞争性个体，将会引发更为激进的设备变革。
                    <br/><br/>
                </i>
                这句话几乎是写给乔布斯的脚注。
                <br/><br/>
                <b>C. 细节讨论</b>
                <br/><br/>
                ● 智能的来源
                <br/><br/>
                当一个门外汉看到一台电脑正在处理非常复杂的任务时，这种表象会让TA认为这是一种神秘的智慧力量在吸收信息并进行智能思考。而事实上，这种智能是对一系列的功能和实体进行相当巧妙的组织，所形成的协同效应的结果。
                <br/><br/>
                以编程为例，一个程序员会经历大致三个层级：1. 针对问题，制定大体上的程序功能（function） 2.
                在第一级上优化，尽可能少地使用function来满足第一级的任务 3. 设计具体的机器命令（command）来满足第二层级的每个功能（function）4.
                如果继续深钻，电气工程师、逻辑工程师、电路设计师……原子物理学家等等，都必须参与进来对不断深钻的层级进行解释。直到我们人类无法理解，这个过程也不会停止。
                <br/><br/>
                Engelbart强调了在这些部件的整合中，组织(organization)是最关键的因素，而借用生物学家的话——协同作用（synergy）——是这些层层嵌套的部件，通过精妙的编排，实现了总体结果远大于部分之和的力量。
                <br/><br/>
                自然界中产生的“智能”，所依赖的一个重要结构就是对基本元素有“层级”（Hierarchy）地进行“组织”（Structuring），这些层层嵌套的零件被巧妙地组织起来，才形成了“智能”的载体。
                <br/><br/>
                ● 智能放大器
                <br/><br/>
                放大智能不是使一个人的自然智力得到提高、或者类似吃了聪明药一般变得更聪明；智能放大的方式是让人类在解决某类复杂问题时的理解能力（Comprehension）或解决手段（Solution）得到增强（Augmented）。
                <br/><br/>
                打一个比方：扩音器能放大人的声音让整个足球场听到，这是一种增强智能。让一个人能发出恐龙的叫声，这是聪明药。
                <br/><br/>
                智能放大的根本在于重新组织“智能”（intellectual capabilities）以形成新的具有协同效应的组织结构
                （synergistic structuring）。而最终拥有这种放大智能的就是H-LAM/T系统<i style={{color: "grey"}}>（见上方A.
                基本定义）</i>。对LAM/T系统的增强手段，就是人类智能的放大器。
                <br/><br/>
                <i style={{color: "grey"}}>
                    In amplifying our intelligence, we are applying the principle synergistic structuring that was
                    followed
                    by natural evolution in developing the basic human capabilities. What we have done in the
                    development of
                    our augmentation means is to construct a superstructure that is a synthetic extension of the natural
                    structure upon which it is. In a very real sense, as represented by the steady evolution of our
                    augmentation means, the development of "artificial Intelligence" has been going on for centuries.
                    <br/><br/>
                    在放大我们的智力的过程中，我们遵循了和自然进化中相同的协同结构原则来发展人类的基本能力。我们在发展增强手段时所做的，是建造一个上层建筑，这个结构是在它所建立的自然结构上进行的综合延伸。从非常真实的意义上讲，已经持续了几个世纪的“人工智能”的发展，就是我们增强手段在稳步进化的代表。
                    <br/><br/>
                </i>
                ● 两种系统：人-人造物
                <br/><br/>
                人类Human和人造物Artifact是H-LAM/T系统中唯二的实体，语言Language、方法Methodology、训练Training均为抽象概念。而人类进行的所有复合过程(composite
                process)，最终都可以分解成精确的人类过程(explicit human process)，和精确的人造物过程(explicit artifact
                process)。
                <br/><br/>
                人机界面（man-machine）这个词被使用了几十年，用以强调人使用机械时的操作边界；但是人-人造物（man-artifact）早已存在了几十个世纪。从使用工具开始、人就和人造物一同进化，这个界面就已经存在了。
                <br/><br/>
                <img src={TwoSystems} alt="" width={"100%"}/>
                <br/><br/>
                上图片中的左侧方框是人类过程，右侧方框是人造物过程，人和人造物之间存在一层界面，被称作“人-人造物界面”（man-artifact
                interface）。左右两个过程之间，通过这层界面进行能量交换，而这部分能量交换的绝大部分目的，是为了信息交换（information
                interchange）。
                <br/><br/>
                这个界面的设计经常只是为了提供一个功能性的耦合，允许人-人造物进行物质和信息交换，以便人发挥出更高级更深层的功能。人的手指运动传递给打字机按键构造，只是人为了更深层的行为：书写、交流、思考等更高级活动的一个环节。
                <br/><br/>
                当然，除了人-人造物的能量交换，这两者和外界环境也有直接的能量信息交换。但最大的挑战在于这个系统内部，也就是这个界面如何协调人-人造物的交互，才能最好地增强人的能力。
                <br/><br/>
                ● 概念，符号和外部符号——抽象和对抽象的具象化
                <br/><br/>
                <ul>
                    <li>对概念的控制（Concept-概念：门后有人 -> 可能受伤 -> 别使劲关门，非语言文字）</li>
                    <li>对符号的控制（Symbol-符号：脑海中的符号，27只羊）</li>
                    <li>人为对外部符号进行控制（External Symbol-外部符号：用树枝在沙子上写下数字27）</li>
                </ul>
                人类因为具有在脑海中对最原始的概念进行操作的能力，所以对身边环境的预测和控制能力大大增强。
                <br/><br/>
                接下来人掌握了使用符号来处理一系列问题，例如使用羊群的数量（27只）来放牧——而不是直接记住每一只羊的脸。
                <br/><br/>
                接着，人开始打绳结，发明了语言、文字，用树枝在沙子上写字，纸、笔，等等。
                这些对外部符号的操作手段，拓展了人类的记忆内存，以及如何调整和控制自己的概念（Thinking，思考）。
                <br/><br/>
                这种对外部符号进行控制的能力对人类思考的进化有重要意义。由此，根据原始的沃尔夫假设（文化被语言结构所限制），Engelbart提出了一个扩充版的“沃尔夫假设”，补充了“是否能有效地使用智能”对理解和改造世界的意义。
                <br/><br/>
                新“沃尔夫假设”
                <br/><br/>
                <i style={{color: "grey"}}>
                    The Whorfian hypothesis states that "the world view of a culture is limited by the structure of the
                    language which that culture uses."
                    But there seems to be another factor to consider in the evolution of language and human reasoning
                    ability. We offer the following hypothesis, which is related to the Whorfian hypothesis: Both the
                    language used by a culture, and the capability for effective intellectual activity, are directly
                    affected during their evolution by the means by which individuals control the external manipulation
                    of
                    symbols. (For identification, we Will refer to this as the Neo-Whorfian hypothesis.)
                    <br/><br/>
                    沃尔夫假说指出，“文化的世界观受该文化所使用的语言结构的限制。”
                    但在语言和人类推理能力的演变过程中，似乎还有另一个因素需要考虑。我们提出以下假说，这与沃尔夫假说相关：文化所使用的语言和<b>有效地进行智力活动的能力</b>，在其演变过程中都直接受到<b>个体控制外部符号操作方式</b>的影响。（为了便于识别，我们将其称为新沃尔夫假说。）
                    <br/><br/>
                </i>
                对H-LAM/T系统而言，由于计算机的引入，相当于引入了一种极其先进的对外部符号进行控制的手段。重点来了：
                <br/><br/>
                <ul>
                    <li>自动化对外部符号的操作——电脑通过先进的图形化技术，把可操作的符号呈现在人类眼前</li>
                </ul>
                如果我们很难想象什么样的人造物或者系统可以提高人们对符号进行操作的能力，那么反过来思考很可能更容易：什么样的情况会削弱人类对外部符号的操作能力？下图就是这样一个实验。
                <br/><br/>
                <img src={DeAug} alt="" width={"100%"}/>
                <br/><br/>
                从上到下是使用不同方式书写"Augmentation is fundamentally a matter of organization."
                <br/><br/>
                -“未增强”（正常在纸上用手写连笔字），20秒
                <br/>
                -“去增强化”（把铅笔绑在一块砖上）写连笔字，65秒
                <br/>
                -“去增强化”（同上）写字号更大的连笔字，42秒
                <br/><br/>
                如果对外界符号操作的能力没有被增强，反而被削弱了，那么就会需要更多的设备材料——更大的字体会占据更多的纸张空白——才能弥补这种“去增强”带来的损失。
                <br/><br/>
                我们接着读，Engelbart在下面这段话中阐述了这样一个神奇“盒子”的畅想：
                <br/><br/>
                <i style={{color: "grey"}}>
                    To reflect further upon the implications of this hypothesis, the following hypothetical artifact
                    development can be considered, representing a different type of external symbol manipulation that
                    could
                    have had considerable effect. Suppose that our young technology of a few generations ago had
                    developed
                    an artifact that was essentially a high speed, semi-automatic table-lookup device—cheap enough for
                    almost everyone to afford and small and light enough to be carried on the person. Assume that the
                    individual cartridges sold by manufacturers (publishers) contained the look-up information, that one
                    cartridge could hold the equivalent of an unabridged dictionary, and that a one-paragraph definition
                    could always be located and displayed on the face of the device by the average practiced individual
                    in
                    less than three seconds. The fortunes of technological invention, commercial interest, and public
                    acceptance just might have evolved something like this.
                    <br/><br/>
                    If it were so very easy to look things up, how would our vocabulary develop, how would our habits of
                    exploring the intellectual domains of others shift, how might the sophistication of practical
                    organization mature (if each person can so quickly and easily look up applicable rules), how would
                    our
                    education system change to take advantage of this new external symbol-manipulation capability of
                    students and teachers (and administrators)?
                    <br/><br/>
                    为了进一步思考这一假设的影响，可以考虑以下假设的人造物发展，这代表了一种不同类型的外部符号操作，可能会产生相当大的影响。假设在几代之前，我们的新兴技术已经开发出一种本质上是高速、半自动的查询设备——便宜到几乎每个人都能买得起，并且小巧轻便到可以随身携带。假设制造商（出版商）出售的单个盒子包含了所有查询信息，一个盒子可以容纳一本未删节词典的内容，并且经过训练的普通人总能在不到三秒钟的时间内在设备屏幕上找到并显示一段话长度的释义。这项技术发明能带来的财富、商业利益和大众的接受，可能就会把它演变成真。
                    <br/><br/>
                    如果查找信息是如此容易，我们的词汇量将如何发展？我们探索他人知识领域的习惯将如何转变？如果每个人都能如此快速轻松地查找适用的规则，实际组织的复杂性将如何成熟？我们的教育系统将如何改变，以利用学生、教师（和管理员）这种新的外部符号操作能力？
                    <br/><br/>
                </i>
                没错，这就是我们手里正拿着的东西——手机——其中的一个子功能。
                <br/><br/>
                简而言之，Engelbart这个研究的全部内容，都基于一个基本视角：人对外部符号进行操作的具体方式，影响着人的智力效率。
                这个“外部符号”包含语言和对概念进行操作的一切标识系统，“对外部符号的操作”包含人所制造的用来储存、表示和控制符号的人造物（工具），方法和技能训练。
                <br/><br/>
                ● 全部能力的层级次序
                <br/><br/>
                如何增强智能需要对“人”和“人造物”两者分别进行研究，其中关键之处，就是考察“人-人造物”系统中的某项要素是否可以通过重新编排而产生新的“协同效应”，从而产生增强智能的手段。
                <br/><br/>
                如果在给出某个能力（无论什么等级或层次的），如果再也无法通过增强这个能力来达到增强智能的目的，那么这个能力就是一种基础能力（Basic
                Capabilities）。
                <br/><br/>
                Engelbart归纳了五种基础能力，文中承认这种划分不是绝对性的，但对增强智能的研究角度非常重要。这五种能力分别是：心理结构化(mental
                structuring)、概念结构化(concept structuring)、符号结构化(symbol structuring)、过程结构化(process
                structuring)和实体结构化(physical structuring)。
                <br/><br/>
                其中心理结构化、概念结构化和符号结构化，对应着H-LAM/T系统中的人(Human) +
                语言(Language)两个元素；过程结构化对应着H-LAM/T系统中的方法(Method)；而实体结构化不言自明，对应着人造物(Artifact)这个实体。
                <br/><br/>
                这五种结构，除实体结构是独立的客观存在外，其余的结构化都会需要相互依赖才得以具备效应。这些结构不断嵌套，以一种链式反应的风格彼此放大，直到产生协同效应——其最重要的表现特征就是：整体大于部分之和。
                <br/><br/>
                这个过程有可能这样（首字母大写是H-LAM/T系统中的部分，小写是结构化系统的类型）：
                <br/><br/>
                1.首先出现了一种新型的人造物(Artifact)，它带来了全新的实体结构化（physical structuring）。
                <br/><br/>
                2.为了使用这种全新的人造物，需要对人(Human)进行特定方法（Method）的操作训练（Training/process structuring）。
                <br/><br/>
                3.全新的过程结构（Method/process structuring），依赖于发明全新的概念结构(concept structuring)、符号结构(symbol structuring)，甚至语言本身（Language）。
                <br/><br/>
                4.而这三种结构（概念、符号、过程），都依赖心理结构(mental structuring)的基本能力。
                <br/><br/>
                5.心理结构因新的概念、符号和过程而发生拓展，全新的心理进而又影响概念和符号的形态。
                <br/><br/>
                6.接着反过来，又催化出更新的过程结构和心理结构。
                <br/><br/>
                7.不断嵌套更新…
                <br/><br/>
                这个“左脚踩右脚”的方式，不停地催化出新的方式、内容、概念、符号、心理，不断循环更新——智能活动因为组成的基本元素相互激发的效应，而变得和过去迥然不同，直到产生“总和远大于部分”的协同效应。
                <br/><br/>
                首先，我们识别了这些基础能力（Basic Capabilities），而增强智能的关键之处在于如何巧妙地组织这些基本能力（Organizing
                & Structuring），让这些能力可以更加高效地产生“协同作用”（Synergy）。想象一下当年对建筑师案例的畅想，就能明白用计算机来精心编排（Structuring）意味着什么。
                <br/><br/>
                智力增强的内涵就在于使用人造物（artifact），来重新编排组织人（man）的基本能力（Basic Capability）。
                <br/><br/>
                综合上面这些内容，让我们对1962年的这篇论文进行一些拓展。现在请大家跟着我做一个推理思考，在不同的时代，人们对外部符号操作能力的关键人造物（artifact）是什么，以及谁在当时拥有或垄断着这种能力：
                <br/><br/>
                1.没有纸笔（或纸笔昂贵）的年代，谁垄断了对符号进行控制的权利？答：口口相传的神话和讲故事是一种对概念（头脑内部）进行操作的垄断能力。
                <br/><br/>
                2.没有打印机的年代呢？答：在翰林院抄书，在岳麓书院中办学讲课，对笔墨纸砚的制造……都是对外部符号进行控制的垄断能力。
                <br/><br/>
                3.纸笔和打印机极大地普及后呢？答：获取记录着符号的书籍，对思维活动和语言内容进行创造性加工，以及这些符号得以传播的覆盖面（出版），是对外部符号进行控制的垄断能力。
                <br/><br/>
                4.当计算机提供了绝佳的记录和操作外部符号的能力呢？答：对信息进行删选，对内容进行创造性关联和加工，以及确保内容所能触达的群体（流量），依然垄断着对外部符号进行操作的能力。
                <br/><br/>
                5.当AI人工智能提供了内容创作的能力呢？谁以及如何，对外部符号进行控制呢？答：AI能力提供者，AI分发者，审核人与分发人。
                <br/><br/><br/>
                <h6><b>3. 老问题：还需要训练掌握那些古老技能吗？</b></h6>
                <br/>
                在1962年，个人电脑尚未到来前，最大的问题是个人电脑能做什么，以及一旦发生会增强哪些人，又会取代哪些劳动力。而在2024年，人工智能作为时代的最大变革，引用我之前写过的一段话来阐述我的基本看法：
                <br/><br/>
                <i style={{color: "grey"}}>
                    有了翻译模型还需不需要学外语？有了编程copilot还需不需要学编程？有了GPT还需不需要写作？如果学习的目标是把自己变成工具，那真没必要学了，机器会比你强很多。如果学习的目的是为了提出有价值的问题、精确思辨以及解决复杂的现实挑战，那地球末日来那天，学习上述技能都依然不过时。这些技能不是智慧的载体，是通往智慧的管道。
                    <br/><br/>
                </i>
                Engelbart的论文中类似的概念更加清晰：对符号进行操作的能力，诞生了“理解”（comprehension）和“问题解决”（problem
                solving）的能力，它们组成了人类最直观的智能现象，但它们的存在方式并非是智能本身。对外部符号控制的能力才是智能活动本身，通过任何方式对“控制外部符号”的能力进行增强的手段，都会带来智能水平的上升，这种上升是精心编排人类基本能力的增强效应，而非直接提高人类智力本身。
                <br/><br/><br/>
                <h6><b>4. 新挑战：智能失速</b></h6>
                <br/>
                让我们再次回到1962年，那个时候的建筑师和今天的建筑师面临的挑战没什么两样：清楚当地的建造和防火规范，深入了解客户对空间的详细需求，计算造价和结构，设计建筑的平面（Plan）和空间排布（Program）。建筑师用铅笔在草图纸上画下的每一条线，都在解决上述问题汇总起来产生的复杂挑战，Engelbart论文开篇对复杂场景（Complex
                Situation）的定义，就是建筑师日常工作的写照。
                <br/><br/>
                正因为建筑师画下的每一条线都需要应对复杂的目的，在那个没有电脑来增强智力的年代，建筑师绝大多数都需要经受极其严苛的手绘训练，TA们非常明白一件事：一根线所代表的混凝土墙体和梁柱楼板，在现实世界中有多么难建造，以及和这根线条的交互（Interaction）意味着什么。在1962年，增强建筑师智能的工具只有一套：书写以及绘画（水彩渲染）用的纸和笔。这件事解释了为什么相当多老派建筑师，对线条的痴迷达到宗教级的程度。
                <br/><br/>
                相反，反观电脑增强智力的现代建筑师，在与电脑交互时通过Sketchup、Rhino等建模软件，汪洋恣肆画出的线条和空间，经常显得随性幼稚，因为从速度上讲，这种人机交互(HCI)的速率，是过去纸笔的1000倍都不止（没有精确计算）。于是基于这种情况，做实体模型（Physical
                Model）就成了一种极其重要的方式来强迫建筑师放慢思考速度，了解自己被增强的想象力，究竟在现实中会带来什么麻烦，尤其是这些放肆的曲线所隐含的工作量到底有多大。
                <br/><br/>
                上述这些论证，是电脑出现后人被智能增强的反面，略有些马后炮。
                但谁能说自己从没有遇到过后工业时代的通病呢：交互速度大幅提升后对工作质量的损害，以及对事物基本原理的丢失。
                <br/><br/>
                这种事曾经发生在建筑师身上，现在马上要发生在计算机工程师身上。大家已经准备不用脑子地让Copilot完成一个既不知道如何运作、也不想知道如何运作的、但恰好能用的功能代码，一如三维建模软件和CAD出现后，被增强的建筑师会陷入的智能漩涡。
                <br/><br/>
                在1962年，Engelbart设想了这样一个增强的智能，但他当时还不能预知智能增强后的弊端。
                <br/><br/>
                在今天，人工智能的崛起让我们面对着一些非比寻常的问题。
                <br/><br/>
                <b>我暂时称之为“智能失速”：</b>
                <br/><br/>
                - 智能工具增强人的智能 ------> <br/>
                - 慢速进行的智力活动被极大地提速 ------> <br/>
                - 被增强的人进行高速的智力活动 ------> <br/>
                - 智力活动中最重要的部分被跳过 ------> <br/>
                - 高级推理只有低速状态下才会发生 ------> <br/>
                - 工具削弱人的高级推理能力（尤其是那部分只能在慢速状态下才会发生的根本性创造活动） ------> <br/>
                - 创造性的智力活动被削弱 ------> <br/>
                - 失速的智能 ------> <br/>
                - 未来是什么？？？
                <br/><br/>
                过度互联网化的社群中，有一种精神涣散的愚钝，这是智能失速最直观的反应。因为尚未意识到智能失速风险的人群，在增强智能的工具面前，几乎不堪一击。
                <br/><br/>
                用手写字、用腿走路、用嘴说话、用脑思考，纸、笔，粉笔、黑板，报刊、书籍……这些工具是过去几十个世纪里，增强智能的工具中使用速度和思考速度最匹配的一批。
                <br/><br/>
                主动放慢使用工具的节奏和主动降低工具效率，在实用意义上也成立——即便是增强的智能，也时不时需要主动降速来提高智能的质量。
                <br/><br/>
                我们和石器时代的祖先，没有自己想象的那么大区别。主动降低对外部符号系统的操作速度——刹车和油门一样重要——都对智力活动本身意义深远。

                <br/><br/><br/>
                <hr/>

                <b>Reference</b>
                <br/>
                <b>1. </b>No Priors Interview: https://www.youtube.com/watch?v=HW5Yeqn9eV4
                <br/>
                <b>2. </b>Augmenting Human Intellect: A conceptual Framework:
                https://www.dougengelbart.org/pubs/augment-3906.html
                <br/>
                <b>3. </b>Douglas Engelbart: https://en.wikipedia.org/wiki/Douglas_Engelbart
                <br/>

            </p>

            <br/>

            <h6 className="App-essay-title">Reading "Augmenting Human Intellect: A Conceptual Framework"</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2024</b>
                <br/><br/>

            </p>
        </div>)
}
