import React from "react";
import {Helmet} from "react-helmet";
// import {Link} from "@mui/material";

export default function Research() {
    return (
        <div>
            <Helmet>
                {/*<title>Research</title>*/}
                <title>Research</title>
            </Helmet>
            <div className="App-research-title">
                <h6>Research</h6>

            </div>
            <br/>
            <div className={"App-research-article"}>
                <i>Anything Interesting or Important.</i>
                {/*<ul className={"App-research-links"}>*/}
                {/*    <li>*/}
                {/*        Virtual Real Estate. In progress.*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        MIT Tech Tracker. Presented by MIT Real Estate*/}
                {/*        Innovation Lab.*/}
                {/*        /!*<Link href={"https://techtracker.mit.edu/"}>MIT Tech Tracker. Presented by MIT Real Estate*!/*/}
                {/*        /!*    Innovation Lab.</Link>*!/*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        Private Cities: Implications for Urban Policy in Developing Countries. The World Bank Study.*/}
                {/*        /!*<Link href={"https://openknowledge.worldbank.org/handle/10986/37016"}>*!/*/}
                {/*        /!*    Private Cities: Implications for Urban Policy in Developing Countries. Study at The World Bank.*!/*/}
                {/*        /!*</Link>*!/*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        Understanding special economic zone policy in China: a conceptual framework and two cases.*/}
                {/*        MIT Graduation Thesis.*/}
                {/*        /!*<Link href={"https://dspace.mit.edu/handle/1721.1/123949"}>MIT Graduation Thesis</Link>*!/*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        Sensable City Guide To Shenzhen. Published by The Senseable City Lab at DUSP, MIT.*/}
                {/*        /!*<Link*!/*/}
                {/*        /!*    href={"https://irp-cdn.multiscreensite.com/87fcdfd8/files/uploaded/Future%20of%20Work%20Shenzhen_editforWebsite_KYL1_15_2019.pdf"}>*!/*/}
                {/*        /!*    Sensable City Guide To Shenzhen. Published by The Senseable City Lab at DUSP, MIT.*!/*/}
                {/*        /!*</Link>*!/*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        "Brackets Conference Room", Published on Domus China, 103 November 2015.*/}
                {/*        Tsinghua University Undergraduate Thesis, WA Award.*/}
                {/*        /!*<Link href={"https://www.wamp.com.cn/post/79"}>*!/*/}
                {/*        /!*    <br/>Tsinghua University Undergraduate Thesis, WA Award.*!/*/}
                {/*        /!*</Link>*!/*/}
                {/*    </li>*/}
                {/*    /!*<li>*!/*/}
                {/*    /!*    Global Cities' Relative Creativity. In progress.*!/*/}
                {/*    /!*</li>*!/*/}
                {/*</ul>*/}
            </div>
        </div>
    );
}