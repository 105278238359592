import React from "react";
import {Helmet} from "react-helmet";
import MonkeyKing from "../../assets/images/monkeyking.jpg";
import PlanetApes from "../../assets/images/the_rise_of_planet_of_the_apes.jpg";

export default function Nomadic() {
    return (
        <div>
            <Helmet>
                <title>猴子 Monkey</title>
            </Helmet>
            <h6 className="App-essay-title">猴子</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 六月</b>
                <br/><br/>
                无论在东方还是西方，不同民族的人总是不约而同地发现自己和猴子的相似之处，强大的模仿学习能力，金字塔式的社会结构，和深藏心底的强烈自我意识和反抗精神。
                <br/><br/>
                西方人给它起名叫凯撒，东方人叫它美猴王。同作为人，我们无法抗拒地描绘着自己的生态位。
                <br/><br/>
                <img src={ MonkeyKing } alt="monkey king" style={{ height: "220px" }}/>
                &nbsp;
                <img src={ PlanetApes } alt="the planet of the apes" style={{ height: "220px" }}/>

            </p>

            <br/>

            <h6 className="App-essay-title">Monkey</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jun 2023</b>
                <br/><br/>

            </p>
        </div>)
}
