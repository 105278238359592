import React from "react";
import {Helmet} from "react-helmet";

export default function EnglishStudy() {
    return (
        <div>
            <Helmet>
                <title>英语学习 Study Of English</title>
            </Helmet>
            <h6 className="App-essay-title">英语学习</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 十月</b>
                <br/><br/>
                如果想击败一个凶悍的对手，应该怎么做？一个良好的策略已经被定义过了，即“战略上藐视对手，战术上重视对手”。战略上的藐视，保证了采取行动所需的基本信心；而战术上的重视，可以通过仔细考察对手的一切，弥补过度的浪漫情怀带来的冒进与风险。
                <br/><br/>
                那么，如果我们想战胜一个历史上向来凶悍且至今都威风犹在的对手，怎能不仔细且深入地研究这个对手的一切呢？在最近的一段时间里，战略上藐视由于种种因素已被良好地达成了，弱小已不再畏惧强大，这个自信的基石被一再巩固，而战术上重视对手的需要，就显得非常紧迫。在这种时候，又怎能不深入地了解、细致地研究对手的文化习俗、思维逻辑和斗争方法呢？如果对手放任无当的自由演化出了野蛮和颓废，那怎能不去研究这些野蛮和颓废，然后战胜它呢？如果想战胜这个对手，那又怎能不仔细考察它的语言呢？
                <br/><br/>
                在南非的种族斗争中，黑人领袖曼德拉被白人关在监狱里27年。在狱中，他一遍又一遍地研究那些把他囚禁在一个狭小空间里强迫劳动的敌人——那些白人的文化、白人的历史、白人的习俗、白人的运动和白人的诗歌。他需要钻进白人的精神世界里，然后寻找那个世界中一切可利用的阿喀琉斯之踵。当想要战胜对手，那你会不自觉地、如饥似渴地学习，了解、解剖这个对手的一切——不是出于崇拜——相反，是出于最强烈的自尊和反抗。你不会放过任何看似稀松平常的细枝末节，你会想象这个对手如一具静静躺在手术床上的尸体一般，任由你解剖，你还要赶在这具尸体完全分解前，把一切细节都尽收眼底、记录在案，然后探寻它每一寸骨血的来龙去脉。
                <br/><br/>
                你一遍又一遍、一点又一点、一层又一层、抽丝剥茧般地分析、消化和吸收，在这个过程里你打造了一张坚固的盾，这把盾让你有了越来越强的抵御对手进攻的能力。而这张盾在不断的修补和校正中，还在变得愈加坚固。直到有一天，你突然意识到，手中拿着的已经不是一把坚不可摧的盾，它变成了一柄无坚不摧的矛，而你最后会挥舞这把长矛刺破对手的最后防线。但这个转守为攻的过程，正需要那两样东西：强烈的自信和哲学家般的冷静。你需要不断地抵御对手的侵犯，在你抵御这些侵犯的每一秒钟，你都在不断研究他们的特点、吸收他们的优势、分析他们的缺陷，直到最后那张盾变成一柄矛。
                <br/><br/>
                有什么比少对多、弱战胜强、劣势制服优势，这种违反规律但暗地里人心所向的成就，更让人振奋的事？保持少，保持弱，保持劣势，我们仍要像法布尔研究昆虫行为一样耐心地研究这个庞然巨物的行动，像达·芬奇切开尸体描画肌肤腠理器官骨骼一样积极地寻找它的弱点和秘密。当然，为了更细致地考察，一个人不免要低头，但这是人格头颅最昂扬向上的时刻，甚至比胜利时刻都更振奋人心。
                <br/><br/>
                想同时做到自信和冷静，一点儿也不容易，有两种倾向更简单：跪倒崇拜的奴性和拒绝考察的傲慢。这两种做法的结果分别是软弱和冒进；这两种做法都选择把眼睛蒙上，任由恐惧或自负支配自己，而拒绝去解剖那具已在眼皮子下的样本；这两种做法都没有站在通往自强的道路上，其中一条通往奴才式的投降，另一条通往机会式的失败。通向目的地的小径细长幽暗，你只能在过程中保持长期的勇气和冷静。
                <br/><br/>
                所以，面对那个体型依旧庞大的对手，怎能不时刻提醒自己，要克服心中对其屈服的冲动，以及视而不见的傲慢？在这个关键时刻，又怎能不直视它、研究它、防御它、解剖它、学习它、试探它、直至战胜它。面对这样一个重要的对手，又怎能不格外仔细地考察它的语言呢？
            </p>

            <br/>

            <h6 className="App-essay-title">Study Of English</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2022</b>
                <br/><br/>

            </p>
        </div>)
}
