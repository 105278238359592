import React from "react";
import {Helmet} from "react-helmet";

export default function ThinkingFreedomTrait() {
    return (
        <div>
            <Helmet>
                <title>思考的自由与特征 The Freedom And Traits Of Thinking</title>
            </Helmet>
            <h6 className="App-essay-title">思考的自由与特征</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 十一月</b>
                <br/><br/>
                自由是思考的前提条件，这很可能是个谬论。
                <br/><br/>
                财富的自由可以被剥夺，比如没收财产；行动的自由可以被剥夺，比如监禁；性自由可以被剥夺，比如阉割；创造的自由可以被剥夺，比如把这个人绑在火刑柱上；表达的自由也可以被剥夺，比如把某些惩罚措施和特定表达内容关联起来。
                <br/><br/>
                但是思考和这些行为都不一样，思考可以随时随地发生，没有任何力量可以剥夺思考者的思考。但是思想可以被剥夺，思想是思考的凝结物，是一种媒介，它是不朽的思考一段固化的记忆；它有声有形，它长着一对儿翅膀，它可以在广阔的天空中翱翔，它也可以被关在玻璃罩子中，允许膜拜，但禁止阅读。控制了思想，就控制了思考的成果。但是，任何力量都从未能控制过思考本身，思考的自由是绝对的。这种绝对自由在于哪怕肉体和精神都被极度限制的情况下，思考依然可以破壳而出，在浩瀚的想象中翩翩起舞。即使科技发达的今天，思考的自由也无法被破坏，无法被清理，无法被根除，它是存在最顽强的证明，是生命最根本的冲动。正因为思考的绝对自由无法被剥夺，所以思考的自由根本不需要被允许。
                <br/><br/>
                思考是生存的强大推动力，它像一群复杂的肌肉组织，训练会改善纤维的强度，思考的质量也因此变得致密，而如果弃之不用，思考也会迅速萎缩。
                <br/><br/>
                思考的目的往往源于对真相的渴望。如果现实存在多个真相，那相应地，也会存在多个思考，每一个思考都在竭尽所能地回答对那个真相的理解。
                <br/><br/>
                因为思考的意义在于理解真相，所以当思考发生质的飞跃时，就说明过去的思考和真相存在偏差，需要修正，所以当思考被完善、被否定、被推翻，往往是思考最硕果累累的象征。思考渴望被推翻的欲望，和思考渴望被认可的欲望，同等强烈。最有价值的思考在于合理地摧毁过去的凝结物，然后在里面加入更高纯度的真理。
                <br/><br/>
                历史故事经常会把伟大的思考者描述得像摩西开路一样神奇。一方面这是真的，伟大的思考者往往能够忍受最极端的孤独；但另一方面，这种神话形象具有极大的误导性，因为思考的训练不太可能是一个神话般的个人活动，思考是一种群体活动。即使站在智力巅峰的人，也绝不能单枪匹马准备好一个庞大思想所需的一切原材料，然后再创造性地发明出一个全然不同的东西。也就是说，绝顶聪明的人也不能把思考训练得可以完全摆脱外界的输入而独立存在。
                <br/><br/>
                思考不能单枪匹马进行的第一个原因，是人类生理决定的。个体的寿命、健康、资源、天赋和训练都是自然所给，但自然创造出了无数个人，而不是一个人，这就决定了个体的生理极限相对于庞大的整体，仅仅是微不足道的一部分，所以研究整体变成了思考的一个母题。同时，因为部分无法独立于整体存在，所以思考者必须和作为整体的他人互动，从所有参与者身上吸取思考所需的材料和养分，这是所有伟大思考者的第一步棋。这些人不是坚硬孤立的陨石，相反常常是一块旁征博引的海绵，在不断吸收所有能吸收的一切内容。
                <br/><br/>
                不能单枪匹马思考的另一个原因，是思考活动的特性所决定的。越有价值的思考越需要否定作为凝结物的思想本身，才能最终决定思考的价值含量。于是，思考训练会本能地邀请所有整体的组成部分，对思考进行再思考。因此，思考只能被辩论、被同意、被追随、被崇拜、被效仿、被完善、被挑战、被否定、被推翻。去掉了被动语态，思考就失去了活力，而恰恰是这种活力赋予了思想完成自我进化的动力。
                <br/><br/>
                一个思考者开始声称自己的思想拥有绝对真理时，总会体现出排斥他人参与的防御性。虽然思想可以因为否定谬论而体现出价值，但思想无法因为否定他人参与而变得更强大。相反，思想必须是一封邀请函，是一块等待锻造的钢铁，它绝不是一副铁镣铐。因为思想只有在邀请别人一同加入这场游戏时，才能最大程度地展现它锋利的价值；因为它太需要被传播、被实践、被认可、被修改、被完善、被否定、直到迎来那个宿命中的“被推翻”。被别人思考是思考者最强大的引擎。和防御型的思考者恰恰相反，越善于思考的思考者，越能清晰地感受到别人思想中哪怕最微不足道的灵感火花——这个人允许自己被影响、被挑战、被感动、被说服——也充分给予了这个人深刻影响外界的能力。思考者和外界，像一对热恋中的情人，此刻只想强烈地看穿彼此和被彼此看穿。
                <br/><br/>
                抑制思考、非黑即白的结论、通过武力迫使思想被当做真理执行，都违反了思考训练的原则。打着自由旗帜的恶霸，不受约束的极权，这两种看似完全相反的事物，其实分享了同一个内核——他们讨厌热恋的危险，把任何辩论和挑战，都视同水火，最后只能自食其果，错过幸福的奇迹。
                <br/><br/>
                现实里没有完美的理想国，强行建造理想国的行为往往会造出利维坦。但千疮百孔的现实里，思想的繁荣仍然可以建立在一个不完美的机制上，这个机制中有一些基本需求应该被首先满足。这个基本需求就是生存，让作为思想载体的人能生存下来，是进行思考的前提和唯一前提，因为思考的自由压根不需要被允许，它会在暗中静待时机，早晚会枝繁叶茂。所以，让普通人保住饭碗和性命，而你们，可以继续统治世界。

            </p>

            <br/>

            <h6 className="App-essay-title">The Freedom And Traits Of Thinking</h6>
            <p className="App-essay-article">
                <br/>
                <b>Nov 2022</b>
                <br/><br/>

            </p>
        </div>)
}
