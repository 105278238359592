import React from "react";
import {Helmet} from "react-helmet";
import AndreaChegut from "../../assets/images/Andrea_Chegut.jpeg";

export default function InMemoryOfAndreaChegut() {
    return (
        <div>
            <Helmet>
                <title>纪念Andrea Marie Chegut | In Memory of Andrea Marie Chegut</title>
            </Helmet>
            <h6 className="App-essay-title">纪念Andrea Marie Chegut</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 十二月</b>
                <br/><br/>
                Andrea Chegut教授是MIT建筑与城市规划学院里最漂亮的女人，我不想争论这件事大家同意多少，至少对我而言是这样的。Andrea有一副深刻又灵活的头脑，她不纠结无关紧要的细节，她的眼睛和大脑，一刻不停地在去粗存精。
                <br/><br/>
                如果你在二十码外就听到一阵清澈爽朗的大笑，伴随着锋利机智的段子和对重要细节毫发无差的拿捏，你就会像我一样，通过这种方式认识一个叫Andrea Chegut的女人，她是麻省理工建筑与城市规划学院的教授，主攻经济学。她不是我在学院里最熟悉的教授，我也不是她最熟悉的学生，我的很多朋友跟她的合作交流比我密切得多。初次见面，她记得你，很快忘掉，然后又很快记起，她像一块高速旋转的陀螺，把一切相关、无关、有趣和无趣的信息，碾碎合并，去伪存真。如果你和她面对面坐着聊天，她绝不会让你觉得无聊，她的笑话永远简洁幽默。没有人可以忽略她，她是个如此显眼的存在。
                <br/><br/>
                从学校毕业后，朋友Alex问我要不要去Andrea的实验室干活，我说好，就这样，我在毕业后才开始认识Andrea。刚开始在实验室的任务是一份查资料的枯燥工作，上班有工资，在Lab干活也让刚结束学业的我看起来没那么游手好闲。做了大半年，工作内容让我丧失了兴趣，所以当Andrea问我会不会写代码时，我毫不犹豫地说会，其实我接到任务后才开始学。于是，这份实验室的工作开始变得有趣，就这样在挑战和现炒现卖的螺旋发展中，被不断推着完成。后来我意识到，世上大多数事情，尤其是当我们必须开始某些未知的创造、冒险和激动人心的旅程，我们永远都不会准备好，我们只能选择开始还是不开始。对于工作，只要你能动用聪明才智把东西做出来，Andrea不介意你现炒现卖，在她这儿，想象力被揉进适量的现实主义，就能合情合理地赋予实现一个目标最趁手的武器。
                <br/><br/>
                2021年春节前后，Andrea在孕期感染了新冠，她一度在呼吸机上用zoom跟我开会。接通会议的时候，我怀疑自己到底是在和一个呼吸机上感染新冠的妊娠期母亲聊天，还是和一团纯粹的钢铁意志谈话。我跟Andrea说，先好好休息！事情总会被搞定！她听了笑笑，说她感觉挺好。嗯，她永远都感觉挺好，不是么？等她从医院康复回家，我和实验室的同事们买了一大束花，给她写了张贺卡，我尽力模仿了Andrea的说话口吻，祝她完全康复并说赶快回来，实验室的工作正迫切等着她。送她的那束花，她拿到手时还是一团花骨朵，但我能猜到那团花盛开的样子有多绚丽。她实在太年轻了。
                <br/><br/>
                去年六月，我离开实验室，从波士顿出发，在纽约朋友家中住了几天，顺便把我的猫寄存在朋友家里，我不想让它在剩下的半个多月中跟我一样在路上颠簸。最后一次和她开工作会，纽瓦克港口的巨轮汽笛发出一阵低沉的轰鸣，引起了她的注意，她的眼睛狡黠地转了几圈，好奇地问我："What's that sound,  Where ARE you?" 我脸上浮现出得意，跟她说："I'm at Newark, the sound is ship horn from the port." 然后我炫耀般地讲了一个颇为粗糙大胆的旅行计划，她唯一的建议就是别在旅途中喝酒。我脑子里塞满了凯鲁亚克式的自在，但整个长途旅行中，我确实滴酒未沾。去年六月在纽瓦克的那次线上会议，是和她的最后一次工作会。会议结束后，我合上电脑，从纽瓦克开上了锈带，我的万里长征才刚刚开始。
                <br/><br/>
                现在我意识到，告别美洲不是穿越茫茫旷野然后坐上飞机回家，当我走完那趟旅程的终点，其实告别了很多东西，包括射出的如利箭般永不回头的生命和时间，留下了一堆若隐若现的记忆碎片。
                <br/><br/>
                隔离期间我投了腾讯的工作，后来也在这儿上班。接到offer后背调，hr需要我的离职证明，但实验室里哪儿有这么多繁文缛节！我做完核酸在医院对面的一个茶餐厅给Andrea写了封关于离职证明的邮件。收到信息后，在地球自转把阳光带到美洲大陆的十二个小时内，她回复了，内容只有一个单词和一个标点：YES！然后是她的签名——依然是强烈的Andrea式风格——完全赞成，然后立即执行。
                <br/><br/>
                今年年中，我偶然读到一篇大西洋月刊的文章，一篇硅谷独角兽Stripe的创始人Patrick Collison写的文章，他也曾是MIT的学生。我当时脑海中闪过一个念头，可能Andrea会觉得有趣，就随手给她发了邮件。她没回，我也没在意，随机忽略一些邮件是她会做的事。我没在意，就像今年初大家都没在意Andrea群发邮件说她要住院休养一样，朋友告诉我，其实那个时候她就查出了癌症。
                <br/><br/>
                我从Alex那儿知道她去世的消息。北京时间凌晨，大力神杯被梅西捧起之后我倒头就睡，起来后才看到Alex的留言，他说Andrea前天去世了，过一会儿就是她的service。悉尼时间比北京时间快3个钟头，我错过了跟她告别的线上仪式。所有的过往突然在今天暂停了，连同过去所有跃动的回忆，都突然静止在一个平平无奇的周一上午。可能她会更想让人记住她快乐的样子，而那些部分也是我对她的全部记忆。
                <br/><br/>
                我会像很多人一样，一遍又一遍给未来的孩子讲，关于那场艰苦卓绝的世界杯决赛，关于那场球赛的诡谲残酷和一个童话般的结尾，关于这三年光怪陆离的现实，关于人间一切比赛的意义终究会变得虚无缥缈。那尊被捧起的大力神杯和凋落的黄叶一样缺少生机，心潮澎湃也总会变得拖泥带水，将要到来的夏天也因此失去魅力。
                <br/><br/>
                永远怀念Andrea Marie Chegut。
                <br/><br/>
                <img src={AndreaChegut} alt="Andrea Marie Chegut" style={ {width: '380px'} } />

            </p>

            <br/>

            <h6 className="App-essay-title">In Memory of Andrea Marie Chegut</h6>
            <p className="App-essay-article">
                <br/>
                <b>Dec 2022</b>
                <br/><br/>


            </p>
        </div>)
}
