import React from "react";
import {Helmet} from "react-helmet";
import Pacific from "../../assets/images/pacific.jpg";
import Atlantic from "../../assets/images/atlantic.jpg";
import Books from "../../assets/images/books.jpg";
import Cowboy from "../../assets/images/cowboy.jpg";
import Destination from "../../assets/images/destination.jpg";
import MIT from "../../assets/images/MIT.jpg";
import Mirror from "../../assets/images/mirror.jpg";
import Selfie from "../../assets/images/selfie.jpg";
import WB from "../../assets/images/WB.jpg";
import WB1 from "../../assets/images/WB1.jpg";
// import WB2 from "../../assets/images/WB2.jpg";
import WB3 from "../../assets/images/WB3.jpg";
import WB4 from "../../assets/images/WB4.jpg";
import WB5 from "../../assets/images/WB5.jpg";
import Bay1 from "../../assets/images/Bay1.jpg";
import Bay2 from "../../assets/images/Bay2.jpg";
import Bay3 from "../../assets/images/Bay3.jpg";
import Bay4 from "../../assets/images/Bay4.jpg";
import H1 from "../../assets/images/H1.jpg";
import H2 from "../../assets/images/H2.jpg";
import H3 from "../../assets/images/H3.jpg";
import Seattle from "../../assets/images/Seattle.jpg";
import Los1 from "../../assets/images/Los1.jpg";
import Los2 from "../../assets/images/Los2.jpg";
import Rocket from "../../assets/images/Rocket.jpg";
import Rocket1 from "../../assets/images/Rocket1.jpg";
import Rocket2 from "../../assets/images/Rocket2.jpg";
import Rocket3 from "../../assets/images/Rocket3.jpg";
import SB1 from "../../assets/images/SB1.jpg";
import SB2 from "../../assets/images/SB2.jpg";
import SB3 from "../../assets/images/SB3.jpg";
import SB4 from "../../assets/images/SB4.jpg";
import SB5 from "../../assets/images/SB5.jpg";
import SB6 from "../../assets/images/SB6.jpg";
import SB7 from "../../assets/images/SB7.jpg";
import Roll from "../../assets/images/Roll.jpg";
import BlueBottle from "../../assets/images/BlueBottle.jpg";
import SantaRosa from "../../assets/images/SantaRosa.jpg";
import LAX1 from "../../assets/images/LAX1.jpg";
import LAX1A from "../../assets/images/LAX1A.jpg";
import LAX2 from "../../assets/images/LAX2.jpg";
import TB from "../../assets/images/TB.jpg";
import ZZ1 from "../../assets/images/ZZ1.jpg";
import ZZ2 from "../../assets/images/ZZ2.jpg";
import ZZ3 from "../../assets/images/ZZ3.jpg";
import ZZ4 from "../../assets/images/ZZ4.jpg";
import ZZ5 from "../../assets/images/ZZ5.jpg";
import T1 from "../../assets/images/T1.jpg";
import T2 from "../../assets/images/T2.jpg";
import T3 from "../../assets/images/T3.jpg";
import Ten1 from "../../assets/images/Ten1.jpg";
// import Ten2 from "../../assets/images/Ten2.jpg";
import Ten3 from "../../assets/images/Ten3.jpg";
// import Ten4 from "../../assets/images/Ten4.jpg";
import Ten5 from "../../assets/images/Ten5.jpg";
// import Ten6 from "../../assets/images/Ten6.jpg";
import Ten7 from "../../assets/images/Ten7.jpg";
import Ten8 from "../../assets/images/Ten8.jpg";
// import Ten9 from "../../assets/images/Ten9.jpg";
import DC1 from "../../assets/images/DC1.jpg";
import DC2 from "../../assets/images/DC2.jpg";
import DC3 from "../../assets/images/DC3.jpg";
import DC4 from "../../assets/images/DC4.jpg";
import DC5 from "../../assets/images/DC5.jpg";
import DC6 from "../../assets/images/DC6.jpg";
import Atlanta from "../../assets/images/Atlanta.jpg";
import NO1 from "../../assets/images/NO1.jpg";
import NO2 from "../../assets/images/NO2.jpg";
import NO3 from "../../assets/images/NO3.jpg";
import Brown1 from "../../assets/images/Brown1.jpg";
import Brown2 from "../../assets/images/Brown2.jpg";
import Houston1 from "../../assets/images/Houston1.jpg";
import Houston2 from "../../assets/images/Houston2.jpg";
import Houston3 from "../../assets/images/Houston3.jpg";
import SD1 from "../../assets/images/SanDiego1.jpg";
import SD2 from "../../assets/images/SanDiego2.jpg";
import Cafe1 from "../../assets/images/Cafe1.jpg";
import Cafe2 from "../../assets/images/Cafe2.jpg";
import Manhattan from "../../assets/images/Manhattan.jpg";
import Bos1 from "../../assets/images/Bos1.jpg";
import Bos2 from "../../assets/images/Bos2.jpg";
import Bos3 from "../../assets/images/Bos3.jpg";
import Bos4 from "../../assets/images/Bos4.jpg";
import Bos5 from "../../assets/images/Bos5.jpg";
import School1 from "../../assets/images/School1.jpg";
import School2 from "../../assets/images/School2.jpg";
import School3 from "../../assets/images/School3.jpg";
import School4 from "../../assets/images/School4.jpg";
import School5 from "../../assets/images/School5.jpg";
import School6 from "../../assets/images/School6.jpg";
import School7 from "../../assets/images/School7.jpg";
import School8 from "../../assets/images/School8.jpg";
import Border from "../../assets/images/Border.jpg";
import Border2 from "../../assets/images/Border2.jpg";
import NYC1 from "../../assets/images/NYC1.jpg";
import NYC2 from "../../assets/images/NYC2.jpg";
import NYC3 from "../../assets/images/NYC3.jpg";
import North1 from "../../assets/images/North1.jpg";
import North2 from "../../assets/images/North2.jpg";
import North3 from "../../assets/images/North3.jpg";
import North4 from "../../assets/images/North4.jpg";
import North5 from "../../assets/images/North5.jpg";
import North6 from "../../assets/images/North6.jpg";
import Seattle2 from "../../assets/images/Seattle2.jpg";
import Seattle3 from "../../assets/images/Seattle3.jpg";
import Seattle4 from "../../assets/images/Seattle4.jpg";
import BookStore1 from "../../assets/images/BookStore1.jpg";
import BookStore2 from "../../assets/images/BookStore2.jpg";
import NYCpre from "../../assets/images/NYCpre.jpg";
import NYCpre1 from "../../assets/images/NYCpre1.jpg";
import NYCpre2 from "../../assets/images/NYCpre2.jpg";
import NYCpre3 from "../../assets/images/NYCpre3.jpg";
// import NYCpre4 from "../../assets/images/NYCpre4.jpg";
import NYCpre5 from "../../assets/images/NYCpre5.jpg";
import NYCpre6 from "../../assets/images/NYCpre6.jpg";
import NYCpre7 from "../../assets/images/NYCpre7.jpg";
import NYCpre8 from "../../assets/images/NYCpre8.jpg";
import NYCpre9 from "../../assets/images/NYCpre9.jpg";
import Saddle from "../../assets/images/Saddle.jpg";
import Map from "../../assets/images/MapFixed.jpg";
import NYCHotel from "../../assets/images/NYCHotel.jpg";
import Ph1 from "../../assets/images/Ph1.jpg";
import Ph2 from "../../assets/images/Ph2.jpg";
import Ph3 from "../../assets/images/Ph3.jpg";
import Ph4 from "../../assets/images/Ph4.jpg";
import Ph5 from "../../assets/images/Ph5.jpg";
import Ph6 from "../../assets/images/Ph6.jpg";
import Google from "../../assets/images/Google.jpg";
import Google1 from "../../assets/images/Google1.jpg";
import Google2 from "../../assets/images/Google2.jpg";
import SanF1 from "../../assets/images/SanF1.jpg";
import SanF2 from "../../assets/images/SanF2.jpg";
import CU1 from "../../assets/images/CU1.jpg";
import CU2 from "../../assets/images/CU2.jpg";
import CU3 from "../../assets/images/CU3.jpg";
import CU4 from "../../assets/images/CU4.jpg";
import CU5 from "../../assets/images/CU5.jpg";
import CU6 from "../../assets/images/CU6.jpg";
import Dong from "../../assets/images/Dong.jpg";
import ABQ1 from "../../assets/images/ABQ1.jpg";
import ABQ2 from "../../assets/images/ABQ2.jpg";
import ABQ3 from "../../assets/images/ABQ3.jpg";
import Bay1A from "../../assets/images/Bay1A.jpg";
import Bay1B from "../../assets/images/Bay1B.jpg";
import Home1 from "../../assets/images/Home1.jpg";
import Home2 from "../../assets/images/Home2.jpg";
import Home3 from "../../assets/images/Home3.jpg";
import Home4 from "../../assets/images/Home4.jpg";
import Home5 from "../../assets/images/Home5.jpg";
import Home6 from "../../assets/images/Home6.jpg";
import Home7 from "../../assets/images/Home7.jpg";
import SZ0 from "../../assets/images/SZ0.jpg";
import SZ1 from "../../assets/images/SZ1.jpg";
import SZ2 from "../../assets/images/SZ2.jpg";
import SeattleFriends from "../../assets/images/SeattleFriends.jpg";

export default function CrossedNorthAmericaAgain() {
    return (
        <div>
            <Helmet>
                <title>二次横跨北美 Crossed North America Again</title>
            </Helmet>
            <h6 className="App-essay-title">二次横跨北美</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 十月</b>
                <br/><br/>
                整个十月我都在旅行。
                <br/><br/>
                所有人的旅行终点都是死亡，旅行的终点没有意义。但旅途中发生的事，会补充终点缺失的意义。从15年开始，我绝大部分时光都在美国度过。疫情后期我回到国内，如今已经两年了，十月份的旅行是我需要的一键暂停。
                <br/><br/>
                虽然我不是Elon Musk，有Isaacson的如椽巨笔为他立传，但我依然需要记录我的故事。
                <br/><br/><br/>
                大致路线（实际旅途中各种路线折返要复杂得多，公路驾驶总里程约一万公里）：
                <br/><br/>
                <img src={Map} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>前奏 Prologue</b></h5><br/>
                2023年10月，中美直飞的航班依旧不见踪影。疫情前的航线不是停飞，就是贵得难以支付。这三年对世界经济的影响，就像在空中拆掉了一架高速运转的波音客机的双侧发动机。
                <br/><br/>
                中华航空（China Airline）识时务者为俊杰的低价机票，深深打动了我，它的说服力毋庸置疑。但从国内飞台北，使用任何一种身份都免不了繁琐，内地身份证、中国护照、通行证都会显得不合适。
                <br/><br/>
                上次在台北是近十年前的事，当阿拉斯加航空（Alaska Airline）把我的登山背包从旧金山直接送到了台北桃园机场，我追了上千公里才要回来。阿航的客服换了5批，每一次叙事我都优化了故事的版本，增强了它的说服力，最后阿航找到了我的背包，还送出了几百美元补偿。反正我连台北机场都没出。这趟也一样，台北只是我中转去洛杉矶的落脚点。
                <br/><br/>
                这个宇宙中只存在两种地理位置，未被探索（uncharted）的海域，和被过度盘踞（over-charted，我编的）的海域。
                <br/><br/>
                总之，别从蛇口港直接去香港机场，聪明的你会像我一样，多试几种交通工具和通关方式后，在飞机起飞前找到对的路。
                <br/><br/>
                <img src={TB} alt="" width={"100%"}/>
                <br/><br/>
                一个人的旅行开始了。
                <br/><br/><br/>
                <h5><b>开始 Beginning</b></h5><br/>
                西海岸时间的晚上，我抵达了洛杉矶机场。在这片土地上学工作近七年，我有两年没见到这片北美大陆了。
                <br/><br/>
                2021年新冠疫情间，我一个人开车横跨过这片土地。那一次，我从大西洋边波士顿的Nahant Beach出发，一路向东，在太平洋边西雅图的Pacific Beach结束，之后一路向南，开到了圣巴巴拉，旅行结束于北纬34.4208°——那是两年前我第一次一个人横跨北美的终点。（两年前横跨北美详见我的博客：<a href="https://www.kunchengblog.com/essay/crossnorthamerica" rel="noreferrer noopener" target="_blank">第一次横跨北美|2021年</a>）
                <br/><br/>
                和之前一样，因为对这片大陆足够熟悉，一个人旅行，对景区避之唯恐不及，对放纵娱乐也无兴趣。信马由缰待几周，有缘会和老朋友在某处相遇，如果没遇到，命运也会让我们在地表某处相逢。
                <br/><br/>
                <img src={Los1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Los2} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>圣巴巴拉 Santa Barbara——圣地亚哥 San Diego——凤凰城 Phoenix</b></h5><br/>
                离开喧闹的洛杉矶机场，两年没回来，信用卡突然被刷亮起了红灯，在租车处和Chase打了半个钟头电话才搞定了信用卡问题，拿到车已经是深夜。两个小时后，我已在圣巴巴拉。
                <br/><br/>
                在圣巴巴拉休息了两天。对比喧闹的洛杉矶，宁静的圣芭芭拉，足球场，落日遛狗，UCSB的生化实验室，我有种度假的感觉了。
                <br/><br/>
                天还没亮就启程，我讨厌不辞而别，但我更不擅长告别。圣巴巴拉是一个引力无穷的黑洞，在这里多待的每一秒钟都让我永远无法离开。
                <br/><br/>
                中午在San Diego吃饭，餐牌的西班牙语含量已经高达九成。墨西哥大妈用粗粝的英文问我要吃什么，我随手点指了一个含有单词Mercado (Market)的玉米面，上面加两个鸡蛋做toppings。墨西哥大妈笑着问我从哪里来，我想都没想就说China，大妈哈哈大笑说：We all came from somewhere! I’m asking about here! 我想了半秒说Boston，大妈说她看见我手机号的前三位就猜到了，她请我免费尝了身后几个桶里的墨西哥饮料，我点了个黄瓜味的。
                <br/><br/>
                离开San Diego没多久，就穿过了亚利桑那州的边境，空调外循环给车里带进一阵荒草和马粪的味道。我摇下车窗，深吸了一口气，把草和马粪的气息充满整个肺，这是亚利桑那州的第一份欢迎礼。与此同时，因地处美墨边境而设置的军事岗哨，则是第二份欢迎礼，岗哨的美军面西而立，被刺眼的阳光照得睁不开眼，机械式地问我：just by yourself? 然后怀疑地盯着后备箱和轮胎。我回答的言语动作像Jeep外挂的备胎一样坦诚，车几乎没停稳就放行了。我不属于他们重点关照的人群侧写。
                <br/><br/>
                Jeep除了酷之外，有一个很大的问题，它像一匹喝了利尿剂的马，对加油站的强烈渴求甚至比喝多了水的我对卫生间的渴求更加强烈，而这是不可接受的。
                <br/><br/>
                Jeep对油站的渴望引导我在进入亚利桑那没多久就在一个苍蝇横飞的加油站停了下来，前台收银员是个大腹便便的白人大爷，跟我说加油站的卫生间坏了一周。我加完油连滚带爬关上门窗，一大群苍蝇早已涌入。在高速上我打开车窗，让呼啸的狂风把苍蝇们卷走，但有一只非常顽固，它藏在某处避风港，关上车窗就出来骚扰。
                <br/><br/>
                亚利桑那州远处山峦的剪影像幕布上的道具，平坦空旷的荒野上公路笔直，连续几个小时都不用打方向，让人昏昏欲睡。幸亏了那只苍蝇，它不停地在我身边警告我专心开车。直到我们到了凤凰城后它才下车，我对它的顽强深表敬意。
                <br/><br/>
                一路向东行进不用和刺眼的夕阳对视，免去了我两年前一路向西的麻烦。但处于地平线附近的夕阳无孔不入，即使不在前方，也会稳定地出现在后视镜里。
                <br/><br/>
                时间一分一秒流逝，后视镜中的夕阳落下了，看了看表，下午6点03分。曾经向西的每一秒钟前进都在延缓夕阳落下的时间，而今天向东的每一英里狂奔都在加速夕阳落下的速度。存在于日地旋转中的平衡，正在把两年前积攒的时间势能以相反的方向，等量还给我。等夕阳的锋芒被地平线遮住，属于亚利桑那州的颜色终于来了，赭石的红开始覆盖加州的灰绿褐黄。
                <br/><br/>
                从圣巴巴拉一路向南，抵至美墨边境，贴着南线然后一路朝大陆的东北角前进，直到波士顿。连接这根对角线的，是宽广的经纬度。
                <br/><br/>
                太平洋湛蓝的海水拍打着西海岸，大西洋铅灰的海水冲击着东海岸，无数水分子把两片大洋连为一体。海水的这端击打着圣巴巴拉，那端撞击着波士顿，在这两者之间，则是无数人、故事和回忆织成的大网，同样的海水冲刷着圣巴巴拉和波士顿的海岸，那是从未征服的大洋。
                <br/><br/>
                <img src={SB1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SB2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SD1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SD2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SB4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SB7} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SB5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SB3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SB6} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>凤凰城 Phoenix——阿布奎基 Albuquerque——圣罗莎 Santa Rosa</b></h5><br/>
                早上从凤凰城出发时已经差不多快中午了，长途跋涉加上时差还是对我造成了不小的影响。我在墨西哥大妈前后高达四个回合的高频敲打房门和破锣嗓的催促中，被从酒店房间里赶出来的。
                <br/><br/>
                中午从凤凰城开出来，亚利桑那的颜色在阳光下绚丽无比，市区周边的山峰，随着迂回的地形渐渐从背景板的剪影里跃出，慢慢被亚利桑那的热烈光线点缀上橘橙黄红。这段位于高原上的跋涉，因为海拔高度的反复升降，植被在阔叶林和针叶林的交织节奏中反复跳跃，相当有趣。
                <br/><br/>
                因为从凤凰城出发得太晚，太阳落山的时间好似提前了五六个钟头，毕竟我彻底失去了整个上午，我应该感谢墨西哥大妈的破锣嗓子。
                <br/><br/>
                太阳落山前后，我看到一朵慢悠悠的云彩形成一团乌云，开始下雨了，但这朵云彩的统治力只有十多分钟，我踩下油门，用发动机摆脱了它的追赶。太阳落山后，在亚利桑那和新墨西哥的州界线附近，赭石色的地貌又开始退回荒原的黄绿青灰，在荒原上狂奔的快乐又回来了！等到了新墨西哥州的Albuquerque已经是夜里。
                <br/><br/>
                今儿是周六，Albuquerque downtown夜里的街上没什么人，但对美国而言很正常。我发了朋友圈，朋友们都提醒我：大家只是都去看热气球了！阿布奎基市热气球节是场盛会，我才想起来，一路上时不时看到在捣鼓着一堆“布料”的人，酒吧里隐约透出的电视里也飘满了热气球。
                <br/><br/>
                地处新墨西哥州，极度复杂的多样化地貌给了Albuquerque丰富的自然历史，市区里有一流的植物园、自然保护区、北美原住民文化和艺术博物馆。此外，电影《奥本海默》里第一颗原子弹的试爆点Los Alamos，就在北边。加上老白，Pink，Saul Goodman的《绝命毒师》和《风骚律师》。这儿旅馆的价格已经完全没法儿住了。Let's get out here;)
                <br/><br/>
                <img src={ABQ1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={ABQ2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={ABQ3} alt="" width={"100%"}/>
                <br/><br/>
                虽然疲惫，但还是驱车一个多钟头开到Santa Rosa，一个长途旅客过夜的小镇。Santa Rosa这样毫无特点的sleepover town才是合适的睡觉点。靠着US Route 66是这个镇子出现的原因。这些过夜小镇里睡的都是长途跋涉的旅客和大车司机。还有一个和Santa Rosa 一模一样名字的小镇，就在加州，这也充分说明了这些镇子的独特性。
                <br/><br/>
                <img src={SantaRosa} alt="" width={"100%"}/>
                <br/><br/>
                类似Spring Field（春田市）在美国有34个之多。可以想象，第一批缺少文化熏陶的欧洲移民，来到一块陌生的土地，期待这片土地上的食物能在春天发芽喂饱自己，就起名春田spring field。
                <br/><br/>
                没有装饰的东西非常真实，即使是千篇一律的真实。这个镇上的每个人不会因为过夜镇的毫无特色而生活在虚假的标本里，他们的生活反而比那些每刻都在作秀的景点真实得多。
                <br/><br/>
                睡吧，疲惫的人。
                <br/><br/><br/>
                <h5><b>圣罗莎 Santa Rosa——布朗斯维尔 Brownsville</b></h5><br/>
                天还没亮我就醒了，身处Santa Rosa，但我要去Brownsville看Elon的火箭——这个决定是我一时兴起做的。但是，开往墨西哥湾的方向会导致我再度南下，而且两地之间不是一星半点的纬度差。
                <br/><br/>
                Santa Rosa到Brownsville之间巨大的距离提醒我，今天是一场持久战，Time to roll!
                <br/><br/>
                <img src={Roll} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6><b>穿过州界</b></h6><br/>
                昨天跨入新墨西哥州，时间就少了一个钟头。清晨出发不久后就跨过了新墨西哥和德克萨斯的州界，我又失去了一个钟头。
                <br/><br/>
                Welcome to Texas! 🏇 欢迎来到德克萨斯！
                <br/><br/>
                州界处是一家叫Tireworks的补胎汽修店。我在跨越州界的地方拍了两张照，旋即在路边停了下来，记录了这一刻心情。
                <br/><br/>
                这时候，远处快速走来一个墨西哥穿搭风格的大汉，他问我：What's up, my friend?
                <br/><br/>
                我说: I'm just passing by! Gonna leave right now!
                <br/><br/>
                他又说：“OK! I thought you had a flat tire.”
                <br/><br/>
                他脸上似笑非笑的样子，灵活的眼睛，牛仔打扮和花纹头巾，略带压迫性的询问，出现在州界的位置，种种迹象表明，他的工作可能不只是修轮胎那么简单。
                <br/><br/>
                <img src={Border} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Border2} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6><b>德州警长</b></h6><br/>
                接着前进，下午从一路朝向东南的高速上下来，穿过德州中南部的一处小镇。镇上的道路限速很低，前方一辆顶上装着黑色架子的白色福特皮卡慢悠悠地大路中央行进，它大摇大摆的样子和缓慢行进的速度让我心烦意乱。我踩了一脚油超过了它。10秒钟后，它亮起了红蓝的警灯。
                <br/><br/>
                德克萨斯州送出了它最具风味的欢迎礼——一位头戴牛仔帽、墨西哥裔、背挂步枪的小镇警长，这位Sheriff从车上下来，向我走来。
                <br/><br/>
                我把双手放在了方向盘能看到的地方。
                <br/><br/>
                警长问我：Do you know how fast you were going?
                <br/><br/>
                我说: Sorry officer. I have no idea.
                <br/><br/>
                警长说：You passed me like a light! （你像一道光一样超过了我！）
                <br/><br/>
                接下来警长问我要了各种证件，然后开始关注我到底为什么这么着急？
                <br/><br/>
                我说我有两年没回美国了，以前在这里读书工作，上周刚回来休假，正着急去看马斯克的火箭，想在天黑前赶到Brownsville。警长听到这儿，哈哈大笑说，我有点儿能理解你多激动了！
                <br/><br/>
                但警长还是给我开了一张相当昂贵的罚单，说这次不需要你出庭，但你必须付一张能让你记住的超速罚单，最后还补充了一句：You should drive more slowly when you are still in my state, or you could spend more time than you want in Texas!
                <br/><br/>
                这是我听过的最无法抗拒的祝福。
                <br/><br/>
                之后的旅程中，我对福特车型、车顶支架、车灯中隐约透出的蓝光、慢悠悠大摇大摆的行进速度，甚至有了生理性的敏感。在德州境内，乃至剩余的全部旅程里，我都相当谨慎地照办了。
                <br/><br/>
                长途旅行中，虽然我头脑冷静，举止得体，外加一张礼貌而体面的嘴，但我必须真诚地感谢这位德州警长的宽容，因为所有这些因素都是缺一不可的，它们对我的个人安全乃至这趟旅行能否继续进行，至关重要。
                <br/><br/><br/>
                <h6><b>风力发电机</b></h6><br/>
                傍晚时分，快到Brownsville的最后两个多钟头，我以为我开在那条拉斯维加斯的“日落大道”上，那条路上的侧向风曾给我留下过深刻的印象。南德克萨斯旷野上的横向狂风几乎要把车吹歪。
                <br/><br/>
                日落过后很快一切都陷入了黑暗，这时候远方地平线上出现了无数的红色小点，时亮时暗，它们成千上万、密密麻麻直通到地平线尽头的样子让人无比惊奇——这些灯是风力发电机立柱上的信号灯。
                <br/><br/>
                这里的一切都开始显得超现实。无人的旷野中没有路灯，无边无际的黑暗加上体力透支的极度疲惫让我无比压抑，心脏的跳动似乎要冲破嗓子眼儿，我想起了两年前在蒙大拿州的旷野里行进的三个钟头，幽闭恐惧和旷野恐惧是会同时发生，你被关在一辆狭小的越野车里，行进在无边无尽的荒原上。
                <br/><br/>
                但终点终将到来，快到Brownsville的时候，路灯重新亮了起来，城市道路的合理尺度照在脸上，驱散了人心深处的恐惧和孤独，甚至疲惫都一扫而空了。
                <br/><br/><br/>
                <h6><b>雨水火箭</b></h6><br/>
                比起看热气球，我更接受大半夜跑来看Elon的火箭🚀。SpaceX的发射基地对公众开放，从新墨西哥开过来一千多公里，我已在美墨边境线上。
                <br/><br/>
                疯子只有我一个，没有庆祝活动，没有发射日期，谁会驱车几千公里跑到乱哄哄的美墨边境，看伫立在漆黑雨幕中的一大坨钢铁？我会，而且再来一遍的话，我还会。我承认Elon调动了我丰富的想象力，但是对人类最极致的火箭内燃机，我调动的想象力更充沛。
                <br/><br/>
                除了佛罗里达的南部，星舰所处的Brownsville已经是美国最南方的边境地带。 在星舰基地旁边的小商店里我买了份炸鸡，我已经饿到心慌出汗，结账时顺口问了Starbase还开着没，能不能看见rockets。收银员是个胖胖的美国姑娘，她撇撇嘴说：你什么都不会看到，因为太晚了，也太黑了。
                <br/><br/>
                很明显，她错了。
                <br/><br/>
                等我开完Starbase最后一段像丧尸片一样贴满警告的单行道，我清楚地看见一尊巨大的银色火箭伫立在漆黑的雨幕中。夜幕被雨雾笼罩，周围除了百无聊赖看管大门的工作人员和零星几个工程师走来走去，没有一个游客。我以为至少会有一个人像我一样疯狂，但没有，我猜那些疯子挑了别的时间或别的地方。盯着火箭出神，我手机上弹出The Economist杂志的推送邮件，巴以冲突开始了，远方的炮火和眼前的火箭形成了最奇特的互文。
                <br/><br/>
                如果下次有什么阻止我再横穿一片荒野，开十三个钟头，跨越一个时区，那就是今天。整个火箭被墨西哥湾的水气洗得干干净净，巨大的火箭悄然无声，周围一片漆黑，偶尔亮起的灯光像一路开往Brownsville时一同亮起的风力发电叶的信号灯，火箭闪亮的金属外壳倒映着一旁的发射架，宛如外星基地。
                <br/><br/>
                <img src={Rocket1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Rocket2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Rocket3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Rocket} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6><b>边境检查官</b></h6><br/>
                因为Brownsville实在太过偏远，我没有任何朋友住在当地。等我打开加油时随手订的旅馆，才发现它的位置问题——在美墨边境的另一侧，我订的旅馆在墨西哥。
                <br/><br/>
                如果Brownsville作为火箭城而像个外星基地，那位于Brownsville另一侧这个西班牙语读音的城市Matamoros，就是外星星球。
                <br/><br/>
                刚过午夜，我只身一人穿过国境线，我问边境处的墨西哥大妈：Need any paperwork? 大妈只是不耐烦地说：Six bucks! （6美元）我掏出信用卡，大妈吼了一句：Cash only! 我递过去20美元纸币，大妈找给我14 美元。
                <br/><br/>
                不需要任何文件证明，一人一车，6刀过路费，半夜十二点多，我跨过了美墨边境，从美国进入墨西哥是如此随意。
                <br/><br/>
                Matamoros的城市里没有美国手机信号，AT&T的信号过边境5分钟后就微弱得几不可闻，同时这个城市的夜景像极了丧尸电影的风格。斑驳的贫民窟建筑外墙，像巧克力蛋糕被勺子挖掉了表面一样，精神失常的路人在马路中央一走一颠。十五分钟过后，我开到了旅馆，一楼是昏暗的小酒馆，周围的道路上没有划线的停车位上稀疏地停着几辆车。
                <br/><br/>
                我用了10秒钟做了决定——离开这里，即时我已经付了房钱。我确信等第二天醒来，我的车可能会像《战争之王》里停靠在非洲草原上的Antonov An-12运输机一样，以最朴素的方式回归自然。
                <br/><br/>
                我没有任何讽刺的意思，午夜的Matamoros让人不安，尤其当我对此地一无所知，没有任何经验知识。我急需返回边境的另一侧。等我沿着无信号的Google Map反向退回至边境线时，迎接我的不再是一个吆喝着6刀过路费的墨西哥大妈，而是荷枪实弹的美国边检岗哨。
                <br/><br/>
                麻烦来了。
                <br/><br/>
                边检官问我要了身份证件，听说我只在墨西哥停留了20多分钟后，开始变得警惕。我们开始了一段抽丝剥茧的问答：
                <br/><br/>
                -你从哪儿来？
                <br/>
                -中国。哦对了，我回美国休假，一个人从加州开过来的。
                <br/><br/>
                -要到哪儿去？
                <br/>
                -I'm driving coast-to-coast. 计划开到波士顿。
                <br/><br/>
                -去波士顿干什么？
                <br/>
                -去波士顿拜访我的朋友和教授，我之前在麻省理工读书。
                <br/><br/>
                -为什么出现在Brownsville，这根本不在你去波士顿的路线上？
                <br/>
                -我去看Elon Musk的火箭，这是我临时做的决定。
                <br/><br/>
                -你有几张旧的美国签证，之前在美国做什么？
                <br/>
                -我在这里上学和工作很多年（我拿出打满一本的旧护照递给他，边检官明显放松了一些，看来眼前这个人本身就很离谱）
                <br/><br/>
                -你去墨西哥那边做什么？
                <br/>
                -我定的旅馆在那边，但是我看到街道和城市的情况，我不放心把车放在那边，虽然我已经付了房钱。
                <br/>
                -嗯，我懂你意思……
                <br/><br/>
                -有没有在Mexico停过车？
                <br/>
                -没有。
                <br/>
                -有没有和任何人和车接触过？
                <br/>
                -没有。
                <br/>
                ……
                <br/><br/>
                一个中国人，孤身一人开着Jeep从加州出发，朝大陆的东北方向前进，在没有任何理由南下德克萨斯来到Brownsville的情况下，午夜时分跨过美墨边境，在墨西哥境内的Matamoros逗留了30分钟后，然后重新进入美国境内。
                <br/><br/>
                如果你是边检官，你会怎么想？我知道你会想什么。你和我和他的猜疑，都情有可原。
                <br/><br/>
                边检官保持了高度警惕，我保持了头脑冷静，举止得体，言辞礼貌体面。
                <br/><br/>
                是的，这些行为很管用。边检官问我什么，我就回答什么，一个字不多，也一个字不少。
                <br/><br/>
                盘问了20分钟后，我在后备箱和所有的行李都被打开仔细检查之后，拿到了通行许可，我从墨西哥回到了美国境内。
                <br/><br/>
                时间已是凌晨一点多。今天的冒险份额够多了，我需要找个地方睡觉。
                <br/><br/><br/>
                <h5><b>布朗斯维尔 Brownsville——休斯顿 Houston——新奥尔良 New Orleans</b></h5><br/>
                <h6><b>Continental Breakfast</b></h6><br/>
                在Brownsville的一家Holiday Inn中醒来，整个屋子都弥漫着旅馆的特殊味道，那是一种非常熟悉且和海拔纬度无关的固定气味。
                <br/><br/>
                一顿毫无绿菜的continental breakfast，包含了一个不健康的人一天所需的全部养分，而这正是我所需要的。
                <br/><br/>
                昨天实在太累了，今天甚至多睡了一个钟头。
                <br/><br/>
                <img src={Brown1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Brown2} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6><b>休斯顿、牛仔帽</b></h6><br/>
                好朋友本科在Rice University读书，我路过Rice的时候，专门给他发了定位，他哈哈大笑说什么时候到波士顿，我说如果休斯顿的堵车能缓解，我会更快的。
                <br/><br/>
                路过大草原，我买了一包红萝卜、一杯酸奶和一瓶水、一顶黑色牛仔帽。
                <br/><br/>
                至于那顶黑色牛仔帽，我的感觉如Rylan Givens那句：我第一眼就看上它了。
                <br/><br/>
                目送休斯顿离开视野👋🏻
                <br/><br/>
                <img src={Mirror} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Houston1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Houston2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Houston3} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6><b>路易斯安娜</b></h6><br/>
                路易斯安娜州是美国国父托马斯·杰斐逊从拿破仑手里买来的，当时拿破仑急需战争资金，美国人占了大便宜。昨晚在加油站加油，坐在一辆白色Jeep里的两个热情的白人姑娘，看见我也开Jeep，冲过来送给我一只做成杰斐逊的鸭子，作为同为Jeep“匪帮”的新奥尔良礼物。
                <br/><br/>
                我几年前就来过新奥尔良，当时在MIT的研究生朋友邀请我去做客，但这趟我们要擦肩而过了。他是新奥尔良土生土长的黑人，刚任新奥尔良市规划委员会主席。
                <br/><br/>
                坊间戏说温斯顿·丘吉尔，在某个醉酒的午后，用一支钢笔✒️创造了约旦。在新奥尔良，那杯酒一定是Bloody Mary。在正午的阳光升起来之前，你的肚中会装上两杯血腥玛丽、二十五只生蚝和酸辣爽口的cajun food，然后你会急需一趟厕所或消化系统切除服务。
                <br/><br/>
                长途跋涉需要高度清醒，我的血液里除了咖啡因和卡车司机的饮食外，没有任何别的东西。朋友出城了，下次再见👋🏻
                <br/><br/>
                <img src={NO1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NO2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NO3} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>新奥尔良 New Orleans——格林威尔 Greenville</b></h5><br/>
                一早就从新奥尔良出发，今天又是万里长征的一天。
                <br/><br/>
                穿过密西西比的一角，来到阿拉巴马州的地界，正式进入阿巴拉契亚山脉的统治范围。耳朵里听着Sweet Home A-LABA-MA!
                <br/><br/>
                我靠近Atlanta发了一张照片配文“每次在Atlanta都过境”。好朋友看到后，offer我去住他家，在南卡。我在高速路肩上停了车，查了下我当时的位置，离他家只有三十分钟。
                <br/><br/>
                <img src={Atlanta} alt="" width={"100%"}/>
                <br/><br/>
                三十分钟之后，我坐在他妈妈一间有不计其数收藏品、如热带雨林般丰富多彩的客厅里，被一座高级按摩椅拉伸。当然，阿姨的酷远不止客厅，我的语言开始贫乏了。
                <br/><br/>
                我本身只求一个沙发，阿姨给了我一间如梦似幻的榻榻米。我跟朋友的生日只差一天，刚在深圳共度生日，又机缘巧合睡在他家榻榻米里。
                <br/><br/>
                旅行不可思议，昨晚的神奇有趣和前天不小心越过美墨边界费不少唇舌但终究回来了一样。人生大抵如此。
                <br/><br/><br/>
                <h5><b>格林威尔 Greenville——华盛顿 Washington D.C.</b></h5><br/>
                起床才发现GreenVille有多Green，绿树掩映的阿巴拉契亚山脉风光，让南卡的宜居展露无疑，Greenville的油价之低让我恨不得再提两桶。
                <br/><br/>
                早上出发去D.C. 在美利坚的首都，我还没发路牌就被朋友收留了。
                <br/><br/>
                他问我今天还是明天到，我回答：一个半钟头。大家都笑了。
                <br/><br/>
                朋友的住处极其靠近华盛顿中心的放射状轴线，高耸挺立的方尖碑，夜幕中神庙状的林肯纪念堂。戴牛仔帽的我，用半打源自圣·路易斯兼具中西部精神的百威，一瓶prosecco，送出了西部的问候。买酒的地方是一个叫Watergate Spirits的酒水超市，就在尼克松事件的那个水门酒店Watergate Hotel，意外感受历史。晚上等他哄儿子睡觉，我不得不说，小家伙长得是真帅。
                <br/><br/>
                <img src={DC1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={DC2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={DC3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={DC4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={DC5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={DC6} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>华盛顿 D.C.——费城 Philadelphia</b></h5><br/>
                从D.C.溜到费城，先吃一个steak sandwich，两年前我在街边吃Pat's的牛排三明治，小哥的态度和国内苍蝇馆子一样恶劣。今儿试试Geno's，态度温和了许多，味道也不如Pat's油腻。这绝对的最近这段时间最放松的一天。
                <br/><br/>
                <img src={Ph1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Ph2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Ph3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Ph4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Ph5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Ph6} alt="" width={"100%"}/>
                <br/><br/>
                下午在费城Brickbat旧书店，挑了四本正常的书。《笛卡尔》、本雅明的《单向街》、《瓦尔登湖》和卡夫卡的《审判》。
                <br/><br/>
                <img src={BookStore1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={BookStore2} alt="" width={"100%"}/>
                <br/><br/>
                书店老板是个大叔，看见我的cowboy hat问我有什么活动，我说我从Santa Barbara开过来的，他吓了一跳。
                <br/><br/>
                <img src={Books} alt="" width={"100%"}/>
                <br/><br/>
                我说那你帮我拍张照吧，这是他拍得最好的一张。
                <br/><br/>
                <img src={Cowboy} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>费城 Philadelphia ——华盛顿 D.C.</b></h5><br/>
                晚上和朋友吃过晚餐，我住在费城的Hotel。这间酒店晚上看似有重要会议，全是拿着行李的美军和高级长官，我旁边的房间住着一个军官，我们互道了晚安后回到了各自的房间。这件屋子的豪华景观让我瞬间回到六年前，我突然意识到，我曾住过这家酒店，很高兴能故地重游。
                <br/><br/>
                我累得倒头就睡。
                <br/><br/>
                一大早从费城离开，开车回D.C.见两位重要的人。我从麻省理工毕业后的第一份工作，在世界银行做经济研究，这两位重要的人是我的老板。最奇妙的是，我们三个人从天南地北，在同一周内出现在D.C.，并且都只待几天，人生真是妙不可言。
                <br/><br/>
                老板请我吃世行旁边的一家印度菜Bindaas，印度同事都说好。这趟来世界银行，已然是访客的牌子，让我唏嘘。疫情让世界天翻地覆，但这是必须接受的历史现实。
                <br/><br/>
                今天我还专门换掉了牛仔的着装，选择与华盛顿融为一体。下回再见，1818 H St. NW.
                <br/><br/>
                <img src={Selfie} alt="" width={"100%"}/>
                <br/><br/>
                <img src={WB} alt="" width={"100%"}/>
                <br/><br/>
                <img src={WB1} alt="" width={"100%"}/>
                {/*<br/><br/>*/}
                {/*<img src={WB2} alt="" width={"100%"}/>*/}
                <br/><br/>
                <img src={WB3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={WB4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={WB5} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>华盛顿 D.C.——纽约 New York City</b></h5><br/>
                周五晚上开车进纽约城是不理智但可以被理解的。在路上加油的时候，我约了清华本科的好友在Bar Meridian小酌，我完全信任他的品味。Bar的位置在布鲁克林，我在朋友圈邀请附近的朋友加入。
                <br/><br/>
                <img src={NYCpre} alt="" width={"100%"}/>
                <br/><br/>
                大约一个半钟头后，我到纽约了，和朋友喝了第一顿酒，这是最近长途跋涉中最放松的一天。我们互相送出纽约和西部的祝福。💤
                <br/><br/>
                从Bar里出来，我把车钥匙装在口袋里，打开了uber，路过Manhattan Bridge，我拍下大桥的模样，这是记忆中纽约最真实的样子。
                <br/><br/>
                <img src={NYCpre1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NYCpre2} alt="" width={"100%"}/>
                <br/><br/>
                一大早在pershing square的雨雾中闻到尿味，路人行色匆匆，从印度人的路边摊买一杯3刀装在泡沫塑料杯里的有渣咖啡，这是nyc最真诚的滋味。
                <br/><br/>
                回到布鲁克林，昨晚停的车还在，不错。中午被高中同桌招待，她的菜丰盛到最多吃了1/4，还有一瓶sake。多年不见，如昨日才见。
                <br/><br/>
                晚上睡在朋友Jersey的公寓里，我拿了一束花💐和一瓶Rosé，朋友的妈妈很明显对红酒不以为然，但她对花很喜欢。我做好了准备，明天开wrangler带她去跳广场舞！😃
                <br/><br/>
                <img src={NYCpre3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NYCHotel} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NYCpre5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NYCpre6} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NYCpre7} alt="" width={"100%"}/>
                <br/><br/>
                晚上和本科朋友吃了曼岛上一家相当地道的泰国菜，纽约杂七杂八的味道又一次让我想起了公元7世纪盛唐的长安城，乌烟瘴气、乱七八糟但充满了活力！
                <br/><br/>
                <img src={NYCpre8} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NYCpre9} alt="" width={"100%"}/>
                <br/><br/>
                第二天，I Set Fire to the Rain! 下了一整天雨的阴冷纽约，终于放晴了。
                <br/><br/>
                <img src={NYC3} alt="" width={"100%"}/>
                <br/><br/>
                So far I've got a speeding ticket from a Texas sheriff before seeing Elon's rockets, a parking ticket from parking in front of Vietnam Embassy in D.C., and a parking ticket in NYC. 🥲
                <br/><br/>
                然后，我死性不改，继续在曼岛上开车，感觉又要下雨了。
                <br/><br/>
                <img src={CU5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={CU6} alt="" width={"100%"}/>
                <br/><br/>
                中午临时约朋友出来coffee，他从北边开了一个钟头来Chelsea，感动，临时能叫出来的朋友胜过所有提前规划！
                <br/><br/>
                <img src={CU1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={CU2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={CU3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={CU4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={NYC1} alt="" width={"100%"}/>
                <br/><br/>
                下午在哥大附近见Emma，她是哥大物理系的phd，你可能在过往某期的福布斯封面上见过她，她的故事比我的旅行要更疯狂，也更鼓舞人心。那本费城旧书店的Descartes，很适合你，送你了，外加一杯decent latte，一并作为我诚挚的祝福。Emma送了我一个香薰蜡烛，good taste.
                <br/><br/>
                祝你好运🤞 期待在Nature或Forbes的封面再看见你！
                <br/><br/>
                <img src={NYC2} alt="" width={"100%"}/>
                <br/><br/>
                晚上回到朋友在Jersey的公寓，从新泽西看曼哈顿岛的景观是无敌的，Jersey在这点上无可替代。
                <br/><br/>
                <img src={Manhattan} alt="" width={"100%"}/>
                <br/><br/>
                我在朋友圈里吆喝了一遍，明天中午曼哈顿Midtown，清华建筑系还在纽约的朋友们，大家去Cafe China聚餐。
                <br/><br/>
                <img src={Cafe1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Cafe2} alt="" width={"100%"}/>
                <br/><br/>
                59 W 37th St, New York, NY 10018
                <br/><br/><br/>
                <h5><b>纽约 New York City——波士顿 Boston</b></h5><br/>
                中午和新老朋友相聚过后我就出发了。Leaving town. Till then New York👋🏻 一路向北 -> 波士顿。
                <br/><br/>
                从纽约出发开到傍晚，我抵达了波士顿郊区，天空中飘起了小雨，是种思念的味道。车开到我曾经住了多年的查尔斯河边的公寓楼下，油箱刚好全空，如有天意。我说服加油站的黑人小哥给我拍了张照。
                <br/><br/>
                <img src={North1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={North2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={North3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={North4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={North5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={North6} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Destination} alt="" width={"100%"}/>
                <br/><br/>
                Hey 麻省理工，77 Massachusetts Ave，我回来了。我一个人开着这辆车，从洛杉矶机场出发，经过圣巴巴拉蔚蓝的太平洋，终于到了波士顿，但我们还没到大西洋边。Tony，让我停好车把钥匙装起来。I'm beat, time for dinner! 🥃
                <br/><br/>
                <img src={MIT} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6><b>太平洋 Pacific Ocean—大西洋 Atlantic Ocean</b></h6><br/>
                离开圣巴巴拉太平洋海岸的我，终于到了大西洋边的波士顿。
                <br/><br/>
                我闻到大西洋的腥味了，在Revere Beach给这趟反向横跨北美大陆的第一段画个句号。
                <br/><br/>
                晚上好朋友飞Amsterdam，我住他在哈佛的房子，在Cambridge的朋友，步行范围就能见到；波士顿周边的朋友，我们驱车相会。
                <br/><br/>
                <img src={Pacific} alt="" width={"100%"}/>
                <br/>
                <div style={{ textAlign: 'center' }}><b><i>太平洋</i></b></div>
                <br/><br/>
                <img src={Atlantic} alt="" width={"100%"}/>
                <br/>
                <div style={{ textAlign: 'center' }}><b><i>大西洋</i></b></div>
                <br/><br/>
                <h6><b>回家 Home</b></h6><br/>
                Cambridge的日料，八百年没去的Total Wine，清晨和日暮常去散步的Nahant Beach，总去吃的那家Texas Roadhouse，我真的回家了。
                <br/><br/>
                晚上和两个老朋友在Seaport吃饭，挑的餐厅、西班牙菜品、聊的话题和大家的精神状态，相当好玩儿且有品位。第二天上午又突然造访了一位老朋友。
                <br/><br/>
                这已经成了我的做事风格，大家见怪不怪。
                <br/><br/>
                <img src={Home1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Home2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Home3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Home4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Home5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Home6} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Home7} alt="" width={"100%"}/>
                <br/><br/>
                中午和师兄在Legal Seafood吃饭，这个地方全是当时在麻省理工读书的回忆。还没到中午，借着Irish Coffee的微醺，我和师兄的聊天节奏和想象力已是晚上11点的水平。
                <br/><br/>
                回到Cambridge，我开车可以关掉导航，走路可以闭着眼睛，在波士顿的那几天基本都在哈佛和MIT活动。
                <br/><br/>
                第一天的下午很好玩儿，她是我在MIT最喜欢的教授之一，正在斯隆给学生上课，我从她助手那儿知道了她的日程安排。我像刺客一样在教室外面静候课堂氛围的变化，直到教室里的脉搏和心跳准备妥当。然后我一身牛仔打扮闯入一个200人的阶梯教室，把礼物递给我教授，然后开始了我的旅行演说。
                <br/><br/>
                这间MIT的课堂是我本次横穿的终点。
                <br/><br/>
                教室里各色人种的学生都惊呆了，然后开始喝彩，我给了教授一个拥抱，然后转身离开。
                <br/><br/>
                她眨眨眼睛，调皮地说：I'll drink it before my next class!
                <br/><br/>
                如果说这种事对我的快乐有多大，就像上次MIT的屋顶上出现了一辆卡车那么离谱，也那么合情合理。也像这趟旅行中一朵舒展翻滚的云彩，或一辆奔腾咆哮的火车头能带给我的快乐一样简单。
                <br/><br/>
                晚上和教授聊天，她没有怪我打扮成西部牛仔拿着一瓶上好的Italian Red闯入了一堂正在进行的MIT核心课，她爱死了，哈哈哈！❤️
                <br/><br/>
                <img src={School1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={School2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={School3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={School4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={School5} alt="" width={"100%"}/>
                <br/><br/>
                <img src={School6} alt="" width={"100%"}/>
                <br/><br/>
                <img src={School7} alt="" width={"100%"}/>
                <br/><br/>
                <img src={School8} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>波士顿 Boston——西雅图 Seattle</b></h5><br/>
                第二天下午和WashU的老朋友聊天，人生如梦，但一定会越来越好！
                <br/><br/>
                晚上见到了苏导，他是剑桥春晚的导演，还是波士顿地区有影响力的话剧社团导演，期待他们今年的波士顿春晚！半夜我还见到了一对儿夫妻，祝你们幸福！
                <br/><br/>
                第三天上午又是一场令人愉悦的coffee chat.
                <br/><br/>
                我真诚地感激旅途中遇到的所有朋友们，感谢他们为我提供旅途的庇护，和无与伦比的人生故事。
                <br/><br/>
                但要暂别了，波士顿和新英格兰风光，还有这辆Jeep，你跟我同吃同睡半个月，我们从西海岸出发，得把你留在东海岸，祝你未来一切顺利！没见到的朋友，我们很快还会再见。
                <br/><br/>
                换个大引擎，把丢失的三小时找回来！➡️西雅图
                <br/><br/>
                <img src={Bos1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bos2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bos3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bos4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bos5} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>西雅图 Seattle——洛杉矶 Los Angeles——圣巴巴拉 Santa Barbara</b></h5><br/>
                一大早我就醒了！我住在好朋友家，她家附近有个吃早饭的好去处。碳水、高蛋白、丰富的脂肪，这些东西对一个人的心理健康非常重要。
                <br/><br/>
                我停好车，点了一份名为Lake Union的早餐，自己在店里享用一份，take out三份，有几个朋友马上吃到了同款早饭。服务员小姐姐面露喜色，毕竟我的小费也给的相当大方，她临走帮我装了一大杯黑咖啡，这正是我需要的。
                <br/><br/>
                <img src={Seattle} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Seattle2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Seattle3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Seattle4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SeattleFriends} alt="" width={"100%"}/>
                <br/><br/>
                以我疯狂即兴的程度，下次一定会再提前个十五分钟跟各位朋友们说。如果没有photo-op，那默契都在心里了！我和朋友们向来懒得拘小节。礼物有我给出的，也有我收到的。👇🏻球球随手拍的。
                <br/><br/>
                <img src={Dong} alt="" width={"100%"}/>
                <br/><br/>
                <h6><b>节日氛围 Holiday Vibe</b></h6><br/>
                从西雅图离开，我回到到了洛杉矶，但我依然没有停留，LA摊大饼的城市结构和夸张的大堵车会是个时间漩涡。我拿到车，重回马鞍之上，一路向北，朝着湾区前进。
                <br/><br/>
                <img src={Saddle} alt="" width={"100%"}/>
                <br/><br/>
                路过圣巴巴拉，我必须停下。在这片天堂般的海域，有太多值得我留恋的东西。棕榈树，沙滩，甲板，帆船……我又有度假的感觉了。
                <br/><br/>
                <img src={H1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={H2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={H3} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>湾区漫游 Bay Area</b></h5><br/>
                从圣巴巴拉出发，开到湾区不到5个钟头（如果不大堵车）。晚上见到了清华本科的同班好友，她是我那年的省状元。她亲手包的包子有家的味道，东湾的沙发也比旅馆的床舒服得多，我补足了睡眠。
                <br/><br/>
                <img src={SanF1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SanF2} alt="" width={"100%"}/>
                <br/><br/>
                湾区的第二天，我去了三趟谷歌，Bay View的各处大楼逛了个遍，科技大厂确实一尘不染，宽敞舒适，我也给朋友们带去了祝福。
                <br/><br/>
                <img src={Google} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Google1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Google2} alt="" width={"100%"}/>
                <br/><br/>
                下午在斯坦福附近的一个Blue Bottle咖啡厅，我与一位哈佛老朋友计划了一场偶遇，生活持续给我惊喜。
                <br/><br/>
                <img src={BlueBottle} alt="" width={"100%"}/>
                <br/><br/>
                在湾区的几天，跟朋友们逛了湾区各处的大厂，吃饭几乎没机会花钱。朋友们做饭的手艺是真好，好朋友的次卧以及客厅搭的行军床也总能给我八个小时睡眠。
                <br/><br/>
                <img src={Bay1A} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bay1B} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bay1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bay2} alt="" width={"100%"}/>
                <br/><br/>
                下午跑到腾讯在湾区的Tencent America看了一眼，大家工卡长得一模一样，但刷不开，我只能用嘴开。前台的白人小哥实在太闲了。
                <br/><br/>
                <img src={Bay3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Bay4} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>洛杉矶——台北——香港——深圳</b></h5><br/>
                从洛杉矶出发，再次台北转机。这次在美西飞，阿拉斯加航空把行李照顾得不错，谢谢！🤝
                <br/><br/>
                <img src={LAX1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={LAX1A} alt="" width={"100%"}/>
                <br/><br/>
                到了台北后，问题变得简单，不出机场，在那儿住一夜。
                <br/><br/>
                <img src={LAX2} alt="" width={"100%"}/>
                <br/><br/>
                从香港回到深圳，空气实在太热了。我从洛杉矶机场出发，两个行李一个背包，并没有直接回家，而是去了腾讯的大楼，接下来的故事不多讲了！🤠🤠🤠
                <br/><br/>
                我为十月的行为艺术画个句号，好朋友们，enjoy!
                <br/><br/>
                <img src={SZ0} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SZ1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={SZ2} alt="" width={"100%"}/>
                <br/><br/>
                这趟旅行的大部分时间，我都睡在朋友的卧室、沙发和地板上，偶尔也会在车里眯几个钟头。
                <br/><br/>
                这个星球依旧有一种荒凉待开发的魔力，我看见多种多样的人生在这个星球上展开，他们是家人、朋友、教授、加油站的热心陌生人、必须预设恶意的警长、边境检察官、无知的种族主义者、面无表情的冰冷陌生人……这些人在任何文化和任何国家都存在。
                <br/><br/>
                必须承认，仰仗着朋友慷慨和命运宽容，我才得以在这个星球上旅行。
                <br/><br/>
                不要期待有任何一个地方是天堂，那会让人天真又愚蠢。
                <br/><br/>
                活在直觉上，是最深思熟虑的生活。
                <br/><br/><br/>
                <h5><b>月底旅行-福建漳州</b></h5><br/>
                我回来的第二天就是部门的团建。我曾经学了这么久的建筑学，第一次看福建土楼竟然是在腾讯团建。也顺便纪念我在地表整整旅行了一个月。
                <br/><br/>
                <img src={ZZ1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={ZZ2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={ZZ3} alt="" width={"100%"}/>
                <br/><br/>
                <img src={ZZ4} alt="" width={"100%"}/>
                <br/><br/>
                <img src={ZZ5} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>腾讯北京总部 Tencent Beijing Headquarter</b></h5><br/>
                上周末我趁没人的时候从深圳飞去北京，后厂村路是中国互联网的胜地。
                <br/><br/>
                刚从美国回来，我的直观感觉是世界级的科技大厂，在建筑物的质量和办公环境上几乎没有高下之分，甚至国内大厂的办公楼可能更气派些。
                <br/><br/>
                至于工作氛围和文化，各有长短，更不要简单地觉得外边的就一定更好，这会暴露一个人在思考和视野上的匮乏。
                <br/><br/>
                <img src={Ten1} alt="" width={"100%"}/>
                {/*<br/><br/>*/}
                {/*<img src={Ten2} alt="" width={"100%"}/>*/}
                <br/><br/>
                <img src={Ten3} alt="" width={"100%"}/>
                {/*<br/><br/>*/}
                {/*<img src={Ten4} alt="" width={"100%"}/>*/}
                <br/><br/>
                <img src={Ten5} alt="" width={"100%"}/>
                {/*<br/><br/>*/}
                {/*<img src={Ten6} alt="" width={"100%"}/>*/}
                <br/><br/>
                <img src={Ten7} alt="" width={"100%"}/>
                <br/><br/>
                <img src={Ten8} alt="" width={"100%"}/>
                {/*<br/><br/>*/}
                {/*<img src={Ten9} alt="" width={"100%"}/>*/}
                <br/><br/>
                从北京深圳到美国西海岸，这些科技大楼为什么都一尘不染，因为在大家不上班的时候，总有保洁人员在维护。建筑物如果两周没人管，你就能明显感觉到。你仔细看，他们永远在画面里。
                <br/><br/>
                <img src={T1} alt="" width={"100%"}/>
                <br/><br/>
                <img src={T2} alt="" width={"100%"}/>
                <br/><br/>
                <img src={T3} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5><b>尾声 Ending</b></h5><br/>
                这个月我时常搞不清自己身处何方，周围是什么，现在几点，张嘴该说什么语言。但奇怪的是，我几乎不用倒时差。好朋友跟我说，可能你21年-23年回国的这两年，就没倒过东岸的时差；而在美国这一个月，你也用的东八区的时间。好的吧！
                <br/><br/>
                我又一次感觉地球是如此小，当用不同功率的引擎去测量时，其尺度结果的差异又如此大。那个伫立在美墨边境一个叫Brownsville的星舰基地里，那具银光闪闪的钢铁火箭绝不是象征式的，它装备着33个猛禽发动机的全金属外壳里有一些遥远但很重要的现实意义。
                <br/><br/>
                这个月旅行中发生的事，记录在这儿。这些远方的人和故事，和每个人都息息相关。

            </p>

            <br/>

            <h6 className="App-essay-title">Crossed North America Again</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2023</b>
                <br/><br/>

            </p>
        </div>)
}
