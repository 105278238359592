import React from "react";
import {Helmet} from "react-helmet";
import Craftsman from "../../assets/images/craftsman.png";

export default function Stirrups() {
    return (
        <div>
            <Helmet>
                <title>工匠：做就是想 Craftsman: Making is Thinking</title>
            </Helmet>
            <h6 className="App-essay-title">工匠：做就是想</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 三月</b>
                <br/><br/>
                <div style={{display: 'flex', justifyContent: 'left'}}>
                    <img src={Craftsman} alt="Craftsman" style={{width: "61.8%"}}/>
                </div>
                <br/><br/>
                <b style={{fontSize: "medium"}}>混血王子 Half-Blood Prince</b>
                <br/><br/>
                工匠的第一重含义，是技术专家。创造原子弹的奥本海默是工匠，解牛的庖丁是工匠，修建大教堂的米开朗琪罗是工匠，敲代码的程序员是工匠，中世纪行会里的银匠是工匠，在房基上砌砖和搅拌混凝土的工人是工匠，纳粹集中营的组织者阿道夫·艾希曼也是工匠。工匠是一个中性词。
                <br/><br/>
                工匠的第二重含义，用自己身体和物质世界直接互动，在改造物质世界的过程中有意识地减少费力、提高效率的人，都是工匠。
                <br/><br/>
                在《哈利·波特》的第六卷，哈利拿着神秘的“混血王子”留下的魔药课本，里面详细的魔药制作技巧让哈利的魔药技能直线上升，连赫敏都变得不值一提。
                <br/><br/>
                这些小抄是西弗勒斯·斯内普留下的，他在魔药课本的空白处留下了这些心得——这是混血王子对自己天赋的自负与褒奖。在这些事无巨细的魔药制作技巧中，斯内普也毫不掩饰地记录了他对课本里展示的工艺技巧嗤之以鼻。
                <br/><br/>
                哈利·波特可以利用这些技术调制魔药来对抗伏地魔，斯内普也可以利用同样的魔药才华加入伏地魔的邪恶阵营。同样的技术，可以因为抉择相反而变得截然不同。
                <br/><br/>
                但《混血王子》在细节处埋下了伏笔。
                <br/><br/>
                虽然技术的目的可以截然不同，但是要形成一种技术，首先需要斯内普在课本上密密麻麻记载这些魔药制作提示（tips），它们进而形成了一组庞大的技巧（technique），这些技巧进一步携带着强烈的工匠特质——即这位混血王子的气质，最后变成了一个完整的、似乎有匠人灵魂存在的技艺（craft）。此刻，技艺完成了对工匠（craftsman）的还魂。
                <br/><br/>
                这个过程中，混血王子的气息由无数的魔药制作技巧和操作细节构建而成，这里包涵了斯内普作为工匠（craftsman）的双手操作、身体实践、大脑思考以及意识判断——混血王子留下的魔药课本，充满着斯内普的气息。
                <br/><br/>
                那些在魔药课本的段落夹缝里写下的高超技艺，时不时还会夹杂着混血王子的自命不凡和对教科书的嘲弄，这些特质也渐渐通过技艺本身，把前一位工匠的特质慢慢渗透在哈利·波特的精神里。斯内普通过魔药混合的技术细节，把自己作为“混血王子”的一部分邪恶气质，也注入了哈利·波特体内。在学习了混血王子的高超技艺后，连一向心胸宽广的哈利·波特，也开始变得傲慢自大起来。
                <br/><br/>
                作为一种思考成果，技艺充满了匠人在物质实践中的独特气息。技艺不可以纯粹而孤立地存在，它和物质世界息息相关。
                <br/><br/>
                制造了原子弹的奥本海默，在1945年11月2日写道，
                <br/><br/>
                <i style={{color: 'grey'}}>It is good to turn over to mankind at large the greatest possible power to
                    control the world and to deal with it according to its lights and its values.<br/>将这个能统治世界的最强力量交还给全体人类，让全部人去决定它的标准和价值，是正确的决策。</i>
                <br/><br/>
                他想把原子弹开源。
                <br/><br/>
                <i style={{color: 'grey'}}>但是，如果技术专家都想不明白自己的技术，普罗大众又能做什么呢？(Sennett,
                    2008)<br/>If the experts cannot make sense of their work, what of the public? (Sennett, 2008)</i>
                <br/><br/>
                但无论如何，混血王子都想交出自己的魔药课本了。
                <br/><br/>
                <b style={{fontSize: "medium"}}>潘多拉 Pandora</b>
                <br/><br/>
                潘多拉不知道她打开的是什么，她只是无比好奇这个盒子里到底有什么，这种好奇发明了火和刀具、也制造了原子弹和毒气室，潘多拉的好奇是中性的。这种无法自控的好奇心，就像科学家们坚信的：“所有可被尝试的事物，都应该也终将会被尝试”，几乎一模一样。
                <br/><br/>
                神话故事为什么会选择一个美丽的女性角色来打开充满混乱的魔盒？为什么全世界的人都不约而同地选择用“祖国母亲”来称呼自己的国家？为什么苏联人会用喀秋莎和女性的名字命名自己的大炮和火箭弹？可能所有人都因为女性而存在，“她”代表着创造，也彻底垄断着孕育生命的权利，这股力量非比寻常，创造和毁灭是同属于工匠能力的两面，一如潘多拉魔盒的比喻。
                <br/><br/>
                <i style={{color: 'grey'}}>Man as his own maker.</i>
                <br/>
                <i style={{color: 'grey'}}>人是自己的缔造者。</i>
                <br/><br/>
                <b style={{fontSize: "medium"}}>手与脑 Hand and Head</b>
                <br/><br/>
                任何思考都需要一定程度的身体行动做支持才能发生，用手拿笔写字可以激发更多灵感，所有人都不陌生。即使是最具精神性的宗教仪式，在精神仪式中也需要身体参与。做跪地拜神的动作，就是通过身体在思考——礼拜所包含的身体行为，本身就是宗教仪式中最重要的部分。虔诚的信徒只需要做一次跪地拜神的动作，就能感受到精神的感召。信徒的身体行动触发了大脑思考——虔诚是“做”礼拜的副产物。
                <br/><br/>
                制造原子弹，制造纳粹毒气室，制造一条桌腿，制造一碗鱼肉拌饭，制造一张布料、一块钢铁和一把上了膛的枪，制造一副精美的装饰油画，制造一架无比智能的机器，制造一栋房屋，制造一块可以播种的土地……塑造物质世界的行为本身，不仅塑造物质，也塑造了思维。正如斯内普在魔药课本上的技巧就天然带有混血王子的灵魂和思考一样，指导物质如何被改造的头脑（head）无法从双手（hand）中剥离。
                <br/><br/>
                操作就是思考，你无法将二者分开。
                <br/><br/>
                在塑造物质的过程中，工匠在思索、解决、实验、执行，同时，工匠也在判断、评估、裁决、审判——这到底该怎么做（How），以及，这一切到底好不好（Why）？
                <br/><br/>
                正因为工匠在制造技术的过程中，就已经进行了天然的思考，人类的道德意识（consciousness）在改造物质的过程中就已经存在了，工匠的思考不是在技术创作后才发生的。因此，工匠的群体中需要尽可能多地引入普通人的劳动，而不应只包含天赋异禀的技术天才。
                <br/><br/>
                同时，作为工匠，我们非常需要理解自己的双手所拥有的庞大力量。人的身体和劳动是大自然的一部分，强行剥离意识思维和身体劳动，我们就无法理解技术的创造力和毁灭性从何而来。每一次对物质进行加工、组装和雕琢，工匠都因为双手的劳作而塑造着物质世界，也深刻地改变着自己的精神。而每一次对物质世界的重构，都让工匠无可避免地承担着潘多拉的角色。我们时刻都站在这个十字路口，一如此刻。
                <br/><br/>
                因此，对物质世界的改造实在太过重要，以至于不能留给那些只会坐而论道的“思想家”们评判——无论他们是技术创作的一员，还是技术制造后的评论家。这场关于如何改造物质世界的辩论和思考，必须在做的过程中，就在第一时间交由工匠们的“双手”去“思考”，才能最小化毁灭性的可能。
                <br/><br/>
                撤退到纯粹的精神领域讨论技术问题，没有价值。那些具有毁灭性的技术挑战，源自于工匠劳作的双手，也只有在工匠双手的劳作中，才能得到解决。
                <br/><br/>
                <i style={{color: 'grey'}}>Making is Thinking.</i>
                <br/>
                <i style={{color: 'grey'}}>做就是想。</i>
                <br/><br/><br/>
                <b>Reference</b>
                <br/>
                <b>1. </b>Richard Sennett, The Craftsman, Yale University Press, 2008.

            </p>

            <hr/>
            <br/>


            <h6 className="App-essay-title">Craftsman: Making is Thinking</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2024</b>
                <br/><br/>
                <div style={{display: 'flex', justifyContent: 'left'}}>
                    <img src={Craftsman} alt="Craftsman" style={{width: "61.8%"}}/>
                </div>
                <br/><br/>
                <b style={{fontSize: "medium"}}>Half-Blood Prince</b>
                <br/><br/>
                A craftsman's first and foremost meaning is that of a technical expert. The creators of the atomic bomb,
                like Oppenheimer, were craftsmen, as was Pao Ding (庖丁), who skillfully cut up an ox[1]; Michelangelo,
                who built cathedrals; programmers who code; silversmiths in medieval guilds; workers laying bricks and
                mixing concrete on foundations; and even Adolf Eichmann, the organizer of Nazi concentration camps, was
                a craftsman. “Craftsman” does not imply kindness.
                <br/><br/>
                The second meaning of a craftsman refers to those who directly interact with the material world using
                their own bodies and consciously seeking to reduce effort and improve efficiency when shaping it.
                <br/><br/>
                In the sixth volume of “Harry Potter,” Harry, with the mysterious “Half-Blood Prince’s” potion textbook
                in hand, sees his potion-making skills skyrocket, rendering even Hermione’s talents negligible.
                <br/><br/>
                These notes were left by Severus Snape, who filled the blank spaces of the potion textbook with his
                insights — this was the Half-Blood Prince’s self-pride and reward for his talent. Snape did not shy away
                from recording his disdain for the craft techniques shown in the textbook amidst these detailed
                potion-making tips.
                <br/><br/>
                Harry could use these techniques to concoct potions to fight against Voldemort, and Snape could
                use the same potion talent to join Voldemort’s evil ranks. The same technology could become drastically
                different due to opposing choices.
                <br/><br/>
                However, “The Half-Blood Prince” plants a foreshadowing in its details.
                <br/><br/>
                Although the purpose of craft can be drastically different, to form a craft, Snape first
                needed to densely record these potion-making tips in the textbook, which then constituted a vast set of
                techniques. These techniques carried a vital craftsman characteristic — the temperament of the
                Half-Blood Prince, eventually becoming a complete craft, as if embodying the soul of a craftsman. At
                this moment, the craft has revived the craftsman.
                <br/><br/>
                In this process, the essence of the Half-Blood Prince is built from countless potion-making techniques
                and operational details, encompassing Snape’s hands-on work, bodily practices, brainpower, and conscious
                judgments — the potion textbook left by the Half-Blood Prince is imbued with Snape’s essence.
                <br/><br/>
                Those superior skills written in the margins of the potion textbook occasionally mixed with the
                Half-Blood Prince’s arrogance and mockery of the textbooks. These traits also gradually permeate Harry’s
                spirit through the craft itself. Through the technical details of potion making, Snape injects
                a part of his “Half-Blood Prince” evil temperament into Harry. After learning superior skills
                from Snape, even the open-hearted Harry Potter becomes arrogant and conceited.
                <br/><br/>
                As a result of thought, craft is filled with the unique essence of a craftsman’s material practice.
                Craft cannot exist in isolation; it is intimately related to the material world.
                <br/><br/>
                Oppenheimer, who made the atomic bomb, wrote on November 2, 1945,
                <br/><br/>
                <i style={{color: 'grey'}}>
                    “It is good to turn over to mankind at large the greatest possible power to control the world and
                    to deal with it according to its lights and its values.” (Oppenheimer, 1945)
                </i>
                <br/><br/>
                He wanted to open-source the atomic bomb.
                <br/><br/>
                <i style={{color: 'grey'}}>If the experts cannot make sense of their work, what of the public? (Sennett,
                    2008)</i>
                <br/><br/>
                Regardless, the Half-Blood Prince wanted to hand over his potion textbook.
                <br/><br/>
                <b style={{fontSize: "medium"}}>Pandora</b>
                <br/><br/>
                Pandora did not know what she was opening; she was insatiably curious about what was inside the box.
                This curiosity made us harness fire, refine stone artifacts, and create atomic bombs and gas chambers.
                Pandora’s curiosity does not imply kindness either. This uncontrollable curiosity is almost identical to
                the belief held by scientific belief: “Everything that can be experimented should and will be
                experimented.”
                <br/><br/>
                Why did mythological stories choose a beautiful female character to open the chaos-filled Pandora’s box?
                Why do people worldwide unanimously use “Motherland” to refer to their country? Why did the Soviets name
                their cannons and rockets after women's names like Katyusha? Perhaps because everyone exists because of
                women, “she” represents creation and monopolizes the right to nurture life. This power is extraordinary;
                creation and destruction are two sides of the same craftsman ability, just like the metaphor of
                Pandora’s box.
                <br/><br/>
                <i style={{color: 'grey'}}>Man as his own maker. (Sennett, 2008) [2]</i>
                <br/><br/>
                <b style={{fontSize: "medium"}}>Hand and Head</b>
                <br/><br/>
                Any thinking requires a certain degree of physical action to occur. Writing with a pen can stimulate
                more inspiration, a fact familiar to everyone. Even the most spiritual rituals require physical
                participation. Performing the act of kneeling in prayer is a form of thought — it contains physical
                actions, which are the most essential part of the religious ceremony. A devout believer only needs to
                perform the act of kneeling once to feel spiritually moved. The believer’s physical actions trigger
                brain thinking — devotion is a byproduct of “making” worship.
                <br/><br/>
                Making an atomic bomb, creating Nazi gas chambers, making a table leg, making a bowl of fish rice,
                making a piece of cloth, a piece of steel, and a loaded gun, creating a beautiful decorative painting,
                creating an intelligent machine, building a house, creating arable land… The act of shaping the material
                world not only shapes materials but also shapes thought. Just as Snape’s techniques naturally carry the
                soul and thoughts of the Half-Blood Prince, the head that guides how materials should be shaped cannot
                be separated from the hand.
                <br/><br/>
                Making is thinking; you cannot separate the two.
                <br/><br/>
                In shaping materials, the craftsman contemplates, solves, experiments, and executes; the craftsman also
                judges, evaluates, decides, and adjudicates — how should this be done; is all this good?
                <br/><br/>
                Precisely because the craftsman naturally thinks during the process of creating craft, human’s moral
                consciousness already exists in the process of shaping materials. The craftsman’s thought does not occur
                only after the creation of technology. Therefore, the community of craftsmen needs to involve as many
                ordinary people’s labor as possible, not just the gifted technical geniuses. And this involvement should
                happen as early as possible.
                <br/><br/>
                At the same time, as craftsmen, we need to understand the immense power our hands possess. Human bodies
                and labor are part of nature; we cannot understand where technology's creative and destructive power
                comes from if physical labor are separated from conscious thinking. When materials are processed,
                sculpted, and assembled, the craftsman shape the material world with their hands and profoundly change
                their spirit. And every reconstruction of the material inevitably casts the craftsman in the role of
                Pandora. We are always at this crossroads, just like now.
                <br/><br/>
                Shaping the material world is too important to be left to “thinkers” who only sit and discuss — whether
                they are part of the creation of this craft or critics after its creation. The debate and thought on how
                to shape the material world must be “thought” by the “hands” of craftsmen at the moment of making such
                artifacts to minimize the potential for destruction.
                <br/><br/>
                Retreating to a purely spiritual realm to discuss technical issues is pointless. Those destructive
                technological challenges originate from the hands of craftsmen at work, and only through the work of
                craftsmen’s hands can they be resolved.
                <br/><br/>
                <i style={{color: 'grey'}}>Making is Thinking. (Sennett, 2008)</i>
                <br/><br/><br/>
                <b>Note</b>
                <br/>
                [1] An ancient story from Taoism classic &lt;Zhuangzi&gt; 《庄子》. The story depicts a skilled chief
                cutting up
                an ox so masterfully that his knife never meets any resistance.
                (https://chinesenotes.com/zhuangzi/zhuangzi003.html)
                <br/>
                [2] As Senette noted in his book, the word Man here “refers generically to human beings.” (Sennett,
                2008, p6)
                <br/><br/>
                <b>Reference</b>
                <br/>
                1. Richard Sennett, The Craftsman, Yale University Press, 2008.


            </p>
        </div>
    )
}
