import React from "react";
import {Helmet} from "react-helmet";

export default function Instinct() {
    return (
        <div>
            <Helmet>
                <title>被ChatGPT取代还是被毁灭？ Replaced Or Extinguished By ChatGPT?</title>
            </Helmet>
            <h6 className="App-essay-title">被ChatGPT取代还是被毁灭？</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 二月</b>
                <br/><br/>
                看见ChatGPT会取代人这类新闻，应该直接划走，这些没有任何深度的报道鼓吹工具对人的取代，是个天大的误会。这个误会就是认为工具如果比人更高效，人就没有了价值。工业革命和信息革命没有淘汰人，所取代的那部分人力最后让社会劳动生产率大幅上升，人让位给那些高度专业的工具，因为它们效率更高，但人不再是工具就意味着人被技术取代了吗？
                <br/><br/>
                人的特点，就是没有价值，也没有目的，但工具永远有目的，越高效的工具有越狭隘专一的目的。这种高效专一的工具以失去独立性和完整性作为代价，在损失自由的同时，获取了更高的效率，但人不是。
                <br/><br/>
                有人觉得ChatGPT写的文章挺好，甚至可以取代人类写作，那么这些人可能对“写作”有深深的误解。写作不是为了给出一个答案，人类写作是为了给出无数个答案，发现无数个未知，甚至提出无数个问题。从这个层面上，搜索引擎依然将是强大的工具，因为我们人类最不需要的东西，就是一个标准答案。混乱和不确定是人之为人的根本。我们不需要一个确定性的未来，人所做的一切努力，都是为了防止被定义、被安排、被利用，人的一切挣扎都在倾其全力不被工具化，只为能自由地重新定义自己。但ChatGPT想要变得无比强大的目标，反而是为了提供最正确有价值的信息——一旦失去这个目的，ChatGPT将一无是处，因此它只能成为工具；同时它也为了成就这个高度专业化的目的，失去了自己的完整性和独立性。
                <br/><br/>
                这些技术只有纤维组织，没有血管、没有心肺、没有脑袋，高度依赖人类编码和数据输入，因而缺乏完整性；它们只有和人一起出现时才能存活——不仅因为它们依赖人类提供芯片、储存、冷却、供电等物理机械本身，更因为它们高度发达的唯一目的就是为了人，因此它们还尤其缺乏独立性。
                <br/><br/>
                人写代码也不是为了完成编码和调试，这部分能力被人工智能取代是好事，可以大幅提高未来创作代码的效率。人写代码是为了看还有什么新的东西可以创造，而不是为了写一堆bug然后debug；如果工具能更高效地制造更多新东西，那人就会决定哪些东西是好的；如果机器能决定哪些东西是好的，那么人就会拿出自由的杀手锏：我需要再想想，什么是“好”。这些技术是人类的延展，在拥有完整性和独立性的技术出现之前，人不可能被“取代”，因为这些技术没有自由度，人只能被技术消灭，就像是被原子弹毁灭，而不像是被外星人取代。
                <br/><br/>
                而被取代和被毁灭完全是两码事：在核战争中灭绝，和被自己发明的高级智慧替代，能是一回事吗？当然不。人被替代的定义应该是另一种独立完整的智慧（可以是人自己发明的）在同等自由度（或更高自由度）的竞争下，淘汰了人类。如果不是同等自由度的竞赛，就不能使用“替代”，而应该使用“消灭”，就像病毒可以消灭我们，但病毒并不是主动"替代"了我们。
                <br/><br/>
                人终将被别的东西取代，但这种类型的智慧只有在比人类本身有更大的自主权和自由度情况下才能发生，而这样的智慧可能比人类更没有“目的性”，更高的自由度决定了更少的目的性，自由度是比效率更高级的力量。
                <br/><br/>
                所以，你可以观察眼下这些当红技术有任何自由度可言吗？不是说这些技术的现状，而是这些技术发展的轨迹和倾向，是朝着更低的完整性和更低的独立性前进；我们正在创造的，是一类更缺乏自主性和自由度的技术，这些技术都高度专门化，同时也高度依附人。它们像没有头脑但有千钧力量的四肢，一个人打不过一条强壮的后腿，但被这条孤零零的后腿打败，能称作大腿取代人么？我们可以被这些技术毁灭，但不是被这些技术取代。
                <br/><br/>
                技术发展还在让这些工具变得更强壮、更专业、但也更残缺，它们似乎并没有变得更完整、更独立、更自由的趋势。就算我们人类可以把所有高科技工具都组装起来，并让他们学会自我复制，那这些高度发达的逻辑也都是人给的，毫无自由度可言。那么，人是否能创造一个毫无目的性（通用的）但是极其高效的工具呢？——这个假设本身，就是对“工具”的解构。
                <br/><br/>
                人类该如何在没有目的的情况下，制造一个高性能的通用工具，这好比在问：在不给出任何确切用途的情况下，如何制造一款高性能的图灵机？问出这个问题本身就引发了更多的问题。并不是人类的技术能力不够，而是技术越高超，工具就会越狭隘、越不完整、越有寄生性。工具只有在摆脱目的性还能存在的时候，才有资格“替代”人类。但人如何在剥离目的的条件下制造一个“完整”而“独立”的“自由工具”，这本身就是一个天然悖论。我们和ChatGPT的关系，可以趋近于“人机融合”或“被消灭”，而非“智慧竞争”或“被替代”。
                <br/><br/>
                所以仅是一根粗壮神经元的ChatGPT，如何能取代人？这个令人惊叹的大语言模型完全有能力服务人或毁掉人，就像核电站和核武器一样，但它们绝不是在替代人。人们对现阶段人工智能的态度，不应该像是在应对另一个文明，而更应该像是在应对另一个义肢，或另一种武器。

            </p>

            <br/>

            <h6 className="App-essay-title">Replaced Or Extinguished By ChatGPT?</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2023</b>
                <br/><br/>

            </p>
        </div>)
}
