import React from "react";
import VanGogh from "../assets/images/van_gogh.gif";
import { Helmet } from "react-helmet";
import { Paper } from '@mui/material';
// import { useNavigate } from 'react-router-dom'; // 导入useNavigate

export default function Home() {
    // const navigate = useNavigate(); // 使用useNavigate钩子
    //
    // React.useEffect(() => {
    //     navigate('/essay'); // 组件加载完成后直接导航到/essay页面
    // }, [navigate]); // 依赖数组确保只在组件加载时执行一次

    return (
        <div className="App-home-title">
            <Helmet>
                {/*<title>Home</title>*/}
                <title>Home</title>
            </Helmet>
            <h6>Home</h6>
            <br/>
            <Paper>
                <figure>
                    <img src={VanGogh} alt="van gogh" className={"App-home-image"}/>
                    {/*<figcaption>&nbsp;Illustration by Brazilian Artist: Raissa Levati Pelegrim</figcaption>*/}
                    <figcaption>&nbsp;Gif by Artist joojaebum.</figcaption>
                </figure>
            </Paper>
        </div>
    )
}
