import React from "react";
import {Helmet} from "react-helmet";

export default function TechnologyAndImagination() {
    return (
        <div>
            <Helmet>
                <title>少就是多 Less Is More</title>
            </Helmet>
            <h6 className="App-essay-title">少就是多</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 二月</b>
                <br/><br/>
                生活最需要的东西到底有多少？一根笔？一张纸？一碗饭？一句关心的问候？一场酣眠？一台电脑，而不是五台？一个好朋友，而不是二十个？一台收音机，而不是环绕低音大喇叭？一部电话，而不是一部智能手机？一杯茶，而不是一瓶烈酒？
                <br/><br/>
                哪些东西是我们真正需要的，这个问题我不能回答，你只能自己回答，因为每个人对不同事物在生命里的排序完全不同。
                <br/><br/>
                一个人如果能不断缩减自己生命中需要的物质和精神，直到不能再减少，那么这个人就事实上在排序自己生存意义的优先级。这个训练很有益，历史上有道高僧在参禅时干的，应该就是这件事。
                <br/><br/>
                我不是有道高僧。
                <br/><br/>
                但是我发现如果把多余的电脑收起来，我会用最后一台有限且珍贵的计算机做一些有意义的事，而不是不停地乱翻一些战争、政治和科技有关的虚伪新闻；如果给一个项目十万元，而不是一个亿，这个项目大概率会有旺盛的创造力；如果一个团队只能有三个人，而不是一百个，那这三个人的效率常常比一百个人还高。
                <br/><br/>
                当我们拥有如此之多时，过载、疲惫、悲伤、烦躁、不安都会找上门，然后我们会用如此之多，去完成如此之少。而当我们拥有的越少，精力就会越专注，当我们越专注某件事，这件事回报给我们的反而越多，最后我们会用如此之少，收获如此之多。
                <br/><br/>
                今天我们最大的敌人不是匮乏，是过剩。这些过剩是如此过量，以至于人们甚至需要额外付钱，来去除那些一开始就被额外造出来的东西。这些东西被我们称作垃圾，而它们，实际上只是被错误获取的资源——它们从一开始就压根不该被我们拥有。
                <br/><br/>
                为了区别我和垃圾生产器的差异，这篇文章写到这儿就结束了。把不属于自己的东西统统扔掉，少就是多。

            </p>

            <br/>

            <h6 className="App-essay-title">Less Is More</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2023</b>
                <br/><br/>

            </p>
        </div>)
}
