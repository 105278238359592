import React from "react";
import {Helmet} from "react-helmet";

export default function Idol() {
    return (
        <div>
            <Helmet>
                <title>对偶像的爱与恨 Love And Hatred Towards Idols</title>
            </Helmet>
            <h6 className="App-essay-title">对偶像的爱与恨</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 七月</b>
                <br/><br/>
                除了崇拜自然神的拜物者——比如中国人，西方人把自己的偶像捏成了一个花白胡子的老年白人男性。在中国人眼里，天神、土地、菩萨、佛祖、玉皇大帝……我们在拜物的历史里不断扩充着自己的万神庙，这些神是各式各样的中国人自己，时而灵验，时而不灵，所以遇到了，都可以拜一拜。
                <br/><br/>
                但无论是谁，归根结底，所有人都在按照自己的样子为自己捏造偶像。所有人都赞美偶像，不允许诋毁。
                <br/><br/>
                一神论的上帝形象，在尼采时代就遭遇了蔑视和挑战；而中国人对所有偶像都可以拜一拜，也包含了轻视，谁有用就拜谁。
                <br/><br/>
                对偶像的爱中包含了天然的恨。
                <br/><br/>
                一个知识渊博的人经常受到知识诅咒(curse of knowledge)。这种障碍是指，见多识广的人往往假设别人的知识深度和理解力和ta们自己一样，于是说话行事的方式中，经常假设别人不需要任何解释和说明就能明白ta们的意思。
                <br/><br/>
                与之类似的情形，还有“道德诅咒”——一个道德高尚的人假定所有人都道德高尚，进而用极度苛刻的道德标准要求所有人，直到所有人在ta眼里都是不够道德的——缺乏同情心、没有正义感、无法共情、不够坚定、屈从于欲望……等等。
                <br/><br/>
                道德诅咒和知识诅咒的表现类似，先通过苛责自己，进而苛责别人。ta们都先按照偶像的方式塑造自己，偶像的纯洁无瑕——即使只是幻觉——也左右了真实世界的人如何行动。
                <br/><br/>
                我们喜爱偶像，也想让别人像爱偶像一样爱自己。我们喜欢赞美，厌恶批评，我们只想作为接受爱戴而不是被攻击的对象。为了投射对自我的喜爱和崇拜，我们在旷野中竖起巨大的石像，把圣贤的侧脸刻在石头和硬币上或纹在胸前，然后不断地讲述英雄的故事，并在有挂钩的地方悬挂特定符号的旗帜。
                <br/><br/>
                但让我们检验这样一个事实：偶像被反对，甚至被诋毁是不可避免的事。苏格拉底和柏拉图会被辛辣地讽刺，孔孟、老子和佛陀，这些圣贤也都不是道德无缺之人。所以，一个人形象越完美无瑕，一个现实而理智的人就理应越觉得不对劲。
                <br/><br/>
                有多少赞美，就会有多少污蔑；有多少潜在的拥护，就有多少潜在的反对。塌房的爱豆们不仅因为ta们确实不自爱，还因为人们对偶像天然的爱中包含着天然的恨。一个力会产生另一个大小相等、方向相反的力。如果一个偶像只受到爱戴而没有被攻击，那么只有一种可能，这个偶像还被崇拜得不够。等众人的爱慢慢积累，过不了多久，赞美的反作用力便会透过各种机关渗透进来。
                <br/><br/>
                类似的，远非圣贤的凡人们在大善举后往往有大恶行，因为那个终将湮灭的凡人在强迫自己遵从道德戒律后，心中常常隐匿着深切的不满，恶行会释放被迫遵守道德的愤懑。所以作为凡人，我们大可以放松对自己过度苛责的程度，适度的自我放松，反而会增进道德水平。
                <br/><br/>
                人的龌龊丑陋的和人的高贵优雅一样永恒。
                <br/><br/>
                承认人的道德缺陷，会获得实用的快乐，更重要的是可以避免陷入“道德诅咒”；此外，接受道德缺陷还可以用来防止那个糟糕的反作用力伤害自己；而最重要的，是可以拥有一个更宽容的眼光审视那个永远不可能到达的偶像：一个注定是镜中花水中月的倒影，一个圆满无缺的偶像，偶-像，我的-形象，Idol，I-Doll，一个自我的倒影。

            </p>
            <br/>
            <h6 className="App-essay-title">Love And Hatred Towards Idols</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jul 2023</b>
                <br/><br/>
            </p>
        </div>)
}
