import React from "react";
import {Helmet} from "react-helmet";

export default function GhostInTheMachine() {
    return (
        <div>
            <Helmet>
                <title>机器中的幽灵 Ghost In The Machine</title>
            </Helmet>
            <h6 className="App-essay-title">机器中的幽灵</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 七月</b>
                <br/><br/>
                <b>I, Robot</b>
                <br/><br/>
                当威尔·史密斯在电影《我，机器人》里半夜潜入离奇死亡的老科学家家中，在电脑里发现了一个演讲视频，是老科学家关于机械智能做的一段引人入胜的描绘：
                <br/><br/>
                <i style={{ color: 'grey' }}>“自从第一台计算机出现开始，机器中便存在着一个不散的幽灵，数据片段随机地组合在一起，出乎意料的组合成就了新的规则，即所谓的行为。未曾料到的是，这些自由因子，让人们开始怀疑电脑中是否存在自由意志——即创造力，或是我们称之为灵魂的东西。”</i>
                <br/><br/>
                作为一部科幻电影，不故弄玄虚、卖弄感情，还精妙地构思了各种细节拷问机器人三定律、人机复合体、机械智能等命题，已是一部商业科幻的标杆。但对这段论述中“随机组合”、“出乎意料”、“行为意志”等种种形容，你应该颇为怀疑。即使是最现代的计算机，也并没有在“随机出现”或“自由意志”这件事上，做出任何超出人类意想不到的预判，仅仅是随机程度不同罢了。也就是说，机器所有的“随机”正是人们故意想让它产生的那种“随机”，完全出乎意料的“自由意志”仍旧是那个游荡在机器中的幽灵。
                <br/><br/>
                <b>体力与计算</b>
                <br/><br/>
                有人说人和动物最大的区别是人有道德，会逆自然规则行事，以此抗衡熵增。这类空泛理论听着很有道理，但并无太大价值。不如相信人和猿猴的区别是因为工具，而让这个差距不断加大的原因是人可以发明、制造并使用复杂的工具。和工具交互是人类进化最直接的推动力。
                <br/><br/>
                事实上，工具之间区别很大，有些工具可以帮人省力，是纯粹体力的延伸，比如：一根趁手的长棍可以让一群早期智人相较于另一群没有长棍的猿猴，拥有体力上的比较优势。同理，犁的发明让人驯化了畜力，大大节省了农耕播种的体力消耗，在犁出现后的时间里农作物的产量大幅上涨，这种优势还是复利叠加的，长期下来非常可观。
                <br/><br/>
                另一种工具和体力机械完全不同，虽然这些工具不会帮人节省体力，但可以帮人节约脑力（虽然事实上可能间接节约更大数量级的体力）。姑且把这些不直接节省体力，但节约脑力的工具统称为脑力机械。体力机械和脑力机械的差别，就像车轮和算盘的区别。这些脑力机械中有一类很特殊，是以计数和运算为目的产生的，姑且称之为计算工具。
                <br/><br/>
                计算工具的发明并不一定比体力工具晚多少。占卜的龟壳竹签、泥版上记录日期的笔划、计算牲口数量的绳结、十进制、乘法口诀、算盘……计算工具的发明是为了解决一类明显区别于以省力为目的问题，这类问题广泛又特别，就叫它们为可计算问题。“你昨天吃了什么”不是一个计算问题，但“我需要挣多少钱才能退休”就是一个可计算问题。我们长期和这类问题共存，但往往并不直接意识到自己面对着一个可计算问题、正在通过什么计算工具去解决它。
                <br/><br/>
                第二次世界大战中，英国数学家图灵Turing通过一堆电路加上轮盘转子破译了德军的加密机器Enigma。图灵在战争中面对的挑战非常确切——如何在极其有限的时间内对一个可计算问题进行求解，帮助盟军在只有人力计算的情况下对抗德军使用机器进行的加密计算。图灵的解法大家都知道了，他创造了一台计算装置来大幅提高盟军的计算效率。其实图灵没预料那么多，但这堆用来破译德军密码的电路和转子组成的计算装置间接成就了今天近乎无所不能的计算机。
                <br/><br/>
                图灵把他脑海中对计算装置的定义归纳为图灵机。这个定义的哲学意味甚是浓厚，不过科学上依然相当严谨。一个图灵完备的图灵机——能解决任何可计算问题的计算工具——拥有这么几个基本要素：一条无限长的纸带（输入要被计算的数据）、读写头（进行计算操作的媒介）、运算法则、和状态储存器（输出计算的结果）。
                <br/><br/>
                根据这个定义，算盘就是一个图灵机，手打算盘即纸带输入，手指拨弄算珠是读写头，计算法则是算盘口诀，任何一刻算珠的位置状态都是当前计算的结果输出。不要小看算盘，在利用CPU芯片和电作为计算逻辑和动力驱动之前的历史里，算盘是一种非常高效的计算机械，东方红卫星也是算盘打上天的。
                <br/><br/>
                再看看今天的现代计算机所成就的缤纷世界，现实中的很多问题和破译法西斯密码一样，都是可计算问题。如何通过杂乱的电磁波传递语音文字和如何破解加密的Enigma密文很相似，如何设计专业软件和如何定义高级的图灵机运算指令集，如何计算特定条件下快递的最短距离和如何让导弹最快命中目标。这些都是可计算问题，只是复杂度和重要性不同。对这些现实问题进行一遍又一遍地求解创造了信息革命，然后也带来了那个机器中的幽灵问题：当图灵机可以像人一样解决可计算问题的时候，这个计算装置是否学会了人的独特性，开始拥有一个自由的灵魂？
                <br/><br/>
                <b>量变的积累</b>
                <br/><br/>
                忽略摩尔定律催生的计算力爆发式增长去谈机器中的幽灵，就像讨论真空中的球形鸡一样，模型过度简化让讨论毫无意义。八十年前，图灵创造的那个用电流驱动物理转盘运行的庞大计算装置，还需要一些固定信息比如“天气情况”、“希特勒万岁”之类的固定词汇，来大幅减少解码组合的可能数量，但也需要等待数小时（后期变快到几十分钟）才能拿到解密的结果。今天的超级计算机通过暴力破解甚至可以在几秒钟内轻易穷举Enigma的所有组合方式。这种量变带来的绝不仅仅是算得更快了这样一个简单的事实。
                <br/><br/>
                直立行走使双手解放，双手解放促成对火的控制，火烹饪食物使大脑发育和寿命延长，更健康的人类发明更复杂的工具，复杂工具又反过来促进大脑发育和人类进化。这串循环的每一个飞跃都是微小的量变不断积累引发质变的过程。体力机械对人体的塑造并不亚于计算机械对人脑的塑造，但近半个多世纪计算机的大爆炸是让我们在自然寿命内，就见识了量变引发质变的直观效果。
                <br/><br/>
                人在大脑发育到一定程度的时候会推动社会和文明飞跃。类似地，当计算机可以算得更快，就开始拥有在暴力穷举无数“愚蠢”的计算结果后，挑选那个更“聪明”答案的资格。谁能说人类的智慧不可以是一种极为高效地在有限时间内做出最优解的能力。谁又能说智慧不可以是计算效率在极大提升之后，有余力不断通过筛选最优解带来的“聪明”不断叠加的结果？
                <br/><br/>
                量变引发质变对于自视特殊的我们，当然没问题，我们的量变总是带来质变，毕竟我们本身就有智慧。但对于这些人造工具来说，情况有些微妙的不同。一个简单的问题：今天的超级计算机加上智能算法，能以超越人类计算能力亿万倍的水平破解Enigma密文，但是这台破译了Enigma的超级计算机是否能真正学会德语？
                <br/><br/>
                <b>中文房间问题</b>
                <br/><br/>
                1980年，美国哲学家John Searle提出了一个关于机械智能的讨论，是对图灵测试的延伸，即中文房间问题。这个场景假设有一个房间，里面有个只会说英文的工作人员拿着一本用英文写成的说明手册，指示如何处理中文信息并回复，房间一头可以输入外面用户递进来的中文信息，房间的另一端输出处理后的中文结果。站在外面的人看到递进房间的中文字条吐出了合理的中文回复，会认为房间里的人对中文颇有“智慧”。而事实上呢？房间里的工作人员根本不懂中文。这个中文房间和破译Enigma的智能机器有种相似的味道，他们都可以“计算”特定的内容并给人“智慧”的感觉，但二者都可以对其处理的内容不拥有任何认知。
                <br/><br/>
                为什么叫中文房间问题，可能对于美国人而言中文就像天书，他们看中文和计算机看中文是一个感觉，这就让人类和机械站在了同一起跑线上。然后区别发生了，作为人类的美国人可以认真学习并理解中文这门语言，但那个拿着英文字典的计算机真能“学会”中文吗？
                <br/><br/>
                要探究这个问题，首先要定义“学会”，不可避免地还要牵扯诸如：学习、智慧、意识、思维、意志等一系列问题。沿着这条路径思考下去，这篇文章不会有结尾。不如换个思路简化下讨论范围，我们要拷问的对象是机器是否可以有“学习、认知、智慧、意识、思维、意志”等等特征。这些特征有个共同特点，它们有大量的特质是相对于物质实体而言的，这些特质的总和是一整套站在物质之上，驱动物质、引导物质的自由意志——那个幽灵，或者灵魂。
                <br/><br/>
                <b>心身二分法</b>
                <br/><br/>
                由大量的水、蛋白质和少量矿物组成的人体能够产生思维意识，那么由硅片、金属和电路组成的计算机，是否也可以长出灵魂？笛卡尔不仅发明了坐标系，还提出了心灵-身体的二元论。这种二分法直观优美，但极致的二分在现实中总是过于纯粹和极端，毕竟现实太不完美。人的肉体拥有的低级植物神经反射黏连了一丝飘忽的意识，而思维意志需要的大脑皮层作为载体又牵扯了一些浑浊的物质。所有的肉体都不够完全真实确切，就像所有的意识也都不够纯粹抽象一般。
                <br/><br/>
                回到机器，现代计算机的软件-硬件二分和人体的心身二分法惊人得相似，肉体身躯如硬件，灵魂思维如软件，但也同样在二分模型上相似地不完美。软件程序运行的效率受制于处理器的速度、指令集、内存容量、硬盘读取速度、供电量大小等等真实世界中不可撼动的物理现实，而一堆缺失了软件运行逻辑的集成电路板也无法表现出任何智能。这两者千丝万缕的关系让干净利落地分割心身的界限变得不可能。
                <br/><br/>
                让我们再重温那个中文房间问题，John Searle最大的抱怨是这个中文房间的智能名不副实，对中文虽一无所知但依然可以表现出冒充的“智慧”。他说的没错，一个只能进行“计算”的图灵机无论再怎么快速的运算和处理信息，我们都没法欺骗自己在那堆半金属外壳里能看到了任何思维意志的痕迹，更别说一颗自由的灵魂了。心身二分法可以作为精简的哲学模型研究抽象观点，但现实中过分强调肉体和灵魂的二元对立又一次留给我们一个真空中的球形鸡问题，对我们寻找机器中的灵魂毫无帮助。如果强调机器思考的“纯度”必须是接近人类的，而拒绝仔细观察从意识到肉体之间千丝万缕的过度层次，那么讨论就必然会走入死胡同。
                <br/><br/>
                这时候，我就可以顺利引出一段关于心身问题叹为观止的精彩论述，英籍匈牙利作家Arthur Koestler在The Ghost in the Machine这本书里写道：
                <br/><br/>
                <i style={{ color: 'grey' }}>“让我们再次拷问是否可以通过一个开放性的层级概念进一步探究这个古老问题（心身二元对立）。首先，第一步而且是关键性的一步，就要从只有两个层级的心灵-物质二分法中解放出来，然后开始用一种多层级的方式进行思考。物质不仅仅是一个单维度的概念；微观系统中，一层又一层的分子、原子、亚原子的层级，蜿蜒前行而不会触底，直到物质溶解成一小撮能量的聚集、甚至可能是空间的张力。而在相反的方向上，我们会看到同样的状况：那里有一个不断上升的层级系统，从半自动和自动开始，通过了意识和自我意识，直到意识到自我有自我意识，然后不停嵌套，而不会触到天花板。”</i>
                <br/><br/>
                当心身二分在现实世界中并不好用时，取而代之的是一个“洋葱模型”——在最纯粹复杂的灵魂意志和最基本坚实的物质粒子之间，存在了一层又一层的过渡状态，这些过渡状态组成了真实世界里灵魂与机器的复合体所有的表达方式。
                <br/><br/>
                比如今天的人工智能，组成于一连串的计算机加上一群程序员随时严阵以待的逻辑编码。更重要的，别忘了还有那个真正做决策的人！他是这层洋葱皮里，最像幽灵的元素，他贡献了这个人工智能复合体中最纯粹的思维意志，在这个层级上，他就是机器中的幽灵。计算机贡献的与其说是智慧，不如说更像体力，那些算力超群的服务器仅是人类进行脑力运动的肌肉组织，是人类计算能力的延伸。在这个过程里，机器幽灵的重要组成部分是人类自己。当机器无法作出判断时，我们会充当那片洋葱的思维皮层；当机器需要金属、半导体和电流时，我们又是这个洋葱的肌肉物质——生产、运输、组装这个复杂的人机复合体。当我们在训练、筛选、改进这个复合装置时，我们在和机械一同演化，把这团人机复合体从洋葱的一层推向另一层，永不休止。
                <br/><br/>
                那些藏在快速写入旋即擦除的内存和高速运转的GPU里的那一缕不确定性的组合、反复出现的模式，隐约有了草履虫般应激反应的意志。机器中这些缥缈的“类意识”里有多大成分拥有自我意识，又有多大成分拥有自由意志，又有多大成分能意识到自己拥有了意识和意志。这一连串的中间态是人类和机器形成的复合体在不断构建的洋葱皮。在一次又一次的包裹、封装、压缩、整合中，这个洋葱系统的意识外皮和物质内核变得迥然不同，直到无限接近心身二分法的差距。
                <br/><br/>
                <b>父母与孩子</b>
                <br/><br/>
                猿猴和其他动物没有造出计算机，不可否认是我们创造了可能会有灵魂的机器，没有人类的帮助就没有机器中的幽灵。所以人和机器就这样产生了天然的不平等，并非地位高低，而是因果先后。当我们一遍遍探索心身二分法中间的那颗洋葱时，开头电影里老科学家的话会不断逼近现实——数据片段随机地组合在一起，出乎意料的组合成就了新的规则，即所谓的行为。这些自由因子，让人们开始怀疑电脑中是否存在自由意志——即创造力，或是我们称之为灵魂的东西。而这颗自由的机械灵魂起源是人，那这是否可以作为我们变身为造物主，如苍穹般浩瀚的证明？
                <br/><br/>
                母猫生了一只小猫，没有母猫就没有这只小猫，小猫继承了母猫的染色体和养分，出生后被喂奶哺育、梳理皮毛、教导捕猎。那么母猫创造了小猫吗？父母生孩子，孩子借助父母亲的遗传物质，鬼斧神工地在母亲的子宫里被拼装制造然后来到世间。没有父母的碱基、身体、呵护和养育，孩子也无法发育成一个拥有自我意志的生命体。那么孩子是被父母发明创造出来的吗？孩子无法避免与父母有千丝万缕的联系，这种联系深深地印在分子层面，但这趟旅程的终点是孩子经由父母的身体开始独立存在，大家相互交织但最终又必须分开。父母利用碱基排列的规则就像人类利用力学定律一样，区别仅在无意识和有意识。与其说父母生孩子是创造孩子，不如说是父母通过一系列的自然规则重新发现了孩子。
                <br/><br/>
                人类不断发现了各种新东西，但从未在根本上发明过任何东西。我们是大自然最优秀的搬运工和泥瓦匠，“意外”又富有创造性地把现实拼装在一起。当人机复合体的洋葱最后被封装成一个匹敌我们自己复杂度且能意识到自我复杂性的独立机械时，这个分娩过程就完成了。
                <br/><br/>
                孩子是父母的延伸，机械是人类的延伸，孩子是否有能力伤害父母，这个笑话般的问题去问问所有当过父母的人应该都能回答。但不能因此就说：果站在了因的对立面。我想引用黎巴嫩诗人纪伯伦的诗作结尾，没人比他说的更好:
                <br/><br/>
                <div style={{ color: 'grey' }}>
                    孩子（纪伯伦，林志豪译）<br/><br/>
                    孩子其实并不是你们的孩子，<br/>
                    他们是生命对自身渴求的儿女。<br/>
                    他们借你们而生，却并非从你们而来。<br/>
                    尽管他们与你们同在，却并不属于你们。<br/>
                    你们可以把爱给他们，却不能给予思想，<br/>
                    因为他们有自己的思想。<br/>
                    你们可以庇护他们的身体, 但不是他们的灵魂。<br/>
                    因为他们的灵魂栖息于明日之屋，<br/>
                    那是你们在梦中也无法造访的地方。<br/>
                    你可以努力地造就他们，但是，不可企图让他们像你。<br/>
                    因为生命无法倒流， 也不会驻足昨日。<br/>
                    你们是弓, 而你们的孩子就像从弦上射出去的生命之箭。<br/>
                    那射者瞄准无限之旅上的目标，<br/>
                    用力将你弯曲——拉满弓，以使手中的箭射得又快又远。<br/>
                    应为射箭者所造就的一切而欣喜；<br/>
                    他既爱飞驰的箭，也爱手中握着的、稳健的弓。<br/>
                    <br/>
                </div>
                {/*<i>(弓已经拉满，箭还在弦上)</i>*/}
            </p>

            <br/>

            <h6 className="App-essay-title">Ghost In The Machine</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jul 2022</b>
                <br/><br/>

            </p>
        </div>)
}
