import React from "react";
import {Helmet} from "react-helmet";

export default function Existentialism() {
    return (
        <div>
            <Helmet>
                <title>存在主义与虚无的多重宇宙：42、钝角和贝果 * Existentialism and the Multiverse of Nothingness: 42, Obtuse Angles, and Everything Bagel *</title>
            </Helmet>
            <h6 className="App-essay-title">存在主义与虚无的多重宇宙：42、钝角和贝果 *</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 三月 | 作者：zzh</b>
                <br/><br/>
                <b>The meaning of life, the universe, and everything</b>
                <br/><br/>
                在《银河系漫游指南》中，42是生命、宇宙和一切的终极答案。
                <br/><br/>
                主人公经历了银河系九九八十一难后，终于来到了一台史前超级电脑“深思Deep Thought”之前（这台电脑不由得让我们想起近期AIGC的发展）。为了探寻宇宙的终极真理，高智慧物种的老鼠制造了一台超级计算机。经历了750万年的运算后，这台机器终于给出了最终的结果:42。
                <br/><br/>
                这个回答令老鼠们难以接受，也令读者怅然若失。于是好事者和geek们尝试过种种方法来合理化这个结果，寻找隐藏在表象后的意义。42是普洛尼克数，是最小的魔方幻方常数，也是超级多重完全数。古腾堡圣经每页都有42行 。古埃及亡者需要接受42位判官的审判。中国古代也有42章经，据传是历史上第一部译出的佛经，也可能是藏有清朝龙脉位置的藏宝图。
                <br/><br/>
                然而，作者已经多次澄清，这个数字没有任何意义。老头子某一天“坐在桌子前，看着花园突然想到，要不就用42吧，然后用打字机敲了出来”。42就是42，end of story，只是我们不愿意相信这就是事情的全部。我们从小到大不断被训练着寻找事物之间的联系，探索现象背后的原理。明确的逻辑和关联让我们能够理解发生的事情，这让我们感到安全，也让我们在丧失意义后手足无措。然而正如超级计算机给出的结论，天地不仁，万物皆为42，然后老鼠们都吓坏了。
                <br/><br/>
                <b>宇宙的尽头是钝角</b>
                <br/><br/>
                任何深耕B站的用户都可能遇到过“钝角”的弹幕，尤其是当视频中出现问题和选项时。有时出题者会在把“钝角”放在选项中；有时即使选项中没有，弹幕也会自顾自地选择钝角。
                <br/><br/>
                “钝角”的梗出自B站著名抽象区up主“艺能人金广发”。在《你又能做对几道题》的视频中，金广发向观众提问，并且给出了三个选项：A、不是；B、有可能不是；C、钝角。钝角这个选项是如此的离经叛道和出其不意，以至于它逐渐演变成一个meme，成为网友的狂欢，消解一切提问和回答的意义。如同42一样，钝角没有任何逻辑可言，可以出现在任何场景中。“钝角”可以是锐角，也可以是鸡你太美。它可以是任何东西，也可以什么都不是。
                <br/><br/>
                钝角之流的互联网meme诞生于加缪所说的荒诞和虚无中。我们可能处在历史上最虚无的时代。曾经能将人们凝聚成共同体的宏大叙事都已凋敝（比如宗教、民族和乌托邦），甚至连最基本的道德准则也岌岌可危。与此同时，无处不在的屏幕让注意力无限分散，推荐算法让我们陷入愈发狭窄的茧房，社会越来越原子化。在这样一个有着无限的、乃至过剩的事情可做的时代，人们却前所未有地感到虚无和emo，选择躺平和佛系。以钝角为代表的互联网抽象文化是虚无的狂欢，也是对资本与权力的无奈抵抗。如果宇宙的真相是42，那我们能做的也就只有钝角了。
                <br/><br/>
                <b>贝果</b>
                <br/><br/>
                近期横扫奥斯卡的《瞬息全宇宙》以一个贝果，重新演绎了从20世纪延续至今的存在主义母题：世界的虚无。不同于笛卡尔的我思故我在，存在主义认为人是先有存在，才有本质，我在故我思。作为“存在”的人，面对的最大敌人是“虚无”，而如何克服虚无就是存在主义渴望回答的问题，也是《瞬息全宇宙》的哲学内核。
                <br/><br/>
                电影里，黑化后的女儿拥有了穿越所有平行宇宙的能力，也即完全的自由。然而这并没有让女儿获得解脱，反而让她感到无尽的痛苦。正如萨特所说，Man is condemned to be free，人因自由而变得有意义，也因自由而变得不自由。在经历了多元宇宙的一切可能性之后，女儿感受到了存在的荒谬和偶然，并且将这种虚无感制作成了一个充满黑色幽默的贝果。
                <br/><br/>
                <i style={{ color: 'grey' }}>“I got bored one day, then I put everything in a bagel... everything. All my hopes and dreams. My old report cards, every breed of dog, every last personal ad on Craigslist, sesame, poppy seed, salt, and it collapsed in on itself, because you see, when you really put everything on a bagel, it becomes this...The truth. Nothing matters.”</i>
                <br/><br/>
                多元宇宙的虚无也是《Rick and Morty》中反复讨论的主题。印象很深的一个段落中，Morty在劝说姐姐Summer不要离家出走。他指着院子里的两个土包，坦白了他们是来自平行宇宙，而这个世界中的爷孙已经死去，被他们亲手埋在院子里。面对Summer鸡毛蒜皮的小烦恼，Morty平静地告诉她：
                <br/><br/>
                <i style={{ color: 'grey' }}>“Nobody exists on purpose. Nobody belongs anywhere. Everybody's gonna die. Come watch TV.”</i>
                <br/><br/>
                无论是将万物浓缩在一个无厘头的贝果之上，还是将自己麻痹在电视或者酒精中，Morty和Jobu都选择了对虚无的接受和妥协。而Eveyln和她的googly eyes，让我们看到了对抗虚无的一种可能。
                <br/><br/>
                Evelyn在遍历了多元宇宙之后，也同样感受到了生命的荒诞。就在她几乎要和Jobu一起走进贝果共同毁灭时，Evelyn还是找到了意义。这个意义可以有很多层面的解读，是纯粹的形而上感召，是穿越时空和维度的母爱，是对不完美的和解与接受，也是从Waymnd身上看到的执着和勇气。我们又回到了电影最开始的宇宙，也是最糟糕的宇宙。Evelyn还是在嫌弃女儿，但是她已经可以理解女儿，感同身受的理解。她们一起看到了时间的尽头，知晓了未来的一切，最终选择接受它，接受自己，并继续前行，体验一切的美好、幸福、失败、烦恼、痛苦，直到自我与自我和解。
                <br/><br/>
                对我来说，最精彩的片段莫过于母女二人变成石头。影片从光怪陆离的快速剪辑中突然安静了下来，场景第一次变得开阔和崇高，沉默的对话充满空性和禅机。母女两块石头就这样躺着，仿佛从时间的开始就一直存在于此。变成石头后，人丧失了一切选择和能动性，本应是最不自由的，但也在超越了选择之后，方能得道解脱。我们为何存在，自由又有什么意义，这些问题都已不再重要，与其徒增烦恼，不如安静地做一块石头。

            </p>

            <br/>

            <h6 className="App-essay-title">Existentialism and the Multiverse of Nothingness: 42, Obtuse Angles, and Everything Bagel *</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2023 | Author: zzh</b>
                <br/><br/>

            </p>
        </div>)
}
