import React from "react";
import {Helmet} from "react-helmet";

export default function AGeneration() {
    return (
        <div>
            <Helmet>
                <title>一代人 A Generation</title>
            </Helmet>
            <h6 className="App-essay-title">一代人</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 一月</b>
                <br/><br/>
                <b>疯狂和理想</b>
                <br/><br/>
                在中国，1962-1972这十年出生的一代人，是中国最幸福的一代人，他们躲过自然灾害、有兄弟姐妹、房子工作有组织安排、爱情纯粹值得追求。除此之外，这代人坐火箭般感受了丛林殖民主义的尾声，然后火力全开地从农业社会冲进工业社会，接着腾云驾雾地迈进信息时代。人类三百年的机械革命史对这代人而言，是映衬他们青春盛宴的一块时代幕布，被极致压缩在了四十年里，这块背景板高度浓缩了铁与汗的快乐。这代人的下一代，正在面临着发动机熄火的余烬，一再被现实告知：胜地不常，盛筵难再，The party is over.
                <br/><br/>
                上一代人的爱情也明显更有意思，虽然老式的婚姻有老式的战争，但没有两个人的爱情是相似的。自由恋爱、登报上电视征婚、朋友介绍、恋爱中还被邀请继续相亲、心猿意马又反追到手……你总能在故事里找到最离谱的想象。今天这代人的爱情就相当无聊了，无聊到风马牛不相及的经历故事都可以被随手冠以“渣男”或“渣女”的帽子。真实有趣的事物是高风险的，所以真实有趣必须接受讽刺甚至明令禁止，导致今天这代人的“自我审查”比外部环境还高效。
                <br/><br/>
                无论这个观察是否客观，但有一个或许为真的事实需要被探究，就是：这代年轻人很少真正体会过活着的滋味。这代人没有战争、没有动乱、没有追求理想的激情契机，也没有探索生存可能的勇气资本，蹦蹦迪喝喝酒创个业就算活着了。这代人没有和自然、社会与命运对抗的机会，也不清楚阳光、空气、食物和水才是活着的根本所需。这代人对疯狂不甚了解，也很可能对理想一无所知。或许上一代人透支了理想，只留给这代人一个安全又无趣的现实；也或许，上一代人的自由只是昙花一现的稀缺品，包括爱情在内的这个时代的很多东西，一点儿也没有意思。
                <br/><br/>
                <b>成熟和幸福</b>
                <br/><br/>
                有次和朋友聊天，说起如果现在是1939年，我是一个18岁的德国青年男子，作为那一代人，他的命运注定要高度动荡，命运要行之事他将毫无还手之力。如果命运可以如此反复无常，那么命运也可以让一代人比另一代人明显地幼稚或显著地成熟，这种成熟或幼稚无法被明确量化，但可以明显地感受和比较。一个悲惨时代往往能缔造更早熟的一代人。
                <br/><br/>
                关于成熟与幼稚的分野绝不是人情世故的练达，在年轻人脑海中，上一代的某些“成熟”是一种虚伪的世故。但这篇文章里的成熟是指，对生命存在的各种可能状态的探索：放肆自我是其中一种，世故虚伪也是其中一种，这些存在方式没有高下之分。对生活姿态的各种可能性的宽容程度，决定了成熟与否。
                <br/><br/>
                因为每代人的差别和风与牛马的差异一样大，于是每代人的后代，不可避免地要作为牛马接受“风”的建议，而这些建议，也很可能变成“风马牛不相及”的建议。“中国最幸福的”上一代人，经常建议下一代要幸福快乐，也希望后代能让自己也更快乐。但幸福感的投入和回报有极为明显的边际效应递减，年轻时一分钱能买得到一份快乐，现在一百万也买不到万分之一的快乐。百尺竿头更进一步是极其艰辛的。
                <br/><br/>
                虽然有着令人瞠目结舌的不同，但每代人都要生老病死，一代人无论通过什么方式生存，都必须接受自己这代人终将长眠的自然规律。在处理生死问题上，一代人和另一代没有太大区别。
                <br/><br/>
                <b>上一代和下一代</b>
                <br/><br/>
                腊月二十七，和家人吃过晚饭，手机上跳出一条《华尔街日报》的今日要闻：
                <br/><br/>
                <i style={{ color: 'grey' }}>Sicilian Mafia’s Bloodiest Era Draws to a Close With Arrest of Old-School Boss.</i>
                <br/>
                <i style={{ color: 'grey' }}>（随着老派头目被捕，西西里黑手党最血腥的时代落幕了。）</i>
                <br/><br/>
                每代人都诉说着下一代人比自己幸福且永远不够成熟；每代人都在不断讽刺着上一代人的污秽浑浊，但又在人生旅程中发现，执拗又疯狂的上一代并非全无道理，而自己和他们也并非全然不同。

            </p>

            <br/>

            <h6 className="App-essay-title">A Generation</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2023</b>
                <br/><br/>

            </p>
        </div>)
}
