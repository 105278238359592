import React from "react";
import {Helmet} from "react-helmet";

export default function TwoKindsOfPeople() {
    return (
        <div>
            <Helmet>
                <title>两类人 Two Kinds Of People</title>
            </Helmet>
            <h6 className="App-essay-title">两类人</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 二月</b>
                <br/><br/>
                有两种完全不同类型的人。第一类被好奇心驱使，这些人以创造和探索为首要目的，好奇是前进的动力，对他们而言，这个世界没有禁忌。第二类人被饥饿的生存法则驱使，以活着为首要目的，存在的唯一分界线是生存或毁灭，财富、权利、影响力是这类人追求的目标，对他们而言，这个世界充满了不容违背的生存法则。
                <br/><br/>
                这两类人是光谱的两个极端，可能因为世界同时存在这两类人，他们的共同存在，贡献了文明的进化和存续。第一类人Stay Naive，保持天真，不负责任，在荒唐的方向上尝试一切；第二类人Stay Hungry，保持饥饿，他们想对存亡负全责，在死亡的列车上对抗一切。这可能是另一种方式来理解乔布斯的半句名言。
                <br/><br/>
                当其中某种成分明显胜出时，就产生了天才或强者。强者在生存上有明显优势，但不具备创造力；天才在创造上独树一帜，但并不明显地适合生存。为了活下来，天才需要了解棍棒法则；但想活得更好，强者需要保持天真。
                <br/><br/>
                但我并不认为一个人可以随意混合二者的优势，但绝大多数人确实是这两种类型的人混合而成的。等量混合这两种类型的禀赋不会得到一个强壮的天才，反而更可能得到一个最普通的正常人：一部分纯真的好奇与创造力（Naive），一部分精明的野蛮和生存饥饿（Hungry）。
                <br/><br/>
                这也似乎暗示了生物仿佛总是具有自我毁灭的倾向，高度智慧的文明被生存问题消灭，强悍的文明退出文明的范畴。一个人会尽力平衡这二者禀赋的配比；而一个组织中，分布在这个光谱两端的人，需要意识到对方的存在，并尽力合作。无论这些愿望是否现实，至少值得尝试。

            </p>

            <br/>

            <h6 className="App-essay-title">Two Kinds Of People</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2023</b>
                <br/><br/>

            </p>
        </div>)
}
