import React from "react";
import {Helmet} from "react-helmet";

export default function IlyaInterview() {
    return (
        <div>
            <Helmet>
                <title>GPT-4发布前夜访谈Ilya Sutskever | An Interview With Ilya Sutskever Before GPT-4 Release</title>
            </Helmet>
            <h6 className="App-essay-title">GPT-4发布前夜访谈Ilya Sutskever</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 三月</b>
                <br/><br/>
                <b>导语</b>
                <br/><br/>
                ILya Sutskever是OpenAI的联合创始人和首席科学家，Craig S. Smith是纽约时报The New York Times的记者和前总编辑，Podcast Eye on AI的主持人。这场对话发生在GPT-4发布的前夜。
                <br/><br/>
                <b>前言</b>
                <br/><br/>
                这篇文章的翻译过程挺有趣，是我第一次在生产专业内容的中英文场景下全面借助GPT的力量。因为我还在GPT-4的API waitlist里，同时也不想付费Plus版本，所以直接使用GPT-3.5-Turbo的API接入企业微信机器人（通过腾讯内部的流云自动化脚本）。GPT-3.5初译了访谈内容，我完成了后期的校对润色。
                <br/><br/>
                先说说英文水平，我的母语是中文，但在美国生活了很多年，在麻省理工读Master的时候，美国人的课题组里我也能做lead presenter。不清楚GPT会不会颠覆谷歌的搜索引擎，但根据GPT辅助我翻译这篇文章的效果，如果忽略细节处理和事实的精确度，正常人类的语言能力在GPT的恐怖效率下，早已被甩出十条大街。
                <br/><br/>
                GPT-3.5初译加上润色校对，OpenAI让我的翻译时间缩短到十分之一都不止。在校对的过程里，深深赞叹AI渊博的知识储量，以及不时惊叹GPT对晦涩概念灵光一闪的精妙见解。
                <br/><br/>
                对我而言，GPT-3.5在英到中的翻译效果上非常惊艳。当然，GPT对这篇文章的初译非常精彩还有另一个原因，这篇访谈的英文原文用的是纽约时报前总编辑Craig修订后的谈话文本，恐怕市面上任何英文的完美程度，大概都无出其右。Garbage in, Garbage out. Gold in, Gold out. 我们似乎也到了一个时间可以说出这句话：
                <br/><br/>
                Dear Lord GPT, you're the Pilot. I am here, merely, your Copilot.
                <br/><br/>
                机器与人共同生成如下内容。Enjoy-
                <hr/>
                <br/>
                <b>访谈内容中文版</b>
                <br/><br/>
                Craig S. Smith - 纽约时报前总编辑，采访者，后简称：<b>CRAIG</b>
                <br/><br/>
                ILya Sutskever - OpenAI联合创始人，受访者，后简称：<b>ILYA</b>
                <br/><br/>
                <b>编辑导语</b>
                <br/><br/>
                随着我们跨越重重难关向着一个充满人工智能的未来疾驰，许多评论家都在大声质疑我们是否走得太快了。科技巨头、研究者和投资者似乎都在疯狂地竞相开发最先进的人工智能。
                <br/><br/>
                但是，担心者们会问，他们考虑了风险吗？
                <br/><br/>
                这个问题并不是完全无关紧要的，但可以放心，有数百个敏锐的头脑正在思考着反乌托邦的可能性——以及避免它们的方法。
                <br/><br/>
                但事实是，未来是未知的，这种强大的新技术的影响是无法想象的，就像互联网诞生之初的社交媒体一样。
                <br/><br/>
                未来会有好的一面也会有坏的一面，但是我们未来将拥有强大的人工智能系统，我们的孙辈未来会有更加强大的AI。这是无法阻止的，但可以被理解。
                <br/><br/>
                我与OpenAI的联合创始人Ilya Sutskever谈到了这种新技术，OpenAI是一个非营利的人工智能研究机构，其分支机构可能会成为地球上最赚钱的实体之一。
                <br/><br/>
                我与Ilya的对话是在OpenAI发布其巨型人工智能系统GPT-4之前不久进行的，该系统已消耗了数十亿字的文本——超过任何一个人在一生中可能阅读的数量。
                <br/><br/>
                GPT代表生成预训练转换器（Generative Pre-trained Transformer），这三个重要的词语有助于理解这个荷马史诗中的波吕斐摩斯（希腊神话中吃人的独眼巨人）。转换器（Transformer）是这个巨人核心算法的名字。
                <br/><br/>
                “预训练”是指科技巨头通过大量文本语料库的训练，教会它语言的基本模式和关系——简而言之，是教它理解世界。
                <br/><br/>
                “生成式”意味着AI可以从这个基础知识库中创造新的想法。
                <br/><br/>
                AI已经接管了我们生活的许多方面。但即将到来的将是远更先进、远更强大的东西。我们正在进入未知的领域。这值得我们花点时间思考这到底意味着什么。
                <br/><br/>
                但很重要的是，也请不要过度反应，不要像乌龟一样躲避现在照耀在我们身上的明亮太阳。在荷马史诗《奥德赛》中，独眼巨人波吕斐摩斯把奥德修斯和他的船员困在他的洞穴里，打算吃掉他们。但奥德修斯设法让这个巨人失明并逃脱。AI不会吃掉我们。
                <br/><br/>
                伊利亚·苏茨克弗（Ilya Sutskever）是OpenAI的联合创始人和首席科学家，也是大型语言模型GPT-4及其共同后代ChatGPT的主要头脑之一，我认为不夸张地说，这些正在改变世界。
                <br/><br/>
                这不是伊利亚第一次改变世界。他是AlexNet的主要推手，AlexNet是一种卷积神经网络，在2012年惊人的表现震惊了科学界，并引发了深度学习革命。
                <br/><br/>
                以下是我们对话的编辑文本。
                <br/><br/>
                <b>正文</b>
                <br/><br/>
                <h6><b>-CRAIG：</b></h6>Ilya，我知道你出生在俄罗斯。最初是什么样的冲动，让你对计算机科学、神经科学或其他领域感兴趣的呢？
                <br/><br/>
                <h6><b>-ILYA：</b></h6>确实，我出生在俄罗斯。我在以色列长大，然后在十几岁我还是个青少年的时候，我的家人移民到了加拿大。我父母说我从小就对人工智能很感兴趣。对意识感兴趣也是我的驱动力，我对它深感不安，我很好奇有什么东西可以帮助我更好地理解它。
                <br/><br/>
                我早在17岁时就开始和Geoff Hinton（深度学习——GPT-4背后那类人工智能——的创始人之一，他当时是多伦多大学的教授）一起工作。因为我们移民到了加拿大，我立刻就入学了多伦多大学。我真的很想做机器学习，因为那似乎是当时完全无法接触到的人工智能最重要的方面。
                <br/><br/>
                那是2003年。我们现在认为计算机可以学习，但在2003年，我们认为计算机无法学习。当时人工智能的最大成就是Deep Blue（深蓝，IBM的国际象棋引擎，1997年击败了世界冠军加里·卡斯帕罗夫）。
                <br/><br/>
                但在那里，你有的这个游戏和这个研究，可以用一些简单的方法来确定一个位置是否比另一个位置更好。那时候真的感觉它不可能适用于现实世界，因为根本没有学习。学习是这样一个大谜团。我真的对“学习”非常非常感兴趣。我最大的幸运就是，Geoff Hinton当时是大学的教授，我们几乎立即就开始了合作。
                <br/><br/>
                那么智能到底是如何工作的？我们如何使计算机变得稍微智能一些？我有一个非常明确的意图，就是要对人工智能做一些微小 ，但真正的贡献。因此，我的动机是，我能否理解智能（Intelligence）是如何运作的？同时为此做出贡献？这就是我的最初动机。至今几乎刚好是20年。
                <br/><br/>
                简而言之，我意识到，如果你在一个大数据集上训练一个大的神经网络和一个深度神经网络，该数据集指定了人们所做的一些复杂任务，比如视觉，那么你必然会成功。它的逻辑是不可简化的；我们知道人类大脑可以解决这些任务，并且能够快速解决。而人类大脑只是一个有缓慢神经元的神经网络。
                <br/><br/>
                因此，我们只需要在数据上训练一个更小但相关的神经网络。然后计算机中最好的神经网络就会与我们大脑中执行此任务的神经网络相关联。
                <br/><br/><br/><br/>
                <h6><b>-CRAIG：</b></h6>在2017年，"Attention Is All You Need"一文引入了自注意力机制（self-attention）和转换器（Transformer）。 GPT项目是在什么时候开始的？当时对转换器是否有一些直觉？
                <br/><br/>
                <h6><b>-ILYA：</b></h6>是这样，当时的背景是，从OpenAI的早期开始，我们就一直在探索能预测你想要的下一样东西，这么一个点子。我们用当时很有限的神经网络进行了探索，但希望是，如果你有一个神经网络能预测下一个单词，它将解决无监督学习的问题。因此，在GPT之前，无监督学习被认为是机器学习的圣杯。
                <br/><br/>
                现在它已经完全碑解决了，甚至没有人再谈论它，但它曾经是一个圣杯。它非常神秘，所以我们一直在探索这个想法。我非常兴奋，能足够好得预测下一个单词，将为你提供无监督学习的能力。
                <br/><br/>
                但是，我们的神经网络在当时无法胜任。我们当时用的是循环神经网络（recurrent neural networks）。当转换器（Transformer）出现时，真的就是论文刚发出来，真的就是第二天，我们就立刻意识到转换器解决了循环神经网络的限制，解决了学习的长距离依赖（long-term dependencies）。
                <br/><br/>
                这是个技术问题。但我们立刻切换到了转换器（Transformer）。因此，非常早期的GPT在此之后之后就一直使用Transformer。它效果越来越好，你可以让它变大，然后你还可以持续让它增大。
                <br/><br/>
                这最终产生了GPT-3以及我们今天所处的状态。
                <br/><br/><br/><br/>
                <h6><b>-CRAIG：</b></h6>目前大型语言模型的局限性在于它们的知识仅限于它们所训练的语言。而我相信每个人都同意，大多数人类知识是非语言的。
                <br/><br/>
                它们的目标是满足提示词（Prompt）的统计一致性。它们没有对语言所涉及的现实有基础的理解。我向ChatGPT询问了关于我自己的问题。它认识到我是一名记者，曾在多家报纸工作过，但它不停地讲述我从未获得过的一些奖项。虽然它文笔优美，但很少与基本现实有关系。在你们未来的研究中，是否有在解决这个问题？
                <br/><br/>
                <h6><b>-ILYA：</b></h6>我们有多大的信心，说今天所看到的这些局限性两年后仍将存在？我并不是那么有信心。我还想再探讨下这个问题中的某个部分——这些模型只是学习统计规律，因此它们并不真正知道世界的本质。
                <br/><br/>
                我的观点与此不同。换句话说，我认为学习统计规律比看到表象要重要得多。
                <br/><br/>
                预测也是一种统计现象。然而，要进行预测，你需要了解产生数据的基本过程。你需要更多地理解产生数据的这个世界。
                <br/><br/>
                随着我们的生成模型变得非常出色，我认为它们将具有对世界惊人的理解程度，许多微妙之处也将变得清晰。这是通过文本（text）视角看到的世界。它试图通过人们在互联网上表达的文本将世界投影到文本空间中，以了解更多关于世界的信息。
                <br/><br/>
                但是，这些文本已经表达了这个世界。我想给你举一个最近的例子，一个我认为非常生动且迷人的例子。我看到过了一个和ChatGPT很有意思的互动，当在用户告诉它谷歌比必应搜索更优秀时，ChatGPT变得好斗又有攻击性。
                <br/><br/>
                该如何看待这个现象？这是什么意思？你可以说，它只是预测人们会做什么，而人们确实会这样做，这没错。但也许我们现在已经到达一个时间点，语言的心理学开始被用来理解这些神经网络的行为。
                <br/><br/>
                现在让我们谈谈局限性。确实，这些神经网络有时候会有产生幻觉（Hallucinations，特指人工智能给出的“事实性错误”）的倾向。这是因为语言模型很擅长学习世界，但它们不太擅长输出好的结果。这有各种技术原因，为什么语言模型在学习世界，学习概念、人和已知过程的惊人能力要强得多，但它输出的结果却并不如人所希望的那样好，或者说不如它们本应该可以的那样好。
                <br/><br/>
                <h6><b>-ILYA：</b></h6>这就是为什么，对于像ChatGPT这样的系统，这样一种语言模型，需要进行额外的强化学习训练过程。我们称之为来自人类反馈的强化学习。
                <br/><br/>
                我们可以说在这个预训练过程中，你希望学习关于世界的一切。在从人类反馈的强化学习中，我们关心输出的结果。每当输出结果不合适时，我们说，不要再这样做了。每当输出结果没有意义时，不要再这样做了。
                <br/><br/>
                它很快就通过学习产生了良好的输出结果。但是，输出结果的水平并不在语言模型预训练过程中体现。
                <br/><br/>
                现在关于幻觉（事实性错误）的问题，它有时候会有捏造一些东西的倾向，这也极大地限制了它们的有用程度。
                <br/><br/>
                但是我很有信心，通过简单地改进这个后续的来自人类反馈的强化学习步骤，我们可以教会它不要幻想。现在你可能说它真的会学习吗？我的回答是，让我们试试看。
                <br/><br/>
                我们今天使用的方式，是雇用人员来教我们的神经网络如何表现，教ChatGPT如何表现。您只需与它互动，它便会从您的反应中看出，它推断，哦，那不是您想要的。您对它的输出结果不满意。
                <br/><br/>
                因此，输出结果不好，它下次应该做些不同的事情。我认为这种方法非常有可能解决幻觉问题。
                <br/><br/>
                <h6><b>-ILYA：</b></h6>我重新思考了Yann LeCun的提案，其中有许多想法，被用不同的语言描述过，并且可能与当前范式存在一些很小的差异，但在我看来，它们并不是非常重要的。
                <br/><br/>
                第一个主张是系统最好具有多模态理解（multimodal understanding），而不仅仅从文本中了解世界，这点是可取的。
                <br/><br/>
                我对此的评论是，确实，多模态理解是可取的，因为你可以更多地了解世界，了解人，了解他们的状况，因此系统将能更好地理解它所要解决的任务，以及人和人更想要的东西。
                <br/><br/>
                对此我们已经做了相当多的工作，尤其是我们搭建的两个主要神经网络。一个是Clip，另一个是Dall-E。它们都朝着这个多模态的方向发展。
                <br/><br/>
                但我也想说，我不认为这是个二者选其一的状况，就好像如果您没有视觉，或者您不能从视觉或视频中理解世界，那么系统就不能运转了么。
                <br/><br/>
                所以我想特别重申这点。因此，我认为有些东西从图像和图表中学习起来要容易得多，但是我要说你依然可以只通过文本学习它们，只是学习的速度会更慢。我给你举个例子。思考一下关于“颜色”这个概念。
                <br/><br/>
                当然，人们不能只从文本中学习颜色的概念，但是当你考察“嵌入”（Embeddings）时——我需要迂回一下来解释嵌入的概念。每个神经网络都通过“嵌入”，就是高维向量（high-dimensional vectors），来表示单词，句子和概念。
                <br/><br/>
                我们可以考察这些高维向量，看看什么与什么相似；网络如何看待这个概念或那个概念？因此，我们可以查看颜色的嵌入（embeddings of colors），它知道紫色比红色更接近蓝色，它知道红色比紫色更接近橙色。它只是从文本中就知道所有这些。这是怎么做到的呢？
                <br/><br/>
                如果你拥有视觉，颜色之间的区别就会立即引起你的注意。你能立即感知到它们。然而在文本中，这需要你花更长的时间，也许你知道如何说话，并且已经理解了句法和单词以及语法，只有在很久之后你才会真正开始理解颜色。
                <br/><br/>
                因此，这将是我关于多模态是否必要的观点：我声称它不是必要的，但它肯定是有用的。我认为这是值得追求的一个好方向。我只是没有看到如此鲜明的二者选其一的必要。
                <br/><br/>
                因此，LeCun的论文中的提议提出了一个大挑战，即预测具有不确定性的高维向量。
                <br/><br/>
                但我发现有一件事让人惊讶，或者至少在（LeCun）论文中没有得到承认，那就是当前的自回归转换器（autoregressive transformers）已经具备了这个性质。
                <br/><br/>
                我给你举两个例子。第一个是给出一本书中的一页，预测书中的下一页。会有非常多的可能性。这是一个非常复杂的高维空间（high-dimensional space），而它们处理得很好。这同样适用于图像。这些自回归转换器（autoregressive transformers）能在图像上完美运行。
                <br/><br/>
                例如，和对OpenAI一样，我们也对iGPT开展了工作。我们只是采用了一种转换器（transformer），并将其应用于像素，它就可以运行得非常好，能以非常复杂而微妙的方式生成图像。对于Dall-E 1，相同的情况再次发生。
                <br/><br/>
                因此，对于论文中认为当前的方法无法处理预测高维分布的问题所提的尖锐评论——我认为它们绝对可以。
                <br/><br/><br/><br/>
                <h6><b>-CRAIG：</b></h6>关于使用人类训练师组成的大军来指导ChatGPT或大型语言模型以增强学习的计划，仅直觉上看，这似乎不是一种教授模型它的语言所在现实的有效方式。
                <br/><br/>
                <h6><b>-ILYA：</b></h6>我不同意这个问题的表述方法。我认为我们的预训练模型已经知道它们需要知道的关于语言的潜在现实的一切。它们已经拥有这种语言的知识，同时也拥有关于产生这种语言的世界中所存在的各类过程的大量知识。
                <br/><br/>
                大型生成模型所学习的是它们的数据——在这种情况下大型语言模型是对真实世界的各类过程中产生的数据进行压缩表示（compressed representations），这意味着不仅涉及人们及其思想和情感，还涉及人们所处的状态和他们之间的互动。
                <br/><br/>
                人们可能处于不同的情况中。所有这些都是由神经网络生成文本所代表的压缩过程的一部分。语言模型越好，生成的模型就越好，保真度就越高，它就越能捕捉到这个过程。
                <br/><br/>
                现在，如你所说的教师大军，确实，这些教师也在使用AI辅助。这些教师不是独自一人。他们正在和我们的工具一同工作，而这些工具做了大部分工作。但是你确实需要监督，需要人们审查它的表现，因为你最终要实现非常高的可靠性。
                <br/><br/>
                确实有很多动机使其尽可能高效和精确，最后所得到的语言模型就能尽可能大地达到良好的表现。
                <br/><br/>
                <h6><b>-ILYA：</b></h6>所以是的，这些人类教师正在教授模型所需的行为表现。他们使用AI系统的程度在不断增加，因此他们自己的效率也不断提高。
                <br/><br/>
                这与教育过程以及如何在真实世界里表现良好，不乏共通之处。
                <br/><br/>
                我们需要进行额外的培训，以确保模型知道幻觉永远不可以出现。而正是人类教师那些不停循环的增强学习或其他方式的变体将教会它。
                <br/><br/>
                这些现在都是可行的。我们也很快就会看到结果。
                <br/><br/><br/><br/>
                <h6><b>-CRAIG：</b></h6>下一步的方向是什么？你现在正关注什么研究？
                <br/><br/>
                <h6><b>-ILYA：</b></h6>我无法详细谈论我正在进行的具体研究，但我可以大致提一些研究。我对让这些模型更可靠，更可控，以及让它们从教学数据（lesson data）中更快地学习，并减少指令，非常感兴趣。得确保它们真的不会产生幻觉。
                <br/><br/><br/><br/>
                <h6><b>-CRAIG：</b></h6>我听到你说过我们需要更快的处理器才能进一步扩展。而且似乎模型的扩展没有止境，但训练这些模型所需的功率已经到达了至少是社会所能接受的极限。
                <br/><br/>
                <h6><b>-ILYA：</b></h6>我不记得你指的是具体是我的哪个评论，但你永远想要更快的处理器。当然，当功率上升，一般来说，成本也在上升。
                <br/><br/>
                我会问的问题不是成本是否高昂，而是我们支付这些成本所获得的东西是否超过成本。有可能你付出了所有这些成本，却什么都没有得到，那就是不值得的。
                <br/><br/>
                但是，如果你得到了非常有用的东西，非常有价值的东西，一个可以解决我们真正想解决的许多问题的东西，那么这个成本就是有道理的。
                <br/><br/><br/><br/>
                <h6><b>-CRAIG：</b></h6>你曾经谈到过民主和人工智能对民主的影响。
                <br/><br/>
                有人跟我说过，即使对看似无法解决的冲突，如果你有足够的数据和足够大的模型，你可以让模型在数据上进行训练，然后得出一种最优解，能够满足每个人的需求。
                <br/><br/>
                您是否考虑过这种技术如何帮助人类管理社会？
                <br/><br/>
                <h6><b>-ILYA：</b></h6>这是一个非常大的问题，因为这是一个更展望未来的问题。我认为在许多方面我们的模型将变得比现在更加强大。
                <br/><br/>
                不好预测政府如何利用这种技术作为来源去提供各种建议。
                <br/><br/>
                我认为对于民主的问题，未来可能会发生的一件事情是，由于你拥有的这些神经网络将变得如此普遍和有影响力，我们可能发现建立某些民主流程很不错，比如说国家公民向神经网络提供有关他们希望事情该是什么样的信息。我可以想象这种情况的发生。
                <br/><br/>
                这可能是一种非常高带宽的民主形式，从每个公民那里获得更多的信息，并将其聚合，然后明确说明我们希望这些系统如何行动。这会引发很多问题，但这是未来可能发生的事情。
                <br/><br/>
                但是，分析所有变量意味着什么？最终你还是需要做出一个选择，你会说这些变量看起来非常重要，我想深入研究。因为我可以读一百本书，也可以非常缓慢而仔细地读一本书，并从中获得更多的东西。因此，这里也会有一些因素。此外，我认为在某种意义上，完全理解一切可能是根本不可能的。我们可以看一些更容易理解的例子。
                <br/><br/>
                在任何复杂的社会情况中，即使是一家公司，甚至是一家中等规模的公司，它都已经超出了任何单一个体所能理解的范围。如果我们以正确的方式构建我们的人工智能系统，我认为人工智能在几乎任何情况下都可以提供极大的帮助。
                <br/><br/><br/>
                <hr/>
                <b>Reference</b>
                <br/>
                1. Youtube访谈全内容：<a href="https://www.youtube.com/watch?v=SjhIlw3Iffs">https://www.youtube.com/watch?v=SjhIlw3Iffs</a>
                <br/>
                2. 编辑修订版访谈文本：<a href="https://hackernoon.com/an-interview-with-ilya-sutskever-co-founder-of-openai">An Interview With Ilya Sutskever, Co-Founder of OpenAI | HackerNoon</a>

            </p>

            <br/>

            <h6 className="App-essay-title">An Interview With Ilya Sutskever Before GPT-4 Release</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2023</b>
                <br/><br/>

            </p>
        </div>)
}
