import React from "react";
import {Helmet} from "react-helmet";

export default function Conspiracy() {
    return (
        <div>
            <Helmet>
                <title>[文]Sivers “我怀念黑帮年代” | Essay by Sivers “I miss the mob.”</title>
            </Helmet>
            <h6 className="App-essay-title">[文]Sivers：“我怀念黑帮年代”</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 十月</b>
                <br/><br/>
                <h5><b>“我怀念黑帮年代。”</b></h5>
                <br/>
                我当时因为一个会议在拉斯维加斯，从机场坐出租车去酒店。我问司机：“你在这儿住了多久？”
                <br/><br/>
                他说：“二十七年。” <br/><br/>
                “哇！这些年变化很大吧？” <br/><br/>
                “是啊。我怀念黑手党。” <br/><br/>
                “啊？真的吗？你是什么意思？”
                <br/><br/>
                “当黑手党掌控这个城市的时候，这里很有趣。那时候只有两个数字重要：进多少钱和出多少钱。只要进的比出的多，大家都很开心。但后来整个城市被这些该死的大公司买下了，里面全是MBA的小聪明，他们试图从每一平方英尺的面积上最大化利润。现在，以前会在我热狗上免费加番茄酱的地方，告诉我加番茄酱要额外收费二十五美分！这个城市失去了所有的乐趣！是的，我怀念黑手党。”（当然，我们可以提出黑手党的其他问题，但我们就把它当作一个隐喻和一个教训吧。）
                <br/><br/>
                我在CD Baby经常讲这个故事。有时MBA类型的人会问我，“你们的增长率是多少？你们的保留收益率占总收入的百分比是多少？你们的预测是什么？”
                <br/><br/>
                我只会说，“我不知道。我甚至不知道那是什么意思。我开这家公司是作为一个爱好帮助我的朋友们，这是它存在的唯一理由。银行里有钱，我过得也不错，所以不用担心。”
                <br/><br/>
                他们会告诉我，如果我更好地分析业务，我可以最大化盈利。然后我会告诉他们关于在拉斯维加斯的出租车司机的故事。
                <br/><br/>
                永远不要忘记你真正为什么做你正在做的事情。你在帮助人吗？他们开心吗？你开心吗？你赚钱了吗？这难道还不够吗？

            </p>

            <br/>

            <h6 className="App-essay-title">Essay from Sivers: “I miss the mob.”</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2024</b>
                <br/><br/>
                <h5><b>“I miss the mob.”</b></h5>
                <br/>
                I was in Las Vegas for a conference, taking a taxi from the airport to the
                hotel. I asked the driver, “How long have you lived here?”
                <br/><br/>
                He said, “Twenty-seven years.”<br/><br/>
                “Wow! A lot has changed since then, huh?”<br/><br/>
                “Yeah. I miss the mob.”<br/><br/>
                “Huh? Really? What do you mean?”
                <br/><br/>
                “When the mafia ran this town, it was fun. There were only two
                numbers that mattered: how much was coming in, and how much was going
                out. As long as there was more in than out, everyone was happy. But then
                the whole town was bought up by these damn corporations full of MBA
                weasels micromanaging, trying to maximize the profit from every square
                foot of floor space. Now the place that used to put ketchup on my hot dog
                tells me it’ll be an extra twenty-five cents for ketchup! It sucked all the fun
                out of this town! Yeah, I miss the mob.” (Sure, we could bring up other
                issues with the mob, but let’s just leave it as a metaphor and a lesson.)
                <br/><br/>
                I told this story a lot at CD Baby. Sometimes MBA types would ask me,
                “What’s your growth rate? What’s your retained earnings rate as a
                percentage of gross? What are your projections?”
                <br/><br/>
                I’d just say, “I have no idea. I don’t even know what some of that
                means. I started this as a hobby to help my friends, and that’s the only
                reason it exists. There’s money in the bank and I’m doing fine, so no
                worries.”
                <br/><br/>
                They’d tell me that if I analyzed the business better, I could maximize
                profitability. Then I’d tell them about the taxi driver in Vegas.
                Never forget why you’re really doing what you’re doing. Are you
                helping people? Are they happy? Are you happy? Are you profitable? Isn’t
                that enough?
                <br/><br/>

            </p>
        </div>)
}
