import React from "react";
import {Helmet} from "react-helmet";
import Stirrup from "../../assets/images/stirrup.png";

export default function Stirrups() {
    return (
        <div>
            <Helmet>
                <title>马镫 Stirrups</title>
            </Helmet>
            <h6 className="App-essay-title">马镫</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 二月</b>
                <br/><br/>
                <b>历史的细节</b>
                <br/><br/>
                顺风而下的水路行船，可以借助风力达到一个稳定的速度。在没有蒸汽动力的推进下，古代帆船的时速一般不会超过20公里/小时。“千里江陵一日还”，行进在三峡上的无动力帆船，速度大概在10公里/小时。李白出发的白帝城在重庆奉节县，江陵在湖北荆州，距离600多公里，日夜兼程最快也需要30多个小时，李白的文字确实喜欢夸张，但把两日压缩到“一日还”，对李白而言已经相当客观了。
                <br/><br/>
                陆路交通的速度其实比水路更快，但陆路的缺点在于稳定性和续航时间没有水路久。
                <br/><br/>
                人正常步行每小时大约能走5公里，正常奔跑有10公里/小时，最快的人类奔跑时速约45公里/小时，而这是瞬时速度，根本不可能持续。一匹马的行走速度和人类接近，但是最高的奔跑速度有70公里/小时，持续奔跑的距离能达到100公里。马是古代人能达到的速度极限，是用来消除广阔距离差的最快手段。
                <br/><br/>
                如果想稳定地行进，马车是个好选择，但马车的缺点在于车身的重量拖慢了行进速度，马车稳定的时速在20公里/小时左右。马车也无法像帆船一样昼夜兼程地赶路，除非马匹可以更换。从奥地利返回巴黎大约一千多公里的距离，按照马车时速20公里每小时，日夜不停地行进最快也需要三天。傍晚睡在马车里，途中不停地更换马匹，历史上，拿破仑从奥地利乘马车昼夜不停地赶回巴黎，确实只用了三天。
                <br/><br/>
                然而军队的行进不能全部依靠马车，因为20公里/小时的行进速度不满足急行军要求，马车也无法在复杂地形上前进，同时坐在马车里也不允许骑兵灵活地参加战斗。
                <br/><br/>
                铲骑（没有任何马具的支撑辅助）的驭马者需要全神贯注，四肢并用地掌握自身平衡，天赋平常的普通人在马背上无辅助地骑几十分钟就足以被折腾得精疲力竭，遑论一群装备着重武器、天赋并不相同的骑兵，让他们高速稳定地无支撑行军十几个小时，简直是天方夜谭。
                <br/><br/>
                跑得快是不够的，还需要跑得稳而灵活。马镫的价值就在于此。
                <br/><br/>
                这种装置可以用木料、纤维或金属打造，非常简单。用一只长得像鞋一样的装置把脚固定起来，悬挂于马鞍之上，骑者的身体就被牢牢支撑在马背上，将体重通过脚而不是腿传递给马匹。这种增强稳定性、降低天赋要求度的半自动驾驶技术出现了。考古发现的最早马镫在中国东汉，双马镫出现在晋，西方主流的历史观点也把马镫的发明归为中国，发明后通过中亚游牧民族的入侵传入西方。（李·约瑟观点，Dien Albert E, The Stirrup and Its Effect on Chinese Military History）
                <br/><br/>
                马镫出现前，骑马人的双腿需要死死夹住马腹才能固定自己不摔下马。骑马者双腿包住马腹，脚尖向下，尽可能降低自己的重心，脚后跟与臀部保持一线，来平衡身体重力。保持在这种姿态下长时间地行进，非常耗费精力。如果是骑兵，还要进行上肢战斗，非常考验天赋和训练程度。所以，无马镫时代的骑马训练周期很长，作战中也没能把马的力量发挥到极致。而日常通勤中，无马镫也挑战着普通骑马者的天赋和训练素质。随着马鞍越来越宽厚，也越来越结实，中国人发明的这双“神奇靴子”，改变了游戏规则。
                <br/><br/>
                马镫大大降低了对日常骑马者的训练要求，马镫出现后妇女和孩童骑马也更普遍了。此外，马镫解放了驭马者的双手和精力，骑马人可以在“驾驶”马匹的同时分心做别的事，比如操作复杂沉重的长矛、弓箭和刀枪。马的动力通过马鞍——马镫——脚传递给骑兵，让骑兵的战斗力在拥有马镫后，对步兵呈现出压倒性优势。
                <br/><br/>
                马镫通过高效地连接骑兵的脚掌，把马力稳定地传输给骑兵，形成了最具有战斗效力的人马复合体。许多历史观点也认为，中世纪的骑兵形成一个全新而强大的阶层——骑士阶级——和马镫的使用和普及有莫大关系。一项体积颇小的发明，巩固了骑兵在战斗中对步兵的压倒性优势，进而支撑了一个全新的阶层，这实在是一件大事。
                <br/><br/>
                <b>划时代的技术</b>
                <br/><br/>
                和李白一样，历史学家也喜欢精巧有力的逻辑结构。
                <br/><br/>
                <i style={{color: 'grey'}}>几乎没有什么发明像马镫这么简单，但也没有什么发明像马镫这样对历史有如此的催化作用。</i>
                <br/><br/>
                <i style={{color: 'grey'}}>——林恩·怀特（Lynn White Jr.）</i>
                <br/><br/>
                但这段话有一定误导性，因为没有任何一项发明可以溯源到某个人在某一个确切的时间点，进行了某项神话般的创造（微积分除了牛顿还有莱布尼茨，更不用说还有伽利略和阿基米德这样的前辈）。何况马镫这种发明的概念根植于人类最简单的直觉中。想想这个词——“划时代”——像分割两块布料一样把历史划分成前后两个部分，这样的比喻比李白诗歌的浪漫主义成分，还要高出好几层楼。
                <br/><br/>
                历史研究者喜欢赋予这些小事物巨大的影响力，很可能也是人类本能地偏好通过故事来理解这个世界，所引发的夸张处理罢了。我们永远会生活在历史进程中，但我们可以根据这个进程中的某项技术被广泛采用的速度和影响力不同，将其分为颠覆性和渐进性。
                <br/><br/>
                颠覆性的新技术在被广泛采用的时间前后产生了巨大的社会震动，这种案例数不胜数：火、纸张、印刷术、火药、指南针、纺织机、电、电灯、电报、电话、计算机、基因编辑、手机……从这个角度看，马镫只是其中一项，而且复杂度和上述任何一项发明的复杂度都不能相提并论。一项重要技术被发明孕育出来后，确实会明显地将人类历史分为前后两节：1. 未被影响——尚未发明，持续改良，2. 被影响——重要改良、广泛采用、全面改造。但这种划分是多元而非二元的。
                <br/><br/>
                然而，对无限的时间而言，再迅速的冲击也不配称之为“技术奇点”。就好比，我们作为人，过去从未睁开的双眼突然睁开了，睁眼的速度是0.1秒/次，这个速度远快于大多数技术的迭代速度。那个我们用0.1秒“睁开眼”这个简单的动作，就把光明引入到我们的黑暗世界，岂不是比历史上任何“划时代”的技术飞跃都更“划时代”？
                <br/><br/>
                但我们强化马镫及一切发明的“划时代”意义，旨在加深对历史戏剧化程度的强调，对我们理解历史在重要性上的分配是如此高度不均，其实并无不妥。因为二八定律在自然界中大量存在，就是活生生的证据。
                <br/><br/>
                现在，让我们重写林恩·怀特的这句话：
                <br/><br/>
                <i style={{color: 'grey'}}>几乎没什么发明像“某样东西”的发明这么复杂，但几乎没有什么发明在可预见的未来像“某样东西”这样，对历史有如此划时代的催化作用。</i>
                <br/><br/>
                <i style={{color: 'grey'}}>——无名氏（注：这个“某样东西”，可以填任何你认为重要的事物。）</i>
                <br/><br/>
                它应该满足两个特点：被快速广泛地采纳，能引起巨大的变化。
                <br/><br/>
                改造世界的事物，确实可以是某样体量很小，也看起来微不足道的东西。
                <br/><br/>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={Stirrup} alt="Stirrup" style={{width: "30%"}}/>
                </div>
                <br/><br/><br/>
                <hr/>
                <b>Reference</b>
                <br/>
                <b>1. </b>Mumford Lewis,  Technics and Civilization, 1934.
                <br/>
                <b>2. </b>Dien Albert E, The Stirrup and Its Effect on Chinese Military History, 1986. http://www.silkroadfoundation.org/artl/stirrup.shtml
                <br/>
                <b>3. </b>杜君立，历史的细节，上海三联书店，2013.
                <br/>
                <b>4. </b>Lynn White Jr., Once More into the Stirrups, Medieval Technology and Social Change, 2003. https://www.jstor.org/stable/25148163


            </p>

            <br/>

            <h6 className="App-essay-title">Stirrups</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2024</b>
                <br/><br/>

            </p>
        </div>)
}
