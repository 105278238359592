import React from "react";
import {Helmet} from "react-helmet";
import R1 from "../../assets/images/RobotCyborg/arm.jpg";
import R2 from "../../assets/images/RobotCyborg/arm1.jpg";
import S1 from "../../assets/images/RobotCyborg/slave.jpg";
import S2 from "../../assets/images/RobotCyborg/slave1.jpg";

export default function SlavAndCyber() {
    return (
        <div>
            <Helmet>
                <title>奴隶机器人与自由机械狗？ Slave Robot And Free Cyborg?</title>
            </Helmet>
            <h6 className="App-essay-title">奴隶机器人与自由机械狗？</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 一月</b>
                <br/><br/>
                <p style={{textAlign: 'center'}}><b>奴隶机器人？👇🏻</b></p>
                <img src={S1} alt="arm 1" width={"100%"}/>
                <br/><br/>
                <img src={S2} alt="arm 2" width={"100%"}/>
                <br/><br/><br/><br/>
                <p style={{textAlign: 'center'}}><b>自由机械狗？👇🏻</b></p>
                <img src={R1} alt="robot 1" width={"100%"}/>
                <br/><br/>
                <img src={R2} alt="robot 1" width={"100%"}/>
                <br/>
            </p>

            <br/>

            <h6 className="App-essay-title">Slave Robot And Free Cyborg?</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2024</b>
                <br/><br/>

            </p>
        </div>)
}
