import React from "react";
import {Helmet} from "react-helmet";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Papa from 'papaparse'
import bullshitsTable from '../../assets/tables/Bullshit.csv';
import Handshake from '../../assets/images/handshake.gif';

function createData(bullshit, noBullshit) {
    return {bullshit, noBullshit};
}

export default function AntiBullshit() {
    const [bullshitRows, setRows] = React.useState([])
    React.useEffect(() => {
        async function getData() {
            const response = await fetch(bullshitsTable);
            const reader = response.body.getReader();
            const result = await reader.read(); // raw array
            const decoder = new TextDecoder('utf-8');
            const csv = decoder.decode(result.value); // the csv text
            // console.log(csv);
            const results = Papa.parse(csv, {header: false}); // object with { data, errors, meta }
            const bullshitRows = results.data; // array of objects
            setRows(bullshitRows);
        }

        getData()
    }, []) // [] means just do this once, after initial render

    console.log(bullshitRows);

    const rows = [];
    bullshitRows.forEach((bullshit) => {
        rows.push(createData(bullshit[0], bullshit[1]));
    });

    return (
        <div>
            <Helmet>
                <title>反屁话运动 Anti-Bullshit Movement</title>
            </Helmet>
            <h6 className="App-essay-title">反屁话运动</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 四月</b>
                <br/><br/>
                互联网屁话，也被称作互联网黑话。屁话的作用之一是体现水平和工作量，但黑话的目的主要为了体现身份和维护信息特权。屁话和黑话都具有巩固信息屏障、浪费时间、扼杀想象、肢解创造…等诸多功效;)
                <br/><br/>
                其实并不是完全反对使用“底层逻辑”这类词语，你如果真有相当底层的逻辑，比方说，这个世界是由原子构成的！那请直接使用“底层逻辑”这个词，也只有“底层”才配得上这种见识。
                <br/><br/>
                完全抵抗对任何词语的使用，一定会最终造成另一种霸权和另一类词语的滥用。大家更希望看到的，是有人能把这类词恰如其分地用对场合，那对任何读者都会是种莫大的惊喜。
                <br/><br/>
                以下是其中一些屁话案例，减少使用屁话有助于知识积累和平等交流，还能缓解多种矛盾。
            </p>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>屁话 Bullshit</b></TableCell>
                            <TableCell><b>不说屁话 No Bullshit</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                            >
                                <TableCell>{row.bullshit}</TableCell>
                                <TableCell>{row.noBullshit}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br/><br/>
            <p className="App-essay-article">
                不定期更新，反对屁话，真诚沟通。
                <img src={Handshake} alt="handshake"/>
            </p>

            <br/>

            <h6 className="App-essay-title">Anti-Bullshit Movement</h6>
            <p className="App-essay-article">
                <br/>
                <b>Apr 2023</b>
                <br/><br/>

            </p>
        </div>)
}
