import React from "react";
import {Helmet} from "react-helmet";

export default function Conspiracy() {
    return (
        <div>
            <Helmet>
                <title>阴谋论的缺陷 The Flaw of Conspiracy Theory</title>
            </Helmet>
            <h6 className="App-essay-title">阴谋论的缺陷</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 十月</b>
                <br/><br/>
                小时候爱看科幻小说，但不只读阿西莫夫和《科幻世界》，小孩子更爱看故事会夹缝中的都市传说、灵异故事，还有UFO未解之谜。这些怪奇物语的最大特点，就是无法证实，最多你也只能说一句，无法证伪。
                <br/><br/>
                放今天看，这些故事有很强的反智色彩。一个理智的现代人很可能听完瞎扯后一笑而过。但必须承认，即使今天再读，这些阴谋传说也最能调动人丰富的想象，我们时常被这些阴谋深深吸引。无论它们在故事会的夹缝里，还是来自视频号或抖音。这些阴谋故事操纵着我们的情绪，进而控制了我们的行动力。
                <br/><br/>
                光明会、深层政府、蜥蜴人、月球背面、人类是外星人的模拟游戏……这些故事的筹码是我们内心深处对整个世界未知的恐惧，这种恐惧是真实存在的。
                <br/><br/>
                很多傲慢又懒得认真思考的“理性人”，会提醒我：你竟然会思考阴谋论啊，赶紧多读读书！掌握了科学真理，就不会相信那些阴谋论的胡扯了！
                <br/><br/>
                哦，是么？那请这些大聪明回答我：时间到底是什么？时间在干嘛？我们所处的这个宇宙，它的边界在哪儿？这个巨大的宇宙蚕豆外面是什么？现代科学似乎解释了所有，但别忘了，中世纪的神学也曾“几乎”解释了万物原理。
                <br/><br/>
                如果你睁大眼睛看，现代科学里依然包含着最纯粹的反智——那些被绝大多数人不假思索就接受认定的公理和假说。此外，你去任何顶级大学看，那里的学阀无时无刻都在提醒你愚蠢效忠比探索未知更重要；更不要说，理性的科学实际上被一群并不完全理性的人类研究（这点很值得钦佩）。
                <br/><br/>
                所以想通过掌握科学知识来去除阴谋论的反智成分，然后根除阴谋论的蛊惑，那还不如去讨论现代科学的神学及宗教起源，至少后者还有自我反省的新鲜感。
                <br/><br/>
                阴谋论蛊惑人心的原因并非相信阴谋论的人都是科学文盲，阴谋论的力量来源于我们并不真正理解我们所处的世界。这个世界的巨大未知性，有力地支撑了我们最真实的恐惧。这种恐惧进而被阴谋论理所应当地驾驭和利用。
                <br/><br/>
                阴谋论一般有三个典型特征。第一，阴谋论会故意对细节模糊。如果阴谋论可以精确叙述组织形成的历史时间及故事细节，那么阴谋论的伤害性就会大大下降。第二，阴谋论需要调动激烈的情绪。丰富的想象会合理化阴谋论的不合理成分；如果无法调动丰富的情绪以激发想象，阴谋论就不能起作用。第三，也是最重要的一点，在阴谋论中，宏观永远可以对微观进行精确打击。下一届总统，下一次瘟疫，下一次股市收割的对象，下一次种族灭绝和科学进步。
                <br/><br/>
                阴谋论的前两点很容易通过思维训练厘清，而第三点需要仔细看才能发现它的缺陷。
                <br/><br/>
                宏大事物的优势在于压倒性的力量和尺度，但宏大作为浩瀚苍穹的代价也正是构造的稳定；宏伟之物缺乏精密的部件和辗转腾挪的变化。你可以描述一个完整稳定的宏伟之物，但你几乎无法描述一群渺小之物，对渺小之物进行描述的方法充其量也只能描述这个系统的机率变化。渺小因为不确定而无法被精确操控。这是渺小之物抵御宏大的关键。
                <br/><br/>
                量子力学适用于微观尺度，微观粒子展现的波动性和“测不准”是渺小事物的“常态”，微观状态会因为观测而变化。相对论良好地描述巨型天体对时空产生的引力扭曲，时间在引力作用下变成了空间的另一维变量，是扭曲的空间完成距离等价的“代价”，一切都变得扁平。
                <br/><br/>
                宏观和微观，它们各有各的势力范围；在超出各自竞技场的尺度外，它们的力量会大打折扣，具体表现为：准确性迅速丧失，因果关系不明。宏观和微观，它们似乎无法控制彼此。
                <br/><br/>
                类似的，对我们的世界系统中，凭空假设一个黑暗组织可以掌握并控制微观个体，精确地预知并操控未来，这种思想和物理学中“拉普拉斯妖”的机械世界观有同等僵化的色彩。
                <br/><br/>
                阴谋论的悖论在于人们构想并坚信一个黑暗组织可以精密地操纵个体，另一方面，人们又热烈地沉迷于讨论“不可知论”。人们迷信着“不可知论”，转头又确信无疑地认为——这个世界存在一个黑暗组织，掌握了所有秘密（“可知”）对微观进行控制。这种自相矛盾的逻辑是被恐惧控制和剥削的产物，它构成了阴谋论的弱点。更严重的问题，阴谋论会最终瓦解人的意义感，消解人的行动力。
                <br/><br/>
                历史过于庞杂，很不可能是由寥寥几个黑暗组织，和所谓的“深层政府”组成的黑色装置能控制的产物。历史更可能由无数渺小的微观结构经历平凡和剧烈、理智或冲动、有意识和无意识的事件，所留下的无穷事实所构成。
                <br/><br/>
                在这个世界里，细节永远无法被精确控制。这个世界的全部历史，甚至因为其细节的无穷无尽，而永远无法被精确描述。
                <br/><br/>
                所以让我们继续反问：如果无法精确掌握每一丝事实和细节，那阴谋论应该如何操纵世界？那个像钟表机械一样精确操控世界的黑暗组织，究竟需要多大的数据量才能实现对微观的摆弄？
                <br/><br/>
                <i style={{color: "grey"}}>
                    “藐小之物皆已流逝，<br/>
                    唯海洋和大地稳固不移。”<br/><br/>
                    ——约翰 · 沃尔夫冈 · 冯 · 歌德
                </i>
                <br/><br/>
                歌德写得磅礴有力，唯一留下的只有宏大，但宏大之物也是唯一可被操控的事物，因为渺小如指缝间的沙砾一般变幻莫测，难以捉摸。
                <br/><br/>
                微小无法被宏大精确控制。蝼蚁的意义，超出大象的安排。

            </p>

            <br/>

            <h6 className="App-essay-title">The Flaw of Conspiracy Theory</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2024</b>
                <br/><br/>

            </p>
        </div>)
}
