import React from "react";
import {Helmet} from "react-helmet";

export default function WhatToLearn() {
    return (
        <div>
            <Helmet>
                <title>大型猫科动物 The Big Cat</title>
            </Helmet>
            <h6 className="App-essay-title">大型猫科动物</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 一月</b>
                <br/><br/>
                这只大型猫科动物是一头雌性狮子，大大咧咧，但心里有十八条沟壑，这些复杂深邃的沟壑和直率强烈的性格是一切矛盾的根源。
                <br/><br/>
                如果你不相信星座，那么这些吉普赛式的浪荡胡扯注定要被理性的巴纳姆效应打败，这些疯狂的预测像梦中呓语，毫无根据。但假设人是完全非理性的（会完全被理性的心理效应扭曲），就像假设人是纯粹理性一样荒唐，螺旋式的心理动力效应源于自我认可的递归反馈，它会在正反馈中被不断加强，直到变成统计学上可被观测到的真相，甚至某些隐秘但强烈的指向或许真的根植于基因中的神秘因子：虽然依旧作为科学秘密，但会更精准地贡献唯物论的支持。正因为自我认可的反馈根植于自我认同，非理性的自我认同最终反而会无比理性而精确地加强算命先生的灵签和塔罗牌的瞎扯。
                <br/><br/>
                所以，你无法准确形容一个狮子座的女性，就像你无法准确预测双鱼座、水瓶座、天蝎座、XX座…的姑娘和大型猫科动物有何不同一般。但是，如果她欣然认同自己诞生时所隐含的风格性情，那么她就会大概率有相当强烈的大型猫科动物的印记——那些带着盛夏的气息，自我确信的烙印，和尚未被解释清楚的猫科动物般的双螺旋印记。她要主动奉行最好的防守就是进攻，同时又奇特地认为最极致的进攻可以是逃跑，只有纯粹行动，没有含混斗争。
                <br/><br/>
                至于是她选择了大型猫科动物，还是大型猫科动物选择了她，也只能由老天爷的神秘回应作答。你无法像形容海滩上的狮子或者密林中的老虎一样形容她，因为她没有标记这些被纹在身上或画成图腾的猛兽记号，比肉体更根深蒂固的，是那些烙在脑海和精神中的符号。

            </p>

            <br/>

            <h6 className="App-essay-title">The Big Cat</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2023</b>
                <br/><br/>

            </p>
        </div>)
}
