import React from "react";
import {Helmet} from "react-helmet";

export default function AWildFeline() {
    return (
        <div>
            <Helmet>
                <title>野生猫科动物 A Wild Feline</title>
            </Helmet>
            <h6 className="App-essay-title">野生猫科动物</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 六月</b>
                <br/><br/>
                楼下有只野猫，瘦削、细长，倒三角形的脸，身上没有一丝赘肉，豹子般的花纹在清晨的光线下闪闪发亮，如一身戎装。亚热带的冬天湿冷，夏季闷热，终年无雪，他在这里游荡。以前有人把跟踪器绑在散养的猫身上“信猫由缰”，那些人会被猫夜里的活动半径吓坏。等两足直立行走的灵长目动物睡着，猫科动物才会重新占领这座城市，他们在黑夜里风驰电掣地游走，插翅般在大地上行军。等太阳照常升起，一切又从未发生过。
                <br/><br/>
                没人研究过他，也没人在意他的白天和黑夜都出现在哪里，不过上午和傍晚人们倒垃圾的时候，会看到他在小区附近转悠。等人们离开，他熟练地原地起跳，径直飞上一米多高的垃圾桶，走钢丝般在桶边缘上前进，然后在合适的位置一跃而入，仔细翻找最后一丝残存的能量。第一次见他是去年底的傍晚，他远远地直勾勾盯着，等我走远后才发出叫一阵高亢的叫声，呼朋引伴地叫来三五只浅色的同类。偶尔有好心人用纸包一些猫粮放在垃圾桶旁，他也要远远看很久，才警惕地慢慢靠近。他是那群野生猫科动物的哨兵。
                <br/><br/>
                城里的野猫平均能活两三年，在短短几轮春秋里，方圆数十公里的野猫都在这片大地上寻找食物。他们看起来都一样，细长精干、警觉灵活，和浑圆肥硕的家猫似乎压根不是一个物种。我很久没有见过他了，几天前的大中午，有一只豹纹的野猫站在汽车保险杠前一动不动，盯着我看，但我不确定那是他。这只野生猫科动物在大地上尽情奔跑，一群走了，会有一群再来。
                <br/><br/>

            </p>

            <br/>

            <h6 className="App-essay-title">A Wild Feline</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jun 2022</b>
                <br/><br/>
                There is a wildcat down the street, slender with an inverted-triangle face and no extra meat on the
                bones. He wears a leopard print, like in shining armor under the morning light. Winters here are wet,
                short, and no snow, while summer is humid and long. He wanders in this bizarre subtropical land.
                Some bored people tied location trackers to their free-ranging cats and would be all spooked by how far
                the cats had explored while they were sleeping. At the time when two-legged, upright-walking primates
                fall
                asleep, those wild felines will re-take over the city. They sprint like thunder and lighting, marching
                as if they had wings. When the sun rises again, nothing has happened.
                <br/><br/>
                No one studied him nor cared where he stayed day and night. But he always wanders nearby in the morning
                and afternoon when people throw away trash. As the human leaves, he masterfully jumps on the edge of the
                trash bin, advancing on the path to the right spot, rushing in when he sees fit, and carefully seeking
                the last bit of left-over energy. The first time I saw him was at the end of last year when he stared at
                me for a long time, then meowed in a high-pitched voice after I was gone, calling for several of his
                kind in light colors. Often, a good fellow wrapped some cat food in paper and placed it beside the trash
                bin.
                He would observe for a long time before approaching. He was the guard of his group.
                <br/><br/>
                Wild felines in the city live two to three years. During this time, these creatures roaming within
                several
                kilometers seek food here. They all look alike, slim and sharp—almost a different species from their
                chubby domestic kind. I had not seen him for quite a while. At noon several days ago, a wild cat in
                leopard print stood still before a sedan bumper, staring at me, but I could not be sure it was him. This
                wild feline runs heartily on this land, a group of them gone while another comes.

            </p>
        </div>)
}
