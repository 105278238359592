import React from "react";
import {Helmet} from "react-helmet";

export default function Rebellion() {
    return (
        <div>
            <Helmet>
                <title>两种叛逆 Two Kinds of Rebellion</title>
            </Helmet>
            <h6 className="App-essay-title">两种叛逆</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 二月</b>
                <br/><br/>
                世间有两种叛逆。其中一种是胡作非为式的反叛，攻击一切不顺着自己性子来的事物，这种叛逆的目的是放纵享乐。世上还有另一种叛逆，你可以把激进的科学家、政治强人（或狂人）、疯狂的发明家、才华横溢的音乐人都关联到这一种——ta们并不随和，ta们的目的只有一个，改造世界。
                <br/><br/>
                第一种胡作非为式的叛逆没有明确的目的，也没有宣言般的言辞和行为，它代表了一种高度贪婪的自我放纵。第二种叛逆是高度纪律性的叛逆，它有组织有目标，你可以从这些人的言行中发现高度结构化的脉络。
                <br/><br/>
                如何区别这两种叛逆，你看看这个人言辞行为中的结构化程度就够了。如果此人随心所欲，肆意妄为，没有明确的目标和反叛原因，言行散漫而固执，那大概率是第一种叛逆。如果ta行为高度自律、言行一致、思维高度结构化，那大概率是第二种叛逆。
                <br/><br/>
                从自我放纵的毛孔中透露出的反抗是一种邪恶，胡作非为式的叛逆只有一个结果，在无意义中自我毁灭。高度纪律性的反抗中渗透出来的叛逆，是一种强大的善，是改造世界最重要的推动力，当然，如果是高度结构化的有组织破坏，这种叛逆也可以是最极致的恶。

            </p>

            <br/>

            <h6 className="App-essay-title">Two Kinds of Rebellion</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2024</b>
                <br/><br/>

            </p>
        </div>)
}
