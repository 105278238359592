import React from "react";
import {Helmet} from "react-helmet";

export default function NoPurpose() {
    return (
        <div>
            <Helmet>
                <title>良夜 Good Night</title>
            </Helmet>
            <h6 className="App-essay-title">良夜</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 十月</b>
                <br/><br/>
                有些人觉得杜甫的诗很一般，倒也不怪这些人，杜甫的文章得有心的人才看得懂，他诗歌里的真情流露和强烈爱憎需要一颗敏感的心才能互动。年轻人谁不爱读李白？谁能不喜欢“天生我材必有用，千金散尽还复来”？但这群人嘲讽杜甫“没什么文采”，让我还是非常诧异。虽然喝多了李太白会脱口而出，但当人生中的强烈感情没有出口时，杜少陵总能如约而至，我预计在未来的人生中，还会无数次和他相逢。
                <br/><br/>
                这位盛唐诗人把唐帝国由盛转衰的一切情绪都写完了，盛世和乱世他都看到过。他的感情恢宏至极又细腻至极，超越阶级、文化、民族和古今。如果和他同时代，他随手写的草稿我也愿意不计代价地留着。他诗里的东西应有尽有，山川、江河、贵族、官吏、战争、社会、军队、爱情、家庭、朋友、创作、仕途、疑惑和愤怒。今天有些人觉得杜甫“太一般”，当杜甫发现他的时代有些人评论那几位初唐诗人写得一般时，他在纸上这么表达愤怒：
                <br/><br/>
                <p style={{ color:'grey' }}>
                    王杨卢骆当时体，轻薄为文哂未休。
                    <br/>
                    尔曹身与名俱灭，不废江河万古流。
                </p>
                王勃、杨炯、卢照邻、骆宾王，初唐四杰的水平先不评论，但杜甫为他们辩护的讽刺体都能写诗，这个水平还够吗？如果不够，下面这些呢？
                <br/><br/>
                <p style={{color: 'grey'}}>
                    李白斗酒诗百篇，长安市中酒家眠，天子呼来不上船，自称臣是酒中仙。
                    <br/>
                    落日照大旗，马鸣风萧萧。
                    <br/>
                    朱门酒肉臭，路有冻死骨。荣枯咫尺异，惆怅难再述。
                    <br/>
                    明眸皓齿今何在？血污游魂归不得。
                    <br/>
                    丹青不知老将至，富贵于我如浮云。
                    <br/>
                    白日放歌须纵酒，青春作伴好还乡。
                    <br/>
                    文章千古事，得失寸心知。
                    <br/>
                    车辚辚，马萧萧，行人弓箭各在腰。爷娘妻子走相送，尘埃不见咸阳桥。
                    <br/>
                    夜雨剪春韭，新炊间黄粱。
                    <br/>
                    ……
                </p>
                杜甫什么都能写，那些随意指摘他的人，连杜子美的诗都没读过几首。如果你有所怀疑，应该买一本《杜工部集》，他的文采风骨会让你热泪盈眶，明白诗歌的力量何在。用杜甫写给曹将军的《丹青引赠曹将军霸》来评价他自己也挺合适:
                <br/><br/>
                <p style={{color: 'grey'}}>
                    即今漂泊干戈际，屡貌寻常行路人。
                    <br/>
                    途穷反遭俗眼白，世上未有如公贫。
                    <br/>
                    但看古来盛名下，终日坎壈缠其身。
                </p>
                不禁想起在唐朝的中后期也发生过嘲笑杜甫李白的事。战斗型文人韩愈，在《调张籍》里调侃学生张籍，也讽刺同时代的文人瞧不起杜甫和李白的文学成就。可以想象韩愈愤怒又一脸讥讽的样子：
                <br/><br/>
                <p style={{color: 'grey'}}>
                    李杜文章在，光焰万丈长。
                    <br/>
                    不知群儿愚，那用故谤伤。
                    <br/>
                    蚍蜉撼大树，可笑不自量！
                    <br/>
                    伊我生其后，举颈遥相望。
                </p>
                对韩愈激烈的愤怒和感情，印象最深的还是《左迁至蓝关示侄孙湘》。他竭力谏言唐宪宗，最终被贬潮州，韩愈把最后一丝愤怒都化作同归于尽式的自嘲中：“云横秦岭家何在？雪拥蓝关马不前。知汝远来应有意，好收吾骨瘴江边。”
                <br/><br/>
                有一部基于真实故事改编的电影《斯图尔特：倒带人生》。斯图尔特是一个英国的流浪汉，一生饱受虐待、极尽坎坷，他对给他写传的作家朋友说：“The day I discovered violence. The day that I found violence, I felt fifty times more strong.” 和暴力一样，诗歌里的愤怒是身体里一系列复杂的神经化学反应，是一种自我保护机制，也是一个相互保证毁灭的危险承诺。
                <br/><br/>
                我还记得发现诗歌中愤怒的那个良夜，那一天我似乎也强壮了50倍。但我也记得发现平静的那个良夜，我甚至强壮了50倍都不止。可惜平静比愤怒稀罕得多，像口袋妖怪的掌机游戏，那个隐藏在家门口山洞里的小精灵超梦，游戏中仅此一个。玩家只有在单挑四大天王和二号人物后，才能到达那里。到了超梦面前的那一刻，捕获它的方式也出奇的简单，你只需扔出游戏中仅此一颗的大师球就行了。没有战斗，没有负隅顽抗，像愤怒后的平静。
                <br/><br/>
                今晚吃饭，有个很重要的人对我说，“You look more angry than before.” 那用一首愤怒而平静的诗作结尾吧。
                <br/><br/>
                <p style={{color: 'grey'}}>
                    不要温和地走进那个良夜（Dylan Thomas 巫宁坤 译）
                    <br/><br/>
                    不要温和地走进那个良夜，
                    <br/>
                    老年应当在日暮时燃烧咆哮；
                    <br/>
                    怒斥，怒斥光明的消逝。
                    <br/>
                    虽然智慧的人临终时懂得黑暗有理，
                    <br/>
                    因为他们的话没有迸发出闪电，他们
                    <br/>
                    也并不温和地走进那个良夜。
                    <br/>
                    善良的人，当最后一浪过去，高呼他们脆弱的善行
                    <br/>
                    可能曾会多么光辉地在绿色的海湾里舞蹈，
                    <br/>
                    怒斥，怒斥光明的消逝。
                    <br/>
                    狂暴的人抓住并歌唱过翱翔的太阳，
                    <br/>
                    懂得，但为时太晚，他们使太阳在途中悲伤，
                    <br/>
                    也并不温和地走进那个良夜。
                    <br/>
                    严肃的人，接近死亡，用炫目的视觉看出
                    <br/>
                    失明的眼睛可以像流星一样闪耀欢欣，
                    <br/>
                    怒斥，怒斥光明的消逝。
                    <br/>
                    您啊，我的父亲，在那悲哀的高处，
                    <br/>
                    现在用您的热泪诅咒我，祝福我吧，我求您。
                    <br/>
                    不要温和地走进那个良夜。
                    <br/>
                    怒斥，怒斥光明的消逝。
                    <br/><br/>
                    Do not go gentle into that good night (Dylan Thomas)
                    <br/><br/>
                    Do not go gentle into that good night,
                    <br/>
                    Old age should burn and rave at close of day;
                    <br/>
                    Rage, rage against the dying of the light.
                    <br/>
                    Though wise men at their end know dark is right,
                    <br/>
                    Because their words had forked no lightning they
                    <br/>
                    Do not go gentle into that good night.
                    <br/>
                    Good men, the last wave by, crying how bright
                    <br/>
                    Their frail deeds might have danced in a green bay,
                    <br/>
                    Rage, rage against the dying of the light.
                    <br/>
                    Wild men who caught and sang the sun in flight,
                    <br/>
                    And learn, too late, they grieved it on its way,
                    <br/>
                    Do not go gentle into that good night.
                    <br/>
                    Grave men, near death, who see with blinding sight
                    <br/>
                    Blind eyes could blaze like meteors and be gay,
                    <br/>
                    Rage, rage against the dying of the light.
                    <br/>
                    And you, my father, there on the sad height,
                    <br/>
                    Curse, bless me now with your fierce tears, I pray.
                    <br/>
                    Do not go gentle into that good night.
                    <br/>
                    Rage, rage against the dying of the light.
                </p>

            </p>

            <br/>

            <h6 className="App-essay-title">Good Night</h6>
            <p className="App-essay-article">
                <br/>
                <b>Oct 2022</b>
                <br/><br/>

            </p>
        </div>)
}
