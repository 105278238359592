import React from "react";
import {Helmet} from "react-helmet";

export default function PressureReliefValve() {
    return (
        <div>
            <Helmet>
                <title>减压阀 Pressure Relief Valve</title>
            </Helmet>
            <h6 className="App-essay-title">减压阀</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 一月</b>
                <br/><br/>
                一个系统不仅需要燃料，还需要刹车，这样才能进行最基本的加减法。此外，在某些关键时刻，还需要一类看起来像一种防御工具或自毁器械的装置，减压阀。
                <br/><br/>
                高压锅、内燃机、成人网站和网络留言板——我们的科学发明乃至社会发明中充满了这类装置。这不是技术或人性的失控，相反，这是更深一层想要驾驭自然的欲望和手段。把锅炉的压力和温度稳定在合适且安全的区域，是让内燃机持续做工的重要前提。它的发明是为了避免更大规模的失败而存在。通过这类装置，系统可以快速做除法。
                <br/><br/>
                减压阀不是一块衣服上的破洞，减压的举动更不是一种遮蔽失败和临阵退缩的物化——事实上，它更像一种复杂的进攻装置——保证在彻底的自我毁灭前，主动刺破高压的危险，然后把系统维持在恒定高效的定义域内。减压阀是高压锅的气门，吸血的水蛭和放血的针头。减压阀是一种进攻装置，它的进攻本能隐藏在被动又迟钝的功能和外观之下。
                <br/><br/>
                自从两百多年前人类开始大规模使用化石燃料，我们的文明就完全寄生在死去的生物躯体组成的能源贮藏上。这个过程就像是，地球通过减压阀把无数生物从地表抹除，然后赋予数亿年后人类这种矮小玲珑的哺乳动物突然繁荣的机会。我们的文明繁荣于地球这个渺小的行星系统的减压阀，过去几亿年古生物死掉的灵魂，被自然用来创造了一出精彩的食腐动物的华彩乐章。
                <br/><br/>
                在地质尺度上，我们使用几亿年的能量积累突然繁荣并不是什么大事，大自然如果有感受，我们的存在乃至消亡都只是一瞬间。大自然抹去那些四足爬行的恐怖蜥蜴，然后用一种两足直立行走的哺乳动物取代它们，这个过程不比高压锅的减压阀突然发出一阵嘶嘶的声响转而沉寂下来——然后让锅里的炖菜变得劲道可口，而不是被炸成一团浆糊抹在墙上——所花费的时间更长。
                <br/><br/>
                自然界庞大的煤炭和石油储藏提供的化石能源让人类生活变得无比舒适，同时也造成了历史上最高效和惨痛的两次自相屠戮——这也是自然在动用减压阀的规则，因为人类作为群体只懂往灶里添柴，而不会使用减压阀把热量移走。过去两百多年，人还持续发现了更多的能量补给，古老的风能、水能和太阳能，以及原子能时代的核裂变和聚变。直到今天，我们仍在不停地往锅炉里添柴的道路上行走。我们大概率不会死于能量匮乏，而更像是要死于压力过大。
                <br/><br/>
                个体崩溃和群体疯狂，经常源于恒久的增加而拒绝减少，更不要提突然释放的可能了——科技竞争，物质美好，世界繁荣，经济增长…你可曾在人类计划中听到过减压阀的痕迹？那根能刺破危险高压的尖针经常被禁止使用，尤其是在群体系统中，人们更少意识到甚至严禁谈论减压阀的重要性，遑论发明这种装置了。
                <br/><br/>
                酒精就像大脑中那架吵闹引擎的燃料，当无限度输入时，早晚会过载；一个动力无穷的头脑，像一个能量过大的系统，都是一口没有气阀的高压锅，早晚要爆炸。
                <br/><br/>
                如果我们做不到给系统减压，那就先给个体减压，从自己的脑袋开始。

            </p>

            <br/>

            <h6 className="App-essay-title">Pressure Relief Valve</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2023</b>
                <br/><br/>

            </p>
        </div>)
}
