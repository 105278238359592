import React from "react";
import {Helmet} from "react-helmet";

export default function OrnamentAndCrime() {
    return (
        <div>
            <Helmet>
                <title>装饰与罪恶 Ornament And Crime</title>
            </Helmet>
            <h6 className="App-essay-title">装饰与罪恶</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 四月</b>
                <br/><br/>
                粗糙的事物中蕴含了一种奇特的美。一方面，粗糙消除了不必要的装饰，如同绘画的留白让人遐想。另一方面，粗糙具有难以替代的扩展性，像达·芬奇随手涂鸦的草稿大概率比一张精细的电脑效果图的暗示和隐喻丰富得多。这种由粗糙产生的独特价值总是反复出现，不断地挑战那些精致的装饰意义何在。
                <br/><br/>
                二十世纪的现代主义建筑运动，大刀阔斧地砍掉了新古典建筑的繁复装饰，只留下建筑最纯粹的核心功能。从芝加哥到东京，平面如刀的二十世纪都市天际线是对密斯·凡·德罗那句“少就是多”最强有力的注解。诚然，装饰具有不可替代的作用，失去了一切装饰，现代建筑常被诟病抹杀了地域文化的特征。对于装饰而言，这种激进的“少”是“退步的”；但对于新建筑的发明，这种“少”是一种极大的进步，为现代科学催生的建造技艺上能再长满新的装饰趣味扫清了障碍。
                <br/><br/>
                类似的，一个值得注意的事实是，很多资深游戏爱好者对粗糙的独立游戏更情有独钟，认为其永远不能被“大厂制造”的商业游戏所取代。毫无疑问，这些玩家也会喜欢制作精良的商业大作——虽然这些作品往往成本高昂、装饰精美、细节繁复。但那些气质粗糙的独立游戏在极有限的成本下，对新技术、新表达、新思潮给了最富创造力的解答，而这些答案蕴含着不可估量的扩展性。
                <br/><br/>
                互联网世界里，美化网站的成本变得越来越低廉，这导致大量互联网产品变得让人眼花缭乱，但最重要的功能却常常被无关紧要的细节淹没。恰恰相反，很多重要的开源软件社区还依然保留着粗糙得能让人瞬间回到九十年代的网站风格。这些看着如同还没做完的网站门户，是对创造价值观的秘而不宣——摒弃复杂无用的衍生品，鼓励核心功能的贡献，保持内核简洁和扩展性。这一点像极了二十世纪的现代主义建筑风格，那些最惊人最有生命力的创造往往伪装在一副“粗糙”的外表下。
                <br/><br/>
                但人们经常把粗糙和粗陋混为一谈，最后导致为了避免粗陋，向过量无用的装饰投降。不必要的装饰像对一栋建筑施加的不必要支撑。在结构力学中，这些多余的“约束”不一定让建筑结构更安全，相反可能对结构的抗震极为有害。事实上，“松弛柔软“的建筑更容易在地震中生存下来。
                <br/><br/>
                综合看来，有价值有活力的创造成果经常是粗糙的，是生长发育的自然形态，即使这种简洁粗糙会看似原始粗粝。在以创造为目标的活动中，精致时常是有罪的，因为精致代表了过度的细节，不需要的功能，和缺乏深思熟虑的盲目扩张。维持有活力的“粗糙“需要大量的思考，未被充分思索的精美装饰会把创造者本该用来仔细推敲事物本质的精力，扔在无穷无尽的次枝末节中，宝贵的创造力被多余的装饰浪费。但剔除无用的装饰，是一个痛苦的旅程，做减法的拘束审慎和做加法的原始快感完全对立。被这种欲望绑架，会耗尽最后一丝宝贵的精力，然后铸成一条通往反创造的捷径。
                <br/><br/>
                保持粗糙意味着真正的自由变通，节俭朴素，适者生存。无时无刻都保持对无用细节的抵制，是对纯粹价值和扩展性的持续创造，即使这种做法会反对一切装饰。如果扼杀有价值的创造发育是一种罪恶，那么任何多余的装饰就是罪恶。
                <br/><br/>
            </p>

            <br/>

            <h6 className="App-essay-title">Ornament And Crime</h6>
            <p className="App-essay-article">
                <br/>
                <b>Apr 2022</b>
                <br/><br/>
                There is a peculiar type of beauty in rawness. On the one hand, a raw object clears the unnecessary
                ornament as a way great paintings leave blank space intentionally for viewers' imagination to breathe.
                On the other hand, the rawness contains an irreplaceable power to extend beyond the original sketchy
                being. The way Leonard da Vinci scribbled on a piece of paper is much more likely to hold more metaphors
                than a well-refined, computer-generated drawing. The unique value of rawness repeatedly emerges and
                keeps challenging the essence of those delicate ornaments.
                <br/><br/>
                Modern Architecture in the Twentieth Century cleansed almost every possible ornament from Neoclassic
                Architecture without hesitance, only keeping the architecture's core parts for their function purpose.
                From Chicago to Tokyo, plane, blade-sharp structures taking over the Twentieth Century urban skyline was
                a potent proof of the Mies Van Der Rohe's famous saying—less is more. Admittedly, the ornament is
                indispensable, and people constantly criticize Modern Architecture for killing cultural identities by
                omitting decorations. As it is, this radical reduction was a "regress" for ornament. Nevertheless, to
                the invention of Modern Architecture, this "purge" was a tremendous "evolution." Removing unnecessary
                decoration swept every obstacle for future ornament, incubated by the new science, to flourish again on
                top of Modern Architecture.
                <br/><br/>
                Similarly, a situation worth noting is that quite a few expert game players would prefer those "raw"
                indie games, independent games produced and funded by individual game developers, to studio-supported
                "big hits." Without a doubt, those players could and would like an exquisite "big hit," which is often
                very costly, heavily decorated, and highly polished in details. But a raw indie game unfolds powerful
                answers to new technology, new expression, and a new trend of thought under minimal funding. And these
                answers contain unlimited potential waiting to expand.
                <br/><br/>
                In the software industry, the cost of beautifying a website is diminishing every second, but only making
                users dazzled by the over-decorated product with basic buttons hidden under some flashy interface. On
                the contrary, many important open-source communities still stay behind raw web portals that look like
                the late 1990s. The seemingly "unfinished" website is a secret manifesto to the code of
                creativity—eliminating irrelevant development, encouraging contribution to the fundamental function, and
                keeping the core neat and expandable. This vibe easily resonates with Modernist Architects, where the
                most astonishing and vibrant creativity hides under a raw and plain appearance.
                <br/><br/>
                Yet, people often mix "raw" with "unrefined," therefore easily surrendering to useless and excessive
                decorations. Such unnecessary ornaments are analogous to redundant supports for architecture. A
                structure is not always more robust to have more supporting elements based on structural mechanics. The
                excessive support and rigidity could do more harm than good to a structural system during an earthquake.
                Interestingly, A "soft" structure is more likely to survive an earthquake than a stiff building.
                <br/><br/>
                Valuable and vibrant creations are usually somewhat "raw" in various situations. It is the natural
                form for growth and development, even though it could look quite sketchy. Over-refinement can often harm
                creation-driven activities since it entails too many details, useless features, and unthoughtful
                expansions. To keep a creation "raw" requires much attention since ornaments waste a creator's
                precious time by asking for endless and trivial details. Deploying that energy to refine the essential
                parts of the creation is much more rewarding. However, cleaning these useless but attractive widgets is
                a path full of hard work. The euphoria of adding items is the inverse feeling of deducting them,
                especially when the latter calls for being careful and cautious. Once surrender to that temptation will
                consume the last bit of creativity, forging a shortcut to the opposite of creation.
                <br/><br/>
                Staying "raw" means true freedom, flexibility, and being the fittest to survive. Constantly resisting
                the lure of ornamentation is to keep recreating actual value and expandability. However, this process
                might end up removing all ornaments. But if killing the potential development of creativity is a crime,
                then any extra ornament is a crime.
                <br/><br/>
            </p>
        </div>
    )
}
