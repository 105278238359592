import React from "react";
import {Helmet} from "react-helmet";

export default function Confession() {
    return (
        <div>
            <Helmet>
                <title>Confession 忏悔录</title>
            </Helmet>
            <h6 className="App-essay-title">忏悔录</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 九月</b>
                <br/><br/>
                西方历史上曾有三个人长篇大论地忏悔，他们写的内容被译作忏悔录，但这个叫法并不准确。因为他们所谓的忏悔，更多的是质问，他们的困惑比想寻求解脱的欲望还要强烈——而这，似乎不是为了对自己的过错寻求宽恕的人会做的事。他们在做的，更像是坦白地承认自己作为人究竟是什么东西：动物，人，或一种转瞬即逝的存在。基于此，说这些内容是忏悔录？更像是坦白录。
                <br/><br/>
                这三个坦白的人分别是奥古斯丁，卢梭和托尔斯泰。
                <br/><br/>

            </p>

            <br/>

            <h6 className="App-essay-title">Confession</h6>
            <p className="App-essay-article">
                <br/>
                <b>Sep 2023</b>
                <br/><br/>

            </p>
        </div>)
}
