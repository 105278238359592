import React from "react";
import {Helmet} from "react-helmet";

export default function Road() {
    return (
        <div>
            <Helmet>
                <title>秦蜀道 Qin Shu Road</title>
            </Helmet>
            <h6 className="App-essay-title">秦蜀道</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 一月</b>
                <br/><br/>
                从关中入蜀的路我开过很多遍，这条和李白出蜀入长安相反方向的道路可以同样艰苦，南下和北伐一样不容易。连接不通人烟的巴蜀和秦塞的交通技术，火车时代通过绕道安康或宝鸡；国道时代的盘山公路基本沿着先秦的古栈道的脉络展开；高速路时代就直白得多，我们能击穿山峦、接平河谷，遇山开路、遇水架桥，高速路变成了一条近乎直线的存在。但这种技术自信在秦岭的暴风雪前不堪一击，一场暴雪可以让穿越秦岭的高速直接瘫痪，因为穿山越岭的隧道并不能保住被落在隧道外面的沥青通道——它们暴露在狂风暴雪下的样子，和千年前那些纵横交错，裸露的秦岭古栈道一样脆弱无助。即使现代技术理应至少在地球上所向披靡，但温度降到零下十几度就足以摧毁这些幼稚的自信。
                <br/><br/>
                但你也会瞬间明白为什么诸葛亮计划北伐应从四川盆地取道广元，从剑阁入阳平关，进入汉中后从宝鸡沿着陈仓道的古老阴影进入关中，这个选择尊重了秦岭的巍峨然后尽力避开它的锋芒。再往前看，你即使没有汉王的权谋，也会像今天的我一样，在大自然的教导下得出“别走栈道，走陈仓”的结论。
                <br/><br/>
                秦岭和大巴山是两座巨大的屏障，关中盆地-秦岭-汉中盆地-大巴山-四川盆地，让气候像灯泡开关一样被操作得泾渭分明，跨过一座山头不仅是另一片云彩支配的晴雨表，还是天差地别的另一种气候，另一种语言，另一种气魄和风度，以及另一种食物：面，米和面，米。

            </p>

            <br/>

            <h6 className="App-essay-title">Qin Shu Road</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2023</b>
                <br/><br/>

            </p>
        </div>)
}
