import React from "react";
import {Helmet} from "react-helmet";
// import Modal from '@mui/material/Modal';
import P1 from "../../assets/images/GPTImpact/1.png";
import P2 from "../../assets/images/GPTImpact/2.png";
import P3 from "../../assets/images/GPTImpact/3.png";
import P4 from "../../assets/images/GPTImpact/4.png";
import P5 from "../../assets/images/GPTImpact/5.png";
import P6 from "../../assets/images/GPTImpact/6.png";
import P7 from "../../assets/images/GPTImpact/7.png";
import P8 from "../../assets/images/GPTImpact/8.png";
import P9 from "../../assets/images/GPTImpact/9.png";
import P10 from "../../assets/images/GPTImpact/10.png";
import P11 from "../../assets/images/GPTImpact/11.png";
import P12 from "../../assets/images/GPTImpact/12.png";
import P13 from "../../assets/images/GPTImpact/13.png";
import P14 from "../../assets/images/GPTImpact/14.png";
import P15 from "../../assets/images/GPTImpact/15.png";
import P16 from "../../assets/images/GPTImpact/16.png";
import P17 from "../../assets/images/GPTImpact/17.png";
import P18 from "../../assets/images/GPTImpact/18.png";
import P19 from "../../assets/images/GPTImpact/19.png";
import P20 from "../../assets/images/GPTImpact/20.png";
import P21 from "../../assets/images/GPTImpact/21.png";
import P22 from "../../assets/images/GPTImpact/22.png";
import P23 from "../../assets/images/GPTImpact/23.png";
import P24 from "../../assets/images/GPTImpact/24.png";
import P25 from "../../assets/images/GPTImpact/25.png";
import P26 from "../../assets/images/GPTImpact/26.png";
import P27 from "../../assets/images/GPTImpact/27.png";
import P28 from "../../assets/images/GPTImpact/28.png";
import P29 from "../../assets/images/GPTImpact/29.png";
import P30 from "../../assets/images/GPTImpact/30.gif";
import P31 from "../../assets/images/GPTImpact/31.png";
import Pro from "../../assets/images/GPTImpact/pro.png";
import Vis from "../../assets/images/GPTImpact/viscalc.png";
import Robot from "../../assets/images/GPTImpact/robot.png";


export default function SelfMotivation() {
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    return (
        <div>
            <Helmet>
                <title>GPT对产品界面的影响 GPT's Impact On Product Interface</title>
            </Helmet>
            <h6 className="App-essay-title">GPT对产品界面的影响</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 六月</b>
                <br/><br/>
                <h5 style={{ fontWeight: 'bold' }}>1. 背景信息</h5>
                <br/>
                <h6 style={{ fontWeight: 'bold' }}>1.1 大模型为什么重要</h6>
                <br/>
                作为大语言模型（large language model, LLM）的一种，OpenAI的GPT (Generative Pre-Trained Transformer)模型展现了今天最接近人类的机器智能，GPT最重要的特征是通过使用万亿级的参数和全互联网的文本数据，使计算机模型产生了智能涌现（Emergence）。物理学的涌现一般指混沌现象里，反复出现某些稳定的模式，涌现是理解复杂的自然现象中最具挑战的一类。从最近OpenAI的掌门人Sam Altman和首席科学家Ilya的访谈中可以了解，如何驯服涌现出智能的大语言模型，以及如何让它稳定安全地服务人（Alignment，对齐），OpenAI也没有完全掌握有效的调试方法，GPT从某种程度上还是个黑盒。
                <br/><br/>
                我们有必要问，为什么一个语言模型会改变世界，ChatGPT确实更会听话，也更会说话了，但一个智能文本交互工具有什么了不起？原因有两个：1. 人工智能有多种模态（Modal），不同模态间的研究，相互渗透也相互竞争，性能最出色的模态会最先定义未来AI应用的轨迹，ChatGPT展现的文本模态智能，会在近期AI的发展中占主导地位。 2. 文本作为人类社会的入口非常重要。
                <br/><br/>
                <b>第1点，多模态。</b>以图像作为模态的人工智能，已发展了多年，在图像识别和自动驾驶上屡建奇功的计算机视觉（Computer Vision）是人工智能的另一个入口。过去十几年三大会CVPR/ICCV/ECCV的论文大爆发，即使去掉水货也是图像智能大爆炸的缩影。而OpenAI的出现扭转了图形智能在AI应用中的瞩目地位，甚至发展轨迹——当Meta发布Segment-Anything（分割图片中不同物体的图形算法），因模型表现出类似GPT在文本模态上强大的零成本迁移能力，有人也惊呼传统CV已死（夸张了）。
                <br/><br/>
                ChatGPT在文本模态上的影响力无需赘述，它超越了简单的科研价值，重新定义文本模态的智能和商业潜力。OpenAI的产品DALL·E，也提供了文本模态外的图像智能。开源的Stable Diffusion和闭源的Midjourney作为文生图领域的霸主，更贡献了无数个创造力行业的”死亡“预警。总而言之，AI在文本和图像两个模态上相互渗透，并在相互竞争中推动智能的边界。
                <br/><br/>
                <b>- OpenAI的文本+图像模态：ChatGPT + DALL·E</b>
                <br/><br/>
                {/*<img src={P1} alt="" width={"100%"} onClick={handleOpen}/>*/}
                {/*<Modal*/}
                {/*    open={open}*/}
                {/*    onClose={handleClose}*/}
                {/*    aria-labelledby="modal-modal-title"*/}
                {/*    aria-describedby="modal-modal-description"*/}
                {/*>*/}
                {/*    <img src={P1} alt="" width={"100%"}/>*/}
                {/*</Modal>*/}
                <img src={P1} alt="" width={"100%"}/>
                <br/><br/>
                <b>- Stable Diffusion Web UI （图像模态）：</b><a href="https://github.com/AUTOMATIC1111/stable-diffusion-webui" rel="noreferrer noopener" target="_blank">stable-diffusion</a>
                <br/><br/>
                <img src={P2} alt="" width={"100%"}/>
                <br/><br/>
                <b>- Midjourney（图形模态）：</b><a href="https://www.midjourney.com/home/?callbackUrl=%2Fapp%2F" rel="noreferrer noopener" target="_blank">Midjourney</a>
                <br/><br/>
                <img src={P3} alt="" width={"100%"}/>
                <br/><br/>
                <b>第2点，文本模态是人类社会的入口。</b>可以参考尤瓦尔·赫拉利（《人类简史》作者）在The Economist专访中的观点：他认为，语言作为人类社会的操作系统，人工智能已经黑入（Hacked）这个系统，AI会通过改变语言这个操作系统本身，彻底改变人类历史。大模型对人类行为和社会反馈的调度，会因为语言系统的入侵而产生巨大影响。
                <br/><br/>
                更多请参考： <a href="https://www.economist.com/by-invitation/2023/04/28/yuval-noah-harari-argues-that-ai-has-hacked-the-operating-system-of-human-civilisation" rel="noreferrer noopener" target="_blank">yuval-noah-harari-argues-that-ai-has-hacked-the-operating-system-of-human-civilisation</a>
                <br/><br/>
                <img src={P4} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>1.2 LLM的成本</h6>
                <br/>
                训练一个真正称得上大的大模型到底需要多少钱？
                <br/><br/>
                首先，不考虑图像和视频，大模型至少需要整个互联网的文本数据；需要上万张A100起步；所需电力的计算能耗将成为不容忽视的成本；试错的代价不可控：以数月计的训练时间和人力成本；模型训练和精确微调的方法未知或不公开，大模型还是个黑盒。这些原因叠加起来，导致这个地球上能拥有大模型的公司屈指可数，因为它需要极为雄厚的财力和极高的风险承受能力，不仅初级玩家无法参与，缺乏冒险精神的大公司也不配拥有。
                <br/><br/>
                伊隆·马斯克在最近一次访谈中估算，训练一个GPT-5等级的模型，可能用到30000-50000块H100芯片，使用最新的技术架构，最顶级的AI研究人员（参考OpenAI，约200多人）。最后马斯克给出了大模型的起步成本。对比最近大模型Startup的估值，这个数字很有参考价值：
                <br/><br/>
                <p style={{ color: 'red', textAlign: 'center', fontSize: '18px', fontWeight: 'bold'}}>2.5亿美元</p>
                <br/>
                <h6 style={{ fontWeight: 'bold' }}>1.3 机会</h6>
                <br/>
                腾讯2023年股东大会上，腾讯CEO马化腾回应有关ChatGPT和AI相关提问时表示，“我们最开始以为（人工智能）是互联网十年不遇的机会，但是越想越觉得，这是几百年不遇的、类似发明电的工业革命一样的机遇。”马化腾表示，互联网企业在AI领域都有很多的积累，腾讯也一样在埋头研发，但是并不急于早早做完，把半成品拿出来展示。“对于工业革命来讲，早一个月把电灯泡拿出来，在长的时间跨度上来看是不那么重要的。关键还是要把底层的算法、算力和数据扎扎实实做好，而且更关键的是场景落地，目前（我们）还在做一些思考。我感觉现在有很多公司太急了，感觉是为了提振股价，我们一贯不是这种风格。”
                <br/><br/>
                综合说，股价可以不急提振，前路漫漫不急创新，大模型不是新应用，它是革命本身。
                <br/><br/>
                我也有一些观点。晚一个月把电灯泡拿出来和早一个月的区别很大，区别在于你会成为爱迪生还是某个无人知晓的第二个发明灯泡的人。但是，大模型虽然能力惊人，但驯化改良的挑战依然艰巨。我们正处于刚造出飞机时的挑战期，想安全稳定地飞行，还需要许多失败的血汗教训理解红线在哪里。OpenAI推出了Plugin 插件，是潜在的一种产品方式，但Plugin目前的商业表现并不清晰，Plugin预期引发的App Store时刻也不明朗，如何把GPT变成有商业价值的产品仍是未知数。这么多年，鹅厂作为后发优势（second mover advantage）的弄潮儿，拥有微创新的杀手锏，发挥自己长处也未必不合理。
                <br/><br/><br/>
                <h5 style={{ fontWeight: 'bold' }}>2. GPT引起人机交互层（HCI/UI）的变化</h5>
                <br/>
                User Interface，用户交互界面，简称UI。今天所有人都生活在UI的海洋里，不少互联网人认为UI ≈ 网页Web + App设计，这种理解极大限制了UI的内涵。UI更专业的定义应该叫做HCI，Human-Computer Interface，人机交互界面。在过去将近一个世纪的发展中，人们根据当时机器的计算能力与智能水平，设计了几代特征明显的UI。我们正处在GUI向NLI的过渡阶段。
                <br/><br/>
                -PCI: Punched Card Interface，打孔卡交互界面
                <br/>
                -CLI: Comand Line Interface，命令行交互界面
                <br/>
                <b>-GUI: Graphic User Interface，图形交互界面</b>
                <br/>
                <b>-NLI: Natural Language Interface，自然语言交互界面</b>
                <br/>
                -BCI: Brain Computer Interface，脑机交互界面
                <br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>2.1 PCI, Punched Card Interface 打孔卡界面</h6>
                <img src={P5} alt="" width={"100%"}/>
                <br/>
                <img src={P6} alt="" width={"100%"}/>
                <br/><br/>
                <b>上图：</b>一叠打孔卡，上面存放着一段程序。
                <br/>
                <b>下图：</b>1950年美国职员正在制作打孔卡片，卡片上存储着一段美国人口调查数据。
                <br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>2.2 CLI, Command Line Interface 命令行界面</h6>
                <img src={P7} alt="" width={"100%"}/>
                <br/><br/>
                编程语言进一步封装和显示设备出现，命令行工具成为电脑最重要的交互界面。CLI操作高效，功能强大。
                <br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>2.3 GUI, Graphic User Interface 用户图形界面</h6>
                乔布斯从施乐公司（Xerox）“偷来”的用户图形化界面 GUI，掀起了个人电脑革命。
                <img src={P8} alt="" width={"100%"}/>
                <br/><br/>
                这层界面的影响力极大，在GUI上诞生了世界上第一款杀手级应用，Macintosh的电子表格 VisiCalc，也是Excel的前身。
                <img src={Vis} alt="" width={"100%"}/>
                <br/><br/>
                直到今天，Mac优美流畅的UI界面依旧是最吸引用户的产品特性之一。
                <img src={P9} alt="" width={"100%"}/>
                <img src={P10} alt="" width={"100%"}/>
                <br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>2.4 NLI, Natural Language Interface 自然语言界面</h6>
                <b>1. Text to Text 文生文</b>  https://openai.com/chatgpt
                <img src={P11} alt="" width={"100%"}/>
                <br/><br/>
                <b>2. Text to Image 文生图</b>  https://openai.com/dall-e-2
                <img src={P12} alt="" width={"100%"}/>
                <br/><br/>
                <b>3. Text to Video 文生视频</b>
                <br/>
                Runway：<a href="https://runwayml.com/" rel="noreferrer noopener" target="_blank">Advancing creativity with artificial intelligence.</a>
                <br/>
                <img src={P13} alt="" width={"100%"}/>
                <br/><br/>
                说一句话: “A beautiful living room concept render.”  “生成一个漂亮的起居室概念渲染。”
                <br/><br/>
                <b>4. Text to Action 文生行为</b>
                <br/>
                Adept: <a href="https://www.adept.ai/" rel="noreferrer noopener" target="_blank">Useful General Intelligence</a>
                <img src={P14} alt="" width={"100%"}/>
                <br/><br/>
                Adept的目标，是通过软件自动化构建一个全能的智能助手。自然语言，将是Adept用户未来唯一需要使用的交互内容。
                <br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>2.5 BCI, Brain Computer Interface 脑机界面</h6>
                Thought to Action，从人类思维到机器行为。去年疯狂宣传的NeuroLink，可以让猴子用意念玩游戏Pong，人也可以用脑机接口操纵简单的游戏和机械假肢。现阶段，更有意义的脑机产品主要帮助残疾人控制义肢，恢复生活能力。今天的脑机技术对于我们讨论革命性的人机交互界面，还为时略早。
                <br/><br/>
                <img src={P15} alt="" width={"100%"}/>
                <img src={P16} alt="" width={"100%"}/>
                <br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>2.6 小结</h6>
                <b>-UI的内涵需要被扩展</b>
                <br/><br/>
                机器与人的沟通需要一层交互介质，这层介质控制着人机交互中输入输出的边界。交互介质会过滤转换人类的输入，让这些千奇百怪的人类输入，对机器而言是安全可识别的；同时，机器返回的结果经过交互介质的过滤转换，对人类也是安全可用、有价值的。
                <br/><br/>
                这层连接人与机器的交互介质，才是UI的定义。
                <br/><br/>
                过去二十年的互联网革命中，GUI以按钮、拖拽、滑轮、手指缩放、多指操作、晃动、翻转、硬件按钮…等有限的操作形式，规范了人想对机器做的一切输入方式，这个经过标准化的input被机器理解，然后返回成标准化的output。PC和移动互联网革命让UI和GUI画了等号，但事实上UI远比GUI现有的交互方式丰富得多。
                <br/><br/>
                <img src={P17} alt="" width={"100%"}/>
                <br/><br/>
                GPT的出现直接破坏了这个平衡，机器变聪明对产品最重要的影响，就是计算机对自然语言的容错性大大提升，它不再需要一个只能接收极有限输入的过滤器，就能听懂人们日常会说的，甚至夹杂着各种逻辑、暗示、讽刺、错误的自然语言。AI对自然语言的容错性提高，一定会破坏当下以GUI作为UI的交互层：
                <br/><br/>
                1. 用户体验（UX）大转换。用户从过去以手指、鼠标的“点击、滑动、拖拽”为主要方式的交互，转变为自然语言为接口的交互。
                <br/><br/>
                2. 现在的GUI会消失吗？不会，两个原因。第一，当模型不够准确，或AI产品化不成熟时，GUI优雅的外观和体验依然对用户有吸引力，以及用手指、鼠标做交互的成本，远比自然语言低。第二，参考UI发展的不同阶段，黑乎乎的命令行落伍了吗？没有，GUI不会立刻消失。如果使用上个时代的交互界面更高效，即使使用门槛较高，这种交互依然会存在。
                <br/><br/>
                3. 命令行工具（CLI）依然是对计算机进行深度操作最高效的方式。在智能未来，如果你需要深度操作一项应用，可能会有人说：把你的GUI打开，就像今天程序员说：把你的terminal打开。
                <br/><br/>
                4. 人机交互界面，会朝着对计算机操作深度变浅，换取使用门槛降低的方向发展。大模型将要引发的这次变化也一样。你可以看到这个趋势：命令行CLI - 图形界面GUI - 自然语言NLI - 脑机接口 BCI，这个走向对计算机深度操作的能力越来越低，也让用户使用的门槛越来越低。
                <br/><br/>
                5. 最优秀的计算机工程师，对机器的深度理解和深度操作能力无法被替代，但也只有最优秀的那群工程师能生存下来。
                <br/><br/>
                从下面这个图能更清楚地看到，为什么GPT会引发产品UI的巨大变动？因为过去的机器语言一直非常苛刻，容错性极低。编程语言中一个标点错误都能导致整个程序无法运行。而大模型带来的最重要的魔力就是显著提高机器对人类自然语言（Natural Language, NL）的容错性。总结下来，未来的自然语言交互界面以文字输入框为起点，以多模态的高动态交互为目标。
                <br/><br/>
                <img src={P18} alt="" width={"100%"}/>
                <br/><br/>
                <b>使用门槛：</b>离人类越近，使用门槛越低。命令行 CLI &gt; 图形界面 GUI &gt; 自然语言 NLI &gt; 脑机接口 BCI
                <br/>
                <b>操作效率：</b>离机器越远，控制效率越低。命令行 CLI &gt; 图形界面 GUI &gt; 自然语言 NLI &gt; 脑机接口 BCI
                <br/><br/>
                <b>-NLI的演化</b>
                <br/><br/>
                <b>·开端：</b>文字输入框
                <br/>
                <b>·发展：</b>多模态输入框，语音、图像、视频
                <br/>
                <b>·目标：</b>输入 - 多模态的文字、声音、图像、视频 => 返回 - 有用的Text、Voice、Image、Video + 有用的软件行为。
                <br/><br/>
                <i>Vision pro能提供什么模态：三维的交互，手势、重力、旋转、语音文字、静态图像、实时视频。</i>
                <br/><br/>
                <img src={Pro} alt="" width={"100%"}/>
                <br/><br/>
                <b>·未来：</b>与人类对话只是大模型理解世界的起点，用LLM作为大脑，摄像机做眼睛，机械臂做四肢，是AI与物理世界交互的新界面。
                <br/><br/>
                <b>-Timberter——一款已存在多年、基于视觉算法的“清点木材”应用。</b>如果我们给它加上大模型的推理能力，可执行搬运的机械臂，会发生什么？
                <br/>
                <img src={P19} alt="" width={"100%"}/>
                <img src={P20} alt="" width={"100%"}/>
                <br/><br/>
                <b>-通过语音控制的机器人？</b>
                <a href="https://www.youtube.com/watch?v=FPM30gXYtc4" rel="noreferrer noopener" target="_blank">OpenAI GPT-4 Whisper语音接口</a>
                <br/>
                <img src={Robot} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5 style={{ fontWeight: 'bold' }}>3. AI生态</h5>
                <br/>
                <h6 style={{ fontWeight: 'bold' }}>3.1 福布斯AI 50</h6>
                <br/>
                Forbes近年来会评选年度最有潜力的50家AI公司。与往年不同，今年的AI公司名单不仅来自北美地区，而是从来自全球800多个公司中选出最具潜力和估值的50个，来自美国、加拿大、以色列、英国和日本。
                <br/><br/>
                下面是我整理的完整名单，OpenAI，Jasper，Hugging Face，Adept……你耳熟能详的AI创业公司里面都有。感兴趣的同学自己去Forbes网站看吧，不展开讨论了。<a href="https://www.forbes.com/lists/ai50/?sh=58cc19b0290f" rel="noreferrer noopener" target="_blank">Forbe AI 50</a>
                <br/>
                <img src={P21} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>3.2 More AI Startups</h6>
                <br/>
                使用场景主要集中在C端：生成式Text、Audio、Image、Video + 搜索 Search + 自动化 Copilt。B端应用多以整合为主，可以是具体的行业垂类：法律、医药健康、学术研究（生物、物理、数学）、智能分析。此外，还有AI基础设施：向量数据库、大模型AI Model、AI安全、开发运维DevOps、自动化Copilt。
                <br/><br/>
                下图包含了更多AI生成类的公司名单（2023年3月，美国VC的视角），感兴趣的同学自行体验吧。
                <img src={P22} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5 style={{ fontWeight: 'bold' }}>4. 大模型与产品的整合</h5>
                <br/>
                <h6 style={{ fontWeight: 'bold' }}>4.1 整合成本</h6>
                <br/>
                这里的整合成本不只是讨论把AI整合进产品的开发成本，这个整合成本更在于：用户通过使用AI智能应用，在完成和过去同等质量的工作所需要的学习和时间成本。当整合成本显著小于原有成本（开发运营成本+用户使用成本），AI应用才有价值。
                <br/><br/>
                <p style={{ color: 'red', textAlign: 'center', fontSize: '16px', fontWeight: 'bold'}}>整合成本 = <br/> AI产品开发成本 + 用户在AI应用完成过去同等质量工作的使用成本</p>
                <br/>
                举两个例子说明整合成本的意义。
                <br/><br/>
                <b>正面：AIGC生成游戏设计的填充素材/物料。</b>
                <br/><br/>
                游戏设计开发中存在劳动密集型工作，准备填充素材，NPC角色对话，风格切换，边缘场景……这类工作对原创性的要求不高，但需要的时间成本并不能显著降低。
                <br/><br/>
                如果使用AI工具生成此类非重要的素材，最后由原本就经验丰富设计师调整，想达到过去同等质量的结果，是完全可行的。
                <br/><br/>
                AI生成非关键素材的整合成本 &lt; &lt; &lt; 传统物料的准备成本
                <br/><br/>
                AIGC在游戏物料场景下值得被推动。
                <br/><br/>
                <b>反面：使用一站式AI生成高级广告的解决方案。</b>
                <br/><br/>
                虽然AI广告解决方案看似降低了过去广告创作过程中文字、图像、视频的生成成本，但一个真正有魅力的高级广告往往需要极其大量的定制创作和二次修改。
                <br/><br/>
                所以，当真正的用户（使用AI创作的广告服务商，或想淘汰广告商的广告需求方），对AI生成的广告内容进行二次调整时，要达到同过去广告同等质量的水准，需要付出的调整成本比传统方式高得多，甚至多数时候完全不可能做到同等质量。
                <br/><br/>
                高端广告的AI整合成本 &gt; &gt; &gt; 传统广告的原有成本。
                <br/><br/>
                AI一站式服务解决高级定制的广告创作，在当下不现实。
                <br/><br/>
                当然，随着模型表现的进步，甚至市场手段和市场偏好发生变化（精准营销，定制偏好）。现在的反面案例会变成正面案例，正面也可能成反面。
                <br/><br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>4.2 整合方式</h6>
                <br/>
                大模型会推动两类产品的发生。一种围绕AI能力打造全新的产品，可以称之为AI Naive/AI原生。另一种是在传统软件上增加AI功能，进行智能化改造，可以叫AI升级/AI Upgrade。这两种方式会形成不同的产品UI。
                <br/><br/>
                整合AI产品中最重要的因素：
                <br/>
                1. AI模型的性能表现
                <br/>
                2. 开发运维AI产品的成本 + 用户使用AI应用的使用成本 （整合成本）。
                <br/><br/>
                <img src={P23} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h5 style={{ fontWeight: 'bold' }}>5. AI 工具资料</h5>
                <br/>
                AI应用的构成：基础设施 Infra + 中间件 Middleware + 应用 Application（这些定义可以相互重叠）
                <br/>
                <img src={P24} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>5.1 AI应用 | Application</h6>
                <br/>
                -搜索引擎：New Bing、Google Bard
                <br/><br/>
                -聊天问答：ChatGPT、Jasper、各类智能聊天应用
                <br/><br/>
                -文生图片：Midjourney、Stable Diffusion
                <br/><br/>
                -文生视频：Runway
                <br/><br/>
                -自动化：Adept
                <br/><br/>
                ……
                <br/><br/>
                更多工具参考（国内）：<a href="https://aitoolbox.cn/" rel="noreferrer noopener" target="_blank">AI工具箱|AI工具集合|AI网站导航</a>
                <br/>
                <img src={P25} alt="" width={"100%"}/>
                <br/><br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>5.2 中间件 | Middleware</h6>
                <br/>
                大模型是一个基础模型（Foundation Model），拥有最广泛的知识，展现出强大的泛化能力（Generalization），但在精确场景下的准确率不足。这也是如何应用大模型的主要挑战。中间件的意义就在于整理专业场景的知识，拓展模型的知识库，提高AI精度，最后提供方便可用的接口连接上层应用。因为自然语言界面成本很低，很多中间件直接提供了应用界面，一个聊天窗口，比如第一个AgentGPT。
                <br/><br/>
                -AgentGPT
                <br/>
                https://github.com/reworkd/AgentGPT
                <br/>
                https://agentgpt.reworkd.ai/
                <img src={P26} alt="" width={"100%"}/>
                <img src={P27} alt="" width={"100%"}/>
                <br/><br/>
                更多中间件工具：
                <br/>
                -Langchain: https://github.com/hwchase17/langchain
                <br/><br/>
                -AutoGPT: https://github.com/Significant-Gravitas/Auto-GPT
                <br/><br/>
                -BabyAGI：https://github.com/yoheinakajima/babyagi
                <br/><br/>
                -HuggingGPT: https://github.com/huggingface/transformers
                <br/><br/>
                ……
                <br/><br/>
                类似的中间件还有很多，不一一列举。
                <br/><br/><br/>
                <h6 style={{ fontWeight: 'bold' }}>5.3 基础设施 | Infra</h6>
                <br/>
                <b>-模型 Model</b>
                <br/><br/>
                大模型：OpenAI GPT、Google Bard、Anthropic、文言一心、百川智能……
                <br/><br/>
                开源模型：
                <br/><br/>
                LLama: https://github.com/facebookresearch/llama
                <br/><br/>
                Alpaca: https://github.com/tatsu-lab/stanford_alpaca
                <br/><br/>
                Vicuna: https://lmsys.org/blog/2023-03-30-vicuna/
                <br/><br/>
                GPT4ALL: https://github.com/nomic-ai/gpt4all
                <br/><br/>
                ChatGLM: https://github.com/THUDM/ChatGLM-6B
                <br/><br/>
                Baichuan-7B: https://github.com/baichuan-inc/baichuan-7B
                <br/><br/>
                ……
                <br/><br/>
                Stable Diffusion（图像模态）: https://github.com/AUTOMATIC1111/stable-diffusion-webui
                <br/><br/>
                <b>-数据库 Database</b>
                <br/><br/>
                参考OpenAI推荐的向量数据库： https://platform.openai.com/docs/guides/embeddings/how-can-i-retrieve-k-nearest-embedding-vectors-quickly
                <br/><br/>
                <img src={P28} alt="" width={"100%"}/>
                <br/><br/>
                Zilliz有开源产品 Milvus： https://github.com/milvus-io/milvus
                <br/><br/>
                <b>-编译运行 Compilation & DevOps</b>
                <br/><br/>
                如何在本地设备和低端设备上运行大模型，是分发AI能力的一个障碍。
                <br/><br/>
                MLC-LLM（Machine Learning Compilation-LLM）是一个面向ML的编译工具。它能让大模型在本地运行。 https://mlc.ai/mlc-llm/
                <br/><br/>
                使用体验如下。在本地环境中通过conda安装mlc-chat-cli-nightly工具，从Hugging Face下载Model，在本地Mac上运行大模型进行问答：
                <br/><br/>
                <img src={P29} alt="" width={"100%"}/>
                <br/><br/>
                在本地移动设备上运行大模型：
                <br/><br/>
                <img src={P30} alt="" style={{ width: '50%', alignSelf: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}/>
                <br/><br/><br/>
                <h5 style={{ fontWeight: 'bold' }}>6. 结语</h5>
                <br/>
                大模型：闭源大模型如GPT，挑战在于模型场景化、数据安全、准确率、调试效率、Prompt Engineering、工程接口的对接。自部署开源模型，不属于严格意义的大模型，挑战在于性能，基准表现。
                <br/><br/>
                中间件：连接模型层与应用层，提供特定领域的知识外挂；拓展应用场景，快速提供应用接口；降低开发和运维成本。
                <br/><br/>
                应用层：使用场景对模型表现的容忍度，收益 = 使用价值 - 整合成本；危害应对：AI幻觉、AI安全。
                <br/><br/>
                用户界面，User Interface，是连接人与计算机的强力粘合剂，产品设计就发生在这个界面上。GPT引发的革命会对产品UI产生巨大影响，文章内容是我过去几个月对AI相关信息的研究整理。这条进化路线需要考虑模型表现是不完美的，从文本交互出发，扩展出丰富多样的多模态，用新的交互体验满足古老的和全新的需要。
                <br/><br/>
                AI革命最核心的商业问题永远是，What's That Interface？
                <br/>
                <img src={P31} alt="" width={"100%"}/>
                <br/><br/>
                再用列侬的这句话做结尾：<i>Everything will be OK in the end. If it's not OK, it's not the end.</i>


            </p>

            <br/>

            <h6 className="App-essay-title">GPT's Impact On Product Interface</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jun 2023</b>
                <br/><br/>

            </p>
        </div>)
}
