import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Box, Container, Grid} from "@mui/material";

function Header() {
    const location = useLocation();
    const {pathname} = location;
    // console.log(pathname);
    const pathSplit = pathname.split("/");
    // console.log(pathSplit);

    return (
        <Box>
            <Container maxWidth={"xs"}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={"App-header"}>
                            <div className='App-header-name'>
                                <p style={{
                                    margin: "0px",
                                    padding: "0px",
                                }}>KUN CHENG</p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className='App-header-navs'>
                            <Link to="/"
                                  className={pathSplit[1] === "" ? "App-header-nav-stayActive" : "App-header-nav"}>Home</Link>
                            <Link to="/sketch"
                                  className={pathSplit[1] === "sketch" ? "App-header-nav-stayActive" : "App-header-nav"}>Sketch</Link>
                            <Link to="/essay"
                                  className={pathSplit[1] === "essay" ? "App-header-nav-stayActive" : "App-header-nav"}>Essay</Link>
                            {/*<Link to="/web3"*/}
                            {/*      className={pathSplit[1] === "web3" ? "App-header-nav-stayActive" : "App-header-nav"}>Web3</Link>*/}
                            <Link to="/research"
                                  className={pathSplit[1] === "research" ? "App-header-nav-stayActive" : "App-header-nav"}>Research</Link>
                            {/*<Link to="/thoughts"*/}
                            {/*      className={pathSplit[1] === "thoughts" ? "App-header-nav-stayActive" : "App-header-nav"}>Thoughts</Link>*/}
                            <Link to="/game"
                                  className={pathSplit[1] === "game" ? "App-header-nav-stayActive" : "App-header-nav"}>Game</Link>
                            <Link to="/contact"
                                  className={pathSplit[1] === "contact" ? "App-header-nav-stayActive" : "App-header-nav"}>Contact</Link>
                        </div>
                    </Grid>
                    <Grid item xs={5}></Grid>

                    <Grid item xs={12}>
                        <div className={"App-header"}>
                            <div style={{
                                borderBottom: "solid 1px",
                                paddingBottom: ".6rem",
                                color: "lightgray",
                                margin: "0px",
                            }}/>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Header;
