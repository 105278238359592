import React from "react";
import {Helmet} from "react-helmet";

export default function DoomButWill() {
    return (
        <div>
            <Helmet>
                <title>注定死亡但会活着 Doomed to Die But Will Live</title>
            </Helmet>
            <h6 className="App-essay-title">注定死亡但会活着</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 十二月</b>
                <br/><br/>
                当硝烟升起，炮火响起时，你知道，你死定了。
                <br/><br/>
                当革命的号角吹响时，你知道，你死定了。
                <br/><br/>
                当比特币像跳水般优美的曲线落下时，你知道，你死定了。
                <br/><br/>
                如果你不是个dog person，那你肯定是个cat person，如果你两者都不是，你知道，你死定了。
                <br/><br/>
                如果你不是黑的，那你就一定是白的，如果你两者都不是，你知道，你死定了。
                <br/><br/>
                如果没有选择向左走，你就一定会向右走，你裹足不前等待机会，你知道，你死定了。
                <br/><br/>
                实际的、具体的、科学的，是流于下乘的、下流的；唯心的、形而上的，才是性感的。如果你既不下流又不性感，你知道，你死定了。
                <br/><br/>
                那只褐色的蚊子，它歇在米色的瓷砖上，一动不动，想把自己埋藏在那片乳色的光晕中。但被我看见，你知道，你死定了。
                <br/><br/>
                长辈像老佛爷一样关注你的宫闱秘事，旁敲侧击暗示你，要按既定的计划进行火箭发射和太空舱回收的任务。一切都那么体面到位，那么贴心，让你忍不住怀疑，火箭发射的失败或许真的不是技术操作的不确定性带来的失误，而是一场人为酿造且还在酝酿的走火。你知道，你死定了。
                <br/><br/>
                大家在卧室和殿堂的隐秘角落中窃窃私语，所有人都比伊隆·马斯克更希望看到，星际穿越之后，火星上竖起巨大的氧气罩，虽然没人相信这是真的。你知道，你死定了。
                <br/><br/>
                但，无论如何，你都还活着，哪怕暂时的，但会永远暂时地活着。

            </p>

            <br/>

            <h6 className="App-essay-title">Doomed to Die But Will Live</h6>
            <p className="App-essay-article">
                <br/>
                <b>Dec 2022</b>
                <br/><br/>


            </p>
        </div>)
}
