import React from "react";
import {Helmet} from "react-helmet";

export default function CrossedNorthAmerica() {
    return (
        <div>
            <Helmet>
                <title>横跨北美 Crossed North America</title>
            </Helmet>
            <h6 className="App-essay-title">横跨北美</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二一 六月</b>
                <br/><br/>
                <h5><b>开始</b></h5><br/>
                穿过锈带，从钢铁丛林到草原风。
                <br/><br/>
                纽约——费城——匹兹堡——克利夫兰——加里——芝加哥。
                <br/><br/><br/>
                <h5><b>漫游中部</b></h5><br/>
                中午离开芝加哥，开了一个钟头黄土飞扬的玉米地才上洲际公路。刚进威斯康星州没多久，歌单循环过两遍就再也不想听了。关了音乐，只听见发动机的轰鸣，混合着狂风呼啸、变道时轮胎压在车道线上的嘶吼，远远看见天空低云威压。很快猛烈的暴风雨拍得视线模糊，高速上只能以不到四十的速度开着双闪慢慢走，直到走出那朵乌云。狂风劲雨过后，天空映着淡淡的粉色，几朵薄云飘着，车子被雨水洗得闪闪发亮，让人耳目一新。
                <br/><br/>
                一路向西追着太阳开，早已换了中部时区，多出一个小时来，太阳也迟迟不肯落下。晚上要到双子城，但中途一时兴起，在欧克莱尔早早下了洲际公路。欧克莱尔绝不是什么旅游景点，但本次旅行的目的也正在于此，对著名景点避之唯恐不及。反正如果以每天超过三百英里的速度在大地上疾驰，所到之处，皆是异乡。威斯康星大学的一个校区在欧克莱尔，按理说镇子应该很有生气。但正值暑假毕业，也可能是疫情打击，镇上没什么学生，在镇里闲逛如同外星人闯入一个陌生星球，一切都井井有条，但又似乎已经废弃多年，非常寂寞。雨后黄昏的天空像隔了八十年代的茶色玻璃，让整个镇子都被人遗忘。
                <br/><br/>
                离开欧克莱尔继续向西，一路上都在找羌笛何须怨杨柳的分界线，但很明显不在威斯康星，也更不会在明尼苏达。这种感觉在密苏里有，穿过圣路易斯的大拱门有西进运动的仪式感。可能羌笛在离开芝加哥，在伊利诺伊郊区玉米面一样褐黄的尘土落下之后，就已经吹过了。等快到双子城，铅蓝色云远处的天空倒映沉在地平线下的夕阳余辉，已然是一番西北风光。明天跨过密西西比河，就是准意义上的西部了。
                <br/><br/>
                芝加哥——欧克莱尔——双子城
                <br/><br/><br/>
                <h5><b>漆黑旷野</b></h5><br/>
                看到洲际公路90 west像看见到亲人，U.S. 212国道不适合胆小的人夜开。漆黑一片的蒙大拿旷野上，天黑之后四个钟头没信号也没见过人，让人本能的恐惧。已到山地时间，又多一个钟头。今天的最后四个钟头比前面加起来还难忘，起来写白天的事。
                <br/><br/><br/>
                <h5><b>初入西部</b></h5><br/>
                上午在双子城闲逛，在圣保罗逛市区看见科学教的教堂，即使信奉最广泛意义上的科学，也可能会对这个教派的态度有所保留。顺着科学教望过去，能远远看到修得如同天主教堂一般的明尼苏达州政府，充满了奇特的暗示性。从密西西比河边的小径看跨河的旧拱桥，刺鼻的沼泽味提醒我这条装满美国故事的母亲河和百年前一模一样。从明尼阿波利斯跨过密西西比，站在河上看到双子城的巨型钢构，后工业时代的工厂和烟囱都改成艺术中心了，但并不妨碍它们展示五大湖边的能源和钢铁有多丰富。
                <br/><br/>
                跨过密西西比，一路向西有两条路，北达科塔州或南达科他州。如何选择可以用一块路牌概括：苏瀑Sioux Falls还是法戈 Fargo？看过美剧冰血暴会明白这种鱼与熊掌不可兼得的感受。南下南达科他州，西部的雏形已经缓缓铺开，地表的建筑进一步收缩，视野变得的开阔，机械化农具和巨型农业构筑物随处可见。苏瀑就在州届线上，抱着猎奇的心态去看，但这里没有苏族的印第安人，也没有冰血暴的野蛮荒诞，只剩下一个士绅化并在蓬勃发展的市中心，还有点闹哄哄的繁华。远远见游客打卡，慌乱之下七拐八拐回到了州际公路90 West。
                <br/><br/>
                随着维度变低，北国的冷峻色彩被代之以彩度极高的刺眼阳光，这将成为整个下午烦躁的根源。盛夏午后的太阳，在一路向西的方向上始终稳稳挂在正前方，从近乎垂直的遮光板下以极其刁钻的角度烤在脸上，让人心烦意乱。向西狂奔的每一英里都在延长太阳的能量，走得越快，阳光越强。这场较量一直持续到傍晚九点零二分的怀俄明州才结束，那时候已是山地时间。下午五点多的时候，南达科他开了将近一半，突然看到一条宽阔的河流，河面平整如镜，极为宽广。跨过密苏里河一切风光都变了，如果密西西比河是西部开始的序章，密苏里河才正式宣布了中西部的界限。土壤在密苏里河的东西两岸天差地别，河东肥沃种植经济作物，河西就只剩碎石薄土，代之以茫茫草原，黄绿青灰，建筑物突然消失的无影无踪。
                <br/><br/>
                从芝加哥开始，被高速的挡风玻璃排扁并留下一团浆液的飞虫就造成了视线问题，每到一个加油站就得用放在油泵边的丝网刷蘸玻璃水清理。等到每一个油站都提供这种设备，会突然发现西部已经包围了你。继续向西穿过南达科他州，经过怀俄明州的一角，夕阳留下最后一点绚丽的色彩就沉了下去，远远见地表隆起，是落基山脉。进入蒙大拿已经日落，旷野之息并不友好，只有漆黑一团又冷峻可怖的四个钟头。一天的二十四小时越来越长，到旅馆的时间也越来越晚，前台服务的态度也越来越懒散。今天在落基山脉里穿梭，希望崇山峻岭能充当遮光板。
                <br/><br/>
                双子城——苏瀑——哈丁
                <br/><br/><br/>
                <h5><b>墨菲定律</b></h5><br/>
                游记先缓缓，要验证墨菲定律，今天绝对是个绝佳的例子，中午出发没多久，刚从博兹曼市出来右后轮胎的胎压就红了，开出去不到三十分钟，一阵颠簸过后，就被扔在旷野上一个信号微弱的地方，除了刚加了一箱油之外没有任何防备。吃了点儿东西然后开始这辈子第一次阅读汽车的使用手册，里面大段大段的保证书像在讽刺我的处境，标注的蒙大拿州的维修点和电话，对我也毫无意义，而关于最重要的备胎维修指南竟然只字未提。喝了口水开工，在90 west的路肩上边走边找信号，直到把一个带广告的教学视频看完。修车点的周围风景非常秀丽，没有下雨也不是天黑，让人心情放松，微弱的信号也给了最有用的情报，修理中还有闲情逸致照相，在呼啸而过的车辆旁摸索着换了40来分钟就装好了。有几点建议，一，拆备胎的时候别砸到脸。二，千斤顶多转几圈抬高点儿，因为没承重的备胎很高；三，备胎的螺栓先用手拧上去，不用上紧，等车放下之后再用扳手拧紧，拧紧，拧紧。
                <br/><br/>
                备胎不能跑太快，下午从蒙大拿州出来就已经快天黑了，等穿过爱达荷州界已经非常疲倦，就近找了个旅馆休息。今天除了研究三轮四驱车的维修艺术，还发生了什么别的，等睡起来再想吧。——蒙大拿与SUV维修艺术
                <br/><br/><br/>
                <h5><b>深入西部</b></h5><br/>
                深入西部，昨天上午从哈丁出发，加完油看到一个小男孩穿着T恤皮靴，瘦削的脸晒得很红，一身牛仔气质，正吃力地抬着一堆压扁的硬纸盒，伸手去揭垃圾桶的盖。我走过去说我帮你开，揭开盖子让他把纸盒放进了垃圾桶，他很腼腆说了句谢谢就转身走了，我看到T恤背后印着一个单词Subway，赛百味。西进运动似乎还远远没有停止，资本还在演化最后一丝剩余的蛮荒，州际公路90旁边那些骑马驰骋的印第安人早就不见了，黝黑瘦削的牛仔也穿上了赛百味的衣服在加油站做三明治，西部还在不停地接受驯化。
                <br/><br/>
                开出哈丁上了高速路，一路上落基山脉变得更加层峦叠嶂，州际公路90 West旁边的一条铁路一直如影随形，火车车厢上画着涂鸦，不久就跨过了黄石河。沿途下了高速到博兹曼市区，看到群山掩映中一个富裕的棕褐色小镇，典雅精致又充满了雅痞的情调，粗粝的牛仔气质已不见踪影。加满了油重新回到90 West，之后的爆胎维修耽误了一个小时，备胎的性能限制又进一步降低旅行的速度，让接下来的旅途中不断回忆如果没有那些微弱的信号该怎么办。毕竟这些关键的信息给了我当天能向西多走三百英里的力量。培根那句知识就是力量让我惶恐不安，因为装好备胎并不能改变我对硫化橡胶如何生产，金属如何铸造，机械构件如何咬合的知识储备，但知道如何更换轮胎这点关键的信息碎片却切切实实让我多走了几百英里，所以培根的名言更准确的翻译可能是：“知道”就是力量！
                <br/><br/>
                一路上每隔五十英里就会停车检查备胎的状况，安全行驶了一百英里之后，我对自己的维修艺术越来越有信心，加上对现代工业制造的信任，备胎只有一半的厚度和其简单粗犷的外表反而让人越来越放心，对维修后的三轮驱动能再开出西部山区也不在担忧，而事实上这种信心也证实是正确的。修理完成后在蒙大拿州内飞驰了三百英里，就到了爱达荷州，跨过州界的那一刻，时钟又跳回一个钟头，终于来到了西部时区。两个钟头后就穿过了爱达荷，大开大阖的落基山脉渐渐变得绵密细腻。
                <br/><br/>
                在斯波坎休息了一晚上后，今天就奔向西雅图了，开出去没多久前方的交通事故让高速堵得无比心慌，山脉也越来越低矮。快到西雅图的时候一路速降，海拔迅速降低让耳鼓非常难受，短短三十分钟，针叶林也慢慢变成阔叶林，一切都似乎在准备着把落基山脉的势能释放在无比辽阔的太平洋里。晚上走到州际公路90 West的最后一个出口 Exit 1，数字1被省略了，这可能也是唯一一个没有编号的90 West出口，Exit Only成了名副其实的Exit Finally。90 West结束在西雅图的市中心，虽然离太平洋还有不少距离，但完成使命的州际公路90依然让人心潮澎湃。
                <br/><br/>
                哈丁——博兹曼——斯波坎——西雅图
                <br/><br/><br/>
                <h5><b>西部终点</b></h5><br/>
                跨过阿巴拉契亚，跨过落基，跨过奔腾的密西西比。从大西洋到太平洋，去了北美大陆的另一个尽头，在西雅图修了车，见到了这趟旅程想见到的一切。后面一路向南，能开多远是多远。
                <br/><br/>
                波士顿 Nahant Beach——西雅图 Pacific Beach
                <br/><br/><br/>
                <h5><b>计划变更</b></h5><br/>
                暂时只开到旧金山。西雅图——波特兰——旧金山
                <br/><br/><br/>
                <h5><b>南方终点</b></h5><br/>
                浪费航空煤油可以节省个人旅途总开支，可以节省汽油，可以增加办事效率。宏观和微观经常背道而驰。这一路飞奔得过快，没见到的朋友我们总会再见！南加州的烈日暴晒让人生畏，但好在太平洋的风很清凉，景也迷人。这趟小旅行向南最远就开到这里吧——北纬34.4208° 这个纬度以南的部分留给下次。
                <br/><br/>
                旧金山——纽约——旧金山——圣巴巴拉
                <br/><br/><br/>
                <h5><b>旅程的最后一篇</b></h5><br/>
                一路向南，从西雅图离开不到三个钟头就到了波特兰，到旅馆已是深夜，大门紧闭，只得用门口的传呼机叫醒前台。傍晚从西雅图开出来，并不能看到太多东西，只觉得郁郁葱葱很多松树。睡到天亮开车去波特兰市区，即使再粗心大意的人，也不会忽略沿途标志性的三角形松树尖，这个自然元素如同宗教符号一般，反复出现在道路旁，建筑的背景中，还有俄勒冈州的车牌上。市区是非常规则如同纽约的方格网，大部分地方都比纽约干净，尿味也淡得多，虽然高楼林立但颇为冷清。中国城附近的街区是流浪汉和精神异常的人聚集的地方，即使在正午明媚的阳光下也感觉萧索不太平，一辆日本餐车抱怨有人偷了他们的招牌。市政府因为疫情大门紧闭，门外地面上见粉笔写着：抵制油罐火车，抵制Zenith石油公司。市中心无印良品的玻璃门至今还裹着硬木板，远处一个精神失常的人站在大路中央对着空气咒骂。一个头发蓬乱、年龄大约四五十岁的女子以稍快于正常行走的速度走向我，手里拿着一张纸似乎想和我交谈。我本能地闪开，她突然非常失望，举起双手对天大喊 Lord Jesus！Lord Jesus！良久不停。
                <br/><br/>
                在波特兰短暂停留之后接着向南开，挺拔俊秀的山脉开始变得平缓，树木的色彩也从深绿变成黄绿，刺眼的阳光里有了更多南国的味道，等到了俄勒冈州与加州的州界，高速路旁用颇有拉丁风味的蜷曲字体写着 Welcome to California，迂回的地貌加上不断退化的灰绿色低矮植物应证了加州的烈日与缺水。越向南开，退去的植被暴露出黄色的土地，植物也聚集在一起形成团状，很有沙漠景观的特色，越向南这种特征就越明显。傍晚时分已经接近旧金山，地势越来越低，太平洋的味道也越来越浓，远处的旧金山湾已经呼之欲出了，几个弯道之后这片宽阔的水域会突然出现在面前。高速行驶在湾区大桥上，飞速划向身后的吊索如同无数双纤细的手把车辆拉到湾对岸。晚上住在山景城的旅馆，下车看到头顶的一轮圆月，从波士顿出发还是一轮新月。
                <br/><br/>
                之后几天处理了一些繁杂的手续，从旧金山飞到纽约又飞回到旧金山，两天内横跨美洲大陆两次，但除了疲惫之外没有什么特殊的感觉。二十六号是向南的最后一天，傍晚前必须回到旧金山赶第二天的飞机。一天内从湾区往返圣巴巴拉大约九个多钟头车程，但看着太平洋沿途的风光绝对不虚此行，ucsb附近更犹如仙境一般。离开圣巴巴拉的时候，加油站大妈告诉我这里最繁荣的产业是宴会，殡葬，和墓园，因为有钱的老人都想在这片天堂般的海域旁边安度晚年。唯一的不便是没有大型购物商场，要购物需开车去洛杉矶，路程很近但要忍受交通拥堵。返程的时候一路向北，开了一天非常疲劳，连喝了两罐咖啡才精神起来。回到旧金山已经傍晚，绿码也在下午批了下来。
                <br/><br/>
                很难比较横跨美洲大陆和进入亚洲边境哪一个更难。美洲倾向对身体耐力的摧残，亚洲重视对精神意志的折磨。把二者加起来，可以最大化地让身体和意志得到历练或彻底崩溃。
                <br/><br/>
                西雅图——波特兰——旧金山——%…@…&——圣巴巴拉——旧金山——上海
                <br/><br/>
            </p>

            <br/>

            <h6 className="App-essay-title">Crossed North America</h6>
            <p className="App-essay-article">
                <br/>
                Jun 2021
            </p>

        </div>)
}
