import React from "react";
import {Helmet} from "react-helmet";

export default function WhatToLearn() {
    return (
        <div>
            <Helmet>
                <title>应该学什么 What to Learn</title>
            </Helmet>
            <h6 className="App-essay-title">应该学什么？</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 四月</b>
                <br/><br/>
                “学习什么东西对我是最重要的？”我经常问我自己这个问题。但奇怪的是，那些一直在回答这个重要问题的人，是我的父母、
                老师和老板，而不是我自己。
                <br/><br/>
                在心底里，我明白地知道如果能切中要害地回答这个问题，就可以让我在最短的时间内去往我想去的目的地。然而，
                在家里父母告诉孩子应该学什么，往往只是基于他们的喜好和人生经历，而时代变迁经常会让这部分知识过时；甚至，
                有些父母只是告诉孩子去抽象地“学习”，这样就可以让他们不要来烦自己。在学校，普通教师只会教期末考试要考的东西，
                这样可以给教学双方都提供最优质的便利。工作中，老板们把自己的目标强行塞进你脑中，最终你除了和他的目标相关的技能外，
                什么也不会学，而这些技能你可能丝毫不感兴趣。
                <br/><br/>
                所有这些场景都是一个个确切的流程，不断地消耗你的意志力与好奇心，直到你精疲力竭又失去激情。
                更昂贵的代价是你可能在这个过程中丢失相信自我直觉的信心和独立思考的能力。而回答“应该学什么”这个问题，
                一个人必须能够进行独立思考。但如你所见，在现代社会中，保持独立思考经常困难重重。而这个问题的答案，也不言自明了。
                <br/><br/>
            </p>

            <br/>

            <h6 className="App-essay-title">What To Learn?</h6>
            <p className="App-essay-article">
                <br/>
                <b>Apr 2022</b>
                <br/><br/>
                "What is the most important thing for me to learn?" I often ask myself this
                question, but weird enough, the people who keep answering this critical question are my parents,
                teachers, and bosses, not me.
                <br/><br/>
                I know deep inside that answering this question to the point could get me to the place I want
                to be in less time. However, parents usually tell their kids what to learn based on their
                preferences and experiences, which could very likely be outdated; even more, some parents tell kids
                to “learn”—in an abstract way—so kids would leave them alone. Teachers only teach what would be on
                finals, giving ultimate convenience to both parties. Bosses force their way into your mind at work,
                and
                you end up doing nothing but learn that very skill regardless of its little relevance to your
                interest.
                <br/><br/>
                All the above is the exact process of consuming your precious will and curiosity until you are
                exhausted and lose passion. Higher price, one could lose the confidence to listen to instinct and
                not be
                independent-minded. But to answer the “what to learn” question, one has to be independent-minded. As
                you
                can see, acquiring that independence is often hurdled in modern society, but the answer to "What to
                learn" is, in turn, self-revealing.
                <br/><br/>
            </p>
        </div>)
}
