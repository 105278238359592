import React from "react";
import {Helmet} from "react-helmet";

export default function Locomotive() {
    return (
        <div>
            <Helmet>
                <title>火车头 Locomotive</title>
            </Helmet>
            <h6 className="App-essay-title">火车头</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 二月</b>
                <br/><br/>
                读本科的时候，有次临近年根儿，我在北京抢不到回家的票。那时候还有人人网，我一边抢票一边无所事事地刷着人人网的帖子。
                <br/><br/>
                然后我看到这样一则故事，它尘封在我的记忆中很久，但从未消失。如果这则故事是真实的，那它就是我读过最鼓舞人心的历史故事之一。即使不是真的，我依然相信在这片土地上，也曾发生过太多类似的事。
                <br/><br/>
                抗战期间，日寇破坏了火车头，炸毁了铁道，大学生们滞留学校没法儿回家过年。但这些年轻人没有无聊得像我一样，一边抢票一边看论坛帖子。相反，土木系的学生“拆东墙补西墙”修补了前方破烂的轨道，热能系的年轻人修好了火车头的蒸汽机，所有年轻人都参与在那场绝不投降的战斗中，他们头顶着上空盘旋的日军飞机，修好了破碎的铁路，然后驾着那辆奔腾的火车头，带着同学们回家过年。
                <br/><br/>
                他们相信生活值得过，未来也值得期待，梦幻般的想象应该飞在那辆咆哮的火车头喷出的浓烟之上，和云彩之上。
                <br/><br/>
                云彩之上。龙年快乐！🐉

            </p>

            <br/>

            <h6 className="App-essay-title">Locomotive</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2024</b>
                <br/><br/>

            </p>
        </div>)
}
