import React from "react";
import {Helmet} from "react-helmet";

export default function TechnologyAndImagination() {
    return (
        <div>
            <Helmet>
                <title>技术与想象力 Technology And Imagination</title>
            </Helmet>
            <h6 className="App-essay-title">技术与想象力</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 一月</b>
                <br/><br/>
                <b>技术的想象</b>
                <br/><br/>
                维持生存最基础的东西只有几样：阳光、空气、食物和水；如果加上衣物、住所和交通，衣食住行构成了最基本的生存资源。但是，一个人如果生活在现代的消费社会，到底需要多少东西？这个范围会远远超过基本需求的那几样。消费社会利用人性的贪婪、懒惰、嫉妒、虚荣……等等弱点，还创造了大量除了生存需求之外的需求，而这些需求推动了技术革命——更快的交通方式，更健康可口的饮食，更华美的服饰，更快速的信息交流，还要包括贵族独享的阳光、空气和水。
                <br/><br/>
                一个人真正喜欢穿的衣服就那么几件，最简单的粗茶淡饭也能满足人的能量需求，除了物质，这个人真正受用的精神食粮也许就那么几本书。当对自己无比坦诚，我们会发现如果为了满足基本生存，生活中99%的东西都毫无用处。但是需要注意，如果生活在现代的消费社会，某些附属品的价值跟空气和水一样重要——一套合适得体的衣物，反过来决定了你是否能得到生存最根本的资源。对技术的实现，反过来塑造了一个完全不同的现实，在这个现实中，生存需求的基本顺序可以完全颠倒。
                <br/><br/>
                虽然最重要的东西都是大自然的馈赠，但通过对消费需求的不断满足，技术被人性弱点不停推动着进化，人性弱点组成了最重要的技术想象力。无论你是否认可这些需求的道德好恶，这些需求都切切实实地激发了对技术的想象，我们的动物本能主宰着技术发展。这些根植于消费需求的技术想象，是对人性中生存、懒惰、嫉妒、虚荣、控制、不平等……的精确满足，这些原始欲望牢牢组成技术进步的原动力，而一项技术的成熟则源于成功地满足人性中这些原始野蛮的成分。这些人性弱点创造的需求比一万所世界一流大学能提供的技术灵感加起来都多。
                <br/><br/>
                这些现象迫使人重新思考技术是否完全中性，如果技术更容易被人性中的弱点激发，那么这些技术进步是否也更容易把我们推向《黑客帝国》中的生物电池，终将让人变得迟钝懒惰，毫无生机。
                <br/><br/>
                <b>技术发展的特点</b>
                <br/><br/>
                技术发展是不可控的，我们以为世界可以通过技术变得更美好。但事实上，这个世界在变得更美好前，很可能会先变得更糟，我们先得撑过技术的愚弄，然后才能真正享受技术。被人性的弱点激发，消费技术需要首先应对人性弱点激发的恶意，然后才能开始通过这项技术稳定地满足人性中的善。对技术消极和积极的应用，会在相互对抗中达到平衡。
                <br/><br/>
                如同我们先得学会防御网络攻击和计算机病毒，然后才能更广泛地享受互联网世界的便利。而这些病毒攻击永远不会被消除，它将和信息技术的善一起永远存在。这个世界在变得更真实前，还会先变得更虚假。在人们正常使用ChatGPT合理地增强工作效率前，ChatGPT首先会被用来生产垃圾信息，并在大学的课程论文中作弊。这项技术天然的不成熟给了人性弱点钻空子的机会。就如同我们首先需要忍受Deep Fake带来的虚假信息和身份伪造，通过一系列弄虚作假的进攻考验，这项智能技术才能最终让我们都享受到“Deep Real”带来的便利。
                <br/><br/>
                技术成熟的第一个标志，是我们有能力控制人性弱点被技术放大。
                <br/><br/>
                <b>判断技术成熟的标志</b>
                <br/><br/>
                人性对更大、更快、更强的追求，源自人性的虚荣，这些人性弱点激发的想象力会在遇到技术瓶颈或被技术彻底满足时，才会停止。比如飞艇，当兴登堡号飞艇的大火宣告商业飞艇时代的结束，人们对飞艇大的追求才被重新严肃地评估，人的想象力被技术的极限打败，飞艇再也没有机会成为一项成熟的技术，而它曾是空中最离奇的想象。超音速客机被造出来之后，商业运营很短的时间就被叫停，因为超音速的巨大噪音和对乘客健康的影响无法平衡超音速旅行带来的刺激，商用超音速客机也变成了一项尚未成熟的技术。但是你会发现，尚未成熟的技术永远标志着想象力的存在，人类对飞行器更大和更快的追求，依旧没有被满足，这些技术想象力的因子，依然在人类灵魂深处纵横驰骋。不成熟的技术反而保全了想象力。
                <br/><br/>
                没有语言文字的时代，人与人的精确沟通像魔法一样神奇，而人类的协作极大程度取决于猜测和运气，如果一个原始人发出的复杂音韵或符号恰好被另一个原始人接收到并正确解读，那么这项沟通技术就无疑于魔法场景。这种音韵和符号的魔法驱动着原始人的技术想象力，而这项技术日臻完善的标志就是语言和文字的出现——它们通过消除了个体沟通的猜测和想象，成了最强有力的社会组织技术。语言文字通过消灭对沟通技术的想象，而成为了一项成熟的技术。
                <br/><br/>
                如果通讯技术没有满足人与人在千里之外也能“即时通讯”（让交流真正变得"天涯若比邻"），那么这项通讯技术就依然幼稚。等到技术彻底满足了想象力中能千里传音的、神话般的场景，这项技术才真正开始成熟。而通讯技术的最终成熟，也一定会导致对那个魔法想象的彻底“消除”。不能彻底消除想象空间的技术，不是成熟的技术。
                <br/><br/>
                于是我们可以顺利引出一个观点：技术发明的首要目的就是消灭想象力的空间。也就是说，如果这项技术还有可被想象的空间，那么就说明这项技术依然不够优秀，不够成熟。对想象力的消除是判断技术是否成熟的第二个标志。
                <br/><br/>
                所以技术的终极目的，是以实现——更准确地讲，是以“消灭”想象力——作为这项技术是否成熟的判断标准而存在。每当消费技术前进一大步，我们作为人类的想象力都会丢失一部分，技术终将蚕食每个阶段人类可想象的生存方式，直到幻象被精准的确定性替代。但人的动物本能总能创造新的幻象，人类的想象力和所创造的技术永远在此消彼长中螺旋上升。

            </p>

            <br/>

            <h6 className="App-essay-title">Technology And Imagination</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jan 2023</b>
                <br/><br/>

            </p>
        </div>)
}
