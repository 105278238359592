import React from "react";
import {Helmet} from "react-helmet";

export default function Hometown() {
    return (
        <div>
            <Helmet>
                <title>故乡 Hometown</title>
            </Helmet>
            <h6 className="App-essay-title">故乡</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 八月</b>
                <br/><br/>
                有一则寓言故事，讲曾经有个年轻人问先知，在哪里能找到属于自己的财富，先知告诉他去巴格达，那里有你想要的东西。年轻人穿过沙漠和暴风雨来到巴格达，很多年过去仍一无所获。有一天，一个衣不蔽体的老人来到他的住处，问年轻人要点剩饭吃，年轻人听老人的口音竟是来自远方的同乡，痛快地招待了他。老人吃罢，告诉年轻人他找的财富不在巴格达，而是被埋在一户人家后院的一棵树下。老人描述了藏宝的地方，年轻人惊讶地发现，那个地方竟是自己故乡的后院。他连夜起身赶回家，按老人的描述，果然在故乡后院里挖出一坛黄金。
                <br/><br/>
                这个虚构的寓言有明显的一千零一夜的奇幻风味。虽然现代故事并不都充满着中东商人的神秘色彩，但现代故事依然遵循着远方的冒险故事带给生活的意义。不离开家乡的年轻人不会知道财富就在眼皮子下，即使他每天都盯着自己那个普通的后院看。
                <br/><br/>
                家是温暖的港湾，但你必须离开它。
                <br/><br/>
                离开了家，家就变成了一块磁铁，麦加的方向，和一个永远的指北针。一代又一代人从家乡离开，即使后来又回到那里，现在的自己也绝不再是从前的自己，家乡也永远变成了故乡。准确地说，背上包袱离开家的那天起，家乡就变成的故乡。
                <br/><br/>
                距离产生美，回不去的是故乡，也只有回不去的才能叫故乡。故乡不会为你穿越了大海和风暴而变得和善，故乡只会在你意想不到的地方提醒你，变的是你，而你早已不属于这里——穿过暴风雨，你记不得暴雨前的样子，你也记不得暴雨中发生了什么，但穿过暴风雨后你一定永远地改变了。别回头望故乡，除非后院里藏着黄金，而现实中，那里什么也没有。

            </p>

            <br/>

            <h6 className="App-essay-title">Hometown</h6>
            <p className="App-essay-article">
                <br/>
                <b>Aug 2023</b>
                <br/><br/>

            </p>
        </div>)
}
