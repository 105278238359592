import React from "react";
import {Helmet} from "react-helmet";

export default function Oversharing() {
    return (
        <div>
            <Helmet>
                <title>过度分享 Oversharing</title>
            </Helmet>
            <h6 className="App-essay-title">过度分享</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 五月</b>
                <br/><br/>
                我在<a href="https://www.kunchengblog.com/essay/howtolearn" rel="noreferrer noopener" target="_blank">如何学习：一场浪费时间的阅读</a>中记录过，学习方法里有一种重要方式是分享，就是通过授之以人——如果你能用简单的语言给别人讲明白——那么就意味着你自己理解透彻了。这种方法称之为“以教督学”。
                <br/><br/>
                “以教督学”的关键步骤是用信息输出引导信息输入，高标准的“教”，焦急地督促着高标准的“学”。但是，“以教督学”的方式似乎在信息时代漏洞百出，人们看似在“以教督学”，而实际上，是在“过度分享”。但在前信息时代，“以教督学”有着更大的回报，和更少的危害——课堂里就是那些学生，他们的理解力还参差不齐，你无法像切换一个频道或App一样切换你的教育对象；所以你费劲浑身力气想教会他们，这种状态下的“以教督学”，有着摧枯拉朽的力量。但在信息时代，你很容易只是在“过度分享”。
                <br/><br/>
                虽然“以教督学”和“过度分享”这两者区别极大，但信息工具让它们变得非常相似。一般情况下，你说得越明白，就有越多人听，越多人听，传播度就越高，所以好的教学内容会迅速传播开，即使这个内容是用最原始的html写成的。但传播度越高的内容，却并不一定都是好的教学内容，病毒营销就可以传播得更快。于是，很多自以为可以通过教会更多人来促进自己“以教督学”，很可能没有起到任何促进自我理解的作用，充其量不过是在“过度分享”罢了——大家一起浸泡在信息的长河里，从同一个起点出发，然后到达了两个截然相反的地方。
                <br/><br/>
                虽然分享不是一件坏事，分享欲强的人希望自己被听到，有些还希望通过分享让身边的人过得更好（有些则不是）；还有一些人，希望能通过逼迫自己大量分享，获取对一些东西更深的理解。这些行为都值得被鼓励，说真的，分享不仅不是没用的，在某些情况下分享有天大的价值。
                <br/><br/>
                但区分“以教督学”和“过度分享”的分水岭，并不是分享内容的实际价值。一个人可以很简单地问问自己，分享时最看重的是什么：是分享的快感？还是对事物的领悟？
                <br/><br/>
                这里引入一个以经济学家查尔斯·古德哈特（Charles Goodhart）命名的定律，这个定律发现：“当一项衡量标准变成目标时，它就不再是一项好的衡量标准。” (when a measure becomes a target, it ceases to be a good measure.)
                <br/><br/>
                一个最直接的例子就是GDP——当作为辅助工具的衡量指标变成了目标本身的时候，这个衡量标准就开始失效。本作为发展指标的国民生产总值（GDP）是一种能相当可靠地衡量国民发展水平和幸福感的数字指标。但当GDP最后成为了一个国家全力追求的目标之后，它对国民发展水平和幸福感的衡量，就开始有越来越大的偏差。
                <br/><br/>
                所以当用传播度来衡量“以教督学”的质量时，就像用GDP衡量国民生活质量一样，阅读、点赞和转发的次数可以相当可靠地衡量“教”的质量；不过一旦阅读、点赞和转发的次数变成“以教督学”的目标时，用来衡量“以教督学”质量的传播度指标就会愈发不准确，甚至完全相悖。
                <br/><br/>
                那如何脱离这种困境？最简单的做法就是问问“以教督学”的真正目的是什么？“以教督学”的真正目的是通过模拟教学，让“高质量的教”催促“高质量的学”，它的重点是“高质量的教”带来的敦促，所以实际上，“以教督学”和传播度毫无关系。
                <br/><br/>
                先不谈通过教学提高理解深度，甚至当你只是想“过度分享”时（比如制造一场病毒传播），如果你过度关注阅读、点赞和转发的次数，也很可能让内容的传播力大大下降，这真是充满了讽刺！不过，本文的主要目的是为了指出，如何在信息时代尽量避免“过度分享”的危害，通过教学更好地督促学习。
                <br/><br/>
                想做到这点，就得理解一件事，高标准的“教”绝对不是好为人师，而过度分享的“教”很可能就是好为人师。所以我要劝自己：好自为之，好好反思。
                <br/><br/>
                分享确实可以加深理解，但分享不等于更好地理解。想更好地理解一样事物，深入思考和自我实践是不能被绕过的铁律。但这个铁律有一点非常奇妙的地方：当你实践得越多，你遇到的挫折、失望和疑惑就越多；当你没有了“虚假理解”的自信，你反而会降低你的分享欲。所以过度分享和深刻理解之间，竟然有种此消彼长的微妙关系。
                <br/><br/>
                那么如何在信息时代，继续利用“以教督学”的优势，促进学习和理解？我有一个简单的猜想：别教别人，教自己。
                <br/><br/>
                我发现了一件神秘又古老的事：老师可以假装教会，学生也可以假装听懂，其所谓“以其昏昏，使其昭昭”！但是，如果你自己教自己，是一件无论如何都无法作弊的事。当然，你也可以欺骗自己学会了，但你心里知道自己如何欺骗自己——这正是自己无法欺骗自己的证明。此外，你还可以只在口头上把自己教会，而拒绝实践，所以这个“自教自学”的“以教督学”，还有一个加强版——你必须亲自实践，去验证你是不是真的懂了，亲身实践自己教自己的东西，能最大程度得杜绝作弊的可能。
                <br/><br/>
                相比过去的策略——想通过大量“分享教学”让自己理解得更深刻——衡量“分享程度”的指标势必成为行动的目标，最终让“加深理解”失去全部意义，教自己是更高质量的“以教督学”。
                <br/><br/>
                语言是有魅惑力的，说得好不代表做得好，更不代表理解得更深刻。尤其当语言作为最锋利的思考武器，当你深入思考任何一件事都离不开语言的时候，那语言干扰你判断自己是否真正明白一件事的迷惑性就愈发凸显。所以必须对语言的魅惑力予以格外重视。
                <br/><br/>
                能通过语言思考把自己教会，只是第一步，当你开始行动，才是检验真理是否真正被理解的关键步骤。理解语言的人热爱分享，经过实践后不愿分享，大量实践之后可能会客观、理性、适度地分享。但你是否会分享，在这个过程中反而是最无关紧要的，那个指标不是目标。
                <br/><br/>
                可能真正能对自己做到“以教督学”的人，都会明白一件事，比理解更重要的是实践，个体的行动胜过分享的万语千言。


            </p>

            <br/>

            <h6 className="App-essay-title">Oversharing</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2023</b>
                <br/><br/>

            </p>
        </div>)
}
