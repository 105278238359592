import React from "react";
import {Helmet} from "react-helmet";

export default function HowToLearn() {
    return (
        <div>
            <Helmet>
                <title>史蒂夫给史蒂夫 From Steve to Steve</title>
            </Helmet>
            <h6 className="App-essay-title">史蒂夫给史蒂夫</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 四月</b>
                <br/><br/>
                一身毛病的人，史蒂夫·乔布斯，但不妨碍他充满人格魅力。
                <br/><br/>
                K-译
                <br/><br/>
                <b>来自：</b>乔布斯，sjobs@apple.com
                <br/>
                <b>发给：</b>乔布斯，sjobs@apple.com
                <br/>
                <b>日期：</b>星期四，9月2日，2010年 11:08 PM
                <hr/>
                我几乎不种我所吃的食物，而我自己种的那部分，我也从未培育或改良过那些种子。
                <br/><br/>
                我没制作过任何我自己穿的衣服。
                <br/><br/>
                我说着一种我从未发明也没完善过的语言。
                <br/><br/>
                我没有发现我所使用的数学。
                <br/><br/>
                我被自由和法律保护但我既没有构思也没有立法，既没有执法维护也没有执行判决。
                <br/><br/>
                我被我从未创造过的音乐打动。
                <br/><br/>
                当我需要医护时，我无助地想帮自己活下来。
                <br/><br/>
                我没有发明过晶体管，微处理器，面向对象的编程语言，或是任何大多数我与之工作的科技。
                <br/><br/>
                我热爱并敬佩我们这个物种，无论是活着的还是死掉的，我也仰仗着他们，才获得了我全部的生活和幸福。
                <hr/>
                发自我的iPad
                <br/><br/><br/>
                <b>From:</b>Steve Jobs, sjobs@apple.com
                <br/>
                <b>To:</b>Steve Jobs, sjobs@apple.com
                <br/>
                <b>Date:</b>Thursday, September 2, 2010 at 11:08PM
                <hr/>
                I grow little of the food I eat, and of the little I do grow I did not breed or perfect the seeds.
                <br/><br/>
                I do not make any of my own clothing.
                <br/><br/>
                I speak a language I did not invent or refine.
                <br/><br/>
                I did not discover the mathematics I use.
                <br/><br/>
                I am protected by freedoms and laws I did not conceive of or legislate, and do not enforce or adjudicate.
                <br/><br/>
                I am moved by music I did not create myself.
                <br/><br/>
                When I needed medical attention, I was helpless to help myself survive.
                <br/><br/>
                I did not invent the transistor, the microprocessor, object oriented programming, or most of the technology I work with.
                <br/><br/>
                I love and admire my species, living and dead, and am totally dependent on them for my life and well being.
                <hr/>
                Sent from my iPad
            </p>

            <br/>

            <h6 className="App-essay-title">From Steve to Steve</h6>
            <p className="App-essay-article">
                <br/>
                <b>Apr 2023</b>
                <br/><br/>

            </p>
        </div>)
}
