import React from "react";
import {Helmet} from "react-helmet";

export default function Company() {
    return (
        <div>
            <Helmet>
                <title>公司 Company</title>
            </Helmet>
            <h6 className="App-essay-title">公司</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 九月</b>
                <br/><br/>
                <b>伙伴 Companion</b>
                <br/><br/>
                公司，company，是一个舶来品。根据韦伯斯特字典（Merriam-Webster Dictionary）的释义，这个词源于13世纪的中世纪英语 companie和cumpaignie。自从诺曼征服英格兰之后，法语的影响开始渗透在英文的字里行间，cumpaignie是中世纪法语的公司。法语，与传统欧洲大陆的商业贸易，一并影响着环境贫瘠的英格兰群岛。此外，company还更多地来自这个词源，companion，同伴，伙伴。没有伙伴，就没有公司。
                <br/><br/>
                中文的词语“公司”，最初被福建、广东一带中国最早接触海洋贸易的人音译，然后开始传播。词头Com，是“公有”之意。Com——公——这个巧妙的音译，让伙伴关系进一步加深，当一个组织被同伴们公有，它就可以成为一个全新的符号，代表贸易能力的商号，共担风险也共享收益。虽然一个人的公司也可以存在，但失去同伴和公有属性，会让组织和空壳变得接近。
                <br/><br/>
                <b>航海与股份制</b>
                <br/><br/>
                1492年，哥伦布发现了一块新大陆，虽然来到巴哈马群岛的他错把这里当成了印度，但这是西欧历史上最重要的一年，此后世界范围内几乎所有血腥暴力和商业贸易，都交织在那次惊心动魄的出海之后。6年后的1498年，一支由葡萄牙人瓦斯科 · 达 · 伽马（Vasco da Gama）带领的船队，在历时10个月之久的漫长航行后，看到了一座绿色的山峰，船员兴奋地大喊：“船长，那儿是印度！”和哥伦布的错误不同，达 · 伽马看到的是真的印度。葡萄牙人发现了从海上来到印度的新秘密通道，率先占据了优势。
                <br/><br/>
                但当时出海航行往往历经数月甚至一年，早期的航海风险会导致一半以上的船员丧生，降低远航风险是发现新航线后至关重要的问题。在发现印度航线后的一百多年后，除了航海技术的进步，距离瓦特改良蒸汽机还有一百多年的时间，远洋航行的船只仍大多是帆船，另一个对抗风险的新制度应运而生。
                <br/><br/>
                <i style={{ color: 'grey' }}>1602年3月，联合东印度公司 (V. O. C) （荷兰）成立。与在一年前率先起步的英国东印度公司相比，联合东印度公司聚集了其 10 倍以上——约650万盾（荷兰盾）——的资本，成为历史上第一家股份制公司。</i>
                <br/><br/>
                <i style={{ color: 'grey'}}>1799 年，联合东印度公司作为一家跨海商贸企业，一直持续经营了大约 200 年之久。公司规定出资时间以10年为一期，并且在出资期间不得擅自撤资退出。而新的投资者与原投资者则必须在 10 年后的“一般清算”时，才可加入或退出。在这一制度的基础上，公司实现了长久而稳定的经营……<br/><br/>（《东印度公司》，浅田实，2016）</i>
                <br/><br/>
                1602的同年，阿姆斯特丹证券交易所成立了，这是世界上第一个股票交易所。可交易的证券市场更让市场信息充分沟通，进一步降低了风险，船队的融资变得更方便。这场制度设计如此早熟，把源源不断从印度通过胡椒和香料攫取巨额财富的荷兰人最后的障碍也扫清了。股份制是其中一部分革新，另一个现代化的发明是有限责任制——荷兰东印度公司对股东实行的“有限责任制”，除了投入的资本外，出资人不必把全部的生命和荣誉都绑在一艘船上，这个发明也充满了现代感。
                <br/><br/>
                几百年后，巴菲特强调你在股票市场上买卖的股票就是你对该公司的一部分拥有权，这个“再强调”很有趣。因为几百年前，阿姆斯特丹证券交易所里一张荷兰东印度公司的股票，对应着拥有荷兰东印度公司的一部分资产并享受分红的权利，这个概念是那么清晰直观，但百年后竟然需要股票大亨重申股票的基本意义。制度创新在带来融资便利的同时，还让金融产品变得无比抽象。抽象性和筹措资金的便利性一同增长，越快捷方便也意味着越精巧复杂。
                <br/><br/>
                最终，那些在烈日风暴、茫然无际的海洋中颠簸数月，祈求能活着望见陆地的探险家们组成的船队和皇家出资人，在对分散风险的执着追求中，不仅推动了航海术的大发展，还创造了当时最先进的资本系统。作为那个时代最惊人的制度设计，荷兰东印度公司允许贵族、官僚和商人把自己的资金以10年为期，投入到公司的资产中，到期前不得提前兑现。政府特许公司的成立，不仅为了分散风险，也为了防止恶性竞争。
                <br/><br/>
                皇家政府为新成立的东印度公司背书、更合理的有限责任制、允许买卖的证券交易所，让刚发现新大陆时需要用过半船员的死亡率做代价的生命冒险，变成了一件源源不断可被资助的风险投资。从这个层面上，股份公司的意义不亚于蒸汽轮船的发明，它们都让远洋航行变得更高效、更安全。但对亚洲、美洲和非洲而言，欧洲航海带来的军事攻击和商业掠夺，让公司带来的破坏力远超其福利。
                <br/><br/>
                除了指代伙伴或友好关系，company的第二个含义还可以指代来访者或客人，不过来访之人到底是敌是友，就有待确认了。在海面上齐头并进的船只并不只有同伴，还有海盗船和别的公司。类似地，当船员们看到海盗，或西部牛仔看到敌人吹着口哨大喊一句：we got companies!（来人了！）其心情与今日与友商谈笑风生的感觉也遥相呼应。
                <br/><br/>
                把代表武力冲突的军事力量和代表和平交易的商业力量挂钩，欧洲的地理大发现兑现了其自身的所有回报。公司可以是一只伪装成商队被贸易滋养的军事力量，也可以是一支由军事力量开路的商业部队。商业交易以军事力量为保障，而军事力量反过来也被商业资本滋养，这仍是今天不少国家高回报的正循环，公司的叫法让这一切行为更加合理化。
                <br/><br/>
                英国对印度的贸易和殖民，随着荷兰东印度公司的黯淡，和不列颠东印度公司的崛起而如日中天。为了获得茶叶、丝绸和瓷器，白银源源不断流入中国，而中国却对英国的商品兴趣不大，在与中国的巨额贸易逆差下，英国通过不列颠东印度公司向中国输入了一种用艳丽的罂粟花提取的成瘾商品，鸦片。鸦片贸易以毒物为商品，并最终以军事力量为辅助，强制进行了对华的毒品贸易。所有国家都需要打一场毒品战争，中国这场毒品战争在近两百年前就和民族战争一并打完了，这些罪恶的成瘾物质深深印在中国人记忆里，直到今天对毒品的零容忍态度，也可追溯到不列颠东印度公司曾对华输出的毒品贸易及其引发的战争动荡带来的痛苦回忆。
                <br/><br/>
                <b>美利坚公司 Corporate America</b>
                <br/><br/>
                在北美的富饶土地上，英国人和法国人通过对印第安人的战争播下了最初贸易的种子。随着西进运动的拓荒，这些贸易的火种穿越整块大陆来到太平洋沿岸。这片辽阔的土地借助资源禀赋和贸易天分，先后形成了煤炭、纺织品、糖、钢铁、铁路在内的巨型公司，同时在大陆上留下了纵横万里的漕运通道、铁路网、公路网和远洋港口。这片土地上的公司为满足人们哪怕最微小的欲望，不断生产着五花八门的商品，然后经由被保护的贸易通道输送到全世界。
                <br/><br/>
                美国的公司们，把美洲打造成了一个公司的世界，出于对这些美国公司巨大而可怕的影响力，美利坚公司 Corporate America，和展现亲切朴实的山姆大叔 (Uncle Sam) 形象，都成了最具美国特色的产物。
                <br/><br/>
                美国传统词典（The American Heritage Dictionary）里对Company的解释，除了伙伴、陪伴、公司等常规含义，company还有军事组织或兵营的意思，指最低一级的军事管理单元——亲密无间的战友们组成的作战单位，一般至少包含两个排。此外，一艘船的船员和长官也被称为“公司”。
                <br/><br/>
                公司商业属性和军事属性的界限变得模糊。从维京海盗时代开始，早期大航海、东印度公司、鸦片贸易，直到今天被称作美利坚公司的Corporate America，在遍布全球的美军基地保驾护航下的贸易路线中，这两种属性从未分离。
                <br/><br/>
                在今天，除了对大宗商品的掠夺还能看到来自国家层面的暴力，美利坚公司生产分销的大量商品都已藏在温情脉脉的面纱之下。但事实上，大宗商品的贸易，尤其包括芯片在内最尖端的科技产品，仍然以坚船利炮作为保障，在世界范围内被军事力量的大小分配。
                <br/><br/>
                东亚的一座岛屿和一家芯片制造公司，可以因此成为整个世界经济和军事的竞争支点。因为这块岛屿不仅占据着大宗商品的贸易航道，又同时占据着最尖端科技产品的生产基地。如果仔细看，今天的这些芯片制造公司对全球经济千丝万缕的影响力，甚至可以比肩曾经东印度公司的影响力，在船上运输的不只是一箱箱的胡椒、香料和成瘾物质，这些最小尺度在纳米级别的科技产品是人类智慧最浓缩的结晶，无比精细也无比有力。
                <br/><br/>
                美利坚是伪装成文明的公司。
                <br/><br/>
                <b>分散风险与沟通效率</b>
                <br/><br/>
                公司通过共同所有制的原则分散了单个出资人的风险，作为回报，公司赋予了股东裁决的权利。
                <br/><br/>
                但让我们假设一个未被现实入侵的理想状态，每个个体都可以进行自由交易，公司体量的无限扩大虽然分散了风险，但随着体量增大，组织运转和商品创造的效率会迅速下降，这让巨型公司的存在不合理性无所遁形，因此超大型公司的出现本应极不自然。
                <br/><br/>
                但现实中的沟通成本并非无关紧要、可有可无的因素，信息沟通包含了：日常交流、谈判、合同、监督、敲诈、风险、违约……当随着公司无限小型化的过程中，即使组织运转和创造发明的效率大大提升，但信息交流环节的成本会快速叠加，沟通的成本反过来会吞噬交易的最后一丝利润。所以公司用规模化的整合，为贸易提供了一定程度上对抗沟通成本高企的解决办法。作为社会性的组织，公司又为每个人的生活提供了词典中那几个最简单的价值：陪伴，伙伴，战友，竞争，生产场所。
                <br/><br/>
                今天的公司不一定站在波涛汹涌的海面上，但现代公司和几百年前摇晃的船身中面黄肌瘦、牙龈出血又浑身肮脏的水手们组成的船队并无本质区别。在组成公司后，这些人分享彼此的陪伴，与同行竞争，承担风险也分享利益。
                <br/><br/>
                这艘船的目的地是茫茫无际的大海还是坚实的陆地，在过去五百年间发生了天翻地覆的变化，但这艘船的象征意义依然存在：所有被公司组织起来的人，和同行的伙伴与竞争的敌手，都依旧坐在一艘风浪颠簸的海船里。

                <br/><br/><br/>
                <hr/>

                <b>Reference</b>
                <br/>
                <b>1. </b>Merriam-Webster Dictionary: [Company] - Middle English companie, from cumpaignie, from cumpaing companion — more at companion.
                <br/>
                <b>2. </b>东印度公司与亚洲之海，[日] 羽田正，2019，理想国出品，北京日报出版社.
                <br/>
                <b>3. </b>东印度公司：巨额商业资本之兴衰，[日] 浅田实，顾珊珊 译，社会科学文献出版社，2016.
                <br/>
                <b>4. </b>东印度公司对华贸易编年史 (1635-1834)，第二卷 三十七章，[美] 马士 (H.B.Morse) 著，区宗华 译，广东人民出版社，2016.
                <br/>
                <b>5. </b>The America Heritage Dictionary: [Company] - 5. a. A subdivision of a military regiment or battalion that constitutes the lowest administrative unit. It is usually under the command of a captain and is made up of at least two platoons. - 6. A ship's crew and officers. See Usage Note at collective noun.

            </p>

            <br/>

            <h6 className="App-essay-title">Company</h6>
            <p className="App-essay-article">
                <br/>
                <b>Sep 2023</b>
                <br/><br/>

            </p>
        </div>)
}
