import React from "react";
import {Helmet} from "react-helmet";

export default function AtThisAge() {
    return (
        <div>
            <Helmet>
                <title>在这个年纪 At This Age</title>
            </Helmet>
            <h6 className="App-essay-title">在这个年纪</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 九月</b>
                <br/><br/>
                在这个年纪，牛顿和帕斯卡早就已经提出了许多不朽的科学理论；费曼已经造完原子弹，正在康奈尔教书，并为发明了原子弹而抑郁，同时在和波耳与狄拉克骂仗；爱因斯坦正准备从专利局辞职，他多年前就发表了光电理论的论文，第二个儿子刚出生，还顺便写了《论辐射问题的现状》；傅里叶正在前往埃及的帆船和马背上酝酿热的解析，他马上要成为埃及学院的秘书长；拿破仑打完了金字塔战役，正在秘密筹备雾月政变的细节；伽瓦罗写完群论的论文已经很多年了，坐牢出狱后跟人决斗身亡，在这个年纪，他已经死了很多年了；王勃即兴创作完《滕王阁序》，也已经死了好几年了；尼采已经写完了《悲剧的诞生》；夏洛蒂·勃朗特正憋着一肚子火写《简·爱》；邓小平同志正在准备长征。
                <br/><br/>
                每一代年轻人都感到被辜负，每一代年轻人都感到被背叛，直到他们不再年轻。我舒展了下四肢，想想今天该吃什么。
            </p>

            <br/>

            <h6 className="App-essay-title">At This Age</h6>
            <p className="App-essay-article">
                <br/>
                <b>Sep 2022</b>
                <br/><br/>

            </p>
        </div>)
}
