import React from "react";
import {Helmet} from "react-helmet";

export default function SelfMotivation() {
    return (
        <div>
            <Helmet>
                <title>如何降低我的个人积极性？ How to Reduce My Self-Motivation?</title>
            </Helmet>
            <h6 className="App-essay-title">如何降低我的个人积极性？</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 五月</b>
                <br/><br/>
                对一件事，如果我想做，我就会做；如果我不想做，我就不会做。这句废话暗藏玄机。因为有时候，你确实不想做但被迫做，想做又被迫不能做。但大量研究都表明，违背个人意愿的非自发行为几乎总会比自发行为的效率差。
                <br/><br/>
                那么基于这个研究事实，我的目标应该是如何增强我的个人积极性，不是么？但这实在太难了。
                <br/><br/>
                一个人的动机是自己长出来的，个人积极性无法在威逼利诱之下生长。所以，如果问“如何增强我的个人积极性”，这个问题就像问“如何强迫一条鱼没有水但能活下来”一样别扭。基于个人积极性的这个特点，我只好把问题反过来：如何才能降低我的个人积极性？
                <br/><br/>
                我总结了几点可以高效降低我个人积极性的办法，当然，如果你压根不相信个人积极性有任何促进效率和增强幸福的能力，那剩下的内容你完全可以正着读。
                <br/><br/>
                1. 请尽可能拒绝倾听你内心的真实想法，尤其当很多人和整个社会都说这样做是不对的，你一定要严格控制这些冲动。
                <br/><br/>
                2. 请严格拒绝追问你自己内心冲动的缘由是什么，无论这些冲动是不是对的。
                <br/><br/>
                3. 如果你仅仅出于好奇而自发地想做某事，请尽快给这件事制定一个任务表以及奖励机制，比如：任务ABCDE……，做到了A可以得到钱、休息、奢侈品，做到了B可以得到糖水或高热量。不断通过贿赂、奖赏自己希望的“正面行为”，你能极为有效地降低你的个人积极性，尤其当这件事本来出自你无意识的好奇，那些奖励会很快替代你的“积极”。
                <br/><br/>
                4. 如果你无法完成规定的任务，请严格把“无法完成”和“自我贬低”关联起来，从而达到威胁自己不许做某件事的目的。例如，你想通过节制饮食和规律睡眠获得健康，就要对自己强调：一旦不严格按照健康行为约束自己，那么你就是个意志力薄弱的懦夫！（侮辱性当然还可以再强点儿。）
                <br/><br/>
                5. 一切为了竞争。如果金钱、荣誉和成功可以激发你的斗志，那么贫穷、耻辱和失败也可以高效遏制你的动力。所以请最大化利用你的挫折和失败，尽情打压你的探索欲，用对失败的恐惧填补对行动的渴望，把追求赢作为一切行动的绝对目的。
                <br/><br/>
                6. 千万不要相信放松的意义，你得强迫身体和思想时刻都高度集中，防止思维在任何情况下游荡，该吃东西的时候别吃，该睡觉的时候别睡。
                <br/><br/>
                7. 你要尽可能地精确计算每件事的投入产出比，迷信付出与回报之间的收益率。一旦没有明确的收益，或回报周期很长，你要果断拒绝执行。尽量做一个安全、高效、合格的工具。
                <br/><br/>
                对别人，你要尽可能地运用奖赏、诱惑、威胁、惩罚，去控制别人的行为和产出，并辅之以强调竞争的绝对意义，长此以往，你就能获得来自那个人的最小积极性。
                <br/><br/>
                对自己，你应该彻底否定内心那些真实但不被认可的想法；同时，你要运用规定任务、奖赏诱惑、惩罚威胁来管理你的好奇心；此外，你还要强调竞争的绝对意义，你要把失败和对失败的恐惧与“自我贬低”直接关联起来。
                <br/><br/>
                能做到这几点，你就很有可能成功抵抗神秘又不靠谱的个人意志和心理动力，最大程度地削弱你的主观能动性，成为一个“高效”的“理性工具”，最终达到大幅降低个人积极性的目的。
                <br/><br/>
                共勉。

            </p>

            <br/>

            <h6 className="App-essay-title">How to Reduce My Self-Motivation?</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2023</b>
                <br/><br/>

            </p>
        </div>)
}
