import React from "react";
import {Helmet} from "react-helmet";

export default function Boredom() {
    return (
        <div>
            <Helmet>
                <title>无聊的意义 The Value Of Boredom</title>
            </Helmet>
            <h6 className="App-essay-title">无聊的意义</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 二月</b>
                <br/><br/>
                这个15分钟的TED演讲很有启发性：How to Get Your Brain to Focus（如何让你的大脑保持专注）<a href="#footnote-1" id="footnote1">[1]</a>。演讲的主题是帮助人们提高专注力，给的建议也很有趣，他并不强调如何训练自己强迫或欺骗大脑“专注地”干某件事，而是教你如何降低大脑的受刺激程度，同时训练自己习惯无聊的时光。如果这些训练和冥想的理念不谋而合，那我可能第一次说服了自己冥想有什么价值。
                <br/><br/>
                而我最感兴趣的，是无聊这件事本身。
                <br/><br/>
                你上次感到无聊是什么时候，或者说，你上次发呆是什么时候？发呆可以是眼神游离地坐在椅子上，或半梦半醒地泡在浴缸里，也可以是长途汽车上盯着窗外重复的风景出神。当你没有必须完成的任务，没有目的地，没有视频、音乐、快餐读物和各种信息的刺激，只是简单地保持发呆的状态，上次是什么时候？你保持了多久？
                <br/><br/>
                在很多人的词典里，无聊是无话可聊的状态，发呆是呆滞的同义词，它们都是缺乏活力的表现，也都是贬义词。所以现代人很少品尝过极致的无聊，我们的专注力甚至还没有一只猫持久。因此，也只有那些远古的牧羊人，在羊群睡着、大地沉寂、星空璀璨的深夜里，他们才会在百无聊赖中发明出一整套星座的称呼和神话故事，并在脑海中幻想着星辰的生死和未来，他们因为无聊透顶而极度专注。
                <br/><br/>
                越晚出生的孩子，会越来越多地觉得人类社会创造出的刺激，比头顶上的璀璨星空要迷人的多，甚至真实的多。对现代人，无聊不仅仅是一种状态，甚至更是一种罪恶，需要被根除医治，或被目的性填满。但通过保持亢奋去医治无聊，往往导致“专注”的消失——这里有非常值得思考的原因。
                <br/><br/>
                当我们不进行任何生产工作或消费娱乐时，就会瞬间感到无聊。无聊会迫使人追求别的刺激，而每当我们服用这些额外刺激时，刺激带来的效果都会降低，然后接踵而来的空虚无聊又迫使我们追求更多、更密集的刺激让大脑活跃起来，这就是上瘾行为。我们对更密集和更大剂量的刺激，充满了渴望，我们分心的原因不是意志力的高低，而是我们的肉体和精神都极度渴望被“打断”，然后被投递更大剂量的刺激。
                <br/><br/>
                从这个角度观察，保持专注的方法不是强迫自己“集中精力”，这种强制性的做法只有有限的效用。要对不专注宣战，就得先学会忍受无聊，然后习惯无聊，最后享受无聊——常年保持在高兴奋度的大脑会慢慢地自动降温，不再主动追求更多的刺激，这样专注就被获得了。“保持专注”是“享受无聊”的副产物。
                <br/><br/>
                所以，通过重新发现“无聊”的价值，我们可以获得“专注”这个副产品；但无聊所孕育的主产物，是另一个东西：阿基米德的浴缸和牛顿的苹果——事实上，和这些先哲巨匠没什么两样，我们也常会在漫无目的的发呆中发现各种新东西，无聊发呆是最宝贵的灵感迸发的来源，一根时刻都高度紧张的神经没有创造力。无聊的作用，是无用的大用。
                <br/><br/>
                演讲里引用了英国诗人托尔金（J. R. R. Tolkien）的一句诗：
                <br/><br/>
                <i style={{ color: 'grey' }}>Not all those who wander are lost.</i>
                <br/>
                <i style={{ color: 'grey' }}>并不是所有的漫游者都迷失了方向。</i>
                <br/><br/>
                我想起大学的一段经历，那是一个周五的晚上，几个朋友在五道口的一家小酒馆喝酒，有法国人、意大利人、荷兰人和我。酒过三巡，游戏结束了，话题也被穷尽，大家都倦了，但还没准备离开，也没有人说话。这是一场聚会中最无聊的尴尬时刻，所有人都期待着下一场狂欢或者回家睡觉，任何计划和目的地都比那个无聊的状态好受。我点了一根香烟，吸了一口，把喝空的杯子倒扣过来捂住喷出来的烟雾，发呆地看着杯子里烟雾的形状，从山云海雾变成白茫茫一片，直到热力学最后把那一小撮烟雾变得彻底混沌。我重新把杯子拿起来，然后重复刚才的动作。
                <br/><br/>
                整个过程里没有人说话，没有人赞同，也没有人反对。大家都沉默地看着一场毫无意义的烟雾仪式，持续了很久。大家似乎忽然意识到，无聊没那么糟糕，无聊可以被接受，甚至一个愚蠢的排遣无聊的行为也值得观赏。那个场面如此安静，如此无聊，也如此真实，仿佛大家一声不响的沉默都比喧哗的交谈所能达到的地方更清晰、更深刻、也更永久，虽然没人知道那个地方是什么。
                <br/><br/>
                无聊不是毒药，无聊甚至会引发一场因为必须直面无聊而产生的解放。你也一样，你不会在无聊的发呆中迷失方向。
                <br/><br/><br/>
                <hr/>
                <b>Reference</b>
                <p id="footnote-1"><a href="#footnote1">[1]</a> TED Talk: How to Get Your Brain to Focus | Chris Bailey: <a
                    href="https://www.youtube.com/watch?v=Hu4Yvq-g7_Y" rel="noreferrer noopener" target="_blank"
                >https://www.youtube.com/watch?v=Hu4Yvq-g7_Y</a></p>

            </p>

            <br/>

            <h6 className="App-essay-title">The Value Of Boredom</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2023</b>
                <br/><br/>

            </p>
        </div>)
}
