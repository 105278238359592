import React from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";


export default function Essay() {
    return (
        <div>
            <Helmet>
                {/*<title>Essay</title>*/}
                <title>Essay</title>
            </Helmet>
            <div className="App-essay-name">
                {/*<h6>Essay 博客  |  Life 生命 · Tech 技术 · Think 思考</h6>*/}
                <h6>Essay</h6>
            </div>
            <br/>
            <div className="App-essay-articles">
                <ul>
                    <li>
                        <Link to={"/essay/jsusage"} className={"App-essay-links"}>
                            Javascript编程This和That的用法<br/>This & That in JavaScript
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/borges"} className={"App-essay-links"}>
                            [文]博尔赫斯 · 时间的新反驳（陆经生·译）<br/>Jorge Luis Borges: A New Refutation of Time
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/mob"} className={"App-essay-links"}>
                            [文]Sivers：“我怀念黑帮年代”<br/>Essay by Sivers: “I miss the mob.”
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/irrelevant"} className={"App-essay-links"}>
                            重要和不重要<br/>Important And Unimportant
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/conspiracy"} className={"App-essay-links"}>
                            阴谋论的缺陷<br/>The Flaw of Conspiracy Theory
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/stem"} className={"App-essay-links"}>
                            理科比文科高贵？<br/>Is STEM Higher Than Liberal Arts?
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/brave"} className={"App-essay-links"}>
                            对与被AI教育<br/>Education to and from AI
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/problemonce"} className={"App-essay-links"}>
                            一个问题只应被解决一次<br/>A Problem Should Only Be Solved Once
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/ownway"} className={"App-essay-links"}>
                            以你自己的方式<br/>In Your Own Way
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/augmentation"} className={"App-essay-links"}>
                            读《增强人的智能：一个概念框架》<br/>Reading "Augmenting Human Intellect: A Conceptual
                            Framework"
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/dayone"} className={"App-essay-links"}>
                            第一天<br/>Day One
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={"/essay/imperialism"} className={"App-essay-links"}>*/}
                    {/*        历史地理的读书笔记<br/>Reading Notes on Historical Geography*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to={"/essay/agent"} className={"App-essay-links"}>*/}
                    {/*        智能代理<br/>AI Agent*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to={"/essay/craftsman"} className={"App-essay-links"}>
                            工匠：做就是想<br/>Craftsman: Making is Thinking
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/stirrups"} className={"App-essay-links"}>
                            马镫<br/>Stirrups
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/rebellion"} className={"App-essay-links"}>
                            两种叛逆<br/>Two Kinds of Rebellion
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/locomotive"} className={"App-essay-links"}>
                            火车头<br/>Locomotive
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/ofrespect"} className={"App-essay-links"}>
                            关于尊重<br/>Of Respect
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/sac"} className={"App-essay-links"}>
                            奴隶机器人与自由机械狗？<br/>Slave Robot And Free Cyborg?
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/ofdeath"} className={"App-essay-links"}>
                            关于死亡<br/>Of Death
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/crossed-north-america-again"} className={"App-essay-links"}>
                            二次横跨北美<br/>Crossed North America Again
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={"/essay/confession"} className={"App-essay-links"}>*/}
                    {/*        忏悔录<br/>Confession*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to={"/essay/company"} className={"App-essay-links"}>
                            公司<br/>Company
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/hometown"} className={"App-essay-links"}>
                            故乡<br/>Hometown
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/words"} className={"App-essay-links"}>
                            言语<br/>Words
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/ccd"} className={"App-essay-links"}>
                            创造、信心和抑郁<br/>Creativity, Confidence, And Depression
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/idol"} className={"App-essay-links"}>
                            对偶像的爱与恨<br/>Love And Hatred Towards Idols
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={"/essay/unconstrained"} className={"App-essay-links"}>*/}
                    {/*        不受约束<br/>Unconstrained*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to={"/essay/monkey"} className={"App-essay-links"}>
                            猴子<br/>Monkey
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/impact"} className={"App-essay-links"}>
                            GPT对产品界面的影响<br/>GPT's Impact On Product Interface
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/peril"} className={"App-essay-links"}>
                            追逐完美的危险<br/>The Peril of Pursuing Perfection
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/selfmotivation"} className={"App-essay-links"}>
                            如何降低我的个人积极性？<br/>How to Reduce My Self-Motivation?
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/oversharing"} className={"App-essay-links"}>
                            过度分享<br/>Oversharing
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/her"} className={"App-essay-links"}>
                            她<br/>Her
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/frontier"} className={"App-essay-links"}>
                            边界，边疆与游击战<br/>Edge, Frontier, and Guerrilla
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/nomad"} className={"App-essay-links"}>
                            游牧部落<br/>Nomadic Tribe
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/steve"} className={"App-essay-links"}>
                            史蒂夫给史蒂夫<br/>From Steve to Steve
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/antibullshit"} className={"App-essay-links"}>
                            反屁话运动<br/>Anti-Bullshit Movement
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/howtolearn"} className={"App-essay-links"}>
                            如何学习：一场浪费时间的阅读<br/>How to Learn: A Waste-Of-Time Type Of Reading
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/mpoh"} className={"App-essay-links"}>
                            谈谈热的动力和适合开发这种动力的机器（萨迪·卡诺）<br/>Reflections On The Motive Power Of Heat
                            And On Machines Fitted to Develop That Power (Sadi Carnot)
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/paradigm"} className={"App-essay-links"}>
                            推动范式转换的人的特质<br/>Traits of The Individual Pushing Paradigm Shifts
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/ilya"} className={"App-essay-links"}>
                            GPT-4发布前夜访谈Ilya Sutskever<br/>An Interview With Ilya Sutskever Before GPT-4 Release
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/existentialism"} className={"App-essay-links"}>
                            存在主义与虚无的多重宇宙：42、钝角和贝果 *<br/>Existentialism and the Multiverse of
                            Nothingness: 42, Obtuse Angles, and Everything Bagel *
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/incomplete"} className={"App-essay-links"}>
                            不全面也不平衡<br/>Incomplete And Imbalanced
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/calendar"} className={"App-essay-links"}>
                            日历<br/>Calendar
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/pathdependency"} className={"App-essay-links"}>
                            技术的惯性<br/>The Path Dependency Of Technology
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/lure"} className={"App-essay-links"}>
                            媒介的魅惑<br/>The Lure Of Medium
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/boredom"} className={"App-essay-links"}>
                            无聊的意义<br/>The Value Of Boredom
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/replace"} className={"App-essay-links"}>
                            被ChatGPT取代还是被毁灭？<br/>Replaced Or Extinguished By ChatGPT?
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/instinct"} className={"App-essay-links"}>
                            直觉<br/>Instinct
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/lessismore"} className={"App-essay-links"}>
                            少就是多<br/>Less Is More
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/knockout"} className={"App-essay-links"}>
                            关于《狂飙》（含剧透）<br/>About The Knockout (Spoilers)
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/twopeople"} className={"App-essay-links"}>
                            两类人<br/>Two Kinds Of People
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/imaginationandtech"} className={"App-essay-links"}>
                            技术与想象力<br/>Technology And Imagination
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/generation"} className={"App-essay-links"}>
                            一代人<br/>A Generation
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/mechanical"} className={"App-essay-links"}>
                            机械美学<br/>Mechanical Aesthetics
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/road"} className={"App-essay-links"}>
                            秦蜀道<br/>Qin Shu Road
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/bigcat"} className={"App-essay-links"}>
                            大型猫科动物<br/>The Big Cat
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/prv"} className={"App-essay-links"}>
                            减压阀<br/>Pressure Relief Valve
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/inmemoryofandrea"} className={"App-essay-links"}>
                            纪念Andrea Marie Chegut<br/>In Memory of Andrea Marie Chegut
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/doombutwill"} className={"App-essay-links"}>
                            注定死亡但会活着<br/>Doomed to Die But Will Live
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/oneandanother"} className={"App-essay-links"}>
                            一个问题和另一个问题<br/>One And One Other Question
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/think"} className={"App-essay-links"}>
                            思考的自由与特征<br/>The Freedom And Traits Of Thinking
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/englishstudy"} className={"App-essay-links"}>
                            英语学习<br/>Study Of English
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/goodnight"} className={"App-essay-links"}>
                            良夜<br/>Good Night
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/nopurpose"} className={"App-essay-links"}>
                            漫无目的<br/>No Purpose
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/atthisage"} className={"App-essay-links"}>
                            在这个年纪<br/>At This Age
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/analyticaltheoryofheat"} className={"App-essay-links"}>
                            热的解析<br/>Analytical Theory Of Heat
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/poa"} className={"App-essay-links"}>
                            自负的毒性<br/>The Poison Of Arrogance
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/qaboutse"} className={"App-essay-links"}>
                            对科学教育的困惑<br/>Questions About Science Education
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/architectandhacker"} className={"App-essay-links"}>
                            建筑师与黑客<br/>Architect And Hacker
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/valueofexpression"} className={"App-essay-links"}>
                            表达的价值<br/>The Value Of Expression
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/ghostinthemachine"} className={"App-essay-links"}>
                            机器中的幽灵<br/>Ghost In The Machine
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/awildfeline"} className={"App-essay-links"}>
                            野生猫科动物<br/>A Wild Feline
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/machineryandrevolution"} className={"App-essay-links"}>
                            机械与大革命<br/>Machinery And Revolution
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/whatquestionsworthanswering"} className={"App-essay-links"}>
                            什么问题值得回答？<br/>What Questions Are Worth Answering?
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/ornamentandcrime"} className={"App-essay-links"}>
                            装饰与罪恶<br/>Ornament And Crime
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/whattolearn"} className={"App-essay-links"}>
                            应该学什么？<br/>What To Learn?
                        </Link>
                    </li>
                    <li>
                        <Link to={"/essay/crossnorthamerica"} className={"App-essay-links"}>
                            横跨北美<br/>Crossed North America
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
