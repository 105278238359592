import React from "react";
import {Helmet} from "react-helmet";

export default function Brave() {
    return (
        <div>
            <Helmet>
                <title>对与被AI的教育 Education to and from AI</title>
            </Helmet>
            <h6 className="App-essay-title">对与被AI教育</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 八月</b>
                <br/><br/>
                <i style={{color: "grey"}}>技术就是在你出生时还不存在的任何东西。——艾伦·凯</i>
                <br/><br/>
                <i style={{color: "grey"}}>Technology is anything that wasn't around when you were born.——Alan Kay</i>
                <br/><br/>
                <b>Brave New Words</b>
                <br/><br/>
                Brave New Worlds（要看细致），作者赫胥黎，中译名《美丽新世界》。他在近100年前描绘了一个被圈养的异托邦，人类及其胚胎都只是工具，而非目的本身，和今天怪异得相似。
                <br/><br/>
                可汗学院的创始人萨尔·可汗（Sal Khan）最近出版了一本新书——Brave New Words，直译：《勇敢新观点》，和赫胥黎‘Brave
                New Worlds，只差一个字母 l。
                <br/><br/>
                中信出版社最近翻译了这本书，译名为《教育新语》。译名少了些冲击力。
                <br/><br/>
                比起赫胥黎大笔如椽，可汗也少了十足的批判力，但可汗试图想象一个不同于赫胥黎笔下，那个令人毛骨悚然的反乌托邦。我们也宁愿希望，那个AI增强的世界才是真的。
                <br/><br/>
                AI是一个概率计算器，这个概率计算器利用有史以来最庞大的服务器集群，对人类知识进行高度压缩。这套新的服务器集群大到什么程度？每几个月旧芯片就会被淘汰；我们需要爆发式的工业建筑设施，才能容纳这些服务器设备；人类需要完全不同以往的电力能源尺度，才能支持这个等级的人工智能，被训练、以及被部署后24小时的推理运转。
                <br/><br/>
                AI是对知识内容进行的高度压缩。同时也是一个在本地和云端，都可以随时随地，对人类输入的问题进行概率演算的计算装置。比起互联网，AI更像一种高度压缩、可随身携带的新型计算机。作为一种新的计算装置，AI不是互联网。互联网本身需要改进的方面，人工智能无法替代完成。
                <br/><br/>
                互联网革命是人与人生产关系的革命。而AI革命从0到1更多影响的，是人和机器的连接方式，换句话说，AI革命是PC革命。最近很多声音也说：这次人工智能革命，更像个人计算机刚出现时的机遇；更像是个人电脑 vs 大型机；更像刚出现了一种革命性的廉价芯片（soft chip），更像一种新式的搜索引擎；其次，人工智能革命会影响互联网本身。
                <br/><br/>
                人类只需要被互连一次，互联网的出现是从0到1，后续的发展是从1到10000。但AI让互联网的形态和规模被改造的程度依旧相当可观，不能低估AI对人类互联方式的改造。但首先，AI会塑造人和机器的交互方式。
                <br/><br/>
                AI模型，是凤毛麟角的科技大公司，和寥寥几个估值过百亿的初创公司，经过数月高强度的数据清洗、训练、测试之后，部署在云端，或公开模型架构和权重，分发给大众使用的“基础人类知识库”。这些智能是这些公司的所有者和工程师们，对智能的认知和理解偏好，所共同塑造的产物。
                <br/><br/>
                这些神秘的模型，不代表所有人。
                <br/><br/>
                这些AI智能的压缩包，文本大多以Transformer架构呈现，图像以Diffusion模型呈现，有如下特征：
                <br/><br/>
                <ul>
                    <li>每次的输出都是一次概率计算；输出越稳定，创造力也越低</li>
                    <li>AI模型的知识压缩包里，含有大量的价值观，时而“显而易见”，时而“讳莫如深”</li>
                    <li>这些“价值判断”的倾向，很大程度上，被最初输入的训练数据所影响</li>
                    <li>后期微调（Fine-Tuning）可以一定程度地对模型再塑造，但无法从根本上改造</li>
                    <li>在需要严谨的数理推理和精确计算时，AI模型的判断不可靠，且非常低效。有多低效？可以想象一下，如果我们在Minecraft游戏里，用高度消耗GPU计算资源的、被计算机渲染出的立方块，再重新构建一个CPU是什么感觉，就能大概明白这种“低效”意味着“多低”</li>
                    <li>模型的“可靠性” vs 模型的“创造力”，两者此消彼长</li>
                    <li>模型的“准确” vs 模型的“效率”，模型越准确效率越低，一个本身已经足够精确的指令问题不需要模型“揣摩”概率</li>
                </ul>
                <br/>
                我们对AI进行的训练，是人对AI的教育。AI技术，是被掌握着训练设施、训练方法和分发渠道的少数人群，直接代表大众对AI进行的教育。相比庞大的人口，他们在财力和技术上更优越，但并非在智识上更优秀——这是极为重要的一点。但在技术的效率智慧上，你应该相信他们的判断。
                <br/><br/>
                反过来，这些被“教育”的AI，正在对我们所有人，尤其是我们的下一代，进行反向教育。AI对我们是一项技术，让我们迷惑又警惕。但对于新生儿，AI只是大自然的一部分，他们视之为理所应当因此毫无防备。
                <br/><br/>
                大家应该看到这里隐含的问题了：AI是一种高度集中的智力分发，但却只被相当少的一部分人塑造。AI技术和这个世界上发生的其他大多数事情，没有本质区别，都非常需要分散风险。
                <br/><br/>
                分散风险的方式有两种：
                <br/><br/>
                <ol>
                    <li>让更多的人接触并参与影响这项技术。允许大众塑造、监督、判断、改进这种强有力的智能，会增加其安全性。</li>
                    <li>我们需要真正优美的产品，像缰绳一样驯服胡言乱语的AI，让这股失控的力量像220V电流一样为我所有。</li>
                </ol>
                <br/>
                {/*<i>To be Continued...</i>*/}

            </p>

            <br/>

            <h6 className="App-essay-title">Education to and from AI</h6>
            <p className="App-essay-article">
                <br/>
                <b>Aug 2024</b>
                <br/><br/>

            </p>
        </div>)
}
