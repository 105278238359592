import React from "react";
import {Helmet} from "react-helmet";

export default function IncompleteAndImbalanced() {
    return (
        <div>
            <Helmet>
                <title>不全面也不平衡 Incomplete And Imbalanced</title>
            </Helmet>
            <h6 className="App-essay-title">不全面也不平衡</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 三月</b>
                <br/><br/>
                <b>不平衡的动力</b>
                <br/><br/>
                为什么中国人做不出chatGPT？其实我对这个问题不感兴趣，这个问题太无聊了。因为答案很简单，而且没有解决办法。我们在这里应该问另一个问题：为什么中国人非要做出来chatGPT？为什么美国人不分享，还要让别人重新发明一遍已经被发明出来的技术？我没在开玩笑，人们能问出如何重新独立发明一项技术的问题本身，就是文明合作极不充分的映射。
                <br/><br/>
                所以我们应该敦促硅谷的科技巨头赶紧开源OpenAI、分享能造福人类的科技创造、同时分散人工智能的潜在风险，尽早把核武器变成核电站。威慑、制衡、合作，大家才能都享有长久的和平，所以应该警告OpenAI这类科技企业不要闭门造车！
                <br/><br/>
                这段话是在说反话吗？还真不是，我没在开玩笑。呼吁chatGPT赶紧开源确实能助力科技扩散的福祉，就像特斯拉能激发一场能源革命的原因之一就是特斯拉技术的全面开源。OpenAI认为扩散AI会被用于作恶，就是一种预设的性恶论，如果不相信人性的善能战胜恶，这些人从哪里来的精神动力进行这些孜孜不倦的创造？真让人迷惑。
                <br/><br/>
                这里隐含着一个很少被思考的问题：即使美国人发明了技术，但哪里说明了美国人一定就是最优秀的控制者和利用者？（你如果是无脑支持某一方并拒绝思考的人，就不用往下看了）有几十亿人接触不到这项技术，本身就是一场无声进行的科技剥削。但有目共睹的是，美国人明显提供了更高效的创造环境，也就像中国人无可辩驳得提供了更纯熟的组织技术和应用技巧。
                <br/><br/>
                所以让我们再问这个问题：如果一部分人发明出了一项技术，为什么不拿出来共享？难道人类的电力能源、GPU、脑力资源是多得需要被浪费吗？答案很简单：因为美国人觉得自己全面又平衡，就像中国人也得被迫认为自己也必须全面又平衡一样。这两个组织都同时体现了最高水平的傲慢，这是过去发生并还在延续的贸易战的根源（但必须再次强调，这场斗争确实是“高度文明的”美国人发起的）。事实上，这两者谁都不全面，也一点儿都不平衡。
                <br/><br/>
                被拟人化的国家和人一样，之间存在非常明显的比较优势。工业革命期间英国的纺织机比葡萄牙的高效，所以英国人在工业革命里可以向葡萄牙输出纺织品，反过来葡萄牙向英国人提供食物，因为食物生产是葡萄牙的比较优势。一个简单的加减乘除就能决定合作的整体效率比独立全面要高。但在今天，孤立竞争击败了贸易合作，这是今天所有人都要面对的问题：国家、组织、公司、家庭、个人，必须越来越全面，必须越来越自我平衡，必须被迫独立来削减对外界的依赖，全面均衡正在成为时代主题。“全面均衡”号召所有国家：文明必须自我第一，国家必须健全强大，能力必须全面完整，投入产出必须合理平衡。
                <br/><br/>
                <b>不平衡的创造力和制造力</b>
                <br/><br/>
                苏联教育对基础教育的训练极其严苛，这造就了苏联人在科学创造上的硬核贡献，但是每个人都过于全面的能力并没有催化出计算机的诞生，而这最后造成了苏联的复杂后果。
                <br/><br/>
                苏联人没有先进的计算设备，所以苏联人的基础数理教育一直遥遥领先西方，因为他们需要用人力对抗机器。直到计算机的出现把苏联人的计算优势消解得一文不值。苏联人率先创造了隐形战斗机的关键设想，但他们因为没有充足的计算能力所以率先实现隐形技术的国家是美国。苏联工程师们有着强大的基础计算能力，但是没有任何苏联人能算得比计算机快；同理，也没有任何一个晚清的武术家能跑得比子弹快。
                <br/><br/>
                在这些历史场景里，创造力极度不平衡地被赋予了美国人。但美国人为自由创造付出了高昂的制度成本，它的内核就是鼓吹松散无纪律，在任何需要服从团结的生产活动中美国毫无优势，而这些场景对人类生存有着极其重大的意义（美好生活的基础非常需要这些纪律）。所以引导制造业回流到这样一个国家，像一个正在发生的笑话。
                <br/><br/>
                <b>不全面的人</b>
                <br/><br/>
                生存是一场重大挫折，有限的资源、无穷的困难、不可战胜的命运，一个人很难独自活下来，无论你多么强大，任何完全脱离了群体社会并拒绝接受他人的体力或脑力劳动成果的人，都难以为继。
                <br/><br/>
                如果一个人号称自己全面发展、制约均衡、无所不能，你应该警惕这些人包含的虚伪和风险。不能否认有些人确实完美得像书里走出来的，但这种全面完美和宏伟庞杂的现实相比简直不值一提。我们是既不全面也不完整的一群人，我们是相当失衡的无数个体组成的庞然巨物。在拼装组织和完善制衡中，人们才勉强组成了一堆时而运转，时而失灵的庞大机器。这个庞大组织时而进化也时而退化，会诞生也会消亡。但对全面平衡的追求几乎贯穿在现代人的一生中。
                <br/><br/>
                这种全面平衡体现在现代人的感情里，一个全面完整独立的人想寻找另一个对手或等价物一起生活，似乎从中可以产生叠加效果。好似米格战斗机对F-117；航母群对核潜艇；感情要发生在两个质量相等、电量相反、相互围绕旋转的正负电子之间。但人们在一起生活的原因是因为大家都很残缺，而不是人都是全面又完整的。有哪一段情感是全面平衡的？这本身就非常值得怀疑，强弱、黑白、好坏，这些组合在差异化下才有意义。
                <br/><br/>
                <b>不全面的认知</b>
                <br/><br/>
                全面发展的谎言在于对“全面”这个词语的过度粉饰，没有人是全面的，被形容成全面完美的那些人，把他们扔在野外，没有社会组织的支持，这些“全面发展”的动物连12个小时都撑不下来。对无尽复杂的现实而言，“全面平衡”是一个理应让人警惕其无所不包程度的庞杂词汇。
                <br/><br/>
                我们理解的“全面”非常狭隘，实际上这个真正的“全面”像一块巨大的面包，我们像面包里的一条条蠕虫，在周身奋力啃出一条条细长的甬道，这些小径无论对这条蠕虫有多么宽阔敞亮，相对那块无边无际的面包和真实世界在“全面”尺度上的全貌，都显得可笑又微不足道。如果你时常觉得自己全面又平衡，无所不能，那也请停止欺骗自己，我们可能只是那块巨型面包中的一条蠕虫罢了。
                <br/><br/>
                如果我们承认这种不全面和不平衡，允许他们发生，甚至培育合作意识来呵护不全面和不平衡，那么就能天然看到合作是所有人的生存现实，但这个真相被掩盖得太久了，以至人们自大到忘记了自己必须依靠其他人才能活下来。远方发生的人和事，真的和你息息相关。
                <br/><br/>
                但我们是如何变成今天这样的？一个合理的猜测是过分强调全面平衡的竞争教育是造成这个状况的根源之一。
                <br/><br/>
                <b>全面的教育</b>
                <br/><br/>
                想起这些，强调一切都要全面发展的应试教育应深感危机。我们最需要全面教育的东西只有一样：对人的教育——让每个人都深刻认识到自己有多不全面、多不完整以及多么不平衡，然后保护每个人被给予的天资禀赋所包含的不完整和不平衡，因为老天注定要让大家按不同的方向探索生命，然后协作共渡这孤独又危险的一生。这或许也是社会组织前进的唯一动力：大分工后协作，而不是全面同化后叠加。
                <br/><br/>
                通过一系列的论证，为中国人为什么没有率先做出chatGPT的问题已经作为一个次等质量的问题被回答了：因为过分强调全面均衡会抑制某些不平衡的发展，而这些不平衡的发展是创造力的沃土。所以重新思考全面、独立、平衡这些话题，会很容易发现社会大分工造成了农业文明、工业革命和信息时代，我们难道还要欺骗自己靠一个人可以办成所有事？所以这里的第一个教训就是警惕“全面独立”的态度，促进协作才是更高效的文明形式；第二点是保护不平衡和不完整的个体——这些人蕴含着社会飞跃的可能和未来大分工的前奏。
                <br/><br/>
                所以，我们所有人真正应该问的是另一个问题：为什么中国人总在问“中国人做不出XXX”？为什么不名正言顺地问“美国人为什么不开源”，美国人为什么不进行比较优势下的贸易交换？甚至更重要的问题是：我们如何创造出一个不以追求全面平衡为目的的世界？在那个世界里，根本不需要问出“中国人为什么做不出XXX”这类问题，因为本身就没有任何国家能独立创造出所有东西，国家和人一样既不全面也不平衡。
                <br/><br/>
                所有系统都需要交换合作，你能发明出引擎，了不起，但你也需要另一个人能把引擎功率开到最大。如何创造一个不孤立、尊重差异、不追求面面俱到、互通有无的新世界才是真正的问题。

            </p>

            <br/>

            <h6 className="App-essay-title">Incomplete And Imbalanced</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2023</b>
                <br/><br/>

            </p>
        </div>)
}
