import React from "react";
import {Helmet} from "react-helmet";

export default function IlyaInterview() {
    return (
        <div>
            <Helmet>
                <title>推动范式转换的人的特质 Traits of The Individual Pushing Paradigm Shifts</title>
            </Helmet>
            <h6 className="App-essay-title">推动范式转换的人的特质</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 三月</b>
                <br/><br/>
                范式转换，"Paradigm Shift"，这个词语从1962年托马斯·库恩（Thomas Kuhn）在他的名著《科学革命的结构》（The Structure of Scientific Revolution）一书中通过对物理科学史的研究从哲学范畴介绍到科学范畴后，日常英语对“范式转换”这个词的滥用就开始了。和Paradigm Shift这个词一同火起来的还有Revolution（革命）这个词。这些词语曾深度捆绑的是“科学革命”这种等级的变化，类似农业到工业的跨度飞跃，而现在则和注水的职位一样，充斥在自我介绍和广告词中。
                <br/><br/>
                所以让我们先把这个词语的含义校正到最狭义范畴的“范式转换”：在物理学中，一种从牛顿经典时空到相对论时空这种等级的范式转换。这两种范式的差异不仅仅是对自然认知程度的极大加深，反而更像是两种截然不同的世界观，两种范式存在的差异甚至是完全不相融的。
                <br/><br/>
                范式转换带来的世界巨变，你能在每一页历史书中找它们的庞大影子，近现代史尤为如此，我们每个人今天依然生活在这本历史巨著的新一页中。
                <br/><br/>
                需要强调，能引发范式转换的不只是一个人，而是一群人，比如艾萨克·牛顿。他是站在文艺复兴的前辈们——哥白尼、第谷、开普勒、伽利略、笛卡尔……从这些巨人的肩膀上，他才得以统一天上的星星和地上的苹果。但即使把这些巨人都加起来，相比范式转换的巨大影响，促成“范式转换”的个体数量相较庞大的人口基数依然太过稀罕，这不得不让人好奇能引发这类范式转换的个体都有什么特征，或者最好能发现这些人有什么共同特点。
                <br/><br/>
                从表象上看，除了这是一群极度聪明的个体之外，哥白尼细致审慎，伽利略善良前卫，牛顿冷漠孤僻、爱因斯坦幽默活泼、费曼诙谐机智……他们的外在性格千奇百怪，和我们芸芸众生区别不大。
                <br/><br/>
                传记作家James Gleick给牛顿和费曼两位科学巨人写过生平传记，还比较了两位科学家的特征。牛顿一辈子没有娶妻，性格孤僻，为人傲慢，后世甚至认为他应该没有过性生活；相反，费曼娶过三任妻子，会跳舞会打手鼓，是酒吧里最引人注目的“费曼先生”。从表象上看，这两个科学巨擘毫无共通之处，甚至性格迥然相异。但Gleick通过深入研究两位科学天才做研究的心理和精神状态，他发现了这两个人的三点相似之处：
                <br/><br/>
                1.Aloneness：孤独，他们做科学研究时几乎总是孤身一人。
                <br/><br/>
                2.Unimaginable Intensity of Concentration：常人难以想象的高度专注。
                <br/><br/>
                3.Passion for Abstraction：对抽象思考的激情。
                <br/><br/>
                现在，让我们把范式转换的范围扩大，从狭义的物理世界里天摇地动的“范式转换”中抽离出来，放置在一个更宽泛的“范式转换”中——事物从曾经被执行、操作和对待的方式，变成另一种全新的，甚至相悖的，被执行、操作或对待的方式。
                <br/><br/>
                这样一来，促进“范式转换”的人群从大物理学家扩散到了伟大政治家、天才诗人和作家、画家、音乐家、哲学家、革命领袖、伟大企业家……那么，李白，杜甫，王安石，巴赫，康德，莫扎特，拿破仑，歌德，托马斯·潘恩，拜伦，夏洛蒂·勃朗特，梵高，马克思，邓小平，玛丽·居里，贝隆夫人，乔布斯，马斯克……这些人都应该赫然在列。
                <br/><br/>
                同理，除了这是一群极为聪明的人之外，这些千差万别的个体确实有一些共通特征。首先，是性格与认知造成他们很难服从，ta们总能发现事物的不合理之处；其次，是对目标的纯粹激情，无论是抽象的还是具体的，同时这个目标也几乎总能通过各种方式对人类产生很大价值（这点甚至无法提前预测）；第三点，是对孤独和挫折难以想象的忍耐，这也是推动范式转换的人的力量源泉。综合起来可以得出的一个初步结论，能推动范式转换的人有三个共同特质：不服从，对有可能产生价值的目标的纯粹激情，对孤独和挫折难以想象的忍耐力。
                <br/><br/>
                这三个特征共存的情况很罕见。首先，有忍耐力的人往往很听话，因为吃苦已经很费劲了，很难有多余的力气去思考该忍耐什么，因此有忍耐力的人经常选择听别人的话，也就是直接接受他人或社会的安排。相应的，不听话的人往往又不能忍耐，因为很多不听话的人不服从的最初动机就是不想吃苦：懒得学习、懒得训练、懒得思考，这类不服从更接近性格软弱，所以不能想当然认为不服从的人想做的事就一定有任何创新精神或革命意义；更不要提他们很可能本身就是缺乏毅力的人，让这些人去执行不服从的意志，实在是个笑话。
                <br/><br/>
                因此，如果只用两句话总结推动范式转换的人的共有特质，那就是：不听话，能吃苦。但这两个特质也很难同时出现在一个人身上，正如上述分析，它们根本上是相悖的。同时，这个人的激情和目标还需要和有价值的问题吻合，而有价值的目标到底栖身何处，像电子一样难以预测，因此更是难上加难；此外，这个人最好智力超群。综合来看，这简直难如登天。

            </p>

            <br/>

            <h6 className="App-essay-title">Traits of The Individual Pushing Paradigm Shifts</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2023</b>
                <br/><br/>

            </p>
        </div>)
}