import React from "react";
import {Helmet} from "react-helmet";

export default function WhatQuestionsAreWorthAnswering() {
    return (
        <div>
            <Helmet>
                <title>什么问题值得被回答 What Questions Are Worth Answering</title>
            </Helmet>
            <h6 className="App-essay-title">什么问题值得被回答？</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 五月</b>
                <br/><br/>
                中国老师都会对孩子们说，学习像盖房子，要打好基础。这句无比正确但重复过太多遍的劝诫，听起来毫无新意。小孩子永远被新奇有趣的知识和脑筋急转弯吸引，基础知识是那些不聪明的孩子听不懂才不断问起来的“次等真理”，我们聪明人早在娘胎里就学会了，大家都这么告诉自己。
                <br/><br/>
                而事实上，真正的天才就像彻头彻尾的傻子，他们都只对最基本的问题“执迷不悟”。比如，如果我跑得像光一样快，会发生什么？爱因斯坦问。当然我不是来重复这些古老智慧和人尽皆知的故事，但我有强烈的意愿去证实这些中国老师们没有说谎。“打好基础”这句口口相传的老话有种神奇的力量。当然，看着这篇文章，多年后老师们会意味深长地说：我早跟你说过了！我不介意他们洋洋得意，因为这是真的。
                <br/><br/>
                如果把注意力从爱因斯坦再次放到物理世界，另一个伟大的物理学家理查德·费曼在他的物理学讲义里写了一个小问题：“如果人类所有的知识都将被毁灭，有且只有一条物理学定律可以被保留下来，那应该是哪一条？”费曼的答案是：“这个世界是由原子组成的。”这个精辟的问答说明了一个深刻的洞见，如果能够回答一个足够基本的问题，那么从微观世界到宏观世界，这个简单问题的答案可以推导出大量有用的复杂结论，从而使得一些基本问题的解可以充分有力地告诉人类这世间一切到底如何运作。
                <br/><br/>
                费曼在麻省理工读博期间被曼哈顿计划招募去造原子弹，那年他24岁。八十年前的年轻物理学博士去田纳西州造原子弹，八十年后的年轻物理学博士去华尔街造金融衍生品，其实他们可能都在回答性质同样根本的问题，之前的是：如何快速终结一场战争？他们做到了，甚至有些一劳永逸。所以现在的问题变成了：战争结束了，我还能做些什么？这种对比或许略有愤世嫉俗。但若能充分回答一个足够基本的问题，其影响是强有力的。如果时间有限，我一定会尽力选择回答那个更基本的问题。
                <br/><br/>
                让古希腊人痴迷数个世纪的美妙几何，由假设了几个最基本的问题构成：过两个点有且只有一条直线，平行线永不相交，线段可以向两个方向无限延长，等等。回答了那五个极其简单的基本公理，欧式几何的殿堂就造好了。类似几何定理，在发现了面积公式和勾股定理后，古埃及人由此获得了计算不规则土地面积并进行分割的能力，于是尼罗河两岸的肥沃土地终于能被清楚地分配和管理，国家的雏形出现了。从尼罗河的上游到下游，有了这套基本的数字系统，帝国管理的技艺变得日益成熟。
                <br/><br/>
                相似的，回答车轮的间距有多宽，文字应该怎么写，一斗米到底有多少，都是相似的基本问题，这些基本问题的解把一个分裂松散的东亚联盟改造成有史以来最长久的中央政权。
                <br/><br/>
                指南针和航海术回答了如何准确辨认方向和利用风力的基本问题，人类开始大规模地安全穿越茫茫无迹的海域。枪炮的发明比火药的发明晚得多，因为这种爆炸物质并不容易控制，枪炮回答了火药没能回答的基本问题——如何控制这股爆炸产生的野蛮力量，并让它们准确伤害对面的敌人而不是自己？西方人通过不断优化枪炮“如何控制火药”这个基本问题的答案，开启了长达五百年的海外征服和殖民史。
                <br/><br/>
                内燃机回答了燃料如何在密闭空间内稳定安全地转化为动力的基本问题，人类开始解放四肢，高效地横跨山脉和大陆，不流一滴汗地从波士顿到西雅图；人类得以自信地穿越大片水域，环绕地球航行，甚至把外星的岩石土壤带回地球。
                <br/><br/>
                核武器回答了核能如何被释放的基本问题，剑拔弩张的国家间第一次对全面战争失去了兴趣，甚至迎来了人类历史上不多见的长期和平，而我们今天要回答的问题和火药的问题非常相似——如何控制这股惊人的能量，让它能带来温暖的食物和交流电，而不是把我们汽化成一屡烟。当然，被质问的从来都不只有基本的科学技术，还有那个悬而未决的政治技术。但毫无疑问，每一次当某些最基本的问题被回答，都会带来社会剧变。
                <br/><br/>
                近几十年横扫一切的互联网，也只是人们回答了如何通过电缆和电磁波来进行不用面对面接触的交流，这改变了人类通信的媒介。如果媒介这个词过于抽象，那么请你想象一个没有互联网的世界，再想象一个没有电话线的世界，再想象一个没有报纸和信件的世界，再想象一个没有纸张的世界，最后想象一个没有语言的世界。每一次对“如何让媒介变得廉价且易于获取”这个基本问题进行创造性地回答，世界都会被颠覆，旧的组织关系被消灭，新的生产方式被构建。
                <br/><br/>
                回答最基本的问题还能让人谦卑，因为一个最自以为是的自大狂，也要在这些问题前低头，诸如：食物一定需要收割长出来的植物或宰杀动物么？有比核能更强大的能源么，它藏在哪里？如何能让人类进行光速旅行？——人类能够安全地音速旅行，不是光速，都是非常困难的，有史以来飞行过的商用超音速客机也只有两倍左右的音速，这个速度只有光速的百万分之二。宇宙的尽头在哪儿？还有别的智慧生物么？
                <br/><br/>
                牛顿说他是在大海边玩石子儿的孩童，面对自然的汪洋大海视而不见。这不是自谦，是人类的现实。当然，也不必所有基本的问题都必须很宏大，解决一个工作问题，钻研一项技术，开办一家公司，服务一个社区，由小到大，“基本”是一个相对概念，而回答更基本的问题总能回报更大。
                <br/><br/>
                研究1+1=2，比了解8954的963次方如何计算对思维的锻炼大得多。对此的第一反应是反直觉的，但仔细思考确实如此，8954的963次方是由无数个1+1=2得出来的，如果1+1不等于2，那么8954的963次方的计算方式也会变得很不一样。1+1作为最纯粹的逻辑推理并没有因为外表简单而失去价值和复杂度，能够优美地回答1+1这个基本问题可以极大地提高回答其衍生问题的效率。最有趣的是，大多数人不愿意回答“基本的”问题，大家并不是觉得这个问题难，而是觉得太简单没有必要回答，如同人们常说的：这就像1+1=2那么简单！
                <br/><br/>
                但是这也衍生出另外三个问题：如何判断这个问题是否足够基本？怎么回答一个基本问题？以及，在回答一个基本问题时，是智力重要，还是努力重要？但这三个衍生问题是否值得被回答，要取决于文章标题的“什么问题值得被回答”的答案是否是“那些最基本的问题”，这个判断的权利在你自己。
                <br/><br/>
            </p>

            <br/>

            <h6 className="App-essay-title">What Questions Are Worth Answering?</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2022</b>
                <br/><br/>
                Chinese teachers often stress a point to kids that learning is like building a house, and you have to
                lay a firm foundation. The very correct but unoriginal advice sounds unattractive. Smart kids are always
                intrigued by interesting puzzles and brain teasers, and the basic knowledge is for their mediocre peers
                who cannot grasp the point quickly enough. Brilliant ones should have understood it before they were
                born, we kids thought this to ourselves.
                <br/><br/>
                But in fact, the true genius resembles a complete fool in many ways that they are both always struggled
                with basic questions. For instance, what would have happened if I traveled as fast as light. Albert
                Einstein asked. To clarify, I did not mean to repeat a well-known story or re-stress old wisdom, but I
                felt compelled to prove that my Chinese teachers were not lying. The old—laying a firm foundation—saying
                is not just some cliche as many others, and it might work as a silver bullet. Undoubtedly, my teacher
                will look at me like seeing a self-fulfilling prophecy and say: “I told you so.” But I am not going to
                mind that, considering the advice is actual.
                <br/><br/>
                Let’s refocus on physics from Albert Einstein. Another great physicist Richard Feynman once framed a
                question in his physics lecture notes—which law of physics should we pass on if there is a destruction
                of all knowledge. Feynman answered himself that the world is made of atoms. The incisive Q&A reveals a
                great insight, where many complicated and valuable deductions from macroworld to microworld can draw
                from a fundamental problem. A solution to a basic question is powerful enough to guide humans in finding
                out how the world works.
                <br/><br/>
                Feynman was recruited as an MIT Ph.D. student to participate in the Manhattan Project to bring about the
                atomic boom when he was 24. Eighty years ago, the young Physics Ph.D. went to Tennessee to build nuclear
                weapons. While eighty years later, the young Physics PhD.s went to Wall Street to devise financial
                derivatives. Still, they could all be answering a question of similar fundamentality. The former one
                was, “How to quickly end a war?” And they provided an answer so well that the current question becomes,
                “What else could I do without a war?” The comparison might be a bit cynical. But adequately answering a
                fundamental question almost always renders far-reaching implications. If the time to solve problems is
                limited, I would like to spend it on those fundamental ones.
                <br/><br/>
                The elegant Geometry that fascinated Ancient Greek for centuries was built upon several uncomplicated
                hypotheses. They are like “one, and only one line can be drawn passing through two given points,”
                “parallel lines never intersect,” “A line is straight and extends infinitely in the opposite
                directions,” etc. Via making those five fundamental hypotheses, the temple of Euclid Geometry was
                constructed. Like Geometry, when the area equation and Pythagorean theorem came to light, the Egyptians
                acquired the skills to calculate and divide irregular land. For the first time, the Egyptians could
                allocate and manage the fertile area around the Nile River, upon which an empire was taking shape. From
                the upside to the downside of the Nile River, the imperial bureaucracy matured, facilitated by this
                numeric system.
                <br/><br/>
                Similarly, asking and answering the questions like the distance between two wheels, what a written
                character should look like, and how much a bucket of rice is are all fundamental. The solutions to the
                above questions unified a loosely connected federation in East Asia. The solution bonded them into one
                of the most long-lived central regimes in human history.
                <br/><br/>
                The use of the compass and the art of navigation answered a fundamental question about how to tell
                direction and utilize wind power. Humans were able to sail across boundless oceans safely and massively.
                The invention of the gun was later than the creation of gunpowder because the method to control the
                power was the gun itself. The gun answered the question that the powder could not answer—how to hurt the
                enemies standing before me but not myself precisely. The Westerners began their oversea conquest and
                colony for almost five hundred years by refining the answers to the gunpowder question.
                <br/><br/>
                The internal combustion engine answered a fundamental question about how to stably and safely transfer
                fossil fuel to kinetic energy in a closed space. Humans started to free up all limbs and leap over
                mountains and continents efficiently, traveling from Boston to Seattle without breaking a sweat.
                Humans confidently sailed across every large stretch of water, navigated the globe, and even brought
                back rock and earth samples from other planets.
                <br/><br/>
                Nuclear weapons answered a fundamental question about how to unleash the energy in the nucleus. The
                total war turned into yesterday’s history between even the most combative and hostile nations. For the
                first time in human history, long-time peace emerged by answering a basic question. Today, we are asking
                a question that resembles the question of gunpowder—how to tame this tremendous nuclear energy and make
                it bring us warm food and electricity but not evaporate us to a trace of smoke. Indeed, the interrogated
                party is not only fundamental science but also a political technique that stays unresolved mainly.
                Undoubtedly, there would be a social upheaval whenever an adequate answer was given to the fundamental
                question.
                <br/><br/>
                In recent decades, the Internet swept almost every industry by answering a fundamental question about
                how people could communicate through fiber optic cables and radio waves without meeting each other.
                Internet altered the communication media. If the word “media” is unduly abstract, please imagine a world
                without the Internet. Then imagine a world without telephone lines. Then imagine a world without
                newspapers and mail. Then imagine a world without writing papers. At last, imagine a world without
                language. Whenever providing a creative answer to how to make communication media cheap and accessible,
                the world turned upside down—old institutions perished, and a new mode of production formed.
                <br/><br/>
                Contemplating a fundamental question humbles the worst megalomaniac. Such questions are, for instance,
                must food come from harvesting crops or killing animals? Is there a more powerful energy source than
                nuclear energy, and where is it? How to travel at the speed of light—as of today, humans can travel at
                supersonic speed, and an ever-flied supersonic commercial airline could travel at Mach 2, which is two
                times the speed of sound and two in a million the speed of light. Where is the boundary of the universe?
                Is there any intelligent extraterrestrial life?
                <br/><br/>
                Isaac Newton said he was a boy playing pebbles and shells on the seashore, unaware of the great ocean
                of truth before him. He did not humble himself. It looks more likely that this is humanity’s reality
                check. Of course, not everything has to be so grand. There are plenty of questions worth answering in
                solving a work problem, studying a piece of technology, starting a business, and serving a community.
                Fundamentality is a relative measure, and answering a more fundamental question always generates a
                bigger reward.
                <br/><br/>
                Studying 1 plus 1 equals 2 is significantly more training to mind than computing 8954 to the power of
                963. Though it seems counter-intuitive, it is true when thinking about it more—8954 to the power of 963
                depends on the result of 1 plus 1. As 1 plus 1 does not equal 2, the method used to calculate 8954 to
                the power of 963 will be entirely different. As a pure logic hypothesis, 1 plus 1 does not lose its
                value or profound implications, given its simple form. An elegant solution to a 1 plus 1 problem would
                give the advantage of answering related high-level questions. Most interestingly, people are not
                enthusiastic about answering a fundamental question, not because they are overwhelmed to solve it, but
                because they find it too easy. Some people would always say that it is as simple as 1 plus 1 equals 2.
                <br/><br/>
                The conclusion also implies other sub-questions, how to determine whether a question is fundamental
                enough, how to answer one such question, and which one of intelligence and effort that matters more when
                solving one. Yet, do these three sub-questions are worth answering much depends on whether the question
                in the title is indeed the fundamental one. Again, the judgmental call is yours.
                <br/><br/>
            </p>
        </div>
    )
}
