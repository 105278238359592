import React from "react";
import {Helmet} from "react-helmet";

export default function MachineryAndRevolution() {
    return (
        <div>
            <Helmet>
                <title>机械与大革命 Machinery And Revolution</title>
            </Helmet>
            <h6 className="App-essay-title">机械与大革命</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 五月</b>
                <br/><br/>
                1779年，英国莱斯特地区，一个叫卢德的织布工人砸毁了几台织布机，因为这些机器抢走了他的饭碗，织布工人辛苦数年才掌握的纺织技术被一种叫织布机的机器和无技术的工人所取代。卢德反抗机械竞争的行为是如此戏剧化，以至于这件事在几十年后成了“卢德分子”——反对机械取代技术人力的信徒们教科书级的模仿对象，捣毁机器的卢德运动在第一次工业革命进入深水区的英格兰地区广泛爆发。今天看来，破坏织布机颇有些指桑骂槐的味道，但当年的织布机作为一种新型机械，技术工人对其恐惧和厌恶完全可以理解，虽然他们内心可能很清楚真正应该怪罪谁。但依然，砸毁不能还手的机器是一种充满了“艺术化”的抗争表达，颇有种英式幽默。
                <br/><br/>
                自从人类开始直立行走，被解放的双手就一刻不停地在制造机械，或者工具。但避免使用工具这个词，是因为工具隐含了主从关系，就好像作为一种工具，机械是我们虔诚的仆人，我们可以完全掌控那些被发明制造出来的东西。但历史和现实都似乎在证明人们并不是这么想的。相反，我们好像时刻在担心机械会酝酿一场针对人类的大革命。
                <br/><br/>
                这种担忧有迹可循。第一次工业革命，手工业者磨砺多年、引以为豪的吃饭本领被机械剥夺。但那时候的机器呆头呆脑，看起来毫无智慧，但抢饭碗的本领着实让人生厌。随后的电气革命带来了各种魔法般的发明，电灯、电报、内燃机，信息和能量在无边无际的空气中蔓延，看不见摸不着但实实在在地取代了大量劳动岗位。于是，电磁波对健康极度有害的论调是那个时代经常谈起的话题；有趣的是，似乎到今天也没能消失。随后发生的事就让人惶恐了，机械先拥有了超越人类的计算能力，虽然依旧笨重粗糙，但很快就开始改头换面，先是体积不断缩小，接着又配备了各种图形系统和交互装置，甚至开始长得有点像人了。同时，其计算性能更是随着摩尔定律让人类愈发望尘莫及。机械先在1997年击败了人类国际象棋大师，而后在拥有了强劲的浮点运算芯片加持的神经网络后，“学习”正式成为了机械能力的一种，2016年AlphaGo击败了围棋九段李世石，让人类在智力上也输得哑口无言。近几年，主打人工智能“创造力”的产品层出不穷，似乎是这些高等机械在说，创造力这种玩意儿也不过是一系列的基本法则重复多遍的筛选结果罢了，毫不神奇。
                <br/><br/>
                照这么看，机械的大革命分为三步。首先，在体力效率上压倒人类。其次，在智力判断上战胜人类。最后，在创造发明上淘汰人类。影视作品通过这三个关键矛盾，把这种恐惧推向了极致。高等机械可以是2001太空漫游里的人工智能HAL 9000；也可以是银翼杀手里体能认知、甚至道德情操都高于人类的人造人；还可以是“我，机器人”里聪明机警、解放机器人大军的Sonny；还能是攻壳机动队和西部世界里不断形成自我意识的机械体。机械不仅体能卓越，美丽英俊，还亦正亦邪，比人类有魅力得多。
                <br/><br/>
                这些假设场景看起来惊悚自洽，然而真的是这样吗？影视作品的创造不用考虑路径问题，这种自由是艺术创作的特权。但如果要真正思考机械与大革命这个话题，发展路径是最需要拷问的内容，而这个内容的核心是：高等机械体是如何获取智慧的？
                <br/><br/>
                根据“奇点理论”，当人工智能——即高等机械的智慧超越人，那么人类社会就会大变样，机械开始翻身做主人，其后机械自发产生的创造是人完全无法理解的，最后这场机械对人类的大革命以人类被消灭而告终。在这个论述中，人和机械的关系是竞争敌对的。
                <br/><br/>
                如果相信这个奇点理论是真的，那么对有神论者，需要告诉他们人类作为被创造物很可能会超越造物主本身，也就是说：我们有可能终将比上帝更强。然后对相信进化论的无神论者，需要证明我们虽然和别的动物一样服从自然演化，但是人类太特殊了，虽然自然界尚未有别的动物发明过超越它们智慧的东西，但人类终究会做到这件事，因为我们是当今地球的霸主。好了，让我们还是依然假设这个奇点理论是真的吧。根据这个理论，越过了技术奇点，就像进入所有物理定律都会失效的黑洞一样，人工智能要做的是我们人类最狂野的想象都猜不到的，那么思考奇点之后的问题似乎没有太大意义，我们还是把注意力放在奇点发生前的时间里，做一点归纳总结。
                <br/><br/>
                回到现实，首先要明确奇点还没到来，缺少这个共识则后面的论证毫无价值。同时要承认，今天大量的人工智能还处于“人工智障”的状态，所以思考高等机械如何通往奇点是一个切中要害的问题。但在回答路径问题前，还要再考察一个基本问题：人类和机械的关系是什么？惯常态度有两种，一种是人类作为机械的主人，我们可以创造、改进、甚至销毁机械体，人主宰着机械；另一种是机械是人类的主人，人类进化的历史是被机械不断异化的历史，人在被机械驯化。首先需要批判的就是这种绝对二分的态度，机械和人类除了成为主人和奴隶外，还有更恰当的第三种状态——机械是人类的延伸，是一种人体装置。
                <br/><br/>
                有一张图片描述移动互联网时代，每个人的手都消失了，手掌的位置是一个iPhone，这张图是人对手机恐惧化的表达，不过并不影响从积极的角度发现这个场景和另一个场景的内在联系。在西班牙的阿尔塔米拉山洞里，旧石器时代的人类描绘了多头栩栩如生的野牛，在这些野牛旁有几个手掌轮廓的图形，大概是那些远古画家的签名。在缺乏工具的时代，那些远古艺术家的手掌似乎有种强烈的欲望，想作为签名机械牢牢地在石头上留下自己的印记。如同今天的手机，作为一切信息的枢纽，总想牢牢地长在人体末端一样。如同手掌，手机变成了人体装置的一部分，机械成了人体的延伸。相似的，筷子作为一种简单的杠杆机械，就是手指的延伸——防烫伤、保持卫生、延展长度。
                <br/><br/>
                电影钢铁侠里面有一段Tony
                Stark在国会对峙的场景，国会议员列举了多个理由阐述为何钢铁侠的盔甲是一种武器应该被国家没收，而Tony只嬉皮笑脸地回答，这套钢铁盔甲是我的高科技假肢，你总不能没收我吧。在某种程度上，机械和人类的关系如同钢铁和钢铁侠的关系。人注定要和机械一同演化。
                <br/><br/>
                有人会反驳，如果这些高等机械开始拥有自我意识并对人类怀有恶意，那怎么会是人类的延伸？其实这个思维就跳过了前面那个未被回答的重要问题：作为一种精密复杂的高等机械，人工智能是通过什么方式获得了意识和控制权的？如果你仔细思考这个过程，就会发现机械的每一丝智慧都是人类给的，并不存在“机器中的鬼魂”。而当人给机械赋予智慧的时候，把人类固有的社会缺陷和奴役同类的倾向也赋予了人工智能；作为一种高级控制机械，它们变成了人类社会最诚实的延伸。如果机械获取了伤害和奴役人的意志手段，那么获取这种“意志”和“手段”的原动力也是人类自身给的。我们对高等机械的恐惧倒不如说是我们在恐惧自己的固有缺陷被高等机械加强和放大，直到最后吞噬了自我。
                <br/><br/>
                如同人的手掌和人体有共生关系，复杂机械和人类社会也是共生的复合体，缺陷的高等机械只会长在缺陷的人类社会机体上。如今大量的科技反思都把矛头直指人工智能，科技巨头们更是“警告”大家警惕人工智能，这种混淆视听的论调隐去了幕后真正的疑问：到底是谁赋予了高等机械窥探隐私、剥削劳动的“权利”和“智慧”？大众相信了这种障眼法，就会做出和卢德一样愤怒地砸毁织布机的事。与其责怪机械，不如对着镜子审视人类自己。如果有一天人工智能统治了我们，那也只是人类固有的霸权和不平等通过机械装置的触角蔓延生长的后果。人才是这场困境的基础，机械只是背负骂名的上层建筑。机械不是主人也不是仆人，机械没有酝酿大革命，机械是人类的延伸。
                <br/><br/>
            </p>

            <br/>

            <h6 className="App-essay-title">Machinery And Revolution</h6>
            <p className="App-essay-article">
                <br/>
                <b>May 2022</b>
                <br/><br/>
                In 1779 Leicester, England, a textile worker named Luddite smashed several weaving machines—the spinney
                jenny called, for this machine took away his livelihood. The craftsmanship that textile workers spent
                years obtaining turned into nothing but a vain when that piece of machinery operated by unskilled labor
                completely replaced them and better. The smashing was so dramatic that several decades later, groups of
                followers—people who are against machines replacing skilled workers—called <i>Luddites</i> by historians,
                destroyed many weaving frames at places in England where the First Industrial Revolution was advancing
                at full speed. Although smashing some weaving frames looked silly and off the point, the machines could
                seem quite repelling and frightening to textile workers when they first appeared. Maybe the textile
                workers knew who to blame. Still, smashing a piece of machinery that was not capable of self-defending
                to give vent to was just way too artistic a protesting demonstration, a bit of English humor even.
                <br/><br/>
                The two freed-up hands had never stopped making machinery or tools since humans walked upright. But
                avoiding using the word tool is because it implies a master-slave relationship. Tools make it seem like
                we humans have mastered every piece of the machinery when creating and making them. But the history and
                the reality told otherwise. On the contrary, we humans are somewhat paranoid about the machinery
                plotting its revolution against us.
                <br/><br/>
                The concern could be justifiable in multiple ways. During the First Industrial Revolution, many artisans
                like Luddites lost their jobs and were deprived of their proudly gained craftsmanship to machines. At
                that time, the machinery looked entirely wooden—and hardly any trace of intelligence. But they were
                pretty good at taking away humans' jobs. Then the Second Industrial Revolution brought inventions no
                less than magics—telephones, telegrams, and internal combustion engines—information and energy
                transmitting through void space, formless but replacing considerable human labor without mercy.
                Therefore, an argument that electromagnetic wave was fatal to the human body was rife in that age, and
                interestingly, it does not stop today. What happened next was a bit terrifying. At first, the machine
                gained a computing power superb to humans, though it still looked bulky and clumsy. Yet, it changed its
                outfit very fast, its size continuously shrunk, and then it was equipped with a graphic and interactive
                interface and even began to look like a human. Meanwhile, the machine's computing capacity, powered by
                Moore's Law, outgunned human's. The machine beat the human chess grandmaster Garry Kimovich Kasparov in
                1997. Then armed with a more powerful floating-point unit and neural network, machine learning became an
                average ability of machinery. In 2016, AlphaGo beat Go's Nine Dan Master Lee Sedol, dwarfing the human's
                intelligence. In recent years, creation-driven AI products stuffed on Internet, almost like the
                machinery speaking aggressively, that creation is no more than some repetitive computation and selection
                algorithms, nothing special!
                <br/><br/>
                Based on the narratives, the revolution of the machinery takes three steps—first, they overcome humans
                in physical capability—then surpass humans in computing efficiency—finally, the machines master the
                secrecy of creativity and replace humanity. Movie makers knew the psychology so well that they took
                advantage of the deep terror and pushed it to the extreme. The intelligent machines are HAL 9000, the
                dark AI in <i>2001: A Space Odyssey</i>. They are the faster, stronger, morally superior cyborgs in <i>Blade Runner</i>.
                They are the sharp-witted Robot Sonny who liberated a robotic army in <i>I, Robot</i>. They are the
                synthetic life that formed self-consciousness in <i>Ghost in the Shell</i> and <i>West World</i>. They are not only
                beautiful and physically superior but know good and evil and are a lot more charming than us.
                <br/><br/>
                The situation looks creepy but plausible. Is that going to be real? Movies are the creative arts that
                enjoy the privilege of being exempted from questioning the How. However, to challenge the myth of
                machinery and revolution, the trajectory of How is the key. To interrogate that question, how machinery
                acquires intelligence takes a central spot.
                <br/><br/>
                According to the <i>Singularity Theory</i>, when the intelligence of machines surpasses humans, the machinery
                will take over the human's position as the master. The self-sustained evolution of the machinery would
                be beyond the craziest imagination of humans after that. Eventually, society ends when machines replace
                humans. The relationship between humans and machines here is rivalry and hostility.
                <br/><br/>
                Believing in this theory, inference will be drawn to theists that as God-made creatures, we are
                inevitable to surpass our creator—humans are very likely to exceed God in the future. And to those
                atheists, though non-known animals in nature crafted a tool that became better than themselves, we
                humans are so different and are meant to be surpassed by our own making, as we are the dominant species
                on this planet. Fine, let's assume this singularity theory holds. Beyond the singularity, it is like
                the law of physics loses validity, and what artificial intelligence would do to suit itself very much
                transcend humans' wildest imagination. Then, to predict what would happen is meaningless. Let's focus on
                what could happen before the singularity to stay relevant.
                <br/><br/>
                Back to reality, first, we must agree that singularity is not here yet. Without this consensus, the
                following discussion loses all its stand. Meanwhile, we must admit that most artificial intelligence
                today is artificial non-intelligence, so to question how artificial intelligence achieves singularity is
                a relevant query. But besides the path to singularity, there is another cornerstone myth—what is the
                relationship between machinery and humanity? A popular view is that humans own the machine, as we can
                create, refine, and dispose of them, indicating that we are the master of machinery. The opposite
                opinion is that machinery masters humanity, as we are tamed and alienated by the machines when we try to
                live with them. First, it is this binary opposition that we must criticize. Apart from the slave-master
                point of view, the machinery could be the third form—an extension of humanity, a human apparatus.
                <br/><br/>
                There is a modern image depicting the age of mobile Internet where everyone's hand is replaced by an
                iPhone, a fearsome expression of humans' deep terror. But it should not overshadow a positive view to
                interpret it. In the Cave of Altamira in the Paleolithic Age, homo sapiens left paintings of bison in
                the chamber with hand marks alongside the graphics—possibly the artists' signature. In the age without
                enough tools, the human hands served as a tool to print signatures on the stone, much like modern
                smartphones—the hub of information—sticking to the end of limbs. Like hands, the phone becomes a human
                apparatus, and the machinery becomes an extension of humanity. Similarly, chopsticks—a piece of simple
                lever machinery—are an extension of fingers, protecting from heat, keeping hygienic, and extending the
                length.
                <br/><br/>
                In the Movie <i>Iron Man</i>, there was a scene where Tony Stark was confronted in the Congress. The
                congressman elaborated that the iron suites were <i>arms</i> (think about the word) and thus had to be
                confiscated by the government. Tony smirked and said that the iron armor was a high-tech
                prosthesis—instead of arms—that could only be taken over if the government wanted to confiscate him. To
                an extent, the relationship between humans and machines is like iron and iron man—we are meant to evolve
                with machinery.
                <br/><br/>
                Some might refute that if intelligent machines grow self-consciousness and stand hostile against
                humanity, how could that be the extension of the human body then? This hypothesis skipped the crucial
                unanswered question: As a sophisticated piece of machinery, how does artificial intelligence acquire
                self-consciousness and the power of control? Thinking deeply and carefully, you will discover that every
                bit of the machine's intelligence is empowered by humanity, and there is no ghost in the machine—what
                wishful thinking! And like the time when humans empower machines with intelligence, we give our
                intrinsic inequality and the tendency to enslave the very same kind of ourselves to those machines. As a
                controlling arm on humanity, this machinery becomes the most natural extension of humanity. And if the
                machines procure the will and the means to hurt humans, the will and means would come from humanity
                itself. The fear we hold towards machinery would more likely be the deep horror that our defects
                amplified by the machines would engulf us in the end.
                <br/><br/>
                Like a symbiotic relationship between hands and humans, complex machinery has a symbiotic relationship
                with humanity, and defective machinery must grow in a flawed human society. Today, countless
                technological reflections point the gun to the technology itself, with tech tycoons warning the mass to
                be careful about artificial intelligence. The confusing argument hides a crucial interrogation about the
                core question: who, precisely, empowers the machinery to prey on privacy and exploit the labor of the
                mass? When people begin to believe the argument, they will destroy the intelligent machines instantly as
                Luddites on the pixel level. Instead of blaming the machines, we should look into the mirror. If
                artificial intelligence controls us one day, it would be the inherent supremacy and inequality within
                humans that sprawls without boundaries. Humanity is the core of this dilemma, not the machines, and the
                machinery is only a scapegoat for the failure. Machinery is NOT a servant; machinery is NOT planning a
                coup against humans; machinery is humanity's extension.
            </p>
        </div>
    )
}
