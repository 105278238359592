import React from "react";
import {Helmet} from "react-helmet";

export default function ArchitectAndHacker() {
    return (
        <div>
            <Helmet>
                <title>建筑师与黑客 Architect And Hacker</title>
            </Helmet>
            <h6 className="App-essay-title">建筑师与黑客</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二二 八月</b>
                <br/><br/>
                <b>猫群</b>
                <br/><br/>
                建筑师像一只爱好过于广泛的猫，喜爱图形、影象、空间、表达、模型、工具、机械……有些甚至喜欢冲击钻、混凝土、还有各类破坏性的电动工具，他们大多很左翼，所以还有不少也喜欢键盘。把建筑师的这些特征做一些小改动，你会看到黑客。无论现实有多么惨淡，书籍和电视剧里的建筑师大多都很自由也讨厌服从，这个职业从未停止对独立的要求。事实上，这两类人可能是心底里最厌恶服从的群体，他们中最激进的一批人经常在系统性地实践不服从。
                <br/><br/>
                为什么GitHub的图标是一只猫？我之前的解释是不少程序员都是重度二次元，一只可爱的猫科动物在风格上与这种审美吻合。直到我听到了一个更合理的解释，才明白GitHub用猫做图标有多恰当。这句话是：管理一群有创造力的黑客，就像你要放牧的对象是一群猫。
                <br/><br/>
                根据你对猫的了解，想象下这个不协调的场景：你希望像驱赶羊群一样驱赶猫群，它们绝不会听你号令的，它们不想理解也拒绝配合。曾经有个实验，需要猫对一堆圆点的数量做出判断，猫会莫名其妙地关注圆点的大小而不是圆点的多少，而这个数量判断的实验连鱼都能通过！这下你开始怀疑，这可能不是猫的智力问题——如果真不是的话——那就是它们从一开始就拒绝服从。猫的散漫本性也导致它们很难像狗一样被驯化，所以也基本没有经济价值（会吃老鼠的除外，但这也越来越少了）。但这种性格上的不服从，反而让猫从古埃及开始就被追捧，还被画在壁画上；在两宋的江南地区，国人养猫蔚然成风，猫经济比之今天都不逊色；而在新冠期间，猫在世界范围内成了最广泛被供养的“心灵安慰物种”之一。所以，很多看似和主流格格不入的反叛，很可能是通往藏在无用之下的“大用”。
                <br/><br/>
                <b>难以服从</b>
                <br/><br/>
                如果十年前有朋友因为电视剧《奋斗》的鼓舞去读建筑系，那上个世纪的西方建筑师们很可能是受到安·兰德那本《源泉》的蛊惑。源泉的主人公Howard Roark是一个建筑师，才华横溢、冰冷傲慢，还有些许反社会气质。就像《广告狂人》里主角和他那句：我们是创作人员，天塌了我们也只管创作。
                <br/><br/>
                建筑师的不服从常发生在自由创作权受威胁时，这也是现实的绝大多数情况，但更重要的原因，是不服从带来的怀疑常常是创作的灵丹妙药，比如这个例子。一个客户带着一张纸，上面画着一块木板和四条腿，然后和建筑师说，我要这么一张桌子。那么建筑师可能心里已经被激怒了，但依然会礼貌地问：你需要这个（指了指图片），还是一张桌子？如果客户说：我想要图片里这样的桌子。那建筑师会再问一遍：你需要这个？还是一张桌子？直到客户明确做出选择。如果客户选了前者，那很可能就被炒了（假设这个建筑师有极大的自由）。但如果客户说：我需要一张桌子。这时候，不服从就会展现出强大的创造力。好，既然你要一张桌子，那么创作起点就是怀疑“木板上安四条腿”的设定从一开始就是错的。你需要的那张桌子，或这个叫桌子的东西——是指一个能提供载重的工作平面（或合理的曲面），那么要四条腿意义何在呢？于是，一张木板加四个脚就被解构得有些可笑。创作的起点经常是不服从，怀疑是这个过程的催化剂。当你强迫建筑师服从，他们就没有了创作激情和灵感。
                <br/><br/>
                <b>以我为主</b>
                <br/><br/>
                当一个自由黑客去设计一个开发工具或软件时，ta考虑的第一件事会是：这个东西老板喜欢吗？应该不会。这些人在乎的第一件事会是：这东西够不够有趣？甚至有没有用都要排在第二位。这种想法几乎是自由建筑师的翻版，在理想的创作环境下，建筑师很可能会先问：这个想法好玩吗？然后才是：这玩意怎么把功能塞进去？
                <br/><br/>
                1989年12月，在家过圣诞节的Guido van Rossum闲得冒烟，就把过去一直想做的事情拿出来打发时间。他想创作一个编程语言，可以把动态类型、函数式编程、垃圾回收、支持OOD等等好处都揉进去，做一个好用且强大的语言。项目开始后一年多，Python的初版诞生了，Python的创造就源于这么一个无聊的圣诞节。当然，他对这个语言的准备长达数年，但没人要求他一定要这么做。
                <br/><br/>
                Linus写LINUX内核的时候，也不是因为可以给某个工作交差，而是因为闭源的Unix吸引了一大帮人想要突破它对操作系统的霸权，而这帮自由分子还没人真正把开源的内核写出来。1991年，22岁的Linus需要一个操作系统，但他没钱买Unix，所以他自己写了一个基本可用的操作系统，然后分享给朋友和同事，叫Linus’s MINIX，他写内核的时候根本没意识到干这件事会产生如此深远的影响。这个自发的激情创作基本和python的诞生过程一样。
                <br/><br/>
                需要承认这些都不是随便发生的小创作，这些开发者和文艺复兴的艺术巨匠一样才华横溢。但这些极具价值的软件和工具，往往都不在黑客的正经工作列表里。能造福大多数人的设计也常在人迹罕至的小径上。正统会选择那条不出错的路走，而忽略最有价值的创造似乎是主流权威的路径依赖。那些另辟蹊径的人往往不知道自己干了什么，但他们会被一种莫名的冲动吸引去做了一件事，而因为充分的训练和良好的直觉告诉他们这件事很有趣，最后事实也证明了其中某些创作成果非常重要。
                <br/><br/>
                在建筑师与黑客的创作故事里，你永远找不到他们站在被动服从的角色上。
                <br/><br/>
                <b>卢浮宫前的金字塔与iPhone键盘</b>
                <br/><br/>
                1983年，贝聿铭接受法国总统密特朗之邀重建卢浮宫的广场入口。假设你是贝聿铭，你会如何设计让那群自由泛滥的法国人民支持？当钻到迎合民意的道路上，法国人会让你非常痛苦。锐利简洁的几何形体、干净的材质和现代性叙事，卢浮宫的玻璃金字塔非常贝聿铭。但这个设计中依然尽力地迎合了法国民众。1798年拿破仑远征埃及，收集了大量埃及文物，几十年后埃及把自己的方尖碑送给了法国（感激法国破译了埃及古文字）。那是法国人最接近统治欧洲的一段时间。用金字塔的形状做卢浮宫入口算挑了法国人最“自豪”的历史记忆。但即使这样，这个玻璃金字塔的设计，依然被巴黎人经年累月的批判，在电影《达·芬奇密码》里还被讽刺为“巴黎脸上的一道疤。”对于一个成千上万人都会看到的地标符号，你无论怎么创作都无法避免要承担非议。
                <br/><br/>
                2007年，苹果的软件工程师Ken Kocienda设计开发了第一款iPhone的触控键盘。这里不展开详细陈述这个耶鲁历史系毕业的老炮怎么毕业后先去玩机车，搞摄影，最后又变成一个程序员的，但他给iPhone开发的第一个键盘系统，可能是移动互联网时代最重要的发明之一。开发好了键盘，他自觉有理地给这个系统增加了一个自动纠错的功能。这下可好，用户因为这个自动纠错闹出来不少笑话，也成了被嘲讽最多且被玩梗玩出花样的产品之一。但Ken总在玻璃屏上打错字，他觉得这个功能很有用且必须加上。但这个产品被成万上亿人使用，受责骂是免不了的。计算机行业里，软硬件的工程都被称作架构Architecture（建筑），设计架构的主工程师被称作架构师Architect（建筑师）。因为创造是相通的，创造者最大的气质之一就是不轻易服从。Architect的另一个含义是造物主。他们会认真聆听用户的需求，但让用户来教他们事情该怎么做？抱歉，想都别想！作为最伟大的产品经理之一，乔布斯说：“我记得亨利·福特曾经说：‘如果我问我的顾客想要什么，他们一定会说：我要一匹更快的马！’人们根本不知道自己想要什么，直到你把这个东西做出来给他们看。”
                <br/><br/>
                这两个例子只是说明，那些能影响到普罗大众的作品，哪怕创作者选择低头接受批判、投降服从也没有太大用，总有人不满意且会口出妄言。所以，如果谄媚迎合不能带来更多人满意，甚至还会危害创作水准，那么拒绝服从反而可能是更高效的策略。
                <br/><br/>
                <b>谦卑的自负</b>
                <br/><br/>
                这两类人大概率都是社会主义者，支持平权，关注弱势，而且表现得也非常谦逊。但其中最成功的那群，内里一定有相当的强硬和自负，还要时不时出来透透气。这也可以理解，如果一颗心毫无防备又温和顺从，在受到非议后会很难坚持己见，现实中总有各种因素让选择听话变成一种更舒服的活法。他们还有一个共性，所创造的东西会被千千万万人使用，最精心的决策也只是最基本的要求，所以必须让自己信服。他们服务的对象是一个人也是千万个人，很普通也很特别，面目模糊也面容清晰。
                <br/><br/>
                有些建筑师甚至喜欢“恶名在外”，因为没有“恶名”就容易被甲方玩弄于股掌之中，被品味庸俗的群众和流行文化伤害。没有坚定的表达和有力的论述，一个开源社区的领袖也容易被一群拒绝配合的猫玩得气若游丝。但如果这个人的声音足够大、足够坚定、足够正确、且最重要的——足够合理有说服力。那么上述的问题就都解决了，甚至ta想关注圆点大小而不是数量都可以被认为一种个人风格——Not a bug, but a feature. 如果在过程中他们得罪了大集团，那只会让这件事更有趣、让他们更开心。比如达·芬奇故意给教堂画一些宗教不正确的圣母像，那才是达·芬奇的心头好，不服从的癖好是他干活的原动力。但能做到这点，不仅要靠个人魅力，还要看历史进程。如果往回倒退两百年，达·芬奇乱画圣母早就被烧死了。
                <br/><br/>
                <b>挑战者</b>
                <br/><br/>
                独立建筑师、自由黑客、还有很多爱写宣言的科学家和思想家，这些对怀疑上瘾，也真诚地尊重各种细节的怪物们，已经远超出标题限定的两类了，他们有个共同的名字，叫挑战者。如果恰巧这个挑战者才华绝伦，也受历史眷顾，那么很容易在命运的关怀下，连同那只盯着圆点大小看的猫一起，以出乎意料的方式变成一段历史佳话。但如果历史不客气，强迫要求他们服从，其中有些人宁愿死也不想配合。
                <br/><br/>
                我不是天才，所以我拥有普通人旁观的特权。但如果你是个挑战者，自觉才华横溢但非常痛苦，那很可能这就是原因——创造是有风险的，不服从也有后果。如果不得志，也别太过自责，杜甫也说：“志士幽人莫怨嗟，古来材大难为用。”

            </p>

            <br/>

            <h6 className="App-essay-title">Architect And Hacker</h6>
            <p className="App-essay-article">
                <br/>
                <b>Aug 2022</b>
                <br/><br/>


            </p>
        </div>)
}
