import React from "react";
import {Helmet} from "react-helmet";

export default function Words() {
    return (
        <div>
            <Helmet>
                <title>言语 Words</title>
            </Helmet>
            <h6 className="App-essay-title">言语</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 八月</b>
                <br/><br/>
                文字能施展的魔法，文字也能把它们都毁掉。喝醉了就没有人在说话，是酒精在说话。精心编排的话术能蛊惑人心，但因为太过精巧，常常易被觉察。复杂深邃的言语会引起误会，往往弄巧成拙。
                <br/><br/>
                没错，阿西莫夫说，比核武器更危险的是语言。

            </p>

            <br/>

            <h6 className="App-essay-title">Words</h6>
            <p className="App-essay-article">
                <br/>
                <b>Aug 2023</b>
                <br/><br/>

            </p>
        </div>)
}
