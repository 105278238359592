import React from "react";
import {Helmet} from "react-helmet";

export default function OneProblemOnce() {
    return (
        <div>
            <Helmet>
                <title>一个问题只应被解决一次 A Problem Should Only Be Solved Once</title>
            </Helmet>
            <h6 className="App-essay-title">一个问题只应被解决一次</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 七月</b>
                <br/><br/>
                <ul>
                    <li>如果一个问题只允许被解决一次，那么此问题在第一次被解决时就需要全神贯注</li>
                    <li>一个函数如果只能定义一次，那第一次就是最后一次</li>
                    <li>喜欢一个人，此刻就行动，事情会提早变成该有的样子</li>
                    <li>讨厌一件事，停止也只需一次，就是此刻</li>
                    <li>需要做决定，现在就决策，坏决策胜过不决策</li>
                    <li>拒绝重复，保持专注，现在行动</li>
                </ul>

            </p>

            <br/>

            <h6 className="App-essay-title">A Problem Should Only Be Solved Once</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jul 2024</b>
                <br/><br/>

            </p>
        </div>)
}
