import React from "react";
import {Helmet} from "react-helmet";

export default function Instinct() {
    return (
        <div>
            <Helmet>
                <title>直觉 Instinct</title>
            </Helmet>
            <h6 className="App-essay-title">直觉</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 二月</b>
                <br/><br/>
                如果你想了解关于直觉和逻辑的深入讨论，那诺贝尔经济学奖得主丹尼尔·卡尼曼（Daniel Kahneman）在《思考，快与慢》里的见解要深入精辟的多。我还没有资格充分讨论这个关于直觉的神秘话题，这里只是开个头。
                <br/><br/>
                有自我感受的生物都会一定程度地服从直觉，并不是它们天生崇拜直觉，而是它们无法摆脱，它们的处境更像是囚徒而非主宰。逻辑可以被训练和控制，但直觉几乎不能被训练和控制，天性是什么样就是什么样。被直觉困扰甚至支配，是直觉存在的形式，如果可以完全摆脱直觉，那这个过程更像是感觉而非直觉。生物对直觉能做的是顺从或引导，它们很难通过反抗彻底摆脱直觉。
                <br/><br/>
                但这并不说明被直觉控制的动物就一定充满了非理性，实际上可能多数时候这个动物都很理智。不过一旦来到直觉支配的竞技场，掌控生物的直觉就经常显露出横冲直撞的真面目，这个时候生物无法自控，直觉支配了它。
                <br/><br/>
                书籍电影和报纸里充满了关于直觉如何准确的“证据”，但这种准确应该被质疑。因为很多人相信的直觉准确，只是这个人在精心搜索证据的过程里强行自我验证罢了，很可能只是心理暗示被过度挖掘的结果。所以在直觉和非直觉（分析性的逻辑思考）能被严格区分之前，直觉是否准确是不好被精确测量的。
                <br/><br/>
                虽然不好精确测量人的直觉对决策效果的影响，但可以猜想，直觉对一个人在好事和坏事上能做的贡献同等大。比如，一个人坚持使用原始动物的直觉，生活在现代社会里，这些直觉经常有反效果，错配的直觉有消极影响。此外，服从直觉有种自我强化的效用，通往美好芳草地的直觉可以在自我强化中让人更快地到达那个地方；但如果某个直觉能引起毁灭性的结果，那么这个直觉在加速毁灭的道路上也可能有同样的功效。直觉不能保证决策的好坏，因为直觉不是以目的为目的，直觉以存在为目的。
                <br/><br/>
                所以听任直觉支配，会让人们在无法预测决策好坏的情况下迷信直觉的神秘天性，并过度关注它起作用的瞬间，而放松对整体事实和依据的判断。即使直觉能反复击败逻辑，也并不能证明直觉是对的，想想那些引起视觉悖论的图案，事实一再被你的眼睛蒙蔽，而你毫无办法。
                <br/><br/>
                但服从某些直觉依然对人有用，因为理智只是人类过去的知识积累和社会法则的固化，这些固化有其自我逻辑，但并不一定与人的天性严丝合缝。而直觉是通往混乱和未来的幽暗小径，直觉是对天性的探索，历史上进步性的突破经常被直觉唤起。所以某些时候，如果所有严密的逻辑都没能打消某些反复出现的直觉，那或许是时候再检查一下逻辑假设的真伪了。
                <br/><br/>
                我们像坐在古罗马斗兽场的现代观众，看着场下奴隶和野兽厮杀。你可能支持野兽，你也可能支持人，成败胜负的概率和观众狂热的呼声息息相关，但呼声也不能完全左右这场血腥比赛的结局。这个过程对现代观众很不友好，不仅因为粗野的场面让文明人难以忍受，更因为胜负的决定权依然不在我们手里，而我们时常以为自己手握着生杀大权。

            </p>

            <br/>

            <h6 className="App-essay-title">Instinct</h6>
            <p className="App-essay-article">
                <br/>
                <b>Feb 2023</b>
                <br/><br/>

            </p>
        </div>)
}
